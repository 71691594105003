import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
 

export interface iFestivos {
    id: number,
    fechaInicio: iDate,
    fechaFin: iDate,
}
export class iFestivos extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFestivos> {
        let iFestivosCollection: Array<iFestivos> = [];
        ob.forEach((element: any) => {
            iFestivosCollection.push(this.convertFromObject(element));
        });
        return iFestivosCollection;
    }

    static convertFromObject(ob: any): iFestivos {
        let iFestivosObj = new iFestivos();
        iFestivosObj.id = ob.id;
        iFestivosObj.fechaInicio = iDate.phpConvert(ob.fechaInicio);
        iFestivosObj.fechaFin = iDate.phpConvert(ob.fechaFin);
        return iFestivosObj;
    }
}