import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Observable, takeUntil } from 'rxjs';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { EditPartesPopupComponent } from '../EditPopup/EditPartesPopup/EditPartesPopup.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import PdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import { EditarParteMovilComponent } from '../EditPopup/EditarParteMovil/EditarParteMovil.component';
pdfMake.vfs = PdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-DescargaPartePopup',
  templateUrl: './DescargaPartePopup.component.html',
  styleUrls: ['./DescargaPartePopup.component.scss'],
})
export class DescargaPartePopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // BINDEOS

  nombre!: string;
  fecha!: string;
  nObra!: string;
  horario!: string;
  vehiculo!: string;
  conductor!: string;
  imagenes: Array<any> = [];
  estado!: string;
  concepto!: string;
  ubicacion!: string;
  comentarios!: string;

  tipo: null | string = null;

  // JSPDF

  doc = new jsPDF('p', 'pt', 'a4');

  constructor(
    private parteSE: ParteService,
    private popup: PopupService,
    public dialogRef: MatDialogRef<DescargaPartePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getParteId(this.modalData.id);
  }

  /**
   * EDIT
   */

  createPDF() {
    window.print();
  }

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  editParte() {
    if (this.modalData.tipo == 'movil') {
      this.popup.openPopup(EditarParteMovilComponent, {
        id: this.modalData.id,
      });
    } else {
      this.popup.openPopup(EditPartesPopupComponent, { id: this.modalData.id });
    }
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.getParteId(this.modalData.id);
        this._unsubInd9.next('');
      });
  }
  /**
   * SETTER
   */

  setter(data: any) {
    this.nombre = `${data.usuario.nombre} ${data.usuario.apellidos}`;
    this.fecha = iDate
      .javascriptConvert(new Date(data.fecha))
      .toStringDate('EU');
    this.nObra = data.obra.numero;
    this.horario =
      `${data.horaInicio.slice(11, 16)}` + ' - ' + data.horaFin.slice(11, 16);
    data.vehiculo != null && data.vehiculo != undefined
      ? (this.vehiculo = data.vehiculo.nombre)
      : (this.vehiculo = 'Sin vehiculo asignado');
    data.vehiculo != null && data.vehiculo != undefined
      ? (this.conductor =
          data.vehiculo.usuario.nombre + ' ' + data.vehiculo.usuario.apellidos)
      : (this.conductor = 'Sin vehiculo asignado');
    this.imagenes = data.imagenParte;
    this.estado = data.estado.toUpperCase();
    this.concepto = data.obra.concepto;
    this.ubicacion = data.obra.ubicacion;
    this.comentarios = data.comentario;
    this.tipo = this.modalData.tipo;
  }

  /**
   * DENY PARTE
   */

  changeEstadoParte(estado: string) {
    this.parteSE.update({ id: this.modalData.id, estado: estado });
    this.parteSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El estado del parte ha sido cambiado'
        );
        this.ngOnInit();
        this.closePopup('refresh');
        this._unsubInd2.next('');
      });
    this.parteSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getParteId(id: number) {
    this.parteSE.getSingle(id);
    this.parteSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        this.setter(data);
        this._unsubInd.next('');
      });
  }
}
