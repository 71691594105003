import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, NotificationsService, iOptionsSelector, } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoParteComponentComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoParteComponent/NuevoParteComponent.component';
import { DescargaPartePopupComponent } from 'src/app/Popups/DescargaPartePopup/DescargaPartePopup.component';
import { EditPartesPopupComponent } from 'src/app/Popups/EditPopup/EditPartesPopup/EditPartesPopup.component';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { UserService } from 'src/app/Services/Api/User.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ObraDetaiParteObra',
  templateUrl: './ObraDetaiParteObra.component.html',
  styleUrls: ['./ObraDetaiParteObra.component.scss']
})
export class ObraDetaiParteObraComponent extends iUnsubscribeDestroy implements OnInit {

  @Input('idObra') idObra: string = '';
  @Input('dataObra') dataObra: any = null;
  @Output() refreshData = new EventEmitter<boolean>();

  isLoadingTabla: boolean = false;
  id = this.activatedRoute.snapshot.paramMap.get('id')


  dataPartesTabla: any[] = []

  // Filtros
  filtro: any = {
    desde: "",
    hasta: "",
    estado: "validado",
    redactor: "",
    busqueda: "",
  }



  displayedColumnsPartes: Array<iDataTableColumns> = [
    { label: 'Fecha', property: 'fecha', },
    // { label: 'Nº Obra', property: '', align: 'center' },
    // { label: 'Proyecto', property: '', align: 'center' },
    { label: 'Empleado', property: 'redactor', align: 'center' },
    { label: 'Tarea', property: 'tarea', align: 'center' },
    { label: 'Horario', property: 'horario', align: 'center' },
    { label: 'Vehiculo', property: 'vehiculo', align: 'center' },
    { label: 'Adjuntos', property: 'images', align: 'center' },
    { label: 'Estado', property: 'estado', align: 'center', classList: 'tableEstado tableEstadoObras' },
  ];

  optionsEstado:iOptionsSelector = {
    items:[
      {label:'Validado', id:0},
      {label:'Pendiente', id:1},
    ],
    clearable: true,
    placeholder: 'Estado'
  }

  optionsRedactor:iOptionsSelector = {
    items:[],
    clearable: true,
    placeholder: 'Redactor'
  }

  optionsPartes: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [{ callback: () => { this.exportexcel() }, icon: "download" }],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private userSE:UserService,private popup: PopupService, private activatedRoute: ActivatedRoute, private parteSE: ParteService, private notificationSE: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getPartePorObra()
    this.getEmpleados()
  }

  /**
   * NUEVO PARTE DE OBRA
   */

  nuvoParteObra() {
    this.popup.openPopup(NuevoParteComponentComponent, { obraId: this.id, tipo: 'nuevo' })
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return }
      this.getPartePorObra();
      this.refreshData.emit(true);
      this._unsubInd2.next("");
    })
  }

  /**
   * ELIMINAR PARTE DE OBRA
   */

  eliminarParteObra(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "Parte de obra" })
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return }
      this.refreshData.emit(true);
      this.deleteParte([event.id]);
      this._unsubInd.next("");
    })
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "Partes de obra" })
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return }
      this.refreshData.emit(true);
      this.deleteParte(event.map((parte: any) => parte.id));
      this._unsubInd.next("");
    })
  }

  deleteParte(id: any[]) {
    this.parteSE.delete(id);
    this.parteSE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return }
      this.refreshData.emit(true);
      this.notificationSE.showFeedBack("Parte de obra eliminado correctamente");
      this._unsubInd2.next("");
    })
    this.parteSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res === null) { return }
      this.notificationSE.showFeedBack("Error al eliminar el parte de obra");
      this._unsub.next("");
    })
  }

  /**
   * EDITAR PARTE DE OBRA
   */

  editarParteObra(event: any) {
    this.popup.openPopup(EditPartesPopupComponent, event);
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return }
      this.refreshData.emit(true);
      this._unsubInd2.next("");
    })
  }

  /**
   * Filtros
   */

  changeSelector(event: any, key: string,option:string) {
    if(!event){ this.filtro[key] = ""; this.getPartePorObra(); return}
    this.filtro[key] = event[option].toLowerCase();
    this.getPartePorObra()
  }

  // DatePicker

  dataFromPicker(event: any, key: string) {
    if(!event){ this.filtro[key] = ""; this.getPartePorObra(); return}
    this.filtro[key] = event.value;
    this.getPartePorObra()

  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.classList.contains('mat-checkbox-checked')
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'PartesReforma.xlsx');

  }

  abrirPopupDescarga(event: any) {
    this.popup.openPopup(DescargaPartePopupComponent, event, "fullSizePopup")
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null || res['dataReturn'] !== "refresh") { return }
      this.getPartePorObra();
      this._unsubInd2.next("");
    })
  }


  /**
   * API Calls
   */

  getPartePorObra() {
    let id = this.activatedRoute.snapshot.paramMap.get('id')
    this.isLoadingTabla = true

    this.parteSE.getAllTablaObras(id, this.filtro.busqueda, this.filtro.desde, this.filtro.hasta, 'validado', this.filtro.redactor);
    this.parteSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return }
      let { data } = res.data;
      this.dataPartesTabla = data.map((item: any) => {return{...item, horario: `${item.horaInicio?.split(' ')[1]} - ${item.horaFin?.split(' ')[1]}` }});

      this.isLoadingTabla = false
      this._unsubInd.next("");
    })

  }

  getEmpleados(){
    this.userSE.getAllEmpleadosReformaTabla(this.idObra);
    this.userSE.getResultAll().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return }
      let { empleados } = res.data;
      let mappedEmpleados = empleados.map((item: any) => {return{label:item.nombre, id: item.id}});
      this.optionsRedactor.items = mappedEmpleados
      this._unsubInd2.next("");
    })
  }


}
