import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoClientePopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoClientePopup/NuevoClientePopup.component';
import { EditClientePopupComponent } from 'src/app/Popups/EditPopup/EditClientePopup/EditClientePopup.component';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';

@Component({
  selector: 'app-AdminClientes',
  templateUrl: './AdminClientes.component.html',
  styleUrls: ['./AdminClientes.component.scss']
})
export class AdminClientesComponent extends iUnsubscribeDestroy implements OnInit {

  dataClientes: Array<any> = [];

  filterText = {
    placeholder: 'Busca por nombre de cliente',
    model: ''
  }
  forceUpdateWidth= new Subject()

    // Paginacion

    paginacionTotal!: number;
    paginacionActual!: number;
    numMaxPag!: number;
    numMin: number = 1;
    numMax: number = 20;
  
    pagina: number = 1;
    maximo: number = 20;

  // Selectores
  optionProyectos: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Proyectos',
    multiple: {
      isMultiple: false,
      checkbox: true
    },
    search: true
  }
  notificationSer: any;

  validateFormat(email: string) {
    if (/^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(email)) { return true; }
    return false;
  }

  // Filter
  filter = {
    proyecto:"",
    busqueda:""
  }

  //Tabla
  displayedColumnsClientes: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
    // { label: 'Proyectos asociados', property: 'obras' },
    // { label: 'Contacto', property: 'telefono' },
    // { label: 'Email', property: 'mail' },

  ];

  isLoadingTabla: boolean = true


  optionsClientes: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }

  constructor(private clientSe: ClienteService, private popup: PopupService, private obraSe: ObraService, private notificationSe: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getAllClients();
    this.getAllObras();
  }

  changeCliente(event: any) {
    if(event === null){this.filter["proyecto"] = ""; this.getAllClients(); return}
    this.filter["proyecto"] = event.label
      this.getAllClients()
 
  }

    /**
   * NEXT PAGE
   */

    nextPage() {
      if (this.numMax === this.paginacionTotal) { return }
      this.pagina++
      this.numMin = this.numMin + this.maximo
      this.numMax = (this.numMax + this.maximo < this.paginacionTotal) ? this.numMax + this.maximo : this.paginacionTotal
      this.getAllClients()
    }
  
    /**
     * PREVIOUS PAGE
    */
  
    previousPage() {
      if (this.numMin === 1) { return }
      this.numMin = this.numMin - this.maximo;
      this.numMax = (this.numMax - this.maximo < this.maximo) ? this.maximo : this.numMax - this.maximo
      this.pagina--
      this.getAllClients()
    }

  getAllClients(): void {
    this.clientSe.getAllTable(this.filter.busqueda);
    this.clientSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      let { data, total } = res.data;
      this.dataClientes = data;
      this.paginacionTotal = total
      this.numMaxPag = Math.ceil(total / this.maximo)
      if (total < this.maximo) this.numMax = total

      setTimeout(() => {
        this.forceUpdateWidth.next(true)
      }, 1);

      this.isLoadingTabla = false;
      this._unsubInd.next("")
    })
  }

  getAllObras(){
    this.obraSe.getAllTable()
    this.obraSe.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
    if(res === null){return}
    let {data} = res.data;
    let mappedObras = data.map((obra: any) => {
      return {label: `${obra.concepto} ${obra.ubicacion}`, value: obra.id}
    })
    this.optionProyectos.items = mappedObras
    })
    this._unsubInd2.next("")
  }

  editCliente(event: any): void {
    this.popup.openPopup(EditClientePopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.ngOnInit()
      this._unsubInd3
    })
  }

  OnclickDeleteButton(event: any): void {
    this.popup.openPopup(AsegurarDeletePopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      let idFromButton = [res.dataReturn.id]
      this.deleteClient(idFromButton)
      this._unsubInd2.next("")
    })
  }

  deleteClient(id: string[] | number[]): void {
    this.clientSe.delete(id);
    this.clientSe.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showFeedBack("El cliente ha sido borrado correctamente")
      this.ngOnInit();
      this._unsubInd4.next("")
    })
    this.clientSe.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      if (res.status === 500) {
        this.notificationSe.showError("Ha habido un problema con el servidor")
        this._unsub.next("")
        return
      }
      if (res.status === 422) {
        this.notificationSe.showError("El cliente no se pudo borrar porque tiene proyectos asociados")
        this._unsub.next("")
        return
      }
      this.notificationSe.showError(res.message)
      this._unsub.next("")
    })
  }

  /**
   * AÑADIR NUEVO CLIENTE
   */

  anadirNuevoCliente(event: any) {
    this.popup.openPopup(NuevoClientePopupComponent)
    this.popup.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(res => {
      if (res === null || res.dataReturn !== "submitted") { return; }
      this.ngOnInit()
      this._unsubInd5.next("")
    })


  }

  /**
   * BUSQUEDA
   * 
   */

  respuestaBusqueda(event: any) {
    this.filter["busqueda"] = event
    this.getAllClients()
  }
}
