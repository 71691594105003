<div class="customInputContainer">
  <div *ngIf="label != ''" class="labelContainer">
    <label>{{label}}</label>
    <ng-content select="[extraLabelContent]"></ng-content>
  </div>
  <input [maxlength]="maxlength" [placeholder]="placeholder" [ngClass]="classList" [ngModel]="model" [type]="_type" (ngModelChange)="onModelChange($event)" [readonly]="readonly" *ngIf="!readonly" #input >
  <input [maxlength]="maxlength" [placeholder]="placeholder" [ngClass]="classList" [ngModel]="model" [type]="_type" (ngModelChange)="onModelChange($event)" [readonly]="readonly" *ngIf="readonly" #input >
  <div class="eyePassword" *ngIf="type=='password'">
    <i class="material-icons" tabindex="0" (keyup.enter)="changeVisbility()" (click)="changeVisbility()">{{_type=='password'?'visibility':'visibility_off'}}</i>
  </div>
</div>