import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iObra, iObraCreate } from 'src/app/Shared/Interfaces/Api/iObra';
import { IObraTabla } from 'src/app/Shared/Interfaces/Api/iObraTabla';

@Injectable({
  providedIn: 'root',
})
export class ObraService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultImagenesObras = new BehaviorSubject<any>(null);
  getResultImagenesObras() {
    return this._resultImagenesObras;
  }
  clearResultImagenesObras() {
    this._resultImagenesObras.next(null);
  }
  protected _resultObrasSelector = new BehaviorSubject<any>(null);
  getResultObrasSelector() {
    return this._resultObrasSelector;
  }
  clearResultObrasSelector() {
    this._resultObrasSelector.next(null);
  }
  protected _resultObrasSelectorError = new BehaviorSubject<any>(null);
  getResultObrasSelectorError() {
    return this._resultObrasSelectorError;
  }
  clearResultObrasSelectorError() {
    this._resultObrasSelectorError.next(null);
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(this.urlApi + '/api/obra' + this.optionsGet(send), this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedObra = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iObra.convertFromCollection(normalizedObra),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSelectorObras() {
    this.http
      .get(this.urlApi + '/api/obras/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(this._resultObrasSelector, this.res);
        },
        error: (error: any) => {
          console.log(error);
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultObrasSelectorError,
            this.res,
            true,
            { method: this.getAll, args: [] }
          );
          this.checkStatusError(error);
        },
      });
  }

  getObraImages(id: number) {
    this.http
      .get(this.urlApi + '/api/imagenes/obra/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedImages =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedImages,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getObraImages,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllTable(
    pagina: number = 1,
    maximo: number = 50,
    busqueda: string = '',
    cliente: string | number = '',
    estado: string = '',
    concepto: string = ''
  ) {
    let send = {
      num_devoluciones: maximo,
      num_pagina: pagina,
      busqueda: busqueda,
      cliente: cliente,
      estado: estado,
      concepto: concepto,
    };
    this.http
      .get(
        `${this.urlApi}/api/table/obra/${this.optionsGet(send)}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedObra = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: IObraTabla.convertFromCollection(data.data),
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTable,
            args: [pagina, maximo, busqueda, cliente, estado, concepto],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllImages(idObra: number) {
    this.http
      .get(`${this.urlApi}/api/imagenes/obra/${idObra}`, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedObra = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedObra,
          });
          this.sendNextObservable(this._resultImagenesObras, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultImagenesObras, this.res, true, {
            method: this.getAllImages,
            args: [idObra],
          });
          this.checkStatusError(error);
        },
      });
  }
  getObrasUserId(
    userId: number,
    pagina: number = 1,
    maximo: number = 50,
    busqueda: string = '',
    concepto: string = '',
    estado: string = '',
    cliente: string = ''
  ) {
    let send = {
      num_devoluciones: maximo,
      num_pagina: pagina,
      busqueda: busqueda,
      cliente: cliente,
      estado: estado,
      concepto: concepto,
    };
    this.http
      .get(
        `${this.urlApi}/api/obra/user/tabla/${userId}${this.optionsGet(send)}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedObra = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedObra,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getObrasUserId,
            args: [userId, pagina, maximo, busqueda, concepto, estado, cliente],
          });
          this.checkStatusError(error);
        },
      });
  }

  getObrasUserIdTotal(
    userId: number,
    pagina: number = 1,
    maximo: number = 50,
    busqueda: string = '',
    concepto: string = '',
    estado: string = '',
    cliente: string = ''
  ) {
    let send = {
      num_devoluciones: maximo,
      num_pagina: pagina,
      busqueda: busqueda,
      cliente: cliente,
      estado: estado,
      concepto: concepto,
    };
    this.http
      .get(
        `${this.urlApi}/api/obra/user/tabla/total/${userId}${this.optionsGet(
          send
        )}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedObra = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedObra, total: data.total },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getObrasUserIdTotal,
            args: [userId, pagina, maximo, busqueda, concepto, estado, cliente],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http.get(this.urlApi + '/api/obra/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedObra = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: normalizedObra,
        });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultIndividualError', this.res, {
          method: this.getSingle,
          args: [id],
        });
        this.checkStatusError(error);
      },
    });
  }

  create(obra: iObraCreate) {
    this.http
      .post(this.urlApi + '/api/obra', obra, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [obra],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(obra: iObra) {
    this.http
      .put(this.urlApi + '/api/obra/' + obra.id, obra, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [obra],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'obras_ids');
    this.http
      .delete(this.urlApi + '/api/obra', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
