import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'app-CalendarioFestivosPopup',
  templateUrl: './CalendarioFestivosPopup.component.html',
  styleUrls: ['./CalendarioFestivosPopup.component.scss']
})
export class CalendarioFestivosPopupComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<CalendarioFestivosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificaciones: NotificationsService) { }

  dates: any = ""
  calendarActive: number = 0;
  activeMonth: boolean | null = null



  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
     
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  getDates(event:any) {
    this.dates = event;
     
  }

}
