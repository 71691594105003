import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iCliente } from "./iCliente";
import { iImagen } from "./iImagen";
import { iParte } from "./iParte";
import { iUser } from "./iUser";
 

export interface iObra {
    id?: number,
    concepto: string,
    numero: number,
    ubicacion: string,
    estado: boolean,
    cliente?: iCliente,
    imagen?: iImagen[],
    operario?: iUser[],
    partes?: iParte[],
}

export interface iObraCreate {
    concepto: string;
    numero: string;
    ubicacion: string;
    estado: string;
    cliente: string;
    imagen: any;
    operario: number[] ;
    proveedor: string;
}

export class iObra extends iBaseObject {
    static convertFromCollection(ob: any): Array<iObra> {
        let iObraCollection: Array<iObra> = [];
        ob.forEach((element: any) => {
            iObraCollection.push(this.convertFromObject(element));
        });
        return iObraCollection;
    }
    
    static convertFromObject(ob: any): iObra {
        let iObraObj = new iObra();
        if(ob.id !== undefined){
            iObraObj.id = ob.id;
        }
        iObraObj.concepto = ob.concepto;
        iObraObj.numero = ob.numero;
        iObraObj.ubicacion = ob.ubicacion;
        iObraObj.estado = ob.estado;
        if (ob.cliente != undefined) {
            iObraObj.cliente = iCliente.convertFromObject(ob.cliente);
        }
        if(ob.imagen !== undefined){
            iObraObj.imagen = iImagen.convertFromCollection(ob.imagen);
        }
        if(ob.partes !== undefined){
            iObraObj.partes = iParte.convertFromCollection(ob.partes);
        }
        iObraObj.operario = ob.operario!=null && ob.operario.length>0 ? iUser.convertFromCollection(ob.operario) : [];
        return iObraObj;
    }
    static empty(){
        let iObraObj = new iObra();
        iObraObj.id = -1;
        iObraObj.concepto = '';
        iObraObj.numero = -1;
        iObraObj.ubicacion = '';
        iObraObj.estado = false;
        iObraObj.cliente = iCliente.empty();
        iObraObj.imagen = [];
        iObraObj.operario = [];
        iObraObj.partes = [];
        return iObraObj;
    }
}