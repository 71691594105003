import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';

@Component({
  selector: 'app-EditClientePopup',
  templateUrl: './EditClientePopup.component.html',
  styleUrls: ['./EditClientePopup.component.scss']
})
export class EditClientePopupComponent extends iUnsubscribeDestroy implements OnInit {
  // data & ngModules
  dataFromCliente = this.modalData;
  nombre: string = this.dataFromCliente.nombre;
  mail: string = this.dataFromCliente.mail;
  telefono: string = this.dataFromCliente.telefono;

  obj: any = {
    id: this.dataFromCliente.id
  }


  // Selector
  optionClients: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.nombre,
    append: true
  }



  constructor(private clienteSe: ClienteService, public dialogRef: MatDialogRef<EditClientePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getDataForSelector()
  }

  getDataForSelector(): void {
    this.clienteSe.getAll();
    this.clienteSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
      let mappedData = data.map((d: any) => {
        return { id: d.id, label: d.nombre }
      })
      this.optionClients.items = mappedData;
      this._unsubInd.next("")
    })
  }

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  obtainChange(element: string, value: any) {
    if (element === 'mail') {
      if (!this.validateFormat(value)) {
        this.notificationSer.showError("Por favor, introduce un formato de email valido");
        return;
      }
    }
    this.obj[element] = value
    this.updateCliente(this.obj);
  }


  updateCliente(obj: any): void {
    this.clienteSe.update(obj);
    this.clienteSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El cliente ha sido modificado correctamente")
      this._unsubInd3.next("")
    })
    this.clienteSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })

  }

  // Regex

  validateFormat(email: string) {
    if (/^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(email)) { return true; }
    return false;
  }

}
