<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVO SEGUIMIENTO TÉCNICO</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">

        <div class="inputForm">
          <label>Fecha Entrada</label>
          <DatePicker (dataFromPicker)="getFechaEntrada($event)" [dataPlaceholder]="placeholderFechaEntrada">
          </DatePicker>
        </div>

        <div class="inputForm">
          <label>Estado</label>
          <selector [options]="optionEstado" [(ngModel)]="estado" classList="obras"></selector>
        </div>

        <div class="inputForm">
          <label>Proyecto</label>
          <CustomInput placeholder="Concepto" [(ngModel)]="concepto">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Calle</label>
          <CustomInput placeholder="Calle" [(ngModel)]="calle">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Número</label>
          <CustomInput placeholder="Número" [(ngModel)]="numero">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Código Postal</label>
          <CustomInput placeholder="Codigo Postal" [(ngModel)]="codigoPostal">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Localidad</label>
          <CustomInput placeholder="Localidad" [(ngModel)]="localidad">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Solicitado por</label>
          <CustomInput placeholder="Solicitado por" [(ngModel)]="orderedBy">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Contacto</label>
          <CustomInput placeholder="Contacto" [(ngModel)]="contacto">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Presupuesto</label>
          <CustomInput placeholder="Presupuesto" [type]="'number'" [(ngModel)]="presupuesto">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Comentario</label>
          <CustomInput placeholder="Comentario" [(ngModel)]="comentario">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Email</label>
          <CustomInput placeholder="Email" [(ngModel)]="email">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Teléfono</label>
          <CustomInput placeholder="Teléfono" [type]="'number'" [(ngModel)]="telefono">
          </CustomInput>
        </div>

        <!-- <div class="inputForm">
          <label>Cliente</label>
          <selector [options]="optionCliente" (change)="obtenerCliente($event)" classList="obras"></selector>
        </div>
         -->
        <div class="inputForm">
          <label>Encargado</label>
          <selector [options]="optionEncargado" (change)="obtenerEncargado($event)" classList="obras"></selector>
        </div>

        <div class="inputForm">
          <label>Fecha Visita</label>
          <DatePicker (dataFromPicker)="getFechaVisita($event)" [dataPlaceholder]="placeholderFechaVisita">
          </DatePicker>
        </div>

        <div class="inputForm">
          <label>Hora Visita</label>
          <CustomInput [type]="'time'" [(ngModel)]="horaVisita">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Fecha Objetivo</label>
          <DatePicker (dataFromPicker)="getFechaObjetivo($event)" [dataPlaceholder]="placeholderFechaVisita">
          </DatePicker>
        </div>


        <div class="inputForm">
          <label></label>
          <button type="button" class="generalbutton" fileInput [multiple]="true"
            [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="imagen"
            (change)="addImagen(imagen)">Añadir Imágenes</button>
        </div>

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevoST()">Añadir Seguimiento Técnico</btn>
    </div>
  </div>
</div>