import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { iSeguimientoTecnico } from 'src/app/Shared/Interfaces/Api/iSeguimientoTecnico';
import { EditVehiculoPopupComponent } from '../EditVehiculoPopup/EditVehiculoPopup.component';

@Component({
  selector: 'app-EditSeguimientoTecnicoPopup',
  templateUrl: './EditSeguimientoTecnicoPopup.component.html',
  styleUrls: ['./EditSeguimientoTecnicoPopup.component.scss'],
})
export class EditSeguimientoTecnicoPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // DATA

  dataFromST: any = this.modalData;

  dataSTIndividual!: any;

  // OBJECT TO PASS
  obj: any = {
    id: this.dataFromST.id,
  };

  // BINDINGS

  // concepto: string = this.dataFromST.concepto
  // ubicacion: string = this.dataFromST.ubicacion
  // contacto: string = this.dataFromST.contacto
  // telefono: number = this.dataFromST.telefono
  // comentario: string = this.dataFromST.comentario
  // fechaVisita: any = this.dataFromST.fechaVisita.toStringDate("EU").replace("-", "/").replace("-", "/")
  // fechaEntrada: any = this.dataFromST.fechaEntrada.toStringDate("EU").replace("-", "/").replace("-", "/")

  concepto!: string;
  ubicacion!: string;
  orderedBy!: string;
  contacto!: string;
  presupuesto!: string;
  telefono!: number;
  horaVisita!: number;
  comentario!: string;
  email!: string;
  fechaVisita!: any;
  fechaObjetivo!: any;
  fechaEntrada!: any;
  estado!: string;
  cliente!: string;
  encargado!: string;

  // IMAGENES

  imagen!: any;
  imagenes: any[] = [];

  // OPCIONES SELECTORES

  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Entregado' },
      { id: 2, label: 'Visitado' },
      { id: 3, label: 'Contactado' },
      { id: 4, label: 'Pendiente' },
    ],
    clearable: false,
    placeholder: '',
    append: true,
  };

  optionCliente: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: '',
    append: true,
  };

  optionEncargado: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: '',
    append: true,
    bindValue: 'id',
  };

  constructor(
    private notificationSE: NotificationsService,
    private seguimientoTecnicoSe: SeguimientoTecnicoService,
    private userSe: UserService,
    private clienteSe: ClienteService,
    public dialogRef: MatDialogRef<EditVehiculoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getST(this.dataFromST.id);
    this.getClientes();
    this.getEncargados();
    // this.setter()

    let date = this.dataFromST.fechaVisita.day;
  }

  // GETTERS

  getClientes(): void {
    this.clienteSe.getAll();
    this.clienteSe
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        let mappedDataForSelector = data.map((d: any) => {
          return { id: d.id, label: d.nombre };
        });
        this.optionCliente.items = mappedDataForSelector;
        this._unsubInd.next('');
      });
  }

  getEncargados(): void {
    this.userSe.getAdminTecnicos();
    this.userSe
      .getResultEmpleados()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
       
        let mappedUsers = data.map((d: any) => {
          return { id: d.id, label: d.nombre }
        })
          this.optionEncargado.items = mappedUsers;
        this._unsubInd2.next('');
      });
  }

  // GET FROM INPUT

  getFromInput(element: string, value: any) {
    this.obj[element] = value;
    this.updateST(this.obj);
  }

  // SETTER

  setter() {
    this.encargado = this.dataSTIndividual?.encargado?.id;
    this.email = this.dataSTIndividual?.email;
    this.presupuesto = this.dataSTIndividual?.presupuesto;
    this.concepto = this.dataSTIndividual?.concepto;
    this.ubicacion = this.dataSTIndividual?.ubicacion;
    this.contacto = this.dataSTIndividual?.contacto;
    this.telefono = this.dataSTIndividual?.telefono;
    this.comentario = this.dataSTIndividual?.comentario;
    this.orderedBy = this.dataSTIndividual?.ordenadoPor;
    this.estado = this.dataSTIndividual?.estado;
    // this.cliente = this.dataSTIndividual?.cliente.nombre
    this.fechaVisita = iDate
      .javascriptConvert(new Date(this.dataSTIndividual?.fechaVisita))
      .toStringDate('EU');
    this.fechaEntrada = iDate
      .javascriptConvert(new Date(this.dataSTIndividual?.fechaEntrada))
      .toStringDate('EU');
    this.fechaObjetivo = iDate
      .javascriptConvert(new Date(this.dataSTIndividual?.fechaObjetivo))
      .toStringDate('EU');
    this.horaVisita = this.dataSTIndividual?.horaVisita;
  }

  // DATE PICKERS

  getDataFromFechaVisita(event: any): void {
    let dateToPass = this.getDateFormat(event);
    this.obj['fechaVisita'] = dateToPass;
    this.updateST(this.obj);
  }

  getDataFromFechaObjetivo(event: any): void {
    let dateToPass = this.getDateFormat(event);
    this.obj['fechaObjetivo'] = dateToPass;
    this.updateST(this.obj);
  }

  getDataFromFechaEntrada(event: any): void {
    let dateToPass = this.getDateFormat(event);
    this.obj['fechaEntrada'] = dateToPass;
    this.updateST(this.obj);
  }

  // SELECTOR

  estadoFromSelector(event: any): void {
    this.obj['estado'] = event.label;
    this.updateST(this.obj);
  }

  encargadoFromSelector(event: any): void {
    this.obj['encargado'] = event;
    this.updateST(this.obj);
  }

  clienteFromSelector(event: any): void {
    this.obj['cliente'] = event.id;
    this.updateST(this.obj);
  }

  // CLOSE
  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // UPDATE

  updateST(obj: any): void {
    this.seguimientoTecnicoSe.update(obj);
    this.seguimientoTecnicoSe
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El Seguimiento Técnico ha sido actualizado correctamente'
        );
        this._unsubInd3.next('');
      });
    this.seguimientoTecnicoSe
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  // FUNCIONALIDAD

  getDateFormat(event: any): string {
    let { value } = event;
    let date = new Date(value);
    let dateToPass = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    return dateToPass;
  }

  addImage(event: any) {
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificationSE.showWarning('Formato de archivo no admitido');
      this.imagen = null;
    } else {
      event.forEach((element: any, index: number) => {
        let obj = {
          url: element.base64,
        };
        if (this.imagenes.length == 0) {
          this.imagenes.push(obj);
        } else {
          this.imagenes.push(obj);
        }
      });

      this.obj['imagenSeguimiento'] = this.imagenes;
      this.updateST(this.obj);
    }
  }

  /**
   * API CALLS
   */

  getST(id: number): void {
    this.seguimientoTecnicoSe.getSingle(id);
    this.seguimientoTecnicoSe
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        this.dataSTIndividual = data;
        this.setter();
        this._unsubInd4.next('');
      });
  }
}
