import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { VerTodoAlertasPopupComponent } from 'src/app/Popups/VerTodoAlertasPopup/VerTodoAlertasPopup.component';
import { AlertaService } from 'src/app/Services/Api/Alerta.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-JefeDashboard',
  templateUrl: './JefeDashboard.component.html',
  styleUrls: ['./JefeDashboard.component.scss']
})
export class JefeDashboardComponent extends iUnsubscribeDestroy implements OnInit {

  forceUpdateWidth = new Subject()

  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Cliente', property: 'cliente', align: 'center' },
    { label: 'Ubicación', property: 'ubicacion', align: 'center', linkAction: (rowElem) => { } },
    { label: 'Nº Obra', property: 'numero', align: 'center' },
    { label: 'Partes pendientes', property: 'partesPendientes', align: 'center', classList: 'tableEstado tableEstadoObras' }
  ];

  optionsObras: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,

  }


  alertaData:any[] = [
    {descripcion: "Cargando alertas"},
    {descripcion: "Cargando alertas"},
    {descripcion: "Cargando alertas"},
    {descripcion: "Cargando alertas"},
    {descripcion: "Cargando alertas"},
    {descripcion: "Cargando alertas"}
  ]

  dataObras = []
  userID!: number;
  isLoading: boolean = false;
  constructor(private obraSE: ObraService, private router: Router, private popup: PopupService,private notificaciones:NotificationsService, private alertaSE:AlertaService, private userSE:UserService) {
    super();
  }

  ngOnInit() {
    this.getMe();
    this.getAllAlertas()
  }

  /**
   * FUNCTIONALITIES
   */
  editObra(event: Event) {
     
    this.popup.openPopup(EditObrasPopupComponent, event);
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      this.isLoading = true;
      this.getAllObras()
      this._unsubInd2.next("")
    })
  }
  /**
   * 
   * Alertas POPUP
   */

  openVerTodoAlertasPopup(){
    this.popup.openPopup(VerTodoAlertasPopupComponent);
  }

  /**
   * 
   * DELETE 
   * 
   */


  deleteObra(event: any, type: string) {
    switch (type) {
      case 'single':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "la obra" });
        break;
      case 'multiple':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras" });
        break;
      case 'multipleSelected':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras seleccionadas" });
        break;
    }
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      switch (type) {

        case 'single':
           
        this.removeObra(event["id"]);
        break;
        case 'multiple':
          var ids = event.map((elem: any) => { return elem.id })
          this.removeObra(ids);
          break;
        case 'multipleSelected':
          var ids = event.map((elem: any) => { return elem.id })
          this.removeObra(ids);
          break;
      }
      this._unsubInd2.next("")
    })
     
  }
  navigateToPartesObras(event:any){
    this.router.navigate(["/jefe/obras/" + event["id"]])
  }
  /**
   * API Calls
   */

  getMe(){
    this.isLoading=true
    this.userSE.getMe();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res===null){return}
      let {data} = res;
       
      this.userID = data.id;
      localStorage.setItem('nmsr', data.id.toString());
      this.getAllObras();
      this._unsubInd2.next("")
    })
  }
  getAllObras() {
     
    this.obraSE.getObrasUserId(this.userID);
    this.obraSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (res === null) { return }
      let { data } = res;
       
      this.dataObras = data;
      setTimeout(() => {
        this.forceUpdateWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd2.next("");
    })
  }
  removeObra(id: string[] | number[]): void {
     
    this.obraSE.delete(id);
    this.obraSE.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificaciones.showFeedBack("La obra ha sido borrado correctamente")
      this.ngOnInit();
      this._unsubInd4.next("")
    })
    this.obraSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificaciones.showError(res.message)
      this._unsub.next("")
    })
  }

  getAllAlertas(){
    this.alertaSE.getAll(6);
    this.alertaSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(res=>{
      if(res===null){return}
      let {data} = res;
      this.alertaData = data;
      this._unsubInd3.next("")
    })
  }
}
