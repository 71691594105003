<FullContainer>
  <h2 class="sectionTitle">alertas</h2>
  <br>
  <div class="alertasContainer">
      <pill *ngFor="let alerta of alertaData" classList="alerta" class="{{alerta.metadata}}" >{{alerta.descripcion}}</pill>

  </div>
  <div class="btnSeparatorContainer">
      <btn classList="bigBtn" (click)="openVerTodoAlertasPopup()" >ver todo</btn>
  </div>
</FullContainer>
