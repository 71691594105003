<div class="fullFooter">

  <div class="iconoTexto" (click)="goToPath('dashboard')">
    <div class="icono">
      <ng-container>
        <mat-icon>article</mat-icon>
      </ng-container>
    </div>
    <div class="texto">
      <p>Partes</p>
    </div>
  </div>

  <div class="iconoTexto" (click)="goToPath('obras')">
    <div class="icono">
      <ng-container>
        <mat-icon>file_copy</mat-icon>
      </ng-container>
    </div>
    <div class="texto">
      <p>Documentos</p>
    </div>
  </div>

  <div class="iconoTexto" (click)="goToPath('profile')">
    <div class="icono">
      <ng-container>
        <mat-icon>person</mat-icon>
      </ng-container>
    </div>
    <div class="texto">
      <p>Perfil</p>
    </div>
  </div>

</div>