<FullContainer>
  <div class="sectionTitleContainer">
    <div class="fillTitle">
      <h2 class="sectionTitle">Empleados</h2>
      <selector *ngIf="submenuSel == 2" [(ngModel)]="yearSel" [options]="optionYear" (change)="updateYear()"></selector>
      <selector *ngIf="submenuSel == 2" [(ngModel)]="monthSel" [options]="optionsMonth" (change)="updateMonth()"></selector>
    </div>
    <toggleSubmenu [elements]="submenuNavegation" [(ngModel)]="submenuSel"></toggleSubmenu>
  </div>
  <app-AdminEmpleadosLista *ngIf="submenuSel == 0" (refreshData)="refreshData($event)" (emitFilters)="getEmitFilters($event)" [dataTable]="empleadosTableData" [isLoading]="isLoadingLista" [tiposUsers]="allRoles" [obras]="allObras" (refreshData)="refreshData($event)"></app-AdminEmpleadosLista>
  <app-AdminEmpleadosDepartamentos *ngIf="submenuSel == 1" (refreshData)="refreshData($event)"  [dataTable]="departamentosTableData" [isLoading]="isLoadingDepartamentos"></app-AdminEmpleadosDepartamentos>
  <app-AdminEmpleadosNominas *ngIf="submenuSel == 2" (refreshData)="refreshData($event)" [dataTable]="nominasTableData" [isLoading]="isLoadingNominas" [mesesSel]="monthSel!=null && monthSel != ''" (refreshMonth)="refreshMonth()" (searchChange)="updateNominasFilter($event)"></app-AdminEmpleadosNominas>
  <!-- <app-AdminEmpleadosPartes *ngIf="submenuSel == 3" [dataTable]="partesTableData" [isLoading]="isLoadingPartes" [vehiculos]="allVehicles" [empleados]="allUsers" [obras]="allObras" [estados]="allEstados" (refreshData)="refreshData($event)"></app-AdminEmpleadosPartes> -->
</FullContainer>