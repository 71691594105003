import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'NuevoVehiculoPopup',
  templateUrl: './NuevoVehiculoPopup.component.html',
  styleUrls: ['./NuevoVehiculoPopup.component.scss'],
})
export class NuevoVehiculoPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // Data ngModel

  nombre: string = '';
  matricula: string = '';
  caducidadSeguro: string = '';
  caducidadITV: string = '';
  asignado: any[] = [];
  dataPlaceholderSeguro = new Date().getFullYear().toString();
  initialDataITV = new Date().getFullYear().toString();

  // Opciones del selector
  optionUsers: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Usuario',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios usuarios',
    },
    append: true,
    search: true,
  };

  constructor(
    private vehiculoSe: VehiculoService,
    private userSe: UserService,
    public dialogRef: MatDialogRef<NuevoVehiculoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllUsers();
  }

  /**
   * Funcionalidad
   */

  getDateFormat(event: any): string {
    let { value } = event;
    let date = new Date(value);
    let dateToPass = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    return dateToPass;
  }

  forceClosePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  getDataFromSeguro(event: any): void {
    let dateToPass = this.getDateFormat(event);
    this.caducidadSeguro = dateToPass;
  }

  getDataFromITV(event: any) {
    let dateToPass = this.getDateFormat(event);
    this.caducidadITV = dateToPass;
  }

  obtenerUsuario(event: any): void {
    this.asignado = event.id;
  }

  /**
   * Llamadas a la API
   */

  getAllUsers(): void {
    this.userSe.getAll();
    this.userSe
      .getResultAll()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        let mappedUsers = data.map((d: any) => {
          return { id: d.id, label: d.nombre };
        });
        this.optionUsers.items = mappedUsers;
        let asignados = mappedUsers.map((d: any) => d.id);
        this.asignado = asignados;
        this._unsubInd.next('');
      });
  }

  submitNuevoVehiculo() {
    if (
      this.nombre == '' ||
      this.matricula == '' ||
      this.caducidadSeguro == '' ||
      this.caducidadITV == '' ||
      this.asignado.length <= 0
    ) {
      return;
    }

    let objectToPass = {
      nombre: this.nombre,
      matricula: this.matricula,
      caducidadSeguro: this.caducidadSeguro,
      incidencias: '',
      caducidadITV: this.caducidadITV,
      usuario: this.asignado,
    };
    this.vehiculoSe.create(objectToPass);
    this.vehiculoSe
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack('Vehiculo creado correctamente');
        this.forceClosePopup('submitted');
        this._unsubInd.next('');
      });
    this.vehiculoSe
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }
}
