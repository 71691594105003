<FullContainer>
  <div class="sectionTitleContainer">
    <div class="fillTitle">
      <h2 class="sectionTitle">Proveedores</h2>
    </div>
  </div>
  <filterActions [placeholderAdd]="'AÑADIR NUEVO'" (addNew)="nuevoProveedor()" (searchSend)="respuestaBusqueda($event)" [inputOptions]="filterText" (searchSend)="updateQuery()" [disableFilter]="true"></filterActions>
  
  <div class="paginacion">
    <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
    <div class="arrows"> 
      <ng-container class="arrowLeft">
        <mat-icon (click)="previousPage()">
          chevron_left
        </mat-icon>
      </ng-container>
      <ng-container class="arrowRight">
        <mat-icon (click)="nextPage()">
          chevron_right
        </mat-icon>
      </ng-container>
    </div>
  </div>
  

  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataProveedores" [displayedColumns]="displayedColumnsProveedores" [options]="optionsProveedores"
      [isLoading]="isLoading" (see)="navigateToProveedores($event)" (clickRow)="navigateToProveedores($event)"
      [classList]="cursorPointer" (edit)="popupEditProveedores($event)" [forceUpdateWidth]="forceUpdateWidth" >
    </dataTable>
  </pill>
</FullContainer>