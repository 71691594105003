<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVA INCIDENCIA </h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">        
        
        <div class="inputForm">
          <label>Descripcion</label>
          <CustomInput placeholder="Descripcion" [(ngModel)]="descripcion" ></CustomInput>
        </div>
        
        <div class="inputForm">
          <label>Estado</label>
          <selector placeholder="Estado" class="sizeInput" [options]="optionEstado" [(ngModel)]="estado" (change)="getEstadoFromSelector($event)" ></selector>
        </div>
        <div class="separatorGrid"></div>
      </div>
      
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="submitNuevaIncidencia()">
        <ng-container>
          <span>Añadir Nueva Incidencia</span>
        </ng-container>
      </btn>
    </div>
  </div>