import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iUnsubscribeDestroy, LoaderService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { takeUntil } from 'rxjs';
import { iUser } from 'src/app/Shared/Interfaces/Api/iUser';
import { iParte } from 'src/app/Shared/Interfaces/Api/iParte';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { GaleriaFotosComponent } from 'src/app/Popups/GaleriaFotos/GaleriaFotos.component';
// ZIP
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';


@Component({
  selector: 'ObraDetailView',
  templateUrl: './ObraDetailView.component.html',
  styleUrls: ['./ObraDetailView.component.scss']
})
export class ObraDetailViewComponent extends iUnsubscribeDestroy implements OnInit {

  // Data Inputs

  dataPartes: Array<iParte> = []
  dataOperarios: Array<iUser> = []
  dataDocumentos: Array<any> = []

  data: any = []
  idObra: any = 0
  filtros: any = {
    estado: "",
    fechaIni: "",
    fechaFin: "",
    busqueda: "",
  }

  // INPUTS

  concepto: string = "Cargando..."
  cliente: string = ""
  estado: string = ""
  ubicacion: string = ""
  numeroObra: string = ""
  imagenes: Array<any> = []
  primeraImagen = ""



  // SUBMENU SELECTOR

  submenuSel: number = 0

  constructor(private popup: PopupService, private loader: LoaderService, private obraSE: ObraService, private activatedRoute: ActivatedRoute, private documentoSE: DocumentoService, private router: Router) {
    super()
  }

  ngOnInit() {
    this.getParams()
  }


// ZIP

async fetchImageAsArrayBuffer(url: string): Promise<ArrayBuffer> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch image from \${url}`);
  }
  return await response.arrayBuffer();
}

async generateZipFromImageUrls(zipFilename: string) {
  let imageUrls = this.imagenes.map((item: any) => item.url)
  const zip = new JSZip();

  // Add each image to the zip file
  for (const imageUrl of imageUrls) {
    const filename = imageUrl.split('/').pop() || 'image';
    const imageData = await this.fetchImageAsArrayBuffer(imageUrl);
    zip.file(filename, imageData);
  }

  // Generate the zip file and save it
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  saveAs(zipBlob, zipFilename);
}


getParams() {
  let id = this.activatedRoute.snapshot.paramMap.get('id')
  this.idObra = id
  this.getObraId(id);
  this.getDocumentosObra(id)
  this.getAllImagenesObra()


}

showOnlyEight() {
  let imagenes = document.querySelectorAll(".smallPictureContainer")!
  imagenes.forEach((element: any, index: any) => {
    if (index > 8) {
      element.style.display = "none"
    }
  })
}

/**
* Google Maps
*/

abrirGoogleMaps() {
  let { lan, lon } = this.data;
  window.open(`https://www.google.com/maps/search/?api=1&query=${lan},${lon}`, '_blank');
}

/**
 * OPEN GALERIA
 */

openGaleria() {
  this.popup.openPopup(GaleriaFotosComponent, this.imagenes)
  this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
    if (res === null) { return }
    this.getParams()
    this._unsubInd2.next("")
  })
}

/**
 * Edit obra
 */

editObra() {
  this.popup.openPopup(EditObrasPopupComponent, this.data)
  this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
    if (res === null) { return }
    this.getParams()
    this._unsubInd2.next("")
  })
}



/**
 * Delete obra
 */

deleteObra() {
  this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: `obra ${this.data.concepto}` })
  this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
    if (res === null) { return }
    this.getObraId(this.data.id)
    this._unsubInd.next("")
  })

}

/**
 * API CALLS
 */

getObraId(id: any) {
  this.loader.open()
  this.obraSE.getSingle(id);
  this.obraSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(res => {
    if (res === null) { return }
    let { data } = res;
    this.data = data;
    this.concepto = data.concepto.toUpperCase()
    this.cliente = data.cliente.nombre
    if (data.estado === true) {
      this.estado = "activa"
    } else {
      this.estado = "inactiva"
    }
    this.ubicacion = data.ubicacion
    this.numeroObra = data.numero
    this.dataPartes = data.partes
    this.dataOperarios = id
    this.loader.close()
    this._unsubInd.next("")
  })
}

getDocumentosObra(id: any) {
  this.documentoSE.getAllDocumentByObra(id, this.filtros.busqueda, this.filtros.estado, this.filtros.fechaIni, this.filtros.fechaFin);
  this.documentoSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
    if (res === null) { return }
    let { data } = res.data;
    this.dataDocumentos = data
    this._unsubInd2.next("")
  })
}

refreshData(event: any) {
  if (event === true) {
    this.getParams()
  }
}

filters(event: any) {
  this.filtros = event
  this.getDocumentosObra(this.idObra)
}

returnObras() {
  this.router.navigateByUrl("/admin/obras")

}

getAllImagenesObra() {
  this.obraSE.getAllImages(this.idObra);
  this.obraSE.getResultImagenesObras().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
    if (res === null) { return }
    let { data } = res;
    this.imagenes = data
    this.primeraImagen = data[0]?.url ?? ""
    this.showOnlyEight()
    this._unsubInd4.next("")
  })
}

}
