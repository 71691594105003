import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { UserService } from 'src/app/Services/Api/User.service';
import { iUser } from 'src/app/Shared/Interfaces/Api/iUser';
import * as XLSX from 'xlsx';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICryptID } from 'src/app/Shared/Interfaces/iCryptID';


@Component({
  selector: 'ObraDetailEmpleados',
  templateUrl: './ObraDetailEmpleados.component.html',
  styleUrls: ['./ObraDetailEmpleados.component.scss']
})
export class ObraDetailEmpleadosComponent extends iUnsubscribeDestroy implements OnInit {

  @Input("dataOperarios") dataOperarios: Array<any> = []
  
  // Control Horario

  isLoadingTabla: boolean = false

  dataHorario: any[] = []

  displayedColumnsHorario:Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' , },
    { label: 'Departamento', property: 'departamento', align:'center' },
    { label: 'Tiempo Trabajado', property: 'tiempoTrabajado' , align:'center'},
  ];

  optionsHorarios: iDataTableOptions = {
    moreIconsHead: [{ callback: () => { this.exportexcel("control_horario") }, icon: "download" }],
    isHovered: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }

  // Vehiculos Asignados

  isLoadingVehiculos: boolean = false

  dataVehiculo: any[] = []

  displayedColumnsVehiculos:Array<iDataTableColumns> = [
    { label: 'Vehiculo', property: 'nombre' ,},
    { label: 'Matricula', property: 'matricula', align:'center' },
  ];

  optionsVehiculos: iDataTableOptions = {
    moreIconsHead: [{ callback: () => { this.exportexcel("vehiculos_asignados")}, icon: "download" }],
    isHovered: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }



  constructor(private popup: PopupService, private userSE: UserService, private activatedRoute:ActivatedRoute, private router:Router) {
    super();
  }

  ngOnInit() {
    this.getEmpleadosVehiculos()
  }


  
  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(tipo:string): void
  {
    let table = document.createElement("table")
    if (tipo == "control_horario") {
      table.append((document.querySelectorAll("dataTable .tableHeader thead")[0] as HTMLElement).cloneNode(true))
      table.append((document.querySelectorAll("dataTable .tableBody tbody")[0] as HTMLElement).cloneNode(true))
    }
    if (tipo == "vehiculos_utilizados") {
      table.append((document.querySelectorAll("dataTable .tableHeader thead")[1] as HTMLElement).cloneNode(true))
      table.append((document.querySelectorAll("dataTable .tableBody tbody")[1] as HTMLElement).cloneNode(true))
    }
   

    table.querySelector("thead .iconsActions")?.remove();
    // table.querySelector("thead .checkboxContainer")?.remove();

    table.querySelectorAll("tbody tr td:last-of-type").forEach(element=>{
      element.remove();
    })
    // table.querySelectorAll("tbody tr td:first-of-type").forEach(element=>{
    //   element.remove();
    // })
    /* pass here the table id */
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, tipo+'.xlsx');

  }

   /**
   * Open detail view Vehiculo
   */

   openVehiculoDetailView(event: any) {
    let { id } = event;
    this.router.navigate([`admin/vehiculos/` + id])
  }

   /**
   * Open detail view Detalle
   */

  empleadoDetalle(event:any){
    let crypt = ICryptID.cryptID(event.id);
    this.router.navigate(['/empleado/' + crypt]);
    
  }

  /**
   * API CALLS
   */


  getEmpleadosVehiculos() {
    this.isLoadingTabla = true
    this.isLoadingVehiculos = true
    let id =this.activatedRoute.snapshot.paramMap.get('id')
    this.userSE.getAllEmpleadosReformaTabla(id);
    this.userSE.getResultAll().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if(res=== null ){return}
      let {empleados, vehiculos} = res.data
      let empleadosArray:any = []
      let vehiculosArray:any = []
 

      for(let empleado in empleados){
        empleadosArray.push(empleados[empleado])
      }

      for(let vehiculo in vehiculos){
        vehiculosArray.push(vehiculos[vehiculo])
      }
      

      this.dataHorario = empleadosArray
      this.dataVehiculo = vehiculosArray

      this.isLoadingTabla = false
      this.isLoadingVehiculos = false
      this._unsubInd.next("")

    })
  }
}
