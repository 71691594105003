import { AfterContentInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { InfoEmpleadoService } from 'src/app/Services/Api/InfoEmpleado.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';

@Component({
  selector: 'app-EditEmpleadosPopup',
  templateUrl: './EditEmpleadosPopup.component.html',
  styleUrls: ['./EditEmpleadosPopup.component.scss']
})
export class EditEmpleadosPopupComponent extends iUnsubscribeDestroy implements OnInit, AfterContentInit {


  // DATA
  dataEmpleado: any = this.modalData
  dataFromSingle: any = ""
  obraUser: any = []


  // OBJECT TO PASS
  obj: any = {
    id: this.dataEmpleado.id
  }

  // INPUTS
  nombre: string = ""
  apellidos: string = ""
  dni: string = ""
  telefono: string = ""
  mail: string = ""
  botas: string = ""
  pantalones: string = ""
  selectedTallas: string | null = null
  obras: any = "";
  departamento!: any;
  role!: string;


  // Opciones selectores
  optionsObras: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: '',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 1,
      multipleTextReplacer: "Varias obras seleccionados"
    }
  }

  optionsDepartamento: iOptionsSelector = {
    items: [],
    placeholder: "",
    append: true,
    clearable: false,
    classList: "maxWidth"
  }

  numberAttributes = [
    { attr: 'min', value: "0" }
  ];

  optionsTallas: iOptionsSelector = {
    items: ['XXL', 'XL', 'L', 'M', 'S', 'XS', 'XXS'],
    placeholder: "",
    append: true,
    clearable: false,
    classList: "maxWidth"
  }
  optionsRole: iOptionsSelector = {
    items: [
      { id: 0, label: 'Operario', valor: 'ROLE_USER' },
      { id: 1, label: 'Administrador', valor: 'ROLE_ADMIN' },
      { id: 2, label: 'Técnico', valor: 'ROLE_TECNICO' },
      // { id: 3, label: 'Cliente', valor: 'ROLE_CLIENTE' },
      { id: 4, label: 'Jefe de Obra', valor: 'ROLE_JEFE_OBRA' },
    ],
    placeholder: 'Role',
    append: true,
    clearable: false,
    bindValue: 'valor',
  }





  constructor(private infoEmpleadoSe: InfoEmpleadoService, private departamentoSe: DepartamentosService, private userSe: UserService, private obraSe: ObraService, private empleadoSe: InfoEmpleadoService, public dialogRef: MatDialogRef<EditEmpleadosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }
  ngAfterContentInit(): void {
    // (document.querySelector("selector") as HTMLElement).style.width = document.querySelector("CustomInput:first-of-type")?.clientWidth + "px";
  }

  ngOnInit() {
    this.getObras()
    this.getUserById()
    this.getDepartamentos()
  }

  getDepartamentos(): void {
    this.departamentoSe.getAll();
    this.departamentoSe.getResult().pipe(takeUntil(this._unsubInd5)).subscribe(res => {
      if (res === null) { return; }

      let { data } = res;
      let optionsForSelector = data.map((d: any) => {
        return { id: d.id, label: d.nombre }
      })
      this.optionsDepartamento.items = optionsForSelector
      this._unsubInd5.next("")
    })
  }

  getUserById(): void {
    let id = this.dataEmpleado.id
    this.userSe.getSingle(id);
    this.userSe.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      let { data } = res
       
      let obrasUser = data.user?.obras?.map((obra: any) => {
        return { id: obra.id, label: `${obra.id} - ${obra.concepto} ${obra.ubicacion}` }
      })
      this.obraUser = obrasUser;
      this.dataFromSingle = data.user;
      this.setData()
      this._unsubInd2.next("")
    })
  }

  getObras(): void {
    this.obraSe.getAll();
    this.obraSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      let { data } = res
       
      let optionsForSelector = data.map((d: any) => {
        return { id: d.id, label: d.concepto }
      })
      this.optionsObras.items = optionsForSelector
      this._unsubInd.next("")
    })
  }

  forceClosePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // Departamento
  departamentoFromSelector(event: any) {
    this.obj["departamento"] = event.id
    this.updateUser(this.obj);
  }

  // Obra
  obraFromSelector(event: any) {
    let objetoVincularObras = { id: this.dataFromSingle.id, obra: event }
    this.userSe.vincularObras(objetoVincularObras)
    this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
      if (res === null) { return }
      this.notificationSer.showFeedBack("El usuario ha sido actualizado correctamente")
      this._unsubInd9.next("")
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })
  }
  // Talla Camiseta
  tallaCamisetaFromSelector(event: any): void {

    let obj: any = {
      id: this.dataFromSingle.infoEmpleado.id
    }
    obj["tallaCam"] = event
    this.updateTallas(obj)
  }

  // Talla Botas y pantalones
  updateBotasPantalones(element: string, value: any): void {
    let obj: any = {
      id: this.dataFromSingle.infoEmpleado.id
    }
    obj[element] = value
    this.updateTallas(obj);
  }


  getFromInput(element: string, value: any) {
    this.obj[element] = value
    this.updateUser(this.obj);
  }

  getRoleFromSelector(event: any) {
    this.obj["role"] = event
    this.updateUser(this.obj);
  }


  updateTallas(obj: any): void {
    this.infoEmpleadoSe.update(obj);
    this.infoEmpleadoSe.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El usuario ha sido actualizado correctamente")
      this._unsubInd4.next("")
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })
  }

  updateUser(obj: any): void {
    this.userSe.update(obj);
    this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El usuario ha sido actualizado correctamente")
      this._unsubInd3.next("")
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })
  }

  setData() {
    this.nombre = this.dataFromSingle.nombre;
    this.dni = this.dataFromSingle.dni;
    this.apellidos = this.dataFromSingle.apellidos;
    this.telefono = this.dataFromSingle.telefono;
    this.mail = this.dataFromSingle.mail;
    this.botas = this.dataFromSingle?.infoEmpleado?.tallaBota;
    this.pantalones = this.dataFromSingle?.infoEmpleado?.tallaPan;
    this.optionsDepartamento.placeholder = this.dataFromSingle?.departamento?.nombre;
    this.optionsTallas.placeholder = this.dataFromSingle?.infoEmpleado?.tallaCam;
    this.optionsObras.placeholder = this.dataFromSingle?.obras[0]?.concepto;
    this.obras = this.dataFromSingle?.obras?.map((id:any) => id.id);
    this.departamento = this.dataFromSingle?.departamento?.nombre;
    this.selectedTallas = this.dataFromSingle?.infoEmpleado?.tallaCam;
    this.role = this.dataEmpleado?.usuario[0];
  }

  getDateFormat(event: any): string {
    let { value } = event;
    let date = new Date((value))
    let dateToPass = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    return dateToPass
  }




}

