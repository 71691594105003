<FullContainer>
  <div class="mainBlock">
    <div class="titleAndToggle">
      <div class="titleContainer">
        <mat-icon class="material-icons-circle" (click)="returnObras()">arrow_back</mat-icon>
        <div class="title">
          <p>{{numeroObra}} - {{concepto}}</p>
        </div>
      </div>
      <div class="toggleContainer">
        <toggleSubmenu [elements]="['DATA','PARTES DE OBRA', 'EMPLEADOS', 'DOCUMENTOS']" [(ngModel)]="submenuSel">
        </toggleSubmenu>
      </div>
    </div>
    <ng-container *ngIf="submenuSel === 0">
      <div class="mainContainer">
        <div class="containerInfo">
          <div class="iconos">
            <ng-container>
              <mat-icon class="icon cursor-pointer" (click)="editObra()">edit</mat-icon>
              <mat-icon class="icon cursor-pointer" (click)="deleteObra()">delete</mat-icon>
            </ng-container>
          </div>
          <p class="header">Cliente</p>
          <p class="subtitle">{{cliente}}</p>
          <p class="header">Estado</p>
          <span class="subtitle tableEstadoObras"
            [ngClass]="{'activa': estado === 'activa', 'inactiva': estado === 'inactiva'}">{{estado | titlecase}}</span>
          <p class="header">Ubicacion</p>
          <div class="d-flex">
            <ng-container>
              <mat-icon class="icon">pin_drop</mat-icon>
            </ng-container>
            <p class="subtitle ubicacion" (click)="abrirGoogleMaps()">{{ubicacion}}</p>
          </div>
          <p class="header">Nº Obra</p>
          <p class="subtitle">{{numeroObra}}</p>
        </div>
        <div class="containerPictures">
          <div class="contentContainerPictures">
            <div class="mainPicture"  >
              <img class="imagen" onerror="this.src='assets/Images/Placeholder.jpg'" src="{{primeraImagen}}"
                alt="imagenPrincipal">
            </div>
            <div class="otherPictures" >
              <div class="smallPictureContainer" *ngFor="let imagen of imagenes; let index = index">
                <img class="imagen" onerror="this.src='assets/Images/Placeholder.jpg'" src="{{imagen?.url}}"
                  alt="imagenPrincipal" *ngIf="index < 9">
              </div>
            </div>
          </div>
          <div class="button">
            <div class="buttonContainer">
              <btn classList="finisimo" (click)="openGaleria()">
                <p>Ver Galería</p>
              </btn>
              <btn classList="finisimo" (click)="generateZipFromImageUrls('images.zip')">
                <p>descargar imágenes</p>
              </btn>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="submenuSel === 1">
      <ObraDetaiParteObra [dataObra]="data" [idObra]="idObra" (refreshData)="refreshData($event)" class="width100p"></ObraDetaiParteObra>
    </ng-container>
    <ng-container *ngIf="submenuSel === 2">
      <ObraDetailEmpleados [dataOperarios]="dataOperarios" class="width100p"></ObraDetailEmpleados>
    </ng-container>
    <ng-container *ngIf="submenuSel === 3">
      <ObraDetailDocumentos [dataDocumentos]="dataDocumentos" (filters)="filters($event)" (refreshData)="refreshData($event)" class="width100p">
      </ObraDetailDocumentos>
    </ng-container>
  </div>


</FullContainer>