<filterActions (addNew)="crearST()" [placeholderAdd]="'AÑADIR NUEVO'">
  <ng-container filterContent>
    <div class="containerSelectores">
      <div>
        <p>Técnico</p>
        <selector [options]="optionTecnico" (change)="getTecnicoFromSelector($event)"></selector>
      </div>
      <div>
        <p>Dirección</p>
        <selector [options]="optionAddress" (change)="getAddressFromSelector($event)"></selector>
      </div>
      <div>
        <p>Estado</p>
        <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
      </div>
      <div class="desde">
        <p>Desde</p>
        <DatePicker (dataFromPicker)="dataFromPickerDesde($event)"></DatePicker>
      </div>
      <div class="hasta">
        <p>Hasta</p>
        <DatePicker (dataFromPicker)="dataFromPickerHasta($event)"></DatePicker>
      </div>
    </div>
  </ng-container>
</filterActions>
<br>
<div class="pipeContainer">
  <pipeLine [pipeList]="pipeList" (pipeItemGroupChange)="changeItem($event)" (pipeListChange)="change($event)" >
    <ng-template pipeLineCardContent let-item="item">
      <div class="pipeLineContent" [attr.disabled]="isLoadingElem(item)">
        <div class="loadingElem" *ngIf="isLoadingElem(item) ?? false"><loaderIcon></loaderIcon></div>
        <p><b>{{item.concepto}}</b></p>
        <p><b>{{item.clienteNombre}}</b></p>
        <p class="pipeUbicacion">{{item.ubicacion}}</p>
      </div>
    </ng-template>
    <ng-template pipeLinePreview let-item="item">
      {{item.concepto}}
    </ng-template>
  </pipeLine>

  <!-- LLEVARME EL CODIGO DE ABAJO A OTRO COMPONENTE Y RECORRERLO CON UN NGIF -->

<div class="segundaSeccion">
  <div class="resumenSection">

    <div class="mesActual">
      <p class="headerBox">MES ACTUAL</p>
      <div class="containers">

        <div class="box">
          <div class="topBox boxColorAmarillo"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{pendientesMes}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">PENDIENTES</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorNaranja"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{contactadoMes}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">CONTACTADO</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorVerdeClaro"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{visitadoMes}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">VISITADO</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorVerde"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{enviadoMes}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">ENTREGADO</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mesActual">
      <h4 class="headerBox">AÑO ACTUAL</h4>
      <div class="containers">

        <div class="box">
          <div class="topBox boxColorAmarillo"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{pendientesAno}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">PENDIENTES</p>
          </div>
        </div>
        
        <div class="box">
          <div class="topBox boxColorNaranja"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{contactadoAno}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">CONTACTADO</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorVerdeClaro"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{visitadoAno}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">VISITADO</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorVerde"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{enviadoAno}}</p>
            <!-- <p class="absolute">(60%)</p> -->
            <p class="smallNumber">ENTREGADO</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mesActual" *ngIf="anterior">
      <h4 class="headerBox">AÑO ANTERIOR</h4>
      <div class="containers">

        <div class="box">
          <div class="topBox boxColorAmarillo"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{anoAnterior.pendiente}}</p>
            <p class="absolute"></p>
            <p class="smallNumber">PENDIENTES</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorNaranja"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{anoAnterior.contactado}}</p>
            <p class="absolute"></p>
            <p class="smallNumber">CONTACTADO</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorVerdeClaro"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{anoAnterior.visitado}}</p>
            <p class="absolute"></p>
            <p class="smallNumber">VISITADO</p>
          </div>
        </div>

        <div class="box">
          <div class="topBox boxColorVerde"></div>
          <div class="bottomBox">
            <p class="bigNumber">{{anoAnterior.enviado}}</p>
            <p class="absolute"></p>
            <p class="smallNumber">ENTREGADO</p>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
</div>
