import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoDepartamentoPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDepartamentoPopup/NuevoDepartamentoPopup.component';
import { EditDepartamentosPopupComponent } from 'src/app/Popups/EditPopup/EditDepartamentosPopup/EditDepartamentosPopup.component';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-AdminEmpleadosDepartamentos',
  templateUrl: './AdminEmpleadosDepartamentos.component.html',
  styleUrls: ['./AdminEmpleadosDepartamentos.component.scss']
})
export class AdminEmpleadosDepartamentosComponent extends iUnsubscribeDestroy implements OnInit {


  @Input("dataTable") dateDepartamentos: Array<any> = [];
  @Input("isLoading") isLoading: boolean = false;

  @Output() refreshData = new EventEmitter();

  forceUpdateWidth = new Subject()


  //Tabla
  displayedColumnsDepartamentos: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
  ];

  optionsDepartamentos: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
    moreIconsHead: [{ callback: () => { this.exportexcel() }, icon: "download" }],
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  }

  constructor(private popup: PopupService, private notificationSer: NotificationsService, private departamentoSe: DepartamentosService) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.forceUpdateWidth.next(true)
    }, 1000);
  }

  openPopupDepartamento(rowDepartamento: any) { }



  editDepartamento(event: any) {
    this.popup.openPopup(EditDepartamentosPopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res !== null) {
        this.refreshData.emit(true)
        this._unsubInd4.next("")
      }
    })

  }

  popupDeleteDepartamento(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      let idFromButton = [res.dataReturn.id]
      this.deleteVehicle(idFromButton)
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }

  deleteVehicle(idFromButton: number[]): void {
    this.departamentoSe.delete(idFromButton);
    this.departamentoSe.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El departamento ha sido borrado correctamente")
      this.ngOnInit();
      this.refreshData.emit(true)
      this._unsubInd3.next("")
    })
    this.departamentoSe.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(`${res.message}`)
      this._unsub.next("")
    })
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: event.length + " departamentos" })
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      let idFromButton = event.map((item: any) => item.id)
      this.deleteVehicle(idFromButton)
      this._unsubInd.next("")
    })
  }

  abrirCrearDepartamento() {
    this.popup.openPopup(NuevoDepartamentoPopupComponent);
    this.popup.returnData().pipe(takeUntil(this._unsubInd5)).subscribe(res => {
      if (res !== null) {
        this.refreshData.emit(true)
        this._unsubInd5.next("")
      }
    })
  }

    /**
   * DESCARGA DE PARTES EN EXCEL
   */

    exportexcel(): void {
      let table = document.createElement("table")
      table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
      table.querySelector("thead .iconsActions")?.remove();
      table.querySelector("thead .checkboxContainer")?.remove();
      let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
      let filteredRows = rows.filter((row) => {
        return row.querySelector('.checkboxContainer mat-checkbox ')!.classList.contains('mat-checkbox-checked')
      })
      if (filteredRows.length > 0) {
        filteredRows.forEach((row) => {
          let newRow = row.cloneNode(true) as HTMLElement;
          newRow.querySelector('td:last-of-type')?.remove();
          newRow.querySelector('td:first-of-type')?.remove();
          table.append(newRow)
        })
      } else {
        table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
        table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
          element.remove();
        })
        table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
          element.remove();
        })
      }
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
      /* save to file */
      XLSX.writeFile(wb, 'Departamentos.xlsx');
  
    }
  

}
