import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { takeUntil } from 'rxjs';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';

@Component({
  selector: 'app-OperarioDocumentosObraId',
  templateUrl: './OperarioDocumentosObraId.component.html',
  styleUrls: ['./OperarioDocumentosObraId.component.scss']
})
export class OperarioDocumentosObraIdComponent extends iUnsubscribeDestroy implements OnInit {

  idObra: any = null;
  conceptoObra: any = 'Cargando...';
  dataDocumentos: any = null;

  constructor(private documentoSE:DocumentoService, private activatedRoute:ActivatedRoute, private popup:PopupService) {
    super();
  }

  ngOnInit() {
    this.getIdFromParams()
    this.getDocumentosPorObras()
  }

  /**
   * 
   * SETTER
   */

  setter(data:any){
    if(data.length === 0){
      this.dataDocumentos = []
      this.conceptoObra = "No hay documentos para esta obra"
      return
    }
    this.conceptoObra = data[0]?.obra
    this.dataDocumentos = data
  }

  /**
   * OPEN DOCUMENT
   */

  openDocumento(event:any){
    if(event.url[event.url.length - 1]==="f"){
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup')
    }
    if(event.url[event.url.length - 1]==="g"){
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event, 'fullSizePopup')
    }
  }

  /**
   * PARAMS
   */

  getIdFromParams(){
    this.idObra = this.activatedRoute.snapshot.paramMap.get('id')
  }

  /**
   * API CALLS
   */

  getDocumentosPorObras(){
    this.documentoSE.getAllDocumentByObra(this.idObra);
    this.documentoSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      let {data} = res.data;
      this.setter(data)
      this._unsubInd.next('')
    })
  }

}
