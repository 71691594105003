<FullContainer>
  <div class="titleSec">
    <i class="material-icons" (click)="goBack()">arrow_back</i>
    <h2 class="sectionTitleCustom">EMPLEADOS /</h2>
    <h3 class="sectionSubtitle">{{user.nombre}} {{user.apellidos}}</h3>
  </div>
  <pill>
    <div class="gridsContainer">
      <div class="GridElements">
        <div class="elementInformation">
          <label>Nombre :</label>
          <span>{{user.nombre}} {{user.apellidos}}</span>
        </div>
        <div class="elementInformation">
          <label>Email :</label>
          <span>{{user.mail}}</span>
        </div>
        <div class="elementInformation">
          <label>Teléfono :</label>
          <span>{{user.telefono}}</span>
        </div>
        <div class="elementInformation">
          <label>DNI :</label>
          <span>{{user.dni}}</span>
        </div>
        <div class="separatorGrid"></div>
        <div class="elementInformation">
          <label>Talla camiseta :</label>
          <span>{{(user.infoEmpleado) ? user.infoEmpleado.tallaCam : '-'}}</span>
        </div>
        <div class="elementInformation">
          <label>Talla pantalón :</label>
          <span>{{(user.infoEmpleado) ? user.infoEmpleado.tallaPan : '-'}}</span>
        </div>
        <div class="elementInformation">
          <label>Talla botas :</label>
          <span>{{(user.infoEmpleado) ? user.infoEmpleado.tallaBota : '-'}}</span>
        </div>
        <div class="separatorGrid"></div>
        <div class="elementInformation">
          <label>Tiempo trabajado ({{mesActual}}) :</label>
          <span>{{horasTrabajadasData[0]?.horas}}</span>
        </div>
        <div class="elementInformation">
          <label>Tiempo trabajado ({{mesAnterior}}) :</label>
          <span>{{horasTrabajadasData[1]?.horas}}</span>
        </div>
        <div class="elementInformation">
          <label>Tiempo trabajado ({{mesAnteriorAnterior}}) :</label>
          <span>{{horasTrabajadasData[2]?.horas}}</span>
        </div>
      </div>
      <div class="GridElements">
        <div class="elementInformation">
          <label>Fecha alta :</label>
          <span>{{getParseDate(user.fechaAlta)}}</span>
        </div>
        <div class="elementInformation">
          <label>Proyectos asignados :</label>
          <div class="proyectosAsignadosContainer">
            <div class="proyectoAsignado" *ngFor="let obra of user.obras">
              <span>{{obra.concepto}} en {{obra.ubicacion}}</span>
              <i class="material-icons" (click)="desVincular(obra)">close</i>
            </div>
          </div>
        </div>
        <!--<div class="separatorGrid"></div>
        <div class="elementInformation">
          <label>Partes entregados (a fecha de hoy) :</label>
          <span>10 / 15</span>
        </div>
        <div class="elementInformation">
          <label>Partes sin entregar :</label>
          <div class="partesSinEntregar">
            <span>10/10/2022</span>
            <span>10/10/2022</span>
            <span>10/10/2022</span>
            <span>10/10/2022</span>
          </div>
        </div>-->
      </div>
    </div>
  </pill>
  <div class="userDocuments">
    <h3>Documentos requeridos</h3>
    <pill classList="fullHeight">
      <div class="informationContainer documentoRequeridos">
        <div class="documento">
          <div class="documento_title">
            <span *ngIf="isWarningDniAnverso"><b>DNI Delante</b></span>
            <span (click)="verDocumento(DNIANVERSO)"  [ngClass]="{documento_ver: DNIANVERSO}" *ngIf="!isWarningDniAnverso"><b>DNI Delante</b></span>
            <btn classList="secundario fino"  (click)="adjuntarFoto('DNIAnverso')" >Adjuntar Documento</btn>
          </div>
          <div class="documento_caducidad">
            <span *ngIf="documentosRequeridos.dniAnverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.dniAnverso.fechaCaducidad | date:'shortDate'}}</span>
            <div class="warningContainer" *ngIf="isWarningDniAnverso">
              <i class="material-icons-outlined">report_problem</i>
              <span>{{textWarningDniAnverso}}</span>
            </div>
          </div>
        </div>
        <div class="documento">
          <div class="documento_title">
            <span *ngIf="isWarningDniReverso"><b>DNI Detrás</b></span>
            <span (click)="verDocumento(DNIREVERSO)" [ngClass]="{documento_ver: DNIREVERSO}" *ngIf="!isWarningDniReverso"><b>DNI Detrás</b></span>
            <btn classList="secundario fino" (click)="adjuntarFoto('DNIReverso')" >Adjuntar Documento</btn>
          </div>
          <div class="documento_caducidad">
            <span *ngIf="documentosRequeridos.dniReverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.dniReverso.fechaCaducidad | date:'shortDate'}}</span>
            <div class="warningContainer" *ngIf="isWarningDniReverso">
              <i class="material-icons-outlined">report_problem</i>
              <span>{{textWarningDniReverso}}</span>
            </div>
          </div>
        </div>
        <div class="documento">
          <div class="documento_title">
            <span *ngIf="isWarningConducirAnverso"><b>Carnet de conducir Delante</b></span>
            <span (click)="verDocumento(CONDUCIRANVERSO)" [ngClass]="{documento_ver: CONDUCIRANVERSO}" *ngIf="!isWarningConducirAnverso"><b>Carnet de conducir Delante</b></span>
            <btn classList="secundario fino" (click)="adjuntarFoto('carnetAnverso')" >Adjuntar Documento</btn>
          </div>
          <div class="documento_caducidad">
            <span *ngIf="documentosRequeridos.conducirAnverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.conducirAnverso.fechaCaducidad | date:'shortDate'}}</span>
            <div class="warningContainer" *ngIf="isWarningConducirAnverso">
              <i class="material-icons-outlined">report_problem</i>
              <span>{{textWarningConducirAnverso}}</span>
            </div>
          </div>
        </div>
        <div class="documento">
          <div class="documento_title">
            <span *ngIf="isWarningConducirReverso"><b>Carnet de conducir Detrás</b></span>
            <span (click)="verDocumento(CONDUCIRREVERSO)" [ngClass]="{documento_ver: CONDUCIRREVERSO}" *ngIf="!isWarningConducirReverso"><b>Carnet de conducir Detrás</b></span>
            <btn classList="secundario fino" (click)="adjuntarFoto('carnetReverso')" >Adjuntar Documento</btn>
          </div>
          <div class="documento_caducidad">
            <span *ngIf="documentosRequeridos.conducirReverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.conducirReverso.fechaCaducidad | date:'shortDate'}}</span>
            <div class="warningContainer" *ngIf="isWarningConducirReverso">
              <i class="material-icons-outlined">report_problem</i>
              <span>{{textWarningConducirReverso}}</span>
            </div>
          </div>
        </div>
      </div>
    </pill>
  </div>

  <div class="nominas">
    <h3>Nóminas</h3>

    <pill class="tablePil" classList="tablePill">
    <dataTable [forceUpdateWidth]="forceUpdateWidth" [data]="dataNominas" [displayedColumns]="displayedColumnsNominas" [options]="optionsNominas" [isLoading]="isLoading" (clickRow)="nominaDetalle($event)" (delete)="deleteNomina($event)" ></dataTable>
    </pill>
  </div>
</FullContainer>