<div class="fullPopup" id="fullPopup" [class.noImage]="imagenes.length == 0">
  <div class="headerPopup" >
    <div class="headerElements" id="noImprimir">
      <div class="closePopup">
        <span (click)="closePopup()" class="iconClosePopup">
          <ng-container  >
            <mat-icon>
              close
            </mat-icon>
          </ng-container>
        </span>
      </div>
    </div>
  </div>

  <div class="contentContainer" id="PDF" #PDF >
    <p class="noData" *ngIf="imagenes.length == 0">No hay imagenes todavía</p>
    <div class="imagenesBlock" id="imprimir" *ngIf="imagenes.length > 0">
      <!-- NGFOR imagenes -->
      <div class="imagenDiv" *ngFor="let imagen of imagenes" (click)="openImageVisorPopup(imagen)" >
        <div class="hover">
          <button  fileInput [accept]="['image/jpeg', 'image/png']" [(ngModel)]="imagenAux"
          (change)="getImage(imagenAux, imagen.id)"><mat-icon >edit</mat-icon></button>
          <button (click)="$event.stopPropagation();deleteImage(imagen)"> <mat-icon >delete</mat-icon></button>
         <button (click)="$event.stopPropagation();downloadImage(imagen.url)">  <mat-icon >download</mat-icon> </button>
          <!-- <a href="/assets/Images/MapsMarkers/contactado.png" download> <mat-icon >download</mat-icon></a> -->

        </div>
        <img class="imagen" [src]="imagen.url" alt="" onerror="this.src='/assets/Images/Placeholder.jpg'">
      </div>
    </div>

  </div>
</div>
