<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">Nuevo proveedor</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Razón social</label>
          <CustomInput placeholder="Razón social" [(ngModel)]="nombre" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Nombre comercial</label>
          <CustomInput placeholder="Nombre comercial" [(ngModel)]="apellidos"></CustomInput>
        </div>
        <div class="inputForm">
          <label>CIF</label>
          <CustomInput placeholder="CIF" [(ngModel)]="dni" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Dirección</label>
          <CustomInput placeholder="Dirección" [(ngModel)]="direccion" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Población</label>
          <CustomInput placeholder="Población" [(ngModel)]="poblacion" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Codigo postal</label>
          <CustomInput placeholder="Código postal" [(ngModel)]="codigoPostal" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email</label>
          <CustomInput placeholder="email" [(ngModel)]="mail" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email prevención</label>
          <CustomInput placeholder="Email prevención" [(ngModel)]="emailPrevencion" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Teléfono</label>
          <CustomInput placeholder="telefono" [(ngModel)]="telefono" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Teléfono 2</label>
          <CustomInput placeholder="telefono2" [(ngModel)]="telefono2" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>IVA</label>
          <CustomInput placeholder="IVA" [(ngModel)]="iva" ></CustomInput>
        </div>
        <div class="inputForm">
          <label>Asignar obras</label>
          <selector class="sizeInput" [options]="optionsObras" [(ngModel)]="obras" (change)="obraFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Estado inicial de las facturas validado</label>
          <p ><mat-checkbox [(ngModel)]="checkbox"></mat-checkbox></p>

          </div>
        </div>
        <!-- <div class="separatorGrid"></div> -->

      </div>
      <br><hr><br>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="submit()">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>