import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iFactura } from 'src/app/Shared/Interfaces/Api/iFactura';

@Injectable({
  providedIn: 'root',
})
export class FacturaService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultFacturasJefeObra = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    super();
  }

  getResultFacturasJefeObra() {
    return this._resultFacturasJefeObra;
  }

  clearResultFacturasJefeObra() {
    this._resultFacturasJefeObra.next(null);
  }

  getAll() {
    this.http.get(this.urlApi + '/api/factura', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedFactura = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iFactura.convertFromCollection(normalizedFactura),
        });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultError', this.res, {
          method: this.getAll,
          args: [],
        });
        this.checkStatusError(error);
      },
    });
  }
  getFacturasPendientesJefeObra(
    idUser: number,
    fechaIni: string = '',
    fechaFin: string = '',
    estado: string = '',
    proveedor: string = '',
    busqueda: string = ''
  ) {
    let send = {
      num_devoluciones: 500000,
      num_pagina: 1,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      estado: estado,
      proveedor: proveedor,
      busqueda: busqueda,
    };
    this.http
      .get(
        this.urlApi +
          '/api/factura/pendiente/jefe/' +
          idUser +
          this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(this._resultFacturasJefeObra, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultFacturasJefeObra,
            this.res,
            true,
            { method: this.getFacturasPendientesJefeObra, args: [idUser] }
          );
          this.checkStatusError(error);
        },
      });
  }
  getAllProveedor(
    id: number,
    page: number,
    max: number,
    busqueda: string = '',
    fechaIni: string = '',
    fechaFin: string = '',
    estado: string = '',
    proyecto: string = ''
  ) {
    let send = {
      num_devoluciones: 500000,
      num_pagina: 1,
      busqueda: busqueda,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      estado: estado,
      proyecto: proyecto,
    };
    this.http
      .get(
        this.urlApi + '/api/factura/proveedor/' + id + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedFactura = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iFactura.convertFromCollection(normalizedFactura),
              total: data.total,
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [
              id,
              page,
              max,
              busqueda,
              fechaIni,
              fechaFin,
              estado,
              proyecto,
            ],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/factura/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedFactura = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iFactura.convertFromObject(normalizedFactura),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(factura: iFactura) {
    this.http
      .post(this.urlApi + '/api/factura', factura, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [factura],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(factura: iFactura) {
    this.http
      .put(
        this.urlApi + '/api/factura/' + factura.id,
        factura,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [factura],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'facturas_ids');
    this.http
      .delete(this.urlApi + '/api/factura', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
