<div class="fullPopup" >
  <div class="headerPopup">
    <div class="headerElements" id="noImprimir">

      <div class="closePopup">
        <span (click)="closePopup()" class="iconClosePopup">
          <ng-container>
            <mat-icon>
              close
            </mat-icon>
          </ng-container>
        </span>
      </div>


    </div>
  </div>

  <div class="contentContainer">
    <ngx-extended-pdf-viewer
    [src]="pdf"
    [useBrowserLocale]="true"
    [textLayer]="true"
    [showHandToolButton]="true"
    [showPresentationModeButton]="true"
    [showDownloadButton]="false"
  ></ngx-extended-pdf-viewer>
  </div>
</div>