<div class="fullView">
  <div class="mainBlock">
    <div class="header">
      <div class="buttonCancelar" (click)="forceClosePopup()">
        <p class="textCancelar">cancelar </p>
        <p>x</p>
      </div>
      <div class="line" *ngIf="!checkboxAusente">
        <span class="buttonHeader1" (click)="setStep(0)" [ngClass]="{active: step === 0}" *ngIf="!checkboxAusente">1</span>
        <span class="buttonHeader2" (click)="setStep(1)" [ngClass]="{active: step === 1}" *ngIf="!checkboxAusente">2</span>
        <span class="buttonHeader3" (click)="setStep(2)" [ngClass]="{active: step === 2}" *ngIf="!checkboxAusente">3</span>
      </div>
    </div>

    <ng-container *ngIf="step===0">
      <div class="contentBlock">
        <div class="whiteBox">
          <div class="infoSection">
            <p class="headerSeleccion"> NOMBRE:</p>
            <p class="infoText">{{datosEmpleado.nombre}} {{datosEmpleado.apellidos}}</p>
          </div>
          <div class="infoSection">
            <p class="headerSeleccion"> FECHA:</p>
            <p class="infoText">{{datosEmpleado.fecha}}</p>
          </div>
        </div>
        <div class="whiteBox" *ngIf="!checkboxAusente">
          <div class="contentWhiteBox" >
            <p class="headerSeleccion .pb1">DIRECCIÓN</p>
            <div class="selector">
              <selector [options]="optionDireccion" [(ngModel)]="direccion" class="maxWidth" (change)="changeDireccion($event)"></selector>
            </div>
          </div>
          <div class="contentWhiteBox">
            <p class="headerSeleccion .pb1">Nº OBRA</p>
            <CustomInput classList="fullWidth" placeholder="Nº OBRA" [readonly]="true" [(ngModel)]="nObra">
            </CustomInput>
          </div>
        </div>
        <div class="whiteBox">
          <div class="contentWhiteBox horario">
            <p class="headerSeleccion">SELECCIONAR HORARIO</p>
            <div class="bloqueSelectores" *ngIf="!checkboxAusente">
              <div class="selectores" >
                <p>Inicio</p>
                <div class="inputSmall">
                  <CustomInput classList="fullWidth" placeholder="Inicio" [type]="'time'"
                    [(ngModel)]="horaInicio" (change)="changeTime($event)">
                  </CustomInput>
                </div>
              </div>
              <div class="icono">
                <ng-container>
                  <mat-icon>
                    chevron_right
                  </mat-icon>
                </ng-container>
              </div>
              <div class="selectores" >
                <p>Fin</p>
                <div class="inputSmall">
                  <CustomInput classList="fullWidth" placeholder="Inicio" [type]="'time'"
                    [(ngModel)]="horaFin" (change)="changeTime($event)">
                  </CustomInput>
                </div>
              </div>
            </div>
            <div class="bloqueTotal" *ngIf="!checkboxAusente">
              <p class="total">Total</p>
              <p class="headerSeleccion">{{objectToPass.horas}}</p>
            </div>
            <p><mat-checkbox [(ngModel)]="checkboxAusente">He estado ausente</mat-checkbox></p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="step===1">
      <div class="contentBlock">
        <div class="whiteBox">
          <div class="contentWhiteBox horario">
            <p class="headerSeleccion .pb1">TAREA</p>
            <div class="selector">
              <selector [options]="optionTarea" class="maxWidth" [(ngModel)]="tarea" (change)="changeTarea($event)"></selector>
            </div>
            <p class="headerSeleccion .pb1">VEHÍCULO</p>
            <div class="selector">
              <selector [options]="optionVehiculo" class="maxWidth" [(ngModel)]="objectToPass.vehiculo"></selector>
            </div>
            <div class="comentarios">
              <p class="headerSeleccion .pb1">COMENTARIOS</p>
              <div class="textArea">
                <CustomTextArea [placeHolder]="'Rellena aquí...'" [(ngModel)]="objectToPass.comentario"></CustomTextArea>
              </div>

            </div>
          </div>

        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="step===2">

      <div class="contentBlock">
        <div class="whiteBox">
          <div class="contentWhiteBox horario button">
            <!-- <button class="redButton" [multiple]="true" fileInput [accept]="['image/*']" [(ngModel)]="imagen"
            (change)="addImage(imagen)">+</button> -->
            <div class="inputPlus">
              <label for="inputFileExtraButton" class="custom-file-upload">
                <mat-icon>image</mat-icon>
            </label>            
              <input  multiple id="inputFileExtraButton" type="file" name="image" accept="image/png, image/jpeg, image/jpg, image/gif" (change)="addImage2($event)">
            </div>
            <p class="addFotos">AÑADIR FOTOS</p>
            <ng-container *ngIf="imagen">
              <mat-icon class="green">check_circle</mat-icon>
            </ng-container>
            <ng-container *ngIf="!imagen && isloading">
              <mat-icon class="yellow">refresh</mat-icon>
            </ng-container>
  
          </div>
        </div>
      </div>



    </ng-container>



    <div class="footer">
      <btn classList="generalbutton backward sizeButton" (click)="moveBack()"  *ngIf="!checkboxAusente " [ngClass]="{backwardDisabled: step === 0}" >
        <ng-container>
          <mat-icon>arrow_back</mat-icon>
        </ng-container>
      </btn>
      <btn classList="generalbutton forward sizeButton" [ngClass]="{forwardDisabled: step === 0 && (objectToPass.horas === 'La hora de inicio no puede ser mayor que la hora de fin' || objectToPass.horas === 'La hora de inicio no puede ser menor que las 8:00' || objectToPass.horas === 'La jornada no puede ser mayor de 8 horas' || objectToPass.obra === '' || moreThan8Hours) || step ===1 && (objectToPass.tarea =='' || objectToPass.comentario =='' ) }" (click)="moveForward()" *ngIf="step !==2 && !checkboxAusente">
        <ng-container>
          <mat-icon>arrow_forward</mat-icon>
        </ng-container>
      </btn>
      <btn classList="generalbutton forward sizeButton" (click)="submit()" *ngIf="step ===2  ||checkboxAusente ">
        <ng-container>
          <mat-icon>check</mat-icon>
        </ng-container>
      </btn>
    </div>
  </div>