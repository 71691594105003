import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/User.service';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';

@Component({
  selector: 'app-JefeObras',
  templateUrl: './JefeObras.component.html',
  styleUrls: ['./JefeObras.component.scss'],
})
export class JefeObrasComponent extends iUnsubscribeDestroy implements OnInit {
  forceUpdateWidth = new Subject();

  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Cliente', property: 'cliente', align: 'center' },
    {
      label: 'Ubicación',
      property: 'ubicacion',
      align: 'center',
      linkAction: (rowElem) => {},
    },
    { label: 'Nº Obra', property: 'numero', align: 'center' },
    {
      label: 'Partes pendientes',
      property: 'partesPendientes',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  optionsObras: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
  };
  userID!: number;

  isFilterOpen = false;
  isSearchOpen = false;
  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: '',
  };

  // Selectores
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Cliente',
  };
  optionConcepto: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Concepto',
  };
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Activa' },
      { id: 0, label: 'Finalizada' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  // FILTROS

  cliente: string = '';
  estado: string = '';
  busqueda: string = '';
  concepto: string = '';

    // Paginacion

    paginacionTotal!: number;
    paginacionActual!: number;
    numMaxPag!: number;
    numMin: number = 1;
    numMax: number = 20;
  
    pagina: number = 1;
    maximo: number = 20;
  
  

  dataObras = [];
  isLoading: boolean = true;
  constructor(
    private obraSE: ObraService,
    private router: Router,
    private popup: PopupService,
    private notificaciones: NotificationsService,
    private userSE: UserService,
    private clienteSE: ClienteService
  ) {
    super();
  }

  ngOnInit() {
    this.getMe();
    this.getClientes();
  }

  /**
   * FUNCTIONALITIES
   */
  editObra(event: Event) {
    this.popup.openPopup(EditObrasPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.isLoading = true;
        this.getAllObras();
        this._unsubInd2.next('');
      });
  }

  changeFilterOpen(newVal: boolean) {
    this.isFilterOpen = newVal;
    this.updateTableMaxHeight();
  }
  changeSearchOpen(newVal: boolean) {
    this.isSearchOpen = newVal;
    this.updateTableMaxHeight();
  }
  updateTableMaxHeight() {
    let val = '';
    if (this.isFilterOpen) {
      val = val + ' - 68px - 1em';
    }
    if (this.isSearchOpen) {
      val = val + ' - 68px - 1em';
    }
    this.optionsObras.maxHeight =
      'calc(100vh - 78px - 4em - 106px - 47px - 4em' + val + ')';
  }

    /**
   * NEXT PAGE
   */

    nextPage() {
      if (this.numMax === this.paginacionTotal) { return }
      this.pagina++
      this.numMin = this.numMin + this.maximo
      this.numMax = (this.numMax + this.maximo < this.paginacionTotal) ? this.numMax + this.maximo : this.paginacionTotal
      this.getAllObras();
    }
  
    /**
     * PREVIOUS PAGE
    */
  
    previousPage() {
      if (this.numMin === 1) { return }
      this.numMin = this.numMin - this.maximo;
      this.numMax = (this.numMax - this.maximo < this.maximo) ? this.maximo : this.numMax - this.maximo
      this.pagina--
      this.getAllObras();
    }
  

  /**
   * FILTROS
   */

  respuestaBusqueda(event: any) {
    if (event === null) {
      this.busqueda = '';
    }
    if (event !== null) {
      this.busqueda = event;
    }
    this.getAllObras();;
  }

  getClienteFromSelector(event: any) {
    if (event === null) {
      this.cliente = '';
    }
    if (event !== null) {
      this.cliente = event.id;
    }
    this.getAllObras();;
  }

  getEstadoFromSelector(event: any) {
    if (event === null) {
      this.estado = '';
    }
    if (event !== null) {
      this.estado = event.id;
    }
    this.getAllObras();;
  }

  getConceptoFromSelector(event: any) {
    if (event === null) {
      this.concepto = '';
    }
    if (event !== null) {
      this.concepto = event.label;
    }

    this.getAllObras();;
  }

  deleteObra(event: any, type: string) {
    switch (type) {
      case 'single':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'la obra',
        });
        break;
      case 'multiple':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'las obras',
        });
        break;
      case 'multipleSelected':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'las obras seleccionadas',
        });
        break;
    }
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        switch (type) {
          case 'single':
            this.removeObra(event['id']);
            break;
          case 'multiple':
            this.popup.openPopup(AsegurarDeletePopupComponent, {
              nombre: 'las obras',
            });
            break;
          case 'multipleSelected':
            this.popup.openPopup(AsegurarDeletePopupComponent, {
              nombre: 'las obras seleccionadas',
            });
            break;
        }
        this._unsubInd2.next('');
      });
  }
  navigateToPartesObras(event: any) {
    this.router.navigate(['/jefe/obras/' + event['id']]);
  }
  /**
   * API Calls
   */

  getAllObras() {
    this.obraSE.getObrasUserIdTotal(this.userID, this.pagina, this.maximo, this.busqueda, this.concepto, this.estado,this.cliente);
    this.obraSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }

        let { data } = res;
        let total = data.total;
        this.paginacionTotal = total
        this.numMaxPag = Math.ceil(total / this.maximo)
        if (total < this.maximo) this.numMax = total
        this.dataObras = data.data;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1);
        this.isLoading = false;
        this._unsubInd2.next('');
      });
  }
  getMe() {
    this.userSE.getMe();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        this.userID = data.id;
        localStorage.setItem('nmsr', this.userID.toString());
        this.getAllObras();
        this._unsubInd3.next('');
      });
  }
  removeObra(id: string[] | number[]): void {
    this.obraSE.delete(id);
    this.obraSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificaciones.showFeedBack(
          'La obra ha sido borrado correctamente'
        );
        this.ngOnInit();
        this._unsubInd4.next('');
      });
    this.obraSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificaciones.showError(res.message);
        this._unsub.next('');
      });
  }

  getClientes() {
    this.clienteSE.getAll();
    this.clienteSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res
      let mappedCliente = data.map((elem: any) => { return { id: elem.id, label: elem.nombre } })

      this.optionCliente.items = mappedCliente
      this._unsubInd2.next("");
    })
  }

}
