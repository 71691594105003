import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { takeUntil } from 'rxjs';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';
import { TipoDocumentoService } from 'src/app/Services/Api/TipoDocumento.service';

@Component({
  selector: 'app-NuevoDocumentoPorObra',
  templateUrl: './NuevoDocumentoPorObra.component.html',
  styleUrls: ['./NuevoDocumentoPorObra.component.scss'],
})
export class NuevoDocumentoPorObraComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // Data ngModel

  fechaCaducidad: string = '';
  tipoDocumento: number = -1;
  tipoDocumentoLabel: string = '';
  estado: string = '';
  obra: string = '';
  usuario: string = '';
  url: string = '';
  carpeta: string = '';
  titulo!: string;
  comentario!: string;
  vehiculo!: number;
  // Docuemntos
  documento: any = '';
  documentos: any = [];
  isloading: boolean = false;
  // Placeholder
  placeholderFechaCaducidad: string = new Date().toISOString().split('T')[0];
  // Opciones del selector
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Aprobado' },
      { id: 2, label: 'Pendiente' },
      { id: 3, label: 'Rechazado' },
      { id: 4, label: 'Pendiente de firma' },
    ],
    clearable: false,
    placeholder: 'Estado',
    append: true,
    search: true,
  };
  optionTipoDocumento: iOptionsSelector = {
    items: [],
    bindLabel: 'nombre',
    clearable: false,
    placeholder: 'Tipo de documento',
    append: true,
    search: true,
  };
  optionObra: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Obra',
    append: true,
    search: true,
  };
  optionCarpeta: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Carpeta',
    append: true,
    search: true,
  };
  optionVehiculos: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Vehiculo',
    append: true,
    search: true,
  };
  optionUsers: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Usuario',
    append: true,
    search: true,
  };
  constructor(
    private carpetaSE: CarpetaService,
    private obraSE: ObraService,
    private documentoSE: DocumentoService,
    private vehiculoSe: VehiculoService,
    private userSe: UserService,
    public dialogRef: MatDialogRef<NuevoDocumentoPorObraComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService,
    private tipoDocSE: TipoDocumentoService
  ) {
    super();
  }
  ngOnInit() {
    this.getAllUsers();
    this.getAllVehiculos();
    this.getAllTipoDocumento();
    this.getAllCarpetas();
  }
  /**
   * Cerrar
   */
  closePopup() {
    this.dialogRef.close();
  }
  forceClosePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  /**
   * Selectores
   */
  obtenerTipoDocumento(event: any) {
    this.tipoDocumento = event.id;
    this.tipoDocumentoLabel = event.nombre;
  }
  obtenerEstado(event: any) {
    this.estado = event.label;
  }
  getCarpetaFromInput(event: any) {
    this.carpeta = event.id;
  }

  obtenerUsuario(event: any) {
    this.usuario = event.id;
  }
  obtenerVehiculo(event: any) {
    this.vehiculo = event.id;
  }
  /**
   * DataPicker
   */

  getFechaDeCaducidad(event: any) {
    this.fechaCaducidad = event.value;
  }

  /**
   * Submit
   */

  submitNuevoDocumento() {
    if (
      this.fechaCaducidad == '' ||
      this.tipoDocumento == -1 ||
      this.estado == '' ||
      this.url == ''
    ) {
      this.notificationSer.showWarning('Por favor, rellene todos los campos');
      return;
    }
    let objectToPass: any = {
      fechaCaducidad: this.fechaCaducidad,
      tipoDocumento: this.tipoDocumento,
      estado: this.estado,
      titulo: this.titulo,
      url: this.url,
      obra: this.modalData,
      carpeta: this.carpeta,
      comentario: this.comentario,
    };

    this.documentoSE.create(objectToPass);
    this.documentoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack('Documento creado correctamente');
        this.forceClosePopup('submitted');
        this._unsubInd4.next('');
      });
    this.documentoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  /***
   * InputFile
   */

  addDocumento(event: any) {
    if (
      event[0].file.type != 'image/png' &&
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/jpg' &&
      event[0].file.type != 'application/pdf'
    ) {
      this.notificationSer.showWarning(
        'Formato de archivo no admitido, por favor suba un archivo .png, .pdf o .jpg'
      );
      this.documento = null;
    } else {
      this.isloading = true;
      event.forEach((element: any) => {
        this.url = element.base64;
      });
      this.isloading = false;
    }
  }

  /**
   * API Calls
   */

  getAllCarpetas(): void {
    this.carpetaSE.getAll();
    this.carpetaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        let { data } = res.data;

        let mappedObras = data.map((d: any) => {
          return { id: d.id, label: d.nombre };
        });
        this.optionCarpeta.items = mappedObras;
        this._unsubInd2.next('');
      });
  }
  getAllTipoDocumento() {
    this.tipoDocSE.getAll();
    this.tipoDocSE
      .getResult()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        this.optionTipoDocumento.items = data.filter((d: any) => {
          if (d.nombre.includes('obra')) {
            return d;
          }
        });

        this._unsubInd5.next('');
      });
  }

  getAllUsers(): void {
    this.userSe.getAll();
    this.userSe
      .getResultAll()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        let mappedUsers = data.map((d: any) => {
          return { id: d.id, label: `${d.nombre} ${d.apellidos}` };
        });
        this.optionUsers.items = mappedUsers;
        this._unsubInd.next('');
      });
  }

  getAllVehiculos(): void {
    this.vehiculoSe.getAll();
    this.vehiculoSe
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;
        let mappedVehiculos = data.map((d: any) => {
          return { id: d.id, label: d.nombre };
        });
        this.optionVehiculos.items = mappedVehiculos;
      });
  }
}
