import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-EditarProveedorPopupComponent',
  templateUrl: './EditarProveedorPopupComponent.component.html',
  styleUrls: ['./EditarProveedorPopupComponent.component.scss']
})
export class EditarProveedorPopupComponentComponent extends iUnsubscribeDestroy implements OnInit {

  // Data
  dataFromSingle: any = this.modalData
  
  // ObraUser
  obraUser: any = []

  // NGMODEL
  nombre: string = "";
  apellidos: string = "";
  telefono: string = "";
  telefono2: string = "";
  mail: string = "";
  emailPrevencion: string = "";
  cif: string = "";
  direccion: string = "";
  poblacion: string = "";
  codigoPostal: string = "";
  iva: number | null = null ;
  obras: any = this.obraUser;


  // OBJECT TO PASS
  obj: any = {
    id: this.dataFromSingle.id
  }


  // Opciones selectores
  optionsObras: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: "",
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 1,
      multipleTextReplacer: "Varias obras seleccionados"
    },
    append: true,
  }


  constructor(private userSe: UserService, private obraSe: ObraService, public dialogRef: MatDialogRef<EditarProveedorPopupComponentComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getObras()
    this.getUserById()

  }


  forceClosePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  setData() {
     
    this.nombre = this.dataFromSingle.nombre;
    this.apellidos = this.dataFromSingle.apellidos;
    this.telefono = this.dataFromSingle.telefono;
    this.mail = this.dataFromSingle.mail;
    this.cif = this.dataFromSingle.dni;
    this.optionsObras.placeholder = this.dataFromSingle?.obras[0]?.concepto
    this.obras = this.obraUser
    this.direccion = this.dataFromSingle.direccion;
    this.poblacion = this.dataFromSingle.poblacion;
    this.codigoPostal = this.dataFromSingle.codigoPostal;
    this.iva = this.dataFromSingle.iva;
    this.telefono2 = this.dataFromSingle.telefono2;
    this.emailPrevencion = this.dataFromSingle.emailPrevencion;

  }

  /**
   * GET FROM INPUT
   */

  getFromInput(element: string, value: any) {
    this.obj[element] = value
    this.updateUser(this.obj);
  }

  /**
   * SELECTORES
   */

    // Obra
    obraFromSelector(event: any) {
      let objetoVincularObras = { id: this.dataFromSingle.id, obra: event.map((e: any) => { return e.id }) }
      this.userSe.vincularObras(objetoVincularObras)
      this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
        if (res === null) { return }
        this.notificationSer.showFeedBack("El usuario ha sido actualizado correctamente")
        this._unsubInd9.next("")
      })
      this.userSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
        if (res === null) { return; }
        this.notificationSer.showError(res.message)
        this._unsub.next("")
      })
    }

  /**
   * UPDATE USER
   */

  updateUser(obj: any): void {
    this.userSe.update(obj);
    this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El usuario ha sido actualizado correctamente")
      this._unsubInd3.next("")
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })
  }



  /**
   * API CALLS
   */


  getUserById(): void {
    let id = this.dataFromSingle.id
    this.userSe.getSingle(id);
    this.userSe.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      let { data } = res
      let obrasUser = data.obras.map((obra: any) => {
        return { id: obra.id, label: `${obra.id} - ${obra.concepto}` }
      })
      this.obraUser = obrasUser;
      this.dataFromSingle = data;
      this.setData()
      this._unsubInd2.next("")
    })
  }

  getObras(): void {
    this.obraSe.getAll();
    this.obraSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      let { data } = res
       
      let optionsForSelector = data.map((d: any) => {
        return { id: d.id, label: `${d.id} - ${d.concepto}` }
      })
      this.optionsObras.items = optionsForSelector
      this._unsubInd.next("")
    })
  }
}