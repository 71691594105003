<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVA TAREA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="nombre">
          </CustomInput>
        </div>
        <!-- <div class="inputForm">
          <label>Encargado</label>
          <selector placeholder="Encargado"  [options]="optionEncargados" (change)="getDataFromEncargado($event)">
          </selector>
        </div> -->

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevoDepartamento()">Añadir Nueva Tarea</btn>
    </div>
  </div>
</div>