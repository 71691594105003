<div class="documentosBlock">

  <div class="dosTablas">



    <div class="documentosRequeridos">
      <h3>Documentos requeridos</h3>
      <pill class="tablePil" classList="tablePill">
        <dataTable [isLoading]="isLoadingTabla" [data]="dataDocumentos" [displayedColumns]="displayedColumnsDocumentos"
          [options]="optionsDocumentos" (see)="seeDocumento($event)" (clickRow)="seeDocumento($event)"></dataTable>
      </pill>
    </div>





    <div class="documentosRequeridos">
      <h3>Documentos pendientes de firma</h3>
      <pill class="tablePil" classList="tablePill">
        <dataTable [isLoading]="isLoadingTabla" [data]="pendienteDeFirma" [displayedColumns]="displayedColumnsFirma"
          [options]="optionsFirma" (see)="seeDocumento($event)" (edit)="editPendienteDeFirma($event)" (clickRow)="seeDocumento($event)"></dataTable>
      </pill>
    </div>

  </div>
</div>