import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { EditDocumentosPopupComponent } from '../EditDocumentosPopup/EditDocumentosPopup.component';

@Component({
  selector: 'app-EditProveedorPopup',
  templateUrl: './EditProveedorPopup.component.html',
  styleUrls: ['./EditProveedorPopup.component.scss']
})
export class EditProveedorPopupComponent extends iUnsubscribeDestroy implements OnInit {

  // DATA

  dataFromProveedores = this.modalData

  // OBJECT TO PASS
  obj: any = {
    id: this.dataFromProveedores.id
  }

  // BINDING

  obra: any = ""
  usuario: any = ""


  // Opciones del selector
  optionObra: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromProveedores,
    append: true
  }

  optionUsuario: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromProveedores,
    append: true
  }


  constructor(private userSe: UserService, private proveedorSe: ProveedorObraService, private obraSe: ObraService, public dialogRef: MatDialogRef<EditDocumentosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }

  ngOnInit() {
     
    this.getObras();
    this.getProveedor();
    this.getUsuarios()
  }

  // GETTERS

  getUsuarios(): void {
    this.userSe.getAll();
    this.userSe.getResultAll().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
       
      let mappedDataForUser = data.map((d: any) => {
        return { id: d.id, label: d.nombre }
      })
      this.optionUsuario.items = mappedDataForUser
      this._unsubInd3.next("")
    })
  }

  getObras(): void {
    this.obraSe.getAll();
    this.obraSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
       
      let mappedDataForObras = data.map((d: any) => {
        return { id: d.id, label: `${d.id} - ${d.concepto} ${d.ubicacion}` }
      })
      this.optionObra.items = mappedDataForObras
      this._unsubInd.next("")
    })

  }

  getProveedor(): void {
    this.proveedorSe.getSingle(this.dataFromProveedores.id);
    this.proveedorSe.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return };
      let { data } = res;
       
      this.obra = { id: data.obra.id, label: `${data.obra.id} - ${data.obra.concepto} ${data.obra.ubicacion}` }
      this.usuario = { id: data.usuario.id, label: `${data.usuario.nombre} ${data.usuario.apellidos}` }
      this._unsubInd2.next("")
    })
  }


  // SELECTOR

  obraFromSelector(event: any) {
    this.obj["obra"] = event.id
    this.updateST(this.obj);
  }

  usuarioFromSelector(event: any) {
    this.obj["usuario"] = event.id
    this.updateST(this.obj);
  }

  // UPDATE

  updateST(obj: any): void {
    this.proveedorSe.update(obj)
    this.proveedorSe.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El proveedor ha sido actualizado correctamente")
      this._unsubInd3.next("")
    })
    this.proveedorSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })

  }



  // CLOSE
  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
}
