<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVO DOCUMENTO </h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">        
        
        <div class="inputForm">
          <label>Tipo documento</label>
          <selector [options]="optionTipoDocumento" (change)="obtenerTipoDocumento($event)" classList="obras"></selector>
        </div>
        <div class="inputForm">
          <label>Fecha de caducidad</label>
          <DatePicker (dataFromPicker)="getFechaDeCaducidad($event)" [dataPlaceholder]="placeholderFechaCaducidad">
          </DatePicker>
        </div>

        <div class="inputForm">
          <label>Título</label>
          <CustomInput placeholder="Título" [(ngModel)]="titulo">
          </CustomInput>
        </div>
        
        <div class="inputForm">
          <label>Documento</label>
          <ng-container *ngIf="documento">
            <mat-icon class="green">check_circle</mat-icon>
          </ng-container>
          <ng-container *ngIf="!documento && isloading">
            <mat-icon class="yellow">refresh</mat-icon>
          </ng-container>

          <button class="generalbutton" type="button" fileInput [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="documento"
          (change)="addDocumento(documento)">Añadir Documento</button>
        </div>


        <div class="inputForm">
          <label>Estado</label>
          <selector [options]="optionEstado" (change)="obtenerEstado($event)" classList="obras"></selector>
        </div>
        <div class="inputForm">
          <label>Comentario</label>
          <CustomInput placeholder="Comentario" [(ngModel)]="comentario">
          </CustomInput>
        </div>
        <div class="inputForm" *ngIf="tipoDocumentoLabel === 'obra' || tipoDocumentoLabel === 'Obra'">
          <label>Obra</label>
          <selector [options]="optionObra" (change)="obtenerObra($event)" classList="obras"></selector>
        </div>
        <div class="inputForm" *ngIf="this.tipoDocumentoLabel === 'usuario' ||this.tipoDocumentoLabel === 'Usuario'">
          <label>Asignado a</label>
          <selector [options]="optionUsers" (change)="obtenerUsuario($event)" classList="obras"></selector>
        </div>        
        <div class="inputForm" *ngIf=" this.tipoDocumentoLabel === 'vehiculo' || this.tipoDocumentoLabel === 'Vehiculo'">
          <label>Vehiculo</label>
          <selector [options]="optionVehiculos" (change)="obtenerVehiculo($event)" classList="obras"></selector>
        </div>        
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="submitNuevoDocumento()">
        <ng-container>
          <span>Añadir Nuevo Documento</span>
        </ng-container>
      </btn>
    </div>
  </div>