import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-EditDepartamentosPopup',
  templateUrl: './EditDepartamentosPopup.component.html',
  styleUrls: ['./EditDepartamentosPopup.component.scss'],
})
export class EditDepartamentosPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // Data from departments
  dataFromDepartamento = this.modalData;

  // Creating obj to pass to update
  obj: any = {};

  // Inputs
  encargado: string = '';

  // Opciones del selector
  optionEncargados: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: `${this.dataFromDepartamento.encargado.nombre} ${this.dataFromDepartamento.encargado.apellidos}`,
    append: true,
  };

  constructor(
    private userSe: UserService,
    private departamentoSe: DepartamentosService,
    public dialogRef: MatDialogRef<EditDepartamentosPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {}

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  closePopup() {
    this.dialogRef.close();
  }

  updateName(event: any): void {
    this.obj['nombre'] = event;
    this.updateEncargado(this.dataFromDepartamento.id, this.obj);
  }

  updateEncargado(id: number, obj: any): void {
    this.departamentoSe.update(id, obj);
    this.departamentoSe
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El departamento ha sido modificado correctamente'
        );
        this._unsubInd.next('');
      });
    this.departamentoSe
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        if (
          res.message.slice(0, 67) ===
          'No ha sido posible guardar tu entidad PDOException: SQLSTATE[23000]'
        ) {
          this.notificationSer.showError(res.message);
          this._unsub.next('');
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }
}
