import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import {
  iSeguimientoTecnico,
  iSeguimientoTecnicoCreate,
} from 'src/app/Shared/Interfaces/Api/iSeguimientoTecnico';
import { iSeguimientoTecnicoTabla } from 'src/app/Shared/Interfaces/Api/iSeguimientoTecnicoTabla';

@Injectable({
  providedIn: 'root',
})
export class SeguimientoTecnicoService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _GetAddreses = new BehaviorSubject<any>(null);
  getResultGetAddreses() {
    return this._GetAddreses;
  }
  clearResultGetAddreses() {
    this._GetAddreses.next(null);
  }
  protected _GetAddresesError = new BehaviorSubject<any>(null);
  getResultGetAddresesError() {
    return this._GetAddresesError;
  }
  clearResultGetAddresesError() {
    this._GetAddresesError.next(null);
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(
        this.urlApi + '/api/seguimiento/tecnico' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedSeguimientoTecnico =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSeguimientoTecnico.convertFromCollection(
              normalizedSeguimientoTecnico
            ),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllTabla(
    pagina: number = 1,
    maximo: number = 50,
    busqueda: string = '',
    tecnico: string = '',
    address: string = '',
    estado: string = '',
    fechaIni: string = '',
    fechaFin: string = ''
  ) {
    let send = {
      num_devoluciones: maximo,
      num_pagina: pagina,
      busqueda: busqueda,
      tecnico: tecnico,
      ubicacion: address,
      estado: estado,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
    };
    this.http
      .get(
        this.urlApi + '/api/seguimiento/tecnico/table' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedSeguimientoTecnico =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iSeguimientoTecnicoTabla.convertFromCollection(
                normalizedSeguimientoTecnico
              ),
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTabla,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEstadisticas() {
    this.http
      .get(
        this.urlApi + '/api/seguimiento/tecnico/estadisticas',
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getEstadisticas,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/seguimiento/tecnico/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedSeguimientoTecnico =
            iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedSeguimientoTecnico,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(seguimientotecnico: any) {
    this.http
      .post(
        this.urlApi + '/api/seguimiento/tecnico',
        seguimientotecnico,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [seguimientotecnico],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(seguimientotecnico: any) {
    this.http
      .put(
        this.urlApi + '/api/seguimiento/tecnico/' + seguimientotecnico.id,
        seguimientotecnico,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [seguimientotecnico],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'seguimientoTecnicos_ids');
    this.http
      .delete(
        this.urlApi + '/api/seguimiento/tecnico',
        this.sendBodyOptions(idsObjs)
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAddresses() {
    this.http
      .get(this.urlApi + '/api/seguimiento/tecnico/select', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedSeguimientoTecnico =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedSeguimientoTecnico,
          });
          this.sendNextObservable(this._GetAddreses, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._GetAddreses, this.res, true, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
}
