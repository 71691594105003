<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVO TIPO DE DOC.</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="objectToPass.nombre">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Periodo</label>
          <CustomInput [type]="'number'" placeholder="Periodo" [(ngModel)]="objectToPass.periodoValidez">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Vinculado a</label>
          <selector [options]="optionVinculado" (change)="getVinculado($event)"></selector>
        </div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevoTipoDoc()">Añadir Nuevo Tipo De Doc.</btn>
    </div>
  </div>
</div>