import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iObra } from "./iObra";
 

export interface iCliente {
    id?: number,
    nombre: string,
    mail: string,
    telefono: number,
    obras: iObra
    idObra?: string
}

export interface iClienteCrear{
    nombre: string;
    mail: string;
    telefono: string;
}
export class iCliente extends iBaseObject {
    static convertFromCollection(ob: any): Array<iCliente> {
        let iClienteCollection: Array<iCliente> = [];
        ob.forEach((element: any) => {
            iClienteCollection.push(this.convertFromObject(element));
        });
        return iClienteCollection;
    }

    static convertFromObject(ob: any): iCliente {
        let iClienteObj = new iCliente();
        if(ob.id !== undefined){
            iClienteObj.id = ob.id;
        }
        iClienteObj.nombre = ob.nombre;
        iClienteObj.mail = ob.mail;
        iClienteObj.telefono = ob.telefono;
        iClienteObj.obras = ob.obras;
        if(ob.idObra !== undefined){
            iClienteObj.idObra = ob.idObra;
        }
        return iClienteObj;
    }

    static empty(): iCliente {
        let iClienteObj = new iCliente();
        iClienteObj.id = -1;
        iClienteObj.nombre = '';
        iClienteObj.mail = '';
        iClienteObj.telefono = -1;
        return iClienteObj;
    }
}