<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modified')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">Cliente: {{dataFromCliente.nombre}}</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Cliente</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="nombre" (change)="obtainChange('nombre', nombre)">
          </CustomInput>
        </div>
        <!-- <div class="inputForm">
          <label>mail</label>
          <CustomInput placeholder="Email" [(ngModel)]="mail" (change)="obtainChange('mail', mail)">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Telefono</label>
          <CustomInput placeholder="Telefono" [(ngModel)]="telefono" (change)="obtainChange('telefono', telefono)">
          </CustomInput>
        </div> -->
        <div class="separatorGrid"></div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>