<div class="fullPopup" id="fullPopup" [class.noImage]="imagenes.length == 0">
  <div class="headerPopup" >
    <div class="headerElements" id="noImprimir">

      <div class="estado">
        <span class="spanEstado"  [ngClass]="{'pendiente': estado === 'PENDIENTE', 'validado': estado === 'ACTIVO' || estado === 'VALIDADO' || estado === 'VALIDADO'}" *ngIf="modalData.tipo !=='movil'">
          {{estado}}
        </span>
      </div>
      <div class="editar" (click)="editParte()" *ngIf="(estado !== 'VALIDADO') && modalData.tipo ==='movil'">
        <ng-container >
          <mat-icon >
            edit
          </mat-icon>
          Editar
        </ng-container>
      </div>
      <div class="editar" (click)="createPDF()">
        <ng-container >
          <mat-icon>
            download
          </mat-icon>
          Descargar
        </ng-container>
      </div>
      <div class="closePopup">
        <span (click)="closePopup()" class="iconClosePopup">
          <ng-container  >
            <mat-icon>
              close
            </mat-icon>
          </ng-container>
        </span>
      </div>
    </div>
  </div>

  <div class="contentContainer" id="PDF" #PDF >
    <div class="tituloContainer">
      <p class="titulo">{{concepto}}</p>
    </div>
    <div class="infoContainer" id="imprimir">

      <div class="labelContent" >
        <div class="labelDiv">
          <p class="label">NOMBRE:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{nombre}}</p>
        </div>
      </div>

      <div class="labelContent">
        <div class="labelDiv">
          <p class="label">FECHA:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{fecha}}</p>
        </div>
      </div>

      <div class="labelContent">
        <div class="labelDiv">
          <p class="label">Nº OBRA:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{nObra}}</p>
        </div>
      </div>
      <div class="labelContent">
        <div class="labelDiv">
          <p class="label">UBICACIÓN:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{ubicacion}}</p>
        </div>
      </div>

      <div class="labelContent">
        <div class="labelDiv">
          <p class="label">HORARIO:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{horario}}</p>
        </div>
      </div>

      <div class="labelContent">
        <div class="labelDiv">
          <p class="label">VEHICULO:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{vehiculo}}</p>
        </div>
      </div>

      <div class="labelContent">
        <div class="labelDiv">
          <p class="label">CONDUCTOR:</p>
        </div>
        <div class="contentDiv">
          <p class="content">{{conductor}}</p>
        </div>
      </div>

      <div class="labelContent" *ngIf="modalData.tipo === 'movil' ">
        <div class="labelDiv">
          <p class="label">Estado:</p>
        </div>
        <div class="contentDiv">
          <p class="content" [ngClass]="{rojo: estado === 'PENDIENTE', verde: estado === 'VALIDADO'}">{{estado}}</p>
        </div>
      </div>

    </div>
    <div class="comentariosBlock" id="imprimir">
      <p class="label">COMENTARIOS</p>
      <p class="content">{{comentarios}}</p>
    </div>

    <div class="imagenesBlock" id="imprimir" *ngIf="imagenes.length > 0">
      <!-- NGFOR imagenes -->
      <div class="imagenDiv" *ngFor="let imagen of imagenes">
        <img class="imagen"  [src]="imagen.url" alt="" onerror="this.src='/assets/Images/Placeholder.jpg'">
      </div>
    </div>

  </div>

  <div class="buttonsContainer" id="noImprimir">
    <div class="buttonsDiv">

      <btn classList="generalbutton close sizeButton" (click)="changeEstadoParte('Pendiente')" *ngIf="tipo !== 'movil'">
        <ng-container>
          <mat-icon>close</mat-icon>
        </ng-container>
      </btn>
      <!-- Close without doing anything -->
      <btn classList="generalbutton close sizeButton" (click)="closePopup()" *ngIf="tipo === 'movil'">
        <ng-container>
          <mat-icon>close</mat-icon>
        </ng-container>
      </btn>
      <btn classList="generalbutton okay sizeButton" (click)="changeEstadoParte('Validado')" *ngIf="tipo !== 'movil'">
        <ng-container>
          <mat-icon>check</mat-icon>
        </ng-container>
      </btn>
      <btn classList="generalbutton download sizeButton" (click)="createPDF()"  *ngIf="tipo !== 'movil'">
        <ng-container>
          <mat-icon>download</mat-icon>
        </ng-container>
      </btn>

    </div>
  </div>
</div>
