<FullContainer>
  <ng-container *ngIf="selectTypeDocument == null">
    <div class="sectionTitleContainer">
      <h2 class="sectionTitle">DOCUMENTOS</h2>
      <div class="addNew" *ngIf="submenuSel === 0">
        <btn classList="iconBtn plusBtn" (click)="openAnadirCarpeta()">
          <i class="material-icons">add</i>
          <span>añadir nueva</span>
        </btn>
      </div>
      <toggleSubmenu [elements]="submenuNavegation" [(ngModel)]="submenuSel"></toggleSubmenu>
    </div>
    <div class="paginacion">
      <div class="numeroDeResultadosMostrados">{{numMinCarpeta}}-{{numMaxCarpeta}} de {{paginacionTotalCarpeta}}</div>
      <div class="arrows">
        <ng-container class="arrowLeft">
          <mat-icon (click)="previousPageCarpetas()">
            chevron_left
          </mat-icon>
        </ng-container>
        <ng-container class="arrowRight">
          <mat-icon (click)="nextPageCarpetas()">
            chevron_right
          </mat-icon>
        </ng-container>
      </div>
    </div>
    <pill class="tablePil" classList="tablePill" *ngIf="submenuSel === 0">
      <dataTable  (deleteSelected)="deleteSelected($event)" [isLoading]="loadingTipoDocumentos"
        [data]="dataTipoDocumentos" [displayedColumns]="displayedColumnsTipoDocumentos"
        [options]="optionsTipoDocumentos" (clickRow)="loadDocumentsFromType($event)" [classList]="cursorPointer"
        (edit)="openEditPopup($event)" (delete)="openDeletePopup($event)"></dataTable>
    </pill>
  </ng-container>
  <ng-container *ngIf="selectTypeDocument != null">
    <div class="sectionTitleContainer">
      <i class="material-icons goBack" (click)="returnCarpetas()">arrow_back</i>
      <h2 class="sectionTitle">DOCUMENTOS</h2>
    </div>
    <filterActions (searchSend)="respuestaBusqueda($event)"  [placeholderAdd]="'AÑADIR NUEVO'" [filterOpen]="isFilterOpen" [inputOptions]="filterText"
      [searchOpen]="isSearchOpen" (filterOpenChange)="changeFilterOpen($event)"
      (searchOpenChange)="changeSearchOpen($event)" (addNew)="addNewDocument()">
      <ng-container filterContent>

        <div class="containerSelectores">
          <div class="estado">
            <p>Estado</p>
            <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
          </div>
          <div class="redactor">
            <p>Tipo de documento</p>
            <selector [options]="optionTipoDoc" (change)="getTipoDocFromSelector($event)"></selector>
          </div>
          <div class="desde">
            <p>Desde</p>
            <DatePicker (dataFromPicker)="dataFromPicker($event, 'desde')"></DatePicker>
          </div>
          <div class="hasta">
            <p>Hasta</p>
            <DatePicker (dataFromPicker)="dataFromPicker($event, 'hasta')"></DatePicker>
          </div>
        </div>
      </ng-container>
    </filterActions>
    <div class="paginacion" *ngIf="submenuSel === 0">
      <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
      <div class="arrows">
        <ng-container class="arrowLeft">
          <mat-icon (click)="previousPage()">
            chevron_left
          </mat-icon>
        </ng-container>
        <ng-container class="arrowRight">
          <mat-icon (click)="nextPage()">
            chevron_right
          </mat-icon>
        </ng-container>
      </div>
    </div>
    
    <pill class="tablePil tableDocumentos" classList="tablePill">
      <dataTable [forceUpdateWidth]="forceUpdateWidthDocumentos" [isLoading]="loadingDocumentos" [data]="dataDocumentos"
        [displayedColumns]="displayedColumnsDocumentos" [options]="optionsDocumentos"
        (edit)="openEditPopupDocumento($event)" (delete)="openDeletePopupDocumento($event)"
        (deleteSelected)="deleteSelectedDocuments($event)" (clickRow)="seeDocumento($event)"
        (see)="seeDocumento($event)"></dataTable>
    </pill>
  </ng-container>

  <ng-container *ngIf="submenuSel === 1">
    <filterActions (addNew)="addNewTipoDoc()" [disableSearch]="true" [placeholderAdd]="'AÑADIR NUEVO'" [disableFilter]="true"></filterActions>
      <pill class="tablePil" classList="tablePill">
        <dataTable [forceUpdateWidth]="forceUpdateWidthDocumentos" [isLoading]="loadingTipoDocs" [data]="dataTipoDocs" [displayedColumns]="displayedColumnsTipoDocs"
          [options]="optionsTipoDocs" [classList]="cursorPointer" (edit)="openEditPopupTipoDoc($event)"
          (delete)="openDeletePopupTipoDoc($event)"></dataTable>
      </pill>
  </ng-container>
</FullContainer>