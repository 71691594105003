<div class="documentosBlock">
  <filterActions (searchSend)="busqueda($event)" (addNew)="addNewDocumento()">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div class="desde">
          <p>Desde</p>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni')"></DatePicker>
        </div>
        <div class="hasta">
          <p>Hasta</p>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin')"></DatePicker>
        </div>
        <div>
          <p>Estado</p>
          <selector [options]="optionEstado" (change)="getFromSelector($event, 'estado')"></selector>
        </div>
      </div>
    </ng-container>
  </filterActions>

  <pill class="tablePil" classList="tablePill">
    <dataTable [isLoading]="isLoadingTabla" [data]="dataDocumentosTabla" [displayedColumns]="displayedColumnsDocumentos"
      [options]="optionsDocumentos" (see)="seeDocumento($event)" (delete)="openDeletePopupDocumento($event)"
      (deleteSelected)="deleteSelectedDocuments($event)" (clickRow)="seeDocumento($event)"
      (edit)="openEditPopupDocumento($event)"></dataTable>
  </pill>


</div>