<sectionLogin-containerLogin>
  <div class="centerContainer">
    <div>
      <h2>Recupera tu contraseña</h2>
      <br>
      <CustomInput classList="fullWidth tall" placeholder="Introduce tu correo electrónico" label="Email" [(ngModel)]="email"></CustomInput>
      <div class="separatorInput"></div>
      <btn classList="fullWidth tall" (click)="sendCredentials()">Enviar mis credenciales</btn>
      <div class="separatorInput"></div>
      <p class="returnLogin">¿Ya recuerda la contraseña? <a routerAnimationRight [routerLink]="['/login']">Iniciar sesión</a></p>
    </div>
  </div>
  
  <ng-container downContent>
    <div class="termsContainer">
      <span>Terminos&Condiciones</span>
      <span>Política de privacidad</span>
    </div>
  </ng-container>
</sectionLogin-containerLogin>