import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iObra } from "./iObra";
import { iProveedorObra } from "./iProveedorObra";
import { iUser } from "./iUser";


export interface iFactura {
    id: number,
    fechaEmision: iDate,
    importe: number,
    notas: string,
    estado: string,
    numero: number,
    obra?: iObra,
    proyecto?: string,
    proveedor?: string,
    url?: string,
    fechaVencimiento?: string,
}
export class iFactura extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFactura> {
        let iFacturaCollection: Array<iFactura> = [];
        ob.forEach((element: any) => {
            iFacturaCollection.push(this.convertFromObject(element));
        });
        return iFacturaCollection;
    }

    static convertFromObject(ob: any): iFactura {
        let iFacturaObj = new iFactura();
        iFacturaObj.id = ob.id;
        iFacturaObj.fechaEmision = iDate.phpConvert(ob.fechaEmision);
        iFacturaObj.importe = ob.importe;
        iFacturaObj.notas = ob.notas;
        iFacturaObj.estado = ob.estado;
        iFacturaObj.numero = ob.numero;
        if (ob.proyecto) {
            iFacturaObj.proyecto = ob.proyecto;
        }
        if (ob.obra) {
            iFacturaObj.obra = iObra.convertFromObject(ob.obra);
        }
        if (ob.proveedor) {
            iFacturaObj.proveedor = ob.proveedor;
        }
        if (ob.url) {
            iFacturaObj.url = ob.url;
        }
        if (ob.fechaVencimiento) {
            iFacturaObj.fechaVencimiento = ob.fechaVencimiento;
        }
        return iFacturaObj;
    }
}