import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { iOptionsSelector, iStaticUtilities, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoParteComponentComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoParteComponent/NuevoParteComponent.component';
import { DescargaPartePopupComponent } from 'src/app/Popups/DescargaPartePopup/DescargaPartePopup.component';
import { EditPartesPopupComponent } from 'src/app/Popups/EditPopup/EditPartesPopup/EditPartesPopup.component';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { iDataTableColumns, iDataTableOptions } from 'src/app/Shared/Interfaces/iDataTable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-AdminEmpleadosPartes',
  templateUrl: './AdminEmpleadosPartes.component.html',
  styleUrls: ['./AdminEmpleadosPartes.component.scss']
})
export class AdminEmpleadosPartesComponent extends iUnsubscribeDestroy implements OnInit, OnChanges {

  @Input("dataTable") datePartes:Array<any> = [];
  @Input("isLoading") isLoading:boolean = false;
  @Input("vehiculos") vehiculos:Array<any> = [];
  @Input("empleados") empleados:Array<any> = [];
  @Input("obras") obras:Array<any> = [];
  @Input("estados") estados:Array<any> = [];

  @Output() refreshData = new EventEmitter<boolean>();

  selectVehiculo:any = null;
  selectEmpleado:any = null;
  selectObra:any = null;
  selectEstado:any = null;
  
  filterText = {
    placeholder: 'Busca por Nº Obra o Horario',
    model:'',
  }

  // Selectores
  optionsVehiculo:iOptionsSelector = {
    items:this.vehiculos,
    clearable: true,
    bindLabel: 'nombre',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios vehículos seleccionados"
    },
    placeholder: 'Vehículo'
  }
  optionsEmpleado:iOptionsSelector = {
    items:this.empleados,
    clearable: true,
    bindLabel: 'nombre',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 1,
      multipleTextReplacer: "Varios empleados seleccionados"
    },
    placeholder: 'Empleados'
  }
  optionsObra:iOptionsSelector = {
    items:this.obras,
    clearable: true,
    bindLabel: 'nombreObra',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varias obras seleccionados"
    },
    placeholder: 'Obra'
  }
  optionsEstado:iOptionsSelector = {
    items:this.estados,
    clearable: true,
    placeholder: 'Estado'
  }
  
  //Tabla
  displayedColumnsPartes:Array<iDataTableColumns>=[
    {label: 'Fecha', property:'date'},
    {label: 'Nº Obra', property:'idObra', align:"center"},
    {label: 'Obra', property:'obra', align:"center"},
    {label: 'Empleado', property:'empleado'},
    {label: 'Horario', property:'horario', align:"center", classList:""},
    {label: 'Vehículo', property:'nombre', align:"center"},
    {label: 'Estado', property:'estado', align:"center", classList:'tableEstado tableEstadoPartes'}
  ];

  optionsPartes:iDataTableOptions={
    isSelectable:true,
    canEdit:true,
    canSee:true,
    moreIconsHead: [{ callback: () => { this.exportexcel() }, icon: "download" }],

    canDelete:true,
    isHovered:true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }
  
  constructor(public usuariosSe:UserService, private popup: PopupService, private parteSe: ParteService, private notificationSe: NotificationsService) { 
    super();
  }

  ngOnInit() {
  }

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.classList.contains('mat-checkbox-checked')
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'ProveedoresDocumentos.xlsx');

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["vehiculos"] != null){ this.optionsVehiculo.items = changes["vehiculos"].currentValue; }
    if(changes["empleados"] != null){ this.optionsEmpleado.items = changes["empleados"].currentValue; }
    if(changes["obras"] != null){ this.optionsObra.items = changes["obras"].currentValue;}
    if(changes["estados"] != null){ this.optionsEstado.items = changes["estados"].currentValue; }
  }

  updateQuery(){
    let sendQuery:any={};
    if(this.selectVehiculo != null){ sendQuery["vehiculo"] = this.selectVehiculo.map((e:any)=>e.id) }
    if(this.selectEmpleado != null){ sendQuery["empleado"] = this.selectEmpleado.map((e:any)=>e.id) }
    if(this.selectObra != null){ sendQuery["obra"] = this.selectObra.map((e:any)=>e.id) }
    if(this.selectEstado != null){ sendQuery["estado"] = this.selectEstado.id }
    if(this.filterText.model != null && this.filterText.model != ''){ sendQuery["busqueda"] = this.filterText.model }
    
    this.usuariosSe.getEmpleadosPartes(1,sendQuery);
  }

  OnclickDeleteButton(event: any): void {
    this.popup.openPopup(AsegurarDeletePopupComponent, {nombre:"el parte"})
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      this.deleteParte(event.id)
      this._unsubInd2.next("")
    })
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "Los partes seleccionados" });
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let ids = event.map((e: any) => e.id)
      this.deleteParte(ids)
      this._unsubInd.next("")
    })
  }

  OnclickEditButton(event:any):void{
    this.popup.openPopup(EditPartesPopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
    if(!res){return}
    this.updateQuery()
    this._unsubInd.next("")
    })
  }

  deleteParte(id:any):void{
    this.parteSe.delete(id);
    this.parteSe.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showFeedBack("El parte ha sido borrado correctamente")
      this.updateQuery()
      this._unsubInd3.next("")
    })
    this.parteSe.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showError(`${res.message}`)
      this._unsub.next("")
    })

  }

  openParte(event:any){
    this.popup.openPopup(DescargaPartePopupComponent, event, 'fullSizePopup')
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null || res['dataReturn'] !== 'refresh') { return; }
      this.refreshData.emit(true)
      this._unsubInd.next("")
    })
  }

  nuevoParte():void{
    this.popup.openPopup(NuevoParteComponentComponent, {tipo:"nuevo"})
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      this.refreshData.emit(true)
      this._unsubInd.next("")
    })
  }


}
