import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { iAlerta } from 'src/app/Shared/Interfaces/Api/iAlerta';

@Injectable({
  providedIn: 'root',
})
export class AlertaService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(numDev: number = 40) {
    this.http
      .get(
        this.urlApi + `/api/alerta?num_devoluciones=${numDev}&num_pagina=1`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedCarpeta =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iAlerta.convertFromCollection(normalizedCarpeta),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [numDev],
          });
          this.checkStatusError(error);
        },
      });
  }

  editAviso(obj: any) {
    this.http
      .put(this.urlApi + `/api/avisos/${obj.id}`, obj, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedData = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedData,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [obj],
          });
          this.checkStatusError(error);
        },
      });
  }
}
