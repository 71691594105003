<div class="fullView">
  <div class="headerBlock">
    <p class="headerText">Perfil</p>
  </div>
  <div class="contentBlock">

    <div class="whiteBlock">
      <div class="informationContainer userInformation">
        <i class="material-icons cancelIcon" *ngIf="isEditing" (click)="cancelEdit()">cancel</i>
        <i class="material-icons editIcon" *ngIf="!isEditing" (click)="openEditPopup()">edit</i>
        <i class="material-icons saveIcon" *ngIf="isEditing" (click)="saveEdit()">save</i>
        <div class="informationLabel">
          <label>Usuario</label>
          <span *ngIf="!isEditing">{{original.nombre}} {{original.apellidos}}</span>
          <div class="dobleInput">
            <CustomInput *ngIf="isEditing" classList="superFino dobleInputLeft" [(ngModel)]="editing.nombre">
            </CustomInput>
            <CustomInput *ngIf="isEditing" classList="superFino dobleInputRight" [(ngModel)]="editing.apellidos">
            </CustomInput>
          </div>
        </div>
        <div class="informationLabel">
          <label>Email</label>
          <span class="truncate" *ngIf="!isEditing">{{original.mail}}</span>
          <CustomInput *ngIf="isEditing" classList="superFino" [(ngModel)]="editing.mail"></CustomInput>
        </div>
        <!-- <div class="informationLabel">
          <label>Departamento</label>
          <span class="truncate" *ngIf="!isEditing" class="capitalize">{{original.departamento}}</span>
          <CustomInput *ngIf="isEditing" classList="superFino" [(ngModel)]="editing.departamento"></CustomInput>
        </div> -->
        <div class="informationLabel">
          <label>Talla camiseta</label>
          <span class="truncate" *ngIf="!isEditing">{{original.tallaCam}}</span>
          <selector *ngIf="isEditing" class="superFino" [(ngModel)]="editing.tallaCam" [options]="optionsTallaCam">
          </selector>
        </div>
        <div class="informationLabel">
          <label>Talla pantalón</label>
          <span class="truncate" *ngIf="!isEditing">{{original.tallaPan}}</span>
          <CustomInput *ngIf="isEditing" classList="superFino" [(ngModel)]="editing.tallaPan"></CustomInput>
        </div>
        <div class="informationLabel">
          <label>Talla botas</label>
          <span class="truncate" *ngIf="!isEditing">{{original.tallaBota}}</span>
          <CustomInput *ngIf="isEditing" classList="superFino" [(ngModel)]="editing.tallaBota"></CustomInput>
        </div>
        <btn class="logout" classList="secundario fino" (click)="changePassword()">cambiar contraseña</btn>
        <btn class="logout" classList="secundario fino" (click)="logout()">cerrar sesión</btn>
      </div>

    </div>

    <p class="headerText">DOCUMENTOS REQUERIDOS</p>


    <div class="whiteBlock">
      <div class="documentContent">
        <div class="labelButton">
          <p class="label">DNI Delante:</p>
          <btn classList="secundario fino" (click)="adjuntarFoto('DNIAnverso')">adjuntar foto</btn>
        </div>
        <div class="documento_caducidad">
          <span *ngIf="documentosRequeridos.dniAnverso.fechaCaducidad">Fecha de caducidad:
            {{documentosRequeridos.dniAnverso.fechaCaducidad | date:'shortDate'}}</span>
          <div class="warningContainer" *ngIf="isWarningDniAnverso">
            <i class="material-icons-outlined">report_problem</i>
            <span>{{textWarningDniAnverso}}</span>
          </div>
        </div>
      </div>

      <div class="documentContent">
        <div class="labelButton">
          <p class="label">DNI Detrás:</p>
          <btn classList="secundario fino" (click)="adjuntarFoto('DNIReverso')">adjuntar foto</btn>
        </div>
        <div class="documento_caducidad">
          <span *ngIf="documentosRequeridos.dniReverso.fechaCaducidad">Fecha de caducidad:
            {{documentosRequeridos.dniReverso.fechaCaducidad | date:'shortDate'}}</span>
          <div class="warningContainer" *ngIf="isWarningDniReverso">
            <i class="material-icons-outlined">report_problem</i>
            <span>{{textWarningDniReverso}}</span>
          </div>
        </div>
      </div>
      <div class="documentContent">
        <div class="labelButton">
          <p class="label">Carnet de conducir Delante</p>
          <btn classList="secundario fino" (click)="adjuntarFoto('carnetAnverso')">adjuntar foto</btn>
        </div>
        <div class="documento_caducidad">
          <span *ngIf="documentosRequeridos.conducirAnverso.fechaCaducidad">Fecha de caducidad:
            {{documentosRequeridos.conducirAnverso.fechaCaducidad | date:'shortDate'}}</span>
          <div class="warningContainer" *ngIf="isWarningConducirAnverso">
            <i class="material-icons-outlined">report_problem</i>
            <span>{{textWarningConducirAnverso}}</span>
          </div>
        </div>
      </div>

      <div class="documentContent">
        <div class="labelButton">
          <p class="label">Carnet de conducir Detrás</p>
          <btn classList="secundario fino" (click)="adjuntarFoto('carnetReverso')">adjuntar foto</btn>
        </div>
        <div class="documento_caducidad">
          <span *ngIf="documentosRequeridos.conducirReverso.fechaCaducidad">Fecha de caducidad:
            {{documentosRequeridos.conducirReverso.fechaCaducidad | date:'shortDate'}}</span>
          <div class="warningContainer" *ngIf="isWarningConducirReverso">
            <i class="material-icons-outlined">report_problem</i>
            <span>{{textWarningConducirReverso}}</span>
          </div>
        </div>
      </div>



    </div>

    <p class="headerText">NÓMINAS</p>

    <div class="whiteBlock">
      <div class="eachParte" (click)="openNomina($event)" id="{{index}}"
        *ngFor="let nomina of dataNominas; let index = index">
        <div class="lines">
          <p class="lineaParte">{{nomina.anyo}}</p>
          <p class="lineaParte fontWeight">{{nomina.mes}}</p>
        </div>
      </div>

    </div>


  </div>

  <div class="footerBlock">
    <Footer></Footer>
  </div>
</div>