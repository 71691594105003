import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iParte } from 'src/app/Shared/Interfaces/Api/iParte';
import { iParteTabla } from 'src/app/Shared/Interfaces/Api/iParteTabla';

@Injectable({
  providedIn: 'root',
})
export class ParteService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultCalendario = new BehaviorSubject<any>(null);
  protected _resultCalendarioUsers = new BehaviorSubject<any>(null);
  protected _resultVacationDates = new BehaviorSubject<any>(null);
  protected _resultAllPartesByJefe = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    super();
  }

  getResultCalendario() {
    return this._resultCalendario;
  }
  getResultCalendarioUsers() {
    return this._resultCalendarioUsers;
  }
  getResultVacationDates() {
    return this._resultVacationDates;
  }
  getResultAllPartesByJefe() {
    return this._resultAllPartesByJefe;
  }

  clearResultCalendario() {
    return this._resultCalendario.next(null);
  }
  clearResultCalendarioUsers() {
    return this._resultCalendarioUsers.next(null);
  }
  clearResultVacationDates() {
    return this._resultVacationDates.next(null);
  }
  clearResultAllPartesByJefe() {
    return this._resultAllPartesByJefe.next(null);
  }

  getAll() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(this.urlApi + '/api/parte' + this.optionsGet(send), this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iParte.convertFromCollection(normalizedParte),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllPartesUser(
    userId: number,
    fechaIni: string = '',
    fechaFin: string = '',
    estado: string = '',
    buscador: string = ''
  ) {
    let send = {
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      estado: estado,
      buscador: buscador,
    };
    this.http
      .get(
        this.urlApi + '/api/parte/user/my/' + userId + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllPartesUser,
            args: [userId],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllPartesByJefe(
    userId: number,
    numPag: number = 1,
    maximo: number,
    options?: any
  ) {
    let sendQuery: any = { num_devoluciones: maximo, num_pagina: numPag };
    if (options != null) {
      Object.keys(options).forEach((key) => {
        if (options[key] != '' && options[key] != null) {
          sendQuery[key] = options[key];
        }
      });
    }
    this.http
      .get(
        this.urlApi + '/api/parte/jefe/' + userId + this.optionsGet(sendQuery),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedParte, total: data.total },
          });
          this.sendNextObservable(this._resultAllPartesByJefe, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllPartesByJefe,
            args: [userId],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllPartesMes(month: number, year: number) {
    this.http
      .get(
        this.urlApi + `/api/parte/calendar/${year}/${month}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextObservable(this._resultCalendario, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultCalendario, this.res, true, {
            method: this.getAllPartesMes,
            args: [month, year],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllPartesMesByJefeDeObra(month: number, year: number, jefeId: number) {
    this.http
      .get(
        this.urlApi + `/api/parte/calendar/${year}/${month}/jefe/${jefeId}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextObservable(this._resultCalendario, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultCalendario, this.res, true, {
            method: this.getAllPartesMesByJefeDeObra,
            args: [month, year],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllVacationDates() {
    this.http
      .get(
        this.urlApi +
          `/api/festivos/?num_devoluciones=500000000000&num_pagina=1`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextObservable(this._resultVacationDates, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultVacationDates, this.res, true, {
            method: this.getAllVacationDates,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllPartesUserMes(month: number, year: number, id: number) {
    this.http
      .get(
        this.urlApi + `/api/parte/recover/${id}/${month}/${year}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextObservable(this._resultCalendario, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultCalendario, this.res, true, {
            method: this.getAllPartesUserMes,
            args: [month, year, id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllPartesFaltantesUser(date: string) {
    this.http
      .get(this.urlApi + `/api/parte/user/${date}`, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextObservable(this._resultCalendarioUsers, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultCalendarioUsers, this.res, true, {
            method: this.getAllPartesFaltantesUser,
            args: [date],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllPartesFaltantesUserByJefeDeObra(date: string, jefeObraId: number) {
    this.http
      .get(
        this.urlApi + `/api/parte/user/${date}/jefe/${jefeObraId}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextObservable(this._resultCalendarioUsers, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultCalendarioUsers, this.res, true, {
            method: this.getAllPartesFaltantesUserByJefeDeObra,
            args: [date, jefeObraId],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllTabla(
    busqueda: string,
    desde: string,
    hasta: string,
    cargadoBBDD: string = '',
    estado = 'validado'
  ) {
    let send = {
      num_devoluciones: 500000,
      num_pagina: 1,
      busqueda: busqueda,
      fechaIni: desde,
      fechaFin: hasta,
      cargadoBBDD: cargadoBBDD,
      estado: estado,
    };
    this.http
      .get(
        this.urlApi + '/api/parte/trabajo' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iParteTabla.convertFromCollection(normalizedParte),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTabla,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllTablaObras(
    obraid: any,
    busqueda: string = '',
    fechaIni: string = '',
    fechaFin: string = '',
    estado: string = '',
    redactor: string = ''
  ) {
    let send = {
      num_devoluciones: 500000,
      num_pagina: 1,
      busqueda: busqueda,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      estado: estado,
      redactor: redactor,
    };
    this.http
      .get(
        this.urlApi + '/api/tabla/parte/' + obraid + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iParteTabla.convertFromCollection(normalizedParte),
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTablaObras,
            args: [obraid, busqueda, fechaIni, fechaFin, estado],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/parte/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedParte = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedParte,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(parte: any) {
    this.http
      .post(this.urlApi + '/api/parte', parte, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          console.log(error);
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [parte],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(parte: any) {
    this.http
      .put(this.urlApi + '/api/parte/' + parte.id, parte, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [parte],
          });
          this.checkStatusError(error);
        },
      });
  }
  changeCargado(parte: any) {
    this.http
      .put(this.urlApi + '/api/parte/multi/cargado', parte, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.changeCargado,
            args: [parte],
          });
          this.checkStatusError(error);
        },
      });
  }
  changeEstado(parte: any) {
    this.http
      .put(this.urlApi + '/api/parte/multi/estado', parte, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.changeCargado,
            args: [parte],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'partes_ids');
    this.http
      .delete(this.urlApi + '/api/parte', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
