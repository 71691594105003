import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iUser } from 'src/app/Shared/Interfaces/Api/iUser';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultAll = new BehaviorSubject<any>(null);
  protected _resultEncargados = new BehaviorSubject<any>(null);
  protected _resultNominas = new BehaviorSubject<any>(null);
  protected _resultEmpleados = new BehaviorSubject<any>(null);
  protected _resultEmpleadosNominas = new BehaviorSubject<any>(null);
  protected _resultEmpleadosPartes = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    super();
  }

  getResultAll() {
    return this._resultAll;
  }
  getResultEncargados() {
    return this._resultEncargados;
  }
  getResultNominas() {
    return this._resultNominas;
  }
  getResultEmpleados() {
    return this._resultEmpleados;
  }
  getResultEmpleadosNominas() {
    return this._resultEmpleadosNominas;
  }
  getResultEmpleadosPartes() {
    return this._resultEmpleadosPartes;
  }

  clearResultAll() {
    this._resultAll.next(null);
  }
  clearResultEncargados() {
    this._resultEncargados.next(null);
  }
  clearResultNominas() {
    this._resultNominas.next(null);
  }
  clearResultEmpleados() {
    this._resultEmpleados.next(null);
  }
  clearResultEmpleadosNominas() {
    this._resultEmpleadosNominas.next(null);
  }
  clearResultEmpleadosPartes() {
    this._resultEmpleadosPartes.next(null);
  }

  getAll() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(this.urlApi + '/api/user/' + this.optionsGet(send), this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalized = data.data;
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: data.data, total: data.total },
          });
          this.sendNextObservable(this._resultAll, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultAll, this.res, true, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEncargados() {
    this.http
      .get(this.urlApi + '/api/user/tecnicos/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalized = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalized,
          });
          this.sendNextObservable(this._resultEncargados, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultEncargados, this.res, true, {
            method: this.getEncargados,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllTabla(busqueda: string) {
    let send = { num_devoluciones: 500000, num_pagina: 1, busqueda: busqueda };
    this.http
      .get(
        this.urlApi + '/api/user/empleados' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalized = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalized,
          });
          this.sendNextObservable(this._resultAll, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultAll, this.res, true, {
            method: this.getAllTabla,
            args: [busqueda],
          });
          this.checkStatusError(error);
        },
      });
  }

  // Vista de reformas detalle => empleados
  getAllEmpleadosReformaTabla(id: any, busqueda: string = '') {
    let send = { num_devoluciones: 500000, num_pagina: 1, busqueda: busqueda };
    this.http
      .get(
        this.urlApi + '/api/user/tabla/empleados/' + id + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalized = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              totalEmpleados: data.totalEmpleados,
              totalVehiculos: data.totalVehiculos,
              empleados: data.empleados,
              vehiculos: data.vehiculos,
            },
          });
          this.sendNextObservable(this._resultAll, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultAll, this.res, true, {
            method: this.getAllEmpleadosReformaTabla,
            args: [id, busqueda],
          });
          this.checkStatusError(error);
        },
      });
  }

  getMe() {
    this.http
      .get(this.urlApi + '/api/user/profile', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedUser = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedUser,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getMe,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getByNameAndSurname(name: string, surname: string) {
    this.http
      .get(
        this.urlApi + `/api/user/search/${name}/${surname}`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getByNameAndSurname,
            args: [name, surname],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http.get(this.urlApi + '/api/user/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedUser = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: normalizedUser,
        });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultIndividualError', this.res, {
          method: this.getSingle,
          args: [id],
        });
        this.checkStatusError(error);
      },
    });
  }

  getOperarios() {
    this.http.get(this.urlApi + '/api/operarios', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalized = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: normalized,
        });
        this.sendNextObservable(this._resultAll, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(this._resultAll, this.res, true, {
          method: this.getOperarios,
          args: [],
        });
        this.checkStatusError(error);
      },
    });
  }

  create(user: any) {
    this.http.post(this.urlApi + '/user', user, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data['data'],
        });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultUpdateError', this.res, {
          method: this.create,
          args: [user],
        });
        this.checkStatusError(error);
      },
    });
  }
  update(user: any) {
    this.http
      .put(this.urlApi + '/api/user/' + user.id, user, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [user],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'users_ids');
    this.http
      .delete(this.urlApi + '/api/user', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }

  getProveedoresList() {
    this.http
      .get(this.urlApi + '/api/user/proveedores/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedUser = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedUser,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getProveedoresList,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  /* DESVINCULAR & VINCULAR OBRAS */
  vincularObras(obras: any) {
    this.http
      .post(this.urlApi + '/api/user/assign', obras, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data['message'],
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.vincularObras,
            args: [obras],
          });
          this.checkStatusError(error);
        },
      });
  }
  vincularUsuarios(users: any) {
    this.http
      .post(this.urlApi + '/api/obra/assign', users, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data['message'],
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.vincularUsuarios,
            args: [users],
          });
          this.checkStatusError(error);
        },
      });
  }

  desvincularObras(idUser: number, idObras: number) {
    this.http
      .post(
        this.urlApi + '/api/user/assign/' + idUser + '/' + idObras + '/false',
        {},
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.desvincularObras,
            args: [idUser, idObras],
          });
          this.checkStatusError(error);
        },
      });
  }

  /* GETTERS vinculados con user */

  getNominas() {
    this.http
      .get(this.urlApi + '/api/user/nominas', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedUser = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedUser,
          });
          this.sendNextObservable(this._resultNominas, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultNominas, this.res, true, {
            method: this.getNominas,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAdminTecnicos() {
    this.http
      .get(this.urlApi + '/api/user/tecnicos/admin/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizeEmpleados =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { total: data.total, data: normalizeEmpleados },
          });
          this.sendNextObservable(this._resultEmpleados, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultEmpleados, this.res, true, {
            method: this.getAdminTecnicos,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEmpleados(numPagina: number = 1, options?: any) {
    let sendQuery: any = { num_devoluciones: 50, num_pagina: numPagina };
    if (options != null) {
      Object.keys(options).forEach((key) => {
        if ((options[key] != '' && options[key] != null) || options[key] == 0) {
          sendQuery[key] = options[key];
        }
      });
    }
    this.http
      .get(
        this.urlApi + '/api/user/empleados' + this.optionsGet(sendQuery),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizeEmpleados = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { total: data.total, data: normalizeEmpleados },
          });
          this.sendNextObservable(this._resultEmpleados, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultEmpleados, this.res, true, {
            method: this.getEmpleados,
            args: [numPagina, options],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEmpleadosJefeDeObra(jefeId: number) {
    this.http
      .get(
        this.urlApi + `/api/operarios/jefe/${jefeId}/selector`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(this._resultEmpleados, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultEmpleados, this.res, true, {
            method: this.getEmpleadosJefeDeObra,
            args: [jefeId],
          });
          this.checkStatusError(error);
        },
      });
  }

  getEmpleadosNominas(
    numPagina: number,
    year: number,
    month: number = 0,
    options?: any
  ) {
    let sendQuery: any = {
      num_devoluciones: 50,
      num_pagina: numPagina,
      year: year,
      mes: month,
    };
    if (options != null) {
      Object.keys(options).forEach((key) => {
        if (options[key] != '' && options[key] != null) {
          sendQuery[key] = options[key];
        }
      });
    }
    this.http
      .get(
        this.urlApi + '/api/empleados/nominas' + this.optionsGet(sendQuery),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedUser = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedUser,
          });
          this.sendNextObservable(this._resultEmpleadosNominas, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultEmpleadosNominas,
            this.res,
            true,
            {
              method: this.getEmpleadosNominas,
              args: [numPagina, year, month, options],
            }
          );
          this.checkStatusError(error);
        },
      });
  }

  getEmpleadosPartes(numPag: number = 1, maximo: number, options?: any) {
    let sendQuery: any = { num_devoluciones: maximo, num_pagina: numPag };
    if (options != null) {
      Object.keys(options).forEach((key) => {
        if (options[key] != '' && options[key] != null) {
          sendQuery[key] = options[key];
        }
      });
    }
    this.http
      .get(
        this.urlApi + '/api/user/empleados/partes' + this.optionsGet(sendQuery),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedUser = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedUser, total: data.total },
          });
          this.sendNextObservable(this._resultEmpleadosPartes, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultEmpleadosPartes, this.res, true, {
            method: this.getEmpleadosPartes,
            args: [numPag, maximo, options],
          });
          this.checkStatusError(error);
        },
      });
  }
}
