<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modified')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR DOCUMENTO PENDIENTE DE FIRMA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <button class="generalbutton" type="button" fileInput [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="documento"
          (change)="addDocumento(documento)">Añadir Documento</button>
        </div>
      </div>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>