<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVA FACTURA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">

        <div class="inputForm">
          <label>Fecha de Emisión</label>
          <DatePicker (dataFromPicker)="getDataFromFecha($event, 'fechaEmision')" [dataPlaceholder]="fecha"></DatePicker>
        </div>

        <div class="inputForm">
          <label>Base imponible</label>
          <CustomInput placeholder="0000" [type]="'number'" [(ngModel)]="objectToPass.importe">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Número de factura</label>
          <CustomInput placeholder="100" [type]="'number'" [(ngModel)]="objectToPass.numero">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Notas</label>
          <CustomInput placeholder="Notas" [(ngModel)]="objectToPass.notas">
          </CustomInput>
        </div>

        <div class="inputForm" *ngIf="modalData.tipo !== 'jefeObra'">
          <label>Fecha Vencimiento</label>
          <DatePicker placeholder="Notas" (dataFromPicker)="getDataFromFecha($event, 'fechaVencimiento')">
          </DatePicker>
        </div>

        <div class="inputForm">
          <label>Obra</label>
          <selector placeholder="Obra" class="sizeInput" [options]="optionObra" [(ngModel)]="obraLabel" (change)="getFromSelector($event, 'obra')"></selector>
        </div>

        <div class="inputForm" *ngIf="modalData.tipo === 'jefeObra'">
          <label>Proveedor</label>
          <selector placeholder="Proveedor" class="sizeInput" [options]="optionProveedor" (change)="getFromSelector($event, 'proveedor')"></selector>
        </div>

        <div class="inputForm">
          <label>Carpeta</label>
          <selector placeholder="Carpeta" class="sizeInput" [options]="optionCarpeta" [(ngModel)]="carpetaLabel" (change)="getFromSelector($event, 'carpeta')"></selector>
        </div>

        <div class="inputForm">
          <ng-container *ngIf="facturaLoaded">
            <mat-icon class="green">check_circle</mat-icon>
          </ng-container>
          <button class="generalbutton" fileInput [accept]="['application/pdf']" [(ngModel)]="factura"
            (change)="addFactura(factura, 'url')">Añadir Factura</button>
        </div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevaFactura()">Añadir Nueva Factura</btn>
    </div>
  </div>
</div>