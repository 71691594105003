import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-TestingCalendar',
  templateUrl: './TestingCalendar.component.html',
  styleUrls: ['./TestingCalendar.component.scss']
})
export class TestingCalendarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
