<div class="flexContainer">
  <pill>
    <div class="flexContainer infoCont">
      <div class="informationContainer">
        <h5>{{nombreCompleto}}</h5>
        <span>{{cif}}</span>
      </div>
      <div class="informationContainer">
        <h5>Contacto:</h5>
        <span>{{nombreCompleto}}</span>
        <span>{{mail}}</span>
        <h5>Teléfonos:</h5>
        <span>{{telefono}}</span>
        <span>{{dataFromSingle?.user?.telefono2}}</span>
      </div>
      <div class="informationContainer">
        <h5>Email prevención:</h5>
        <span>{{dataFromSingle?.user?.mail}}</span>
        <h5>IVA</h5>
        <span>{{dataFromSingle?.user?.iva}}</span>
      </div>
    </div>
  </pill>
</div>
<div class="filters">
  <btn classList="iconBtn plusBtn" (click)="nuevoDocumentoAFirmar()">
    <i class="material-icons">add</i>
    <span>añadir nuevo documento a firmar</span>
  </btn>
</div>
<pill class="tablePil" classList="tablePill">
  <dataTable [data]="dateProveedores" [forceUpdateWidth]="forceUpdateWidth" [displayedColumns]="displayedColumnsProveedores" [options]="optionsProveedores" [isLoading]="isLoading" (edit)="openEditPopupDocumento($event)" (delete)="openDeletePopup($event)" (deleteSelected)="deleteSelected($event)" (see)="navigateToProveedores($event)" (clickRow)="navigateToProveedores($event)"></dataTable>
</pill>