import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iResultHttp, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ImagenService } from 'src/app/Services/Api/Imagen.service';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from '../AsegurarDeletePopup/AsegurarDeletePopup.component';
import { VisorDeDocumentoImagenComponent } from '../VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDeImagenComponent } from '../VisorDeImagen/VisorDeImagen.component';

@Component({
  selector: 'app-GaleriaFotos',
  templateUrl: './GaleriaFotos.component.html',
  styleUrls: ['./GaleriaFotos.component.scss']
})
export class GaleriaFotosComponent extends iUnsubscribeDestroy implements OnInit {

  imagenes: Array<any> = [];
  imagenAux: any = null;
  constructor(public dialogRef: MatDialogRef<GaleriaFotosComponent>, @Inject(MAT_DIALOG_DATA) private _modalData: any, private imageService: ImagenService, private notificaciones: NotificationsService, private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
     
    this.imagenes = this._modalData;
    document.querySelector(".cdk-overlay-pane.fullSizePopup mat-dialog-container")?.classList.add("galeria");
  }

  async  downloadImage(imageSrc:any) {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'image'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  
  openImageVisorPopup(imagen:any){
    this.popupSE.openPopup(VisorDeImagenComponent, {url:imagen.url}, 'fullSizePopup');

  }


  closePopup(event?: any) {
    document.querySelector(".cdk-overlay-pane.fullSizePopup mat-dialog-container")?.classList.remove("galeria");
    let p = { dataReturn: event ?? '' };
    this.dialogRef.close(p);
  }
  getImage(event: any, id: number) {
    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificaciones.showWarning("Formato de archivo no admitido")
    } else if (event[0].file.size > 5024288) {
      this.notificaciones.showWarning("Tamaño de archivo no admitido, máximo 5MB")
    } else {
      this.editImage({ id: id, url: event[0].base64 })

    }
  }
  editImage(event: any) {
    this.imageService.update(event);
    this.imageService.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (res == null) { return }
      this.notificaciones.showFeedBack('Imagen editada correctamente')
      this._unsubInd.next('')
        this.closePopup('edit');
    })
    this.imageService.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (res == null) { return }
      this.notificaciones.showError(res.message)
      this._unsub.next('')
    })
  }
  deleteImage(event: any) {
    this.popupSE.openPopup(AsegurarDeletePopupComponent, { nombre: "la imagen" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      this.imageService.delete(event.id);
      this.imageService.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
        if (res == null) { return }
        this.notificaciones.showFeedBack('Imagen borrada correctamente')
        this._unsubInd.next('')
          this.closePopup('delete');
      })
      this.imageService.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
        if (res == null) { return }
        this.notificaciones.showError(res.message)
        this._unsub.next('')
      })
      this._unsubInd2.next("")
    })
     
  }

}
