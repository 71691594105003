import { transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnimationControllerService, iUnsubscribeDestroy, NotificationsService, slideNavigationFunctions, slideNavigationTypes, TokenManagmentService, VariablesDesignSystemService } from '@quasar_dynamics/basic-designsystem';
import { filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './Services/Api/Login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition("* => slideToLeft", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)),
      transition("* => slideToRight", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)),
      transition("* => slideToTop", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)),
      transition("* => slideToBottom", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToBottom)),
      transition("* => slideToLeftDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)),
      transition("* => slideToRightDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)),
      transition("* => slideToTopDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)),
      transition("* => slideToBottomDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToBottom)),
    ])
  ],
})
export class AppComponent extends iUnsubscribeDestroy implements OnInit {

  title = 'Teldomo';
  
  constructor(private router: Router,  private titleSE: Title,  public animService: AnimationControllerService, public loginSe:LoginService, public notifications:NotificationsService){
    super();
  }
  ngOnInit(): void {
    console.log("%cDeveloped by: ", "color: rgb(90, 170,221); font-size: 24px;");
    console.log("%cQUASAR DYNAMICS", "color: rgb(90, 170,221); font-size: 64px;font-weigth: bold;");
    console.log("%cIf you like the project, you can find many more on our website:   https://quasardynamics.com/", "color: rgb(90, 170,221); font-size: 18px;");

    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);
    
    TokenManagmentService.listenerTokenLost().pipe(takeUntil(this._unsub)).subscribe(res=>{
      this.notifications.showError("Token expirado, por favor vuelve a iniciar sesión.")
      this.loginSe.logOut();
    })

    TokenManagmentService.listenerStartRefreshToken().pipe(takeUntil(this._unsub)).subscribe(res=>{
      this.loginSe.refreshToken()
    })

    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
      ).subscribe((title: string) => {
        if (title) {
          this.titleSE.setTitle(`Teldomo - ${title}`);
        }
      });
  }
}
