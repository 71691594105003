<FullContainer>
  <div class="sectionTitleContainer">
    <h2 class="sectionTitle">Partes de trabajo</h2>
    <div class="botonVacaciones" *ngIf="submenuSel === 2" >
      <ng-container >
        <mat-icon (click)="openVacationsPopup()">
          edit
        </mat-icon>
      </ng-container>
      <p class="vacacionesEdit"(click)="openVacationsPopup()"> VACACIONES</p></div>
    <toggleSubmenu [elements]="submenuNavegation" (change)="reloadPartes()" [(ngModel)]="submenuSel"></toggleSubmenu>
  </div>

  <ng-container *ngIf="submenuSel == 0">
    <AdminPartesValidar></AdminPartesValidar>
  </ng-container>

  <ng-container *ngIf="submenuSel == 1">

  <div class="calendarAndButton">
    <div class="calendarButtons">
      <div>
        <p>Desde</p>
        <DatePicker (dataFromPicker)="dataFromPickerDesde($event)"></DatePicker>
      </div>
      <div class="arrowDiv">
        <span class="material-icons arrow">arrow_forward</span>
      </div>
      <div>
        <p>Hasta</p>
        <DatePicker (dataFromPicker)="dataFromPickerHasta($event)"></DatePicker>
      </div>

      <div class="estadoSelector">
        <p>Estado</p>
        <selector [options]="optionEstado" (change)="getEstadoToFilter($event)"></selector>
      </div>
    </div>
    <div class="buttonCambiarEstado">
      <btn classList="contactButton" (click)="cambiarEstado()">
        <ng-container>
          <span>Cambiar Estado</span>
        </ng-container>
      </btn>
    </div>
  </div>

  <pill class="tablePil" classList="tablePill">
    <dataTable [forceUpdateWidth]="forceUpdateWidth" [isLoading]="isLoadingTabla" [data]="partesData" [displayedColumns]="displayedColumnsPartes"
      [options]="optionsPartes" (changeSelecteds)="selectedPartes($event)" (deleteSelected)="deleteSelected($event)" (delete)="deletePartePopup($event)" ></dataTable>
  </pill>

  </ng-container>

  <ng-container *ngIf="submenuSel === 2">
    <div class="calendarioPart">
      <div class="calendarioContainerDesign">
          <div class="moveCalendar">
            <HousingCalendar (refreshPopup)="refreshPopup($event)" [vacationPopup]="vacationPopup"></HousingCalendar>
          </div>
      </div>
    </div>
  </ng-container>

</FullContainer>