import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { LoginService } from 'src/app/Services/Api/Login.service';

@Component({
  selector: 'Footer',
  templateUrl: './Footer.component.html',
  styleUrls: ['./Footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private notificaciones:NotificationsService, private loginSe:LoginService, private router:Router ) { }

  ngOnInit() {
  }

  logOut(){
    this.notificaciones.showInfo("Se ha cerrado la sesión actual");
    this.loginSe.logOut();
  }

  goToPath(path:string){
    this.router.navigateByUrl(`/operario/${path}`)
  }

}
