<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modified')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR PROVEEDOR</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Obra</label>
          <selector [options]="optionObra" [(ngModel)]="obra" (change)="obraFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Usuario</label>
          <selector [options]="optionUsuario" [(ngModel)]="usuario" (change)="usuarioFromSelector($event)"></selector>
        </div>
      </div>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>