<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">Alertas: </h3>
    </div>
    <div class="popupBody">
      <pill class="tablePil" classList="tablePill">
        <dataTable [isLoading]="isLoadingTabla" [data]="dataAlertas" [displayedColumns]="displayedColumnsAlertas"
          [options]="optionsAlertas"></dataTable>
      </pill>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="closePopup()">
        <ng-container>
          <span>Cerrar</span>
        </ng-container>
      </btn>
    </div>
  </div>