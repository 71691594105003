import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iUnsubscribeDestroy, NotificationsService, sha256 } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';

@Component({
  selector: 'app-RecoverPasswordPage',
  templateUrl: './RecoverPasswordPage.component.html',
  styleUrls: ['./RecoverPasswordPage.component.scss']
})
export class RecoverPasswordPageComponent extends iUnsubscribeDestroy implements OnInit {

  objectToPass = {
    new_password: '',
    recover_token: ''
  }
  disableButton: boolean = true

  newPassword: string = ""

  constructor(private activatedRoute: ActivatedRoute, private notificationSE: NotificationsService, private loginSE:LoginService, private router:Router) {
    super();
  }

  ngOnInit() {
    this.getToken()
  }

  getToken() {
    this.objectToPass.recover_token = this.activatedRoute.snapshot.queryParamMap.get('token')!.replaceAll(' ', '+')
  }

  checkPasswords() {
    if ((this.objectToPass.new_password === this.newPassword)&&(this.objectToPass.new_password !=='' && this.newPassword !== '')) {
      this.disableButton = false
      return
    }
    if(this.objectToPass.new_password !== this.newPassword &&(this.objectToPass.new_password !=='' && this.newPassword !== '')){
      this.disableButton = true
      this.notificationSE.showWarning('Las contraseñas no coinciden')
      return
    }
    return

  }
  sendCredentials() {
    this.loginSE.cambiarPassword({recover_token: this.objectToPass.recover_token, new_password: sha256(this.objectToPass.new_password)!});
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return;}
      this.notificationSE.showFeedBack('Se ha cambiado la contraseña correctamente')
      this.router.navigate(['/login'])
      this._unsubInd.next('');
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return;}
       
      this.notificationSE.showError(res.message)
      this._unsub.next('');
    })
  }

}


