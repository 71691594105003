import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-VisorDePdfPopup',
  templateUrl: './VisorDePdfPopup.component.html',
  styleUrls: ['./VisorDePdfPopup.component.scss']
})
export class VisorDePdfPopupComponent implements OnInit {

  pdf:string = this.modalData.url


  constructor(public dialogRef: MatDialogRef<VisorDePdfPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
  }
  closePopup(){
    this.dialogRef.close();
  }

}
