import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import { take, takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/User.service';
import { ImagenService } from 'src/app/Services/Api/Imagen.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';

@Component({
  selector: 'app-NuevaObraPopup',
  templateUrl: './NuevaObraPopup.component.html',
  styleUrls: ['./NuevaObraPopup.component.scss']
})
export class NuevaObraPopupComponent extends iUnsubscribeDestroy implements OnInit {

  // Inputs
  estado:any =1
  cliente = ""
  operario:number[] = []
  proveedor:string = ""
  concepto: string = ""
  ubicacion: string = ""
  cp: string = ""
  poblacion: string = ""
  numero!:number
  numeroObra: string = ""
  calle: string = ""

  // Imágenes
  imagen: any = null;
  imagenes: Array<any> = []
  isloading: boolean = false;
  imagenUpdated: any


  // OPCIONES ESTADOS
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: "Activa" },
      { id: 0, label: "Finalizada" },
    ],
    clearable: false,
    placeholder: "Estado",
    bindValue: "id",
    append: true,
    search: true,
  }

  // OPCIONES ESTADOS
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "Cliente",
    append: true,
    search: true,
  }

  // OPCIONES ESTADOS
  optionOperario: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "Operario",
    bindValue: "id",
    append: true,
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios operarios seleccionados"
    },
  }

  // OPCIONES ESTADOS
  optionProveedor: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "Proveedor",
    append: true,
    search: true,
    bindValue: "id",
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios proveedores seleccionados"
    },

  }



  constructor(private obraSE: ObraService, private imagenSE: ImagenService, private userSE: UserService, private clienteSE: ClienteService, public dialogRef: MatDialogRef<NuevaObraPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSE: NotificationsService, private proveedorSE:ProveedorObraService) {
    super();
  }





  ngOnInit() {
    this.getAllClientes()
    this.getAllOperarios()
    this.getAllProveedores()
  }

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  /**
   * INPUTFILE
   */

  addImage(event: any) {
     ;

    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.imagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.imagenes.length == 0) {
          this.imagenes.push(obj)
        } else {
          this.imagenes.push(obj)
        }
      });

    }
  }
  /**
   * INPUT
   */

  getDataFromInput(key: string) {
  }

  /**
   * SELECTORES
   *   */

  getEstadoFromInput(event: any) {
    this.estado = event.id
  }
  getClienteFromInput(event: any) {
    this.cliente = event.id
  }
  getOperarioFromInput(event: any) {
     
    let listOfOperarios = event.map( (operario:any) =>{
      return operario.id
    })
    this.operario = listOfOperarios
  }
  getProveedorFromInput(event: any) {
    this.proveedor = event.id
  }



  /*
  * API CALLS
  **/

  // AllClientes

  getAllClientes() {
    this.clienteSE.getAll();
    this.clienteSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return }
      let { data } = res
      let dataForClienteInput = data.map((cliente: any) => {
        return { id: cliente.id, label: cliente.nombre }
      })
      this.optionCliente.items = dataForClienteInput
      this._unsubInd.next("")
    })
  }

  // AllOperarios

  getAllOperarios() {
    this.userSE.getAll()
    this.userSE.getResultAll().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return }
      let { data, total } = res.data;
      let dataForOperarioInput = data?.map((operario: any) => {
        return { id: operario?.id, label: operario?.nombre }
      })
      this.optionOperario.items = dataForOperarioInput
      this.operario = dataForOperarioInput.map((operario:any) =>  operario.id)
      this._unsubInd2.next("")
    })
  }

  // Proveedores

  getAllProveedores(){
    this.proveedorSE.getAllTabla()
    this.proveedorSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
    if(res === null){return}
    let {data} = res.data
    let dataForProveedorInput = data.map((proveedor:any) => {
      return {id: proveedor.id, label: proveedor.nombre}
    })
    this.optionProveedor.items = dataForProveedorInput
    this._unsubInd3.next("")
    })

  }

  submitNuevaObra() {

    if (this.concepto === "" ||  this.numeroObra === "" || this.cliente === "" || this.estado === "" || this.operario.length <= 0||  this.cp === "" || this.numero === 0 || this.poblacion === "" || this.calle ==="") { this.notificationSE.showWarning("Por favor, complete todos los campos antes de enviar la solicitud"); return; }

    this.ubicacion = `C/${this.calle} ${this.numero} ${this.cp} ${this.poblacion}`

    this.createObra()
  }



  createObra() {
    let nuevaObra = {
      concepto: this.concepto,
      numero: this.numeroObra,
      ubicacion: this.ubicacion,
      cliente: this.cliente,
      imagen: this.imagenes,
      estado: this.estado,
      operario: this.operario,
      proveedor: this.proveedor
    }

    this.obraSE.create(nuevaObra);
    this.obraSE.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return }
      this.notificationSE.showFeedBack("La obra ha sido creada correctamente");
      this.forceClosePopup("submitted")
      this._unsubInd4.next("")
    })
    this.obraSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return }
      this.notificationSE.showError(res.message);
      this._unsub.next("")
    })

  }

}
