import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FacturaService } from 'src/app/Services/Api/Factura.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';
import { VisorDeDocumentoImagenComponent } from '../../VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from '../../VisorDePdfPopup/VisorDePdfPopup.component';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';

@Component({
  selector: 'app-NuevaFacturaPopup',
  templateUrl: './NuevaFacturaPopup.component.html',
  styleUrls: ['./NuevaFacturaPopup.component.scss'],
})
export class NuevaFacturaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  /**
   * OBJECT TO PASS
   */
  facturaLoaded: boolean = false;
  objectToPass: any = {
    fechaEmision: '',
    importe: null,
    notas: '',
    estado: 'Pdte. Validación',
    numero: null,
    proveedor: this.modalData.tipo === 'proveedor' ? this.modalData.id : '',
    documento: {
      url: '',
      estado: 'pendiente',
      tipoDocumento: 8,
      carpeta: null,
      titulo: 'Factura',
      fechaCaducidad: '',
      user: this.modalData.id,
    },
  };

  obraLabel: any = null;
  carpetaLabel: any = null;
  factura: any = '';

  optionObra: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Obra',
    append: true,
    search: true,
  };
  optionProveedor: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Proveedor',
    append: true,
    search: true,
  };
  optionCarpeta: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Carpeta',
    append: true,
    search: true,
  };

  fecha: string = iDate.javascriptConvert(new Date()).toStringDate('EU');

  constructor(
    public dialogRef: MatDialogRef<NuevaFacturaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private obraSE: ObraService,
    private notificationsSE: NotificationsService,
    private facturaSE: FacturaService,
    private proveedorSE: ProveedorObraService,
    private popup: PopupService,
    private carpetaSE: CarpetaService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllObras();
    this.getProveedores();
    this.getCarpetas();
  }

  /**
   * DATEPICKER
   */

  getDataFromFecha(event: any, key: string) {
    this.objectToPass[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('EU');
  }

  getDataCaducidad(event: any) {
    this.objectToPass.documento.fechaCaducidad = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('EU');
  }

  /**
   * SELECTOR
   */

  getFromSelector(event: any, key: string) {
    if (key == 'obra') {
      this.objectToPass[key] = event.id;
      this.obraLabel = event.label;
    } else if (key == 'proveedor') {
      this.objectToPass[key] = event.id;
    } else if (key == 'carpeta') {
      this.objectToPass.documento.carpeta = event.id;
    } else {
      this.objectToPass[key] = event.label;
    }
  }

  addFactura(event: any, key: string) {
    if (event[0].file.type != 'application/pdf') {
      this.notificationsSE.showWarning(
        'Formato de archivo no admitido, por favor suba un archivo pdf'
      );
      return;
    }
    this.facturaLoaded = true;
    this.objectToPass.documento.url = event[0].base64;
  }

  /**
   *
   * CERRAR
   */

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * SUBMIT
   */

  submitNuevaFactura() {
    if (
      this.objectToPass.fechaEmision === '' ||
      this.objectToPass.proveedor === '' ||
      this.objectToPass.importe === null ||
      this.objectToPass.estado === '' ||
      this.objectToPass.numero === null ||
      this.objectToPass.proveedor === ''
    ) {
      this.notificationsSE.showWarning('Por favor, rellena todos los campos');
      return;
    }

    this.facturaSE.create(this.objectToPass);
    this.facturaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationsSE.showFeedBack('Factura creada con éxito');
        this.closePopup('submitted');
        this._unsubInd.next('');
      });
    this.facturaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationsSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getAllObras() {
    this.obraSE.getObrasUserId(this.modalData.id);
    this.obraSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;
        let mappedObra = data.map((obra: any) => {
          return { id: obra.id, label: `${obra.concepto} ${obra.ubicacion}` };
        });
        this.optionObra.items = mappedObra;
        this._unsubInd.next('');
      });
  }

  getProveedores() {
    this.proveedorSE.getAllTabla();
    this.proveedorSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;
        this.optionProveedor.items = data.map((item: any) => {
          return { ...item, label: item.nombre };
        });
        this._unsubInd3.next('');
      });
  }

  getCarpetas() {
    this.carpetaSE.getAll(1, 5000000);
    this.carpetaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;
        let mappedData = data.map((item: any) => {
          return { ...item, label: item.nombre };
        });
        this.optionCarpeta.items = mappedData;
        this._unsubInd5.next('');
      });
  }
}
