<div class="flexContainer">
  <pill>
    <div class="flexContainer infoCont">
      <div class="informationContainer">
        <h5>{{nombreCompleto}}</h5>
        <span>{{cif}}</span>
      </div>
      <div class="informationContainer">
        <h5>Contacto:</h5>
        <span>{{nombreCompleto}}</span>
        <span>{{mail}}</span>
        <span>{{telefono}}</span>
      </div>
      <div class="informationContainer">
        <h5>Email prevención:</h5>
        <span>prevencion@ejemplo.com</span>
      </div>
    </div>
  </pill>

</div>
<div class="filters">
  <btn classList="iconBtn plusBtn" (click)="addDocumentosRequeridos()">
    <i class="material-icons">add</i>
    <span>Solicitar nuevos documentos</span>
  </btn>
</div>
<div class="paginacion">
  <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
  <div class="arrows">
    <ng-container class="arrowLeft">
      <mat-icon (click)="previousPage()">
        chevron_left
      </mat-icon>
    </ng-container>
    <ng-container class="arrowRight">
      <mat-icon (click)="nextPage()">
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
</div>

<pill class="tablePil" classList="tablePill">
  <dataTable [data]="dateProveedores" [displayedColumns]="displayedColumnsProveedores" [options]="optionsProveedores" [isLoading]="isLoading"  (delete)="openDeletePopup($event)" (deleteSelected)="deleteSelected($event)" ></dataTable>
</pill>