<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR PARTE</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="nombre" [(ngModel)]="nombre" (change)="getFromInput('nombre', nombre)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Hora inicio</label>
          <CustomInput [type]="'time'" [maxlength]="5" placeholder="entrada" [(ngModel)]="entrada" (change)="getFromInput('horaInicio', entrada)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Hora Fin</label>
          <CustomInput [type]="'time'" [maxlength]="5" placeholder="entrada" [(ngModel)]="salida" (change)="getFromInput('horaFin', salida)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Comentario</label>
          <CustomInput placeholder="comentario" [(ngModel)]="comentario" (change)="getFromInput('comentario', comentario)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Estado</label>
          <selector class="sizeInput" [options]="optionEstado" [(ngModel)]="estadoSeleccionado" (change)="estadoFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Usuario</label>
          <selector class="sizeInput" [name]="'user'" [options]="optionUsers" [(ngModel)]="userSeleccionado" (change)="usuarioFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Obra</label>
          <selector class="sizeInput" [options]="optionObras" [(ngModel)]="obraSeleccionada" (change)="obraFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Vehiculo</label>
          <selector class="sizeInput" [options]="optionVehiculo" [(ngModel)]="vehiculoSeleccionado" (change)="vehiculoFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Adjuntos</label>
          <ng-container *ngIf="imagen">
            <mat-icon class="green">check_circle</mat-icon>
          </ng-container>
          <ng-container *ngIf="!imagen && isloading">
            <mat-icon class="yellow">refresh</mat-icon>
          </ng-container>
          <button type="button" class="generalbutton" fileInput [multiple]="true" [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="imagen"
          (change)="addImagen(imagen)">Añadir Imagen</button>
        </div>
        <!-- <div class="separatorGrid"></div> -->
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>
