import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationsService,
  PopupService,
  iDataTableColumns,
  iDataTableOptions,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaObraPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaObraPopup/NuevaObraPopup.component';
import { AñadirNuevoSTComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoSeguimientoTecnicoPopup/NuevoSeguimientoTecnicoPopup.component';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { EditSeguimientoTecnicoPopupComponent } from 'src/app/Popups/EditPopup/EditSeguimientoTecnicoPopup/EditSeguimientoTecnicoPopup.component';
import { VerTodoAlertasPopupComponent } from 'src/app/Popups/VerTodoAlertasPopup/VerTodoAlertasPopup.component';
import { AlertaService } from 'src/app/Services/Api/Alerta.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { HandlerService } from 'src/app/Services/Utils/Handler.service';
import { iGoogleMapsMark } from 'src/app/Shared/Interfaces/iGoogleMapsMark';

@Component({
  selector: 'app-AdminAlertas',
  templateUrl: './AdminAlertas.component.html',
  styleUrls: ['./AdminAlertas.component.scss'],
})
export class AdminAlertasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  forceUpdateWidth = new Subject();

  // Alertas

  alertaData: any[] = [
    { descripcion: 'Cargando alertas' },
    { descripcion: 'Cargando alertas' },
    { descripcion: 'Cargando alertas' },
    { descripcion: 'Cargando alertas' },
    { descripcion: 'Cargando alertas' },
    { descripcion: 'Cargando alertas' },
  ];

  // Loading

  isLoadingST: boolean = true;
  isLoadingObras: boolean = true;

  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Cliente', property: 'cliente', align: 'center' },
    { label: 'Nº Obra', property: 'num_obra', align: 'center' },
    {
      label: 'Ubicación',
      property: 'ubicacion',
      align: 'center',
      linkAction: (rowElem) => {},
    },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  optionsObras: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
  };

  dataObras = [];

  displayedColumnsSeguimiento: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Cliente', property: 'clienteNombre', align: 'center' },
    {
      label: 'Ubicación',
      property: 'ubicacion',
      align: 'center',
      linkAction: (rowElem) => {},
    },
    {
      label: 'Presupuesto',
      property: 'presupuesto',
      align: 'center',
      classList: 'presupuestoTableWidth',
    },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoPartes',
    },
  ];

  optionsSeguimiento: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
    maxHeight: 480,
  };
  dataSeguimiento = [];

  center = { lat: 40, lng: -0.3812912 };
  zoom = 7;
  markers: Array<any> = [];
  optionsMap: google.maps.MapOptions = {
    center: { lat: 40, lng: -0.3812912 },
    zoom: 7,
    styles: HandlerService.getMapStyle(),
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  };

  forceUpdateWidthObras = new Subject();

  constructor(
    private alertaSE: AlertaService,
    private notificationSE: NotificationsService,
    private obraSE: ObraService,
    private router: Router,
    private popup: PopupService,
    private seguimientoTecnicoSE: SeguimientoTecnicoService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllObras();
    this.seguimientoTecnico();
    this.getAlertas();
  }
  clickMark(ev: any, mark: iGoogleMapsMark) {}

  navigateST() {
    this.router.navigateByUrl('/admin/seguimiento');
  }

  /**
   *  OBRA
   */

  addNewObra() {
    this.popup.openPopup(NuevaObraPopupComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }

        this.ngOnInit();
        this._unsubInd3.next('');
      });
  }

  openDeleteObraPopup(event: any): void {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: 'la obra' });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd8))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteObras(event.id);
        this._unsubInd8.next('');
      });
  }

  openEditObraPopup(event: any) {
    this.popup.openPopup(EditObrasPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd9.next('');
      });
  }

  deleteObras(obj: any) {
    this.obraSE.delete(obj);
    this.obraSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack(
          'La Obra ha sido borrada correctamente'
        );
        this.ngOnInit();
        this._unsubInd7.next('');
      });
    this.obraSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  deleteSelectedObras(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'Las Obras seleccionadas',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteObras(ids);
        this._unsubInd6.next('');
      });
  }

  // API Call

  getAllObras() {
    this.obraSE.getAllTable(1, 8);
    this.obraSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        this.dataObras = data;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1000);
        this.isLoadingObras = false;
        this._unsubInd.next('');
      });
  }

  /**
   * Seguimiento Técnico
   */

  addST() {
    this.popup.openPopup(AñadirNuevoSTComponent);
  }

  editSeguimiento(event: any) {
    this.popup.openPopup(EditSeguimientoTecnicoPopupComponent, event);
  }

  OpenDeletePopup(event: any): void {
    let dataToPass = Object.assign({ nombre: 'Seguimiento Técnico' }, event);

    this.popup.openPopup(AsegurarDeletePopupComponent, dataToPass);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let idFromButton = [res.dataReturn.id];
        this.deleteST(idFromButton);
        this._unsubInd.next('');
      });
  }

  deleteST(obj: any) {
    this.seguimientoTecnicoSE.delete(obj);
    this.seguimientoTecnicoSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack(
          'El Seguimiento Tecnico ha sido borrado correctamente'
        );
        this.ngOnInit();
        this._unsubInd2.next('');
      });
    this.seguimientoTecnicoSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(`${res.message}`);
        this._unsub.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'Los Seguimientos técnicos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteST(ids);
        this._unsubInd5.next('');
      });
  }

  /**
   * ALERTAS
   */

  getAlertas() {
    this.alertaSE.getAll(12);
    this.alertaSE
      .getResult()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        this.alertaData = data.map((alerta: any) => {
          return {
            descripcion: alerta.descripcion,
            metadata: alerta.metadata[0],
          };
        });

        this._unsub.next('');
      });
  }

  /**
   * SET POINTERS
   */

  setPointers(data: any) {
    this.markers = [];

    data.map((ST: any, index: number) => {
      let icon!: any;
      switch (ST.estado) {
        case 'Visitado':
          icon = '/assets/Images/MapsMarkers/visitado.png';
          break;
        case 'Enviado':
          icon = '/assets/Images/MapsMarkers/enviado.png';
          break;
        case 'Contactado':
          icon = '/assets/Images/MapsMarkers/contactado.png';
          break;
        case 'Pendiente':
          icon = '/assets/Images/MapsMarkers/pendiente.png';
          break;
      }

      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(ST.lan, ST.lon),
        icon: icon,
        title: ST.id.toString(),
      });
      this.markers.push(marker);
    });
  }

  seguimientoTecnico() {
    this.seguimientoTecnicoSE.getAllTabla(1, 8);
    this.seguimientoTecnicoSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;

        this.dataSeguimiento = data;
        this.setPointers(data);
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1000);
        this.isLoadingST = false;
        this._unsubInd4.next('');
      });
  }

  /**
   * VER TODO ALERTAS POPUP
   */

  openVerTodoAlertasPopup() {
    this.popup.openPopup(VerTodoAlertasPopupComponent);
  }

  /**
   * IR A ALERTA
   */

  goToAlert(event: any) {}

  /**
   * Open detail view obra
   */

  openObraDetailView(event: any) {
    let { id } = event;
    this.router.navigate([`admin/obras/` + id]);
  }

  /**
   * ST DETAIL VIEW
   */

  openSeguimientoTecnicoDetailView(event: any) {
    let { id } = event;
    this.router.navigate([`admin/seguimiento/` + id]);
  }
}
