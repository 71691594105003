import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { IncidenciaService } from 'src/app/Services/Api/Incidencia.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-EditIncidenciaPopup',
  templateUrl: './EditIncidenciaPopup.component.html',
  styleUrls: ['./EditIncidenciaPopup.component.scss'],
})
export class EditIncidenciaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  fecha: string = this.modalData.fecha.replace(/-/g, '/');
  descripcion: string = this.modalData.descripcion;

  // Object to send to the API

  objectToPass: any = {
    id: this.modalData.id,
  };

  // SELECTOR

  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pendiente' },
      { id: 2, label: 'Finalizado' },
    ],
    placeholder: this.modalData.estado,
    multiple: {
      isMultiple: false,
      checkbox: true,
    },
    clearable: false,
    search: true,
    append: true,
  };

  constructor(
    private incidenciaSE: IncidenciaService,
    private notificationSE: NotificationsService,
    public dialogRef: MatDialogRef<EditIncidenciaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }

  ngOnInit() {}

  /**
   * DataPicker
   */

  dataFromPicker(event: any, key: string) {
    this.objectToPass[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('EU');
    this.editIncidencia(this.objectToPass);
  }

  /**
   * SELECTOR
   */

  getEstadoFromSelector(event: any, key: string) {
    this.objectToPass[key] = event.label;
    this.editIncidencia(this.objectToPass);
  }

  /**
   * INPUT
   */

  getDataFromInput(event: any, key: string) {
    this.objectToPass[key] = this.descripcion;
    this.editIncidencia(this.objectToPass);
  }

  /**
   * CERRAR
   */

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * EDITAR
   */

  editIncidencia(obj: any) {
    this.incidenciaSE.update(obj);
    this.incidenciaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack('Incidencia editada correctamente');
        this._unsubInd.next('');
      });
    this.incidenciaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }
}
