import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iClienteTabla {
  id?: number,
  nombre: string,
  mail: string,
  telefono: number,
  obra: string
  obraId?: string
  proyecto: string
}
export class iClienteTabla extends iBaseObject {
  static convertFromCollection(ob: any): Array<iClienteTabla> {
      let iClienteTablaCollection: Array<iClienteTabla> = [];
      ob.forEach((element: any) => {
          iClienteTablaCollection.push(this.convertFromObject(element));
      });
      return iClienteTablaCollection;
  }

  static convertFromObject(ob: any): iClienteTabla {
      let iClienteTablaObj = new iClienteTabla();
      if(ob.id !== undefined){
          iClienteTablaObj.id = ob.id;
      }
      iClienteTablaObj.nombre = ob.nombre;
      iClienteTablaObj.mail = ob.mail;
      iClienteTablaObj.telefono = ob.telefono;
      iClienteTablaObj.proyecto = ob.proyecto;
      iClienteTablaObj.obra = ob.obra;
      if(ob.obraId !== undefined){
          iClienteTablaObj.obraId = ob.obraId;
      }
      return iClienteTablaObj;
  }

  static empty(): iClienteTabla {
      let iClienteTablaObj = new iClienteTabla();
      iClienteTablaObj.id = -1;
      iClienteTablaObj.nombre = '';
      iClienteTablaObj.mail = '';
      iClienteTablaObj.telefono = -1;
      return iClienteTablaObj;
  }
}