import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import {
  iVehiculo,
  iVehiculoCreate,
} from 'src/app/Shared/Interfaces/Api/iVehiculo';
import { iVehiculoTable } from 'src/app/Shared/Interfaces/Api/iVehiculoTable';

@Injectable({
  providedIn: 'root',
})
export class VehiculoService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(
        this.urlApi + '/api/vehiculo' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedVehiculo =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iVehiculo.convertFromCollection(normalizedVehiculo),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getTable(
    pagina: number = 1,
    maximo: number = 20,
    busqueda: string = '',
    asignado: string = '',
    ITVDesde: string = '',
    ITVHasta: string = '',
    seguroDesde: string = '',
    seguroHasta: string = ''
  ) {
    let send = {
      num_devoluciones: maximo,
      num_pagina: pagina,
      busqueda: busqueda,
      asignado: asignado,
      fechaIniITV: ITVDesde,
      fechaFinITV: ITVHasta,
      fechaIniSeguro: seguroDesde,
      fechaFinSeguro: seguroHasta,
    };
    this.http
      .get(
        this.urlApi + '/api/vehiculo/tabla' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedVehiculo = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iVehiculoTable.convertFromCollection(normalizedVehiculo),
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getTable,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: any) {
    this.http
      .get(this.urlApi + '/api/vehiculo/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedVehiculo = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iVehiculo.convertFromObject(normalizedVehiculo),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(vehiculo: any) {
    this.http
      .post(this.urlApi + '/api/vehiculo', vehiculo, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [vehiculo],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(vehiculo: iVehiculo) {
    this.http
      .put(
        this.urlApi + '/api/vehiculo/' + vehiculo.id,
        vehiculo,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [vehiculo],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'vehiculos_ids');
    this.http
      .delete(this.urlApi + '/api/vehiculo', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res.status = error.error.code;
          this.res.ok = false;
          this.res.message = error.error.message;
          // this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
