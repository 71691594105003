<filterActions [placeholderAdd]="'AÑADIR NUEVO'" (searchSend)="respuestaBusqueda($event)" [inputOptions]="filterText" (searchSend)="updateQuery()" textBtn="Añadir empleado" (addNew)="newEmpleado()">
  <ng-container filterContent>
    <div class="containerSelectores">
      <selector [options]="optionUsuario" [(ngModel)]="selectedUsers" (change)="updateQuery()"></selector>
      <selector [options]="optionsObras" [(ngModel)]="selectedObras" (change)="updateQuery()"></selector>
      <selector [options]="optionsBloqueado" [(ngModel)]="selectedBloqueado" (change)="updateQuery()"></selector>
    </div>
  </ng-container>
</filterActions>
<pill class="tablePil" classList="tablePill">
  
  <dataTable [forceUpdateWidth]="forceUpdateWidth" [data]="dataEmpleados" [displayedColumns]="displayedColumnsEmpleados" [options]="optionsEmpleados" [isLoading]="isLoading" (see)="navigateToEmpleado($event)" (clickRow)="navigateToEmpleado($event)" [classList]="cursorPointer" (edit)="editEmpleados($event)" (delete)="OnclickDeleteButton($event)"></dataTable>
</pill>