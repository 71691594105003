import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { EditVehiculoPopupComponent } from '../EditVehiculoPopup/EditVehiculoPopup.component';

@Component({
  selector: 'app-EditPartesPopup',
  templateUrl: './EditPartesPopup.component.html',
  styleUrls: ['./EditPartesPopup.component.scss'],
})
export class EditPartesPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // Boolean
  isloading: boolean = false;

  // Inputs
  nombre: string = 'Parte';
  fecha: string = '';
  entrada: string = '';
  salida: string = '';
  horario: string = '';
  imagen: any = null;
  imagenes: Array<any> = [];
  comentario: string = '';

  // Data
  dataFromPartes: any = this.modalData;

  // OBJECT TO PASS
  obj: any = {
    id: this.dataFromPartes.id,
  };

  // Opciones del selector
  optionUsers: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Seleccione un usuario',
    append: true,
  };

  optionObras: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromPartes.obra,
    append: true,
  };

  optionVehiculo: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromPartes.nombre,
    append: true,
  };

  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Validado' },
      { id: 2, label: 'Pendiente' },
    ],
    clearable: false,
    placeholder: 'Seleccione un estado',
  };
  estadoSeleccionado: any = null;
  userSeleccionado: any = null;
  obraSeleccionada: any = null;
  vehiculoSeleccionado: any = null;
  // Regex

  regex = new RegExp('^([01]?[0-9]|2[0-3]):[0-5][0-9]$');

  constructor(
    private parteSe: ParteService,
    private obraSe: ObraService,
    private vehiculoSe: VehiculoService,
    private userSe: UserService,
    public dialogRef: MatDialogRef<EditVehiculoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getParteId(this.modalData.id);
    this.getAllUsers();
    this.getAllVehicles();
    this.getAllObras();
  }

  // GETTERS
  getAllUsers(): void {
    this.userSe.getAll();
    this.userSe
      .getResultAll()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        let mappedUsers = data.map((d: any) => {
          return { id: d.id, label: `${d.nombre} ${d.apellidos}` };
        });
        this.optionUsers.items = mappedUsers;
        this._unsubInd.next('');
      });
  }

  getAllVehicles(): void {
    this.vehiculoSe.getAll();
    this.vehiculoSe
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        let { data } = res;
        let mappedVehiculos = data.map((d: any) => {
          return { id: d.id, label: d.nombre };
        });
        this.optionVehiculo.items = mappedVehiculos;
        this._unsubInd2.next('');
      });
  }

  getAllObras(): void {
    this.obraSe.getSelectorObras();
    this.obraSe
      .getResultObrasSelector()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        let { data } = res;

        let mappedObras = data.map((d: any) => {
          return { id: d.id, label: d.ubicacion };
        });
        this.optionObras.items = mappedObras;
        this._unsubInd3.next('');
      });
    this.obraSe
      .getResultObrasSelectorError()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsubInd3.next('');
      });
  }

  // INPUTS

  getFromInput(element: string, value: any): void {
    if (element === 'horaInicio' || element === 'horaFin') {
      if (!this.regex.test(value)) {
        this.notificationSer.showError(
          'Por favor introduce la hora con el formato correcto, hh:mm'
        );
        return;
      }
      let today = new Date();
      let date = today.toISOString().split('T');
      value = `${date[0]} ${value}:00`;
    }
    this.obj[element] = value;
    this.updateParte(this.obj);
  }

  estadoFromSelector(event: any): void {
    this.obj['estado'] = event.label;
    this.updateParte(this.obj);
  }

  usuarioFromSelector(event: any): void {
    this.obj['usuario'] = event.id;
    this.updateParte(this.obj);
  }

  obraFromSelector(event: any): void {
    this.obj['obra'] = event.id;
    this.updateParte(this.obj);
  }

  vehiculoFromSelector(event: any): void {
    this.obj['vehiculo'] = event.id;
    this.updateParte(this.obj);
  }

  getDataFromFecha(event: any): void {
    let dateToPass = this.getDateFormat(event);
    this.obj['fecha'] = dateToPass;
    this.updateParte(this.obj);
  }

  // UPDATE

  updateParte(obj: any): void {
    this.parteSe.update(obj);
    this.parteSe
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El parte ha sido actualizado correctamente'
        );
        this._unsubInd4.next('');
      });
    this.parteSe
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  getParteId(id: number) {
    this.parteSe.getSingle(id);
    this.parteSe
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }

        console.log(res.data.horaInicio.slice(11, 16));

        this.nombre = res.data.nombre;
        this.fecha = iDate
          .javascriptConvert(new Date(res.data.fecha))
          .toStringDate('EU');
        this.comentario = res.data.comentario;
        this.entrada = res.data.horaInicio.slice(11, 16);
        this.salida = res.data.horaFin.slice(11, 16);
        res.data.estado.toLowerCase() === 'pendiente'
          ? (this.estadoSeleccionado = { id: 2, label: 'Pendiente' })
          : (this.estadoSeleccionado = { id: 1, label: 'Validado' });
        this.userSeleccionado = {
          id: res.data.usuario.id,
          label: res.data.usuario.nombre + ' ' + res.data.usuario.apellidos,
        };
        this.obraSeleccionada = {
          id: res.data.obra.id,
          label: res.data.obra.ubicacion,
        };
        this.vehiculoSeleccionado = {
          id: res.data.vehiculo.id,
          label: res.data.vehiculo.nombre,
        };
        this._unsubInd4.next('');
      });
  }

  // CLOSE
  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // FUNCIONALIDAD

  /**
   *
   * ADD Imagen
   *
   */

  addImagen(event: any) {
    this.isloading = true;
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificationSer.showWarning('Formato de archivo no admitido');
      this.imagen = null;
      this.isloading = false;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64,
        };
        if (this.imagenes.length == 0) {
          this.imagenes.push(obj);
        } else {
          this.imagenes.push(obj);
        }

        this.obj['imagenParte'] = this.imagenes;
        this.isloading = false;
        this.updateParte(this.obj);
      });
    }
  }

  regexHora(hora: string): boolean {
    return this.regex.test(hora);
  }

  getDateFormat(event: any): string {
    let { value } = event;
    let date = new Date(value);
    let dateToPass = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    return dateToPass;
  }
}
