import { ThisReceiver } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDate, iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { TipoDocumentoService } from 'src/app/Services/Api/TipoDocumento.service';

@Component({
  selector: 'app-EditDocumentosPopup',
  templateUrl: './EditDocumentosPopup.component.html',
  styleUrls: ['./EditDocumentosPopup.component.scss']
})
export class EditDocumentosPopupComponent extends iUnsubscribeDestroy implements OnInit {
  
  // DATA
  dataFromDocumentos = this.modalData

  // INPUTS
  fecha: string = (this.dataFromDocumentos.tipo !== "carpeta")? this.dataFromDocumentos.data.fecha : iDate.javascriptConvert(new Date(this.dataFromDocumentos.data.fecha)).toStringDate('EU').replaceAll('-','/')
  fechaDeCaducidad: string = (this.dataFromDocumentos.tipo !== "carpeta")? this.dataFromDocumentos.data.fechaCaducidad : this.dataFromDocumentos.data.fechaCaducidadRecortada ;
  titulo: string = (this.dataFromDocumentos.tipo !== "carpeta" )?  this.dataFromDocumentos?.data.documento : this.dataFromDocumentos.data.titulo;
  estado: string = (this.dataFromDocumentos.tipo !== "carpeta")? this.dataFromDocumentos.data.estado : this.dataFromDocumentos.data.estado;

    // OBJECT TO PASS
  obj: any = {
    id:  (this.dataFromDocumentos.tipo !== "carpeta")? this.dataFromDocumentos.data.idDocumento : this.dataFromDocumentos.data.documentoId  
  }

  // OPCIONES ESTADOS
  optionEstado: iOptionsSelector = {
    items: [
      {id:1,label:"Validado"},
      {id:2,label:"Pendiente"},
      {id:3,label:"Pendiente de firma"},
      {id:4,label:"Revisión"},
      {id:5,label:"Rechazado"},
      {id:6,label:"Caducado"},
    ],
    clearable: false,
    append: true
  }

  optionTipo: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromDocumentos,
    append: true
  }

  optionObras: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromDocumentos,
    append: true
  }
  optionCarpeta: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromDocumentos.nombreCarpeta,
    append: true
  }



  constructor(private carpetaSE:CarpetaService ,private tipoDocumentoSe: TipoDocumentoService ,private documentoSe: DocumentoService, public dialogRef: MatDialogRef<EditDocumentosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }


  ngOnInit() {
     
    if(this.dataFromDocumentos.tipo === "carpeta"){
    this.getCarpeta()
    }
    if(this.dataFromDocumentos.tipo === "proveedor"){
      this.setterProveedor();
    }
  }

  // GETTERS

  setterProveedor(){
    this.obj.id = this.dataFromDocumentos.data.id
  }


  obtainChange(element: string, value: any) {
    this.obj[element] = value
    this.updateDocumento(this.obj);
  }

  updateDocumento(obj: any) {
     
    this.documentoSe.update(obj)
    this.documentoSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El documento ha sido actualizado correctamente")
      this._unsubInd.next("")
    })
    this.documentoSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })
  }

  // Selectores

  estadoFromSelector(event: any) {
    this.obj["estado"] = event.label
    this.updateDocumento(this.obj);
  }

  getDataFromFecha(event: any) {
    this.obj["fecha"] = iDate.javascriptConvert(new Date(event.value)).toStringDate()
    this.updateDocumento(this.obj);
  }

  getDataFromFechaDeCaducidad(event: any) {
    this.obj["fechaCaducidad"] = iDate.javascriptConvert(new Date(event.value)).toStringDate()
    this.updateDocumento(this.obj);
  }



  getDataFromInput(key: string) {
  this.obj[key] = this.titulo
  this.updateDocumento(this.obj);
  }

// FUNCIONALIDAD

forceClosePopup(dataReturn: any) {
  let p = { dataReturn: dataReturn };
  this.dialogRef.close(p);
}

getDateFormat(event: any): string {
  let { value } = event;
  let date = new Date((value))
  let dateToPass = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  return dateToPass
}

carpetaFromSelector(event: any) {
  this.obj["carpeta"] = event.id
  this.updateDocumento(this.obj);
}

/**
 * API CALL
 */

getCarpeta(){
  this.carpetaSE.getAll();
  this.carpetaSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res:any)=> {
    if(res === null){return;}
    let {data} = res.data;
     
    let mappedCarpeta = data.map((item: any) => {
      return {id: item.id, label: item.nombre}
    })
     
    this.optionCarpeta.items = mappedCarpeta
    this._unsubInd3.next("")
  })
}

}
