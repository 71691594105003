import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
 

export interface iCarpeta {
    id: number,
    nombre: string,
    descripcion: string,
}

export interface iCarpetaCrear {
    nombre: string;
    descripcion: string;
}

export class iCarpeta extends iBaseObject {
    static convertFromCollection(ob: any): Array<iCarpeta> {
        let iCarpetaCollection: Array<iCarpeta> = [];
        ob.forEach((element: any) => {
            iCarpetaCollection.push(this.convertFromObject(element));
        });
        return iCarpetaCollection;
    }

    static convertFromObject(ob: any): iCarpeta {
        let iCarpetaObj = new iCarpeta();
        iCarpetaObj.id = ob.id;
        iCarpetaObj.nombre = ob.nombre;
        iCarpetaObj.descripcion = ob.descripcion;
        return iCarpetaObj;
    }

    static empty(): iCarpeta {
        let iCarpetaObj = new iCarpeta();
        iCarpetaObj.id = -1;
        iCarpetaObj.nombre = '';
        iCarpetaObj.descripcion = '';
        return iCarpetaObj;
    }
}