<FullContainer>
  <h2 class="sectionTitle">perfil</h2>
  <div class="sectionsProfile">
    <div>
      <div class="userInformationContainer">
        <div class="userGeneralInformation">
          <h3 class="transparentHeader">Información general</h3>
          <pill classList="fullHeight">

            <div class="informationContainer" [ngClass]="{proveedorInformation: rol[0] === 'ROLE_PROVEEDOR',userInformation:rol[0] !== 'ROLE_PROVEEDOR' }">
              <i class="material-icons cancelIcon" *ngIf="isEditing" (click)="cancelEdit()">cancel</i>
              <i class="material-icons editIcon" *ngIf="!isEditing" (click)="isEditing= !isEditing">edit</i>
              <i class="material-icons saveIcon" *ngIf="isEditing" (click)="saveEdit()">save</i>
              <div class="informationLabel">
                <label>Usuario</label>
                <span *ngIf="!isEditing || rol[0] == 'ROLE_PROVEEDOR'">{{original.nombre}} {{original.apellidos}}</span>
                <div class="dobleInput">
                  <CustomInput *ngIf="isEditing && rol[0] !== 'ROLE_PROVEEDOR'" classList="superFino dobleInputLeft" [(ngModel)]="editing.nombre"></CustomInput>
                  <CustomInput *ngIf="isEditing && rol[0] !== 'ROLE_PROVEEDOR'" classList="superFino dobleInputRight" [(ngModel)]="editing.apellidos"></CustomInput>
                </div>
              </div>
              <div class="informationLabel">
                <label>Email</label>
                <span *ngIf="!isEditing || rol[0] == 'ROLE_PROVEEDOR'">{{original.mail}}</span>
                <CustomInput *ngIf="isEditing && rol[0] !== 'ROLE_PROVEEDOR'" classList="superFino" [(ngModel)]="editing.mail"></CustomInput>
              </div>
              <div class="informationLabel">
                <label>Contraseña</label>
                <span *ngIf="!isEditing">***********</span>
                <btn *ngIf="isEditing" (click)="openPasswordPopup()">Editar Contraseña</btn>
              </div>
              <div class="informationLabel" *ngIf="rol[0] === 'ROLE_ADMIN'">
                <label>Departamento</label>
                <span *ngIf="!isEditing" class="capitalize">{{original.departamento}}</span>
                <CustomInput *ngIf="isEditing"  classList="superFino" [(ngModel)]="editing.departamento"></CustomInput>
              </div>
              <div class="informationLabel" *ngIf="rol[0] !== 'ROLE_PROVEEDOR'">
                <label>Talla camiseta</label>
                <span *ngIf="!isEditing">{{original.tallaCam}}</span>
                <selector *ngIf="isEditing" class="superFino" [(ngModel)]="editing.tallaCam" [options]="optionsTallaCam"></selector>
              </div>
              <div class="informationLabel" *ngIf="rol[0] !== 'ROLE_PROVEEDOR'">
                <label>Talla pantalón</label>
                <span *ngIf="!isEditing">{{original.tallaPan}}</span>
                <CustomInput *ngIf="isEditing"  classList="superFino" [(ngModel)]="editing.tallaPan"></CustomInput>
              </div>
              <div class="informationLabel" *ngIf="rol[0] !== 'ROLE_PROVEEDOR'">
                <label>Talla botas</label>
                <span *ngIf="!isEditing">{{original.tallaBota}}</span>
                <CustomInput *ngIf="isEditing"  classList="superFino" [(ngModel)]="editing.tallaBota"></CustomInput>
              </div>
              <btn class="logout" classList="secundario fino" (click)="logout()">cerrar sesión</btn>
            </div>



          </pill>
        </div>
        <div class="userDocuments" *ngIf="rol[0] !== 'ROLE_PROVEEDOR'">
          <h3>Documentos requeridos</h3>
          <pill classList="fullHeight">
            <div class="informationContainer documentoRequeridos">
              <div class="documento">
                <div class="documento_title">
                  <span (click)="openDocumento(documentosRequeridos.dniAnverso)" class="cursor-pointer"><b>DNI Delante</b></span>
                  <btn classList="secundario fino" (click)="adjuntarFoto('DNIAnverso')">adjuntar foto</btn>
                </div>
                <div class="documento_caducidad">
                  <span [ngClass]="{orange: dniAnversoACaducar, red:dniAnversoCaducado}" *ngIf="documentosRequeridos.dniAnverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.dniAnverso.fechaCaducidad | date:'shortDate'}}</span>
                  <div class="warningContainer" *ngIf="isWarningDniAnverso">
                    <i class="material-icons-outlined">report_problem</i>
                    <span>{{textWarningDniAnverso}}</span>
                  </div>
                </div>
              </div>
              <div class="documento">
                <div class="documento_title">
                  <span  (click)="openDocumento(documentosRequeridos.dniReverso)"class="cursor-pointer"><b>DNI Detrás</b></span>
                  <btn classList="secundario fino" (click)="adjuntarFoto('DNIReverso')">adjuntar foto</btn>
                </div>
                <div class="documento_caducidad">
                  <span [ngClass]="{orange: dniReversoACaducar, red:dniReversoCaducado}"  *ngIf="documentosRequeridos.dniReverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.dniReverso.fechaCaducidad | date:'shortDate'}}</span>
                  <div class="warningContainer" *ngIf="isWarningDniReverso">
                    <i class="material-icons-outlined">report_problem</i>
                    <span>{{textWarningDniReverso}}</span>
                  </div>
                </div>
              </div>
              <div class="documento">
                <div class="documento_title">
                  <span   (click)="openDocumento(documentosRequeridos.conducirAnverso)"class="cursor-pointer"><b>Carnet de conducir Delante</b></span>
                  <btn classList="secundario fino" (click)="adjuntarFoto('carnetAnverso')">adjuntar foto</btn>
                </div>
                <div class="documento_caducidad">
                  <span [ngClass]="{orange: conducirAnversoACaducar, red:conducirAnversoCaducado}" *ngIf="documentosRequeridos.conducirAnverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.conducirAnverso.fechaCaducidad | date:'shortDate'}}</span>
                  <div class="warningContainer" *ngIf="isWarningConducirAnverso">
                    <i class="material-icons-outlined">report_problem</i>
                    <span>{{textWarningConducirAnverso}}</span>
                  </div>
                </div>
              </div>
              <div class="documento">
                <div class="documento_title">
                  <span  (click)="openDocumento(documentosRequeridos.conducirReverso)"class="cursor-pointer"><b>Carnet de conducir Detrás</b></span>
                  <btn classList="secundario fino" (click)="adjuntarFoto('carnetReverso')">adjuntar foto</btn>
                </div>
                <div class="documento_caducidad">
                  <span [ngClass]="{orange: conducirReversoACaducar, red:conducirReversoCaducado}" *ngIf="documentosRequeridos.conducirReverso.fechaCaducidad">Fecha de caducidad: {{documentosRequeridos.conducirReverso.fechaCaducidad | date:'shortDate'}}</span>
                  <div class="warningContainer" *ngIf="isWarningConducirReverso">
                    <i class="material-icons-outlined">report_problem</i>
                    <span>{{textWarningConducirReverso}}</span>
                  </div>
                </div>
              </div>
            </div>


            
          </pill>
        </div>
      </div>
    </div>
    <div class="userNominas" *ngIf="rol[0] !== 'ROLE_PROVEEDOR'">
      <h3>Nóminas</h3>
      <pill class="tablePil" classList="fullHeight tablePill">
        <dataTable [isLoading]="isLoadingTabla" [data]="dataNominas" [displayedColumns]="displayedColumnsNominas" [options]="optionsNominas" (clickRow)="openNomina($event)"></dataTable>
      </pill>
    </div>
  </div>
</FullContainer>