import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { iProveedorObra } from 'src/app/Shared/Interfaces/Api/iProveedorObra';
import { iProveedorObraTabla } from 'src/app/Shared/Interfaces/Api/iProveedorObraTabla';

@Injectable({
  providedIn: 'root',
})
export class ProveedorObraService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(
        this.urlApi + '/api/proveedor/obra/' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedProveedorObra =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProveedorObra.convertFromCollection(normalizedProveedorObra),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllTabla(pagina: number = 1, maximo: number = 20, busqueda: string = '') {
    let send = {
      num_devoluciones: maximo,
      num_pagina: pagina,
      buscador: busqueda,
    };
    this.http
      .get(
        this.urlApi + '/api/user/tabla/proveedores' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedProveedorObra =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iProveedorObraTabla.convertFromCollection(
                normalizedProveedorObra
              ),
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTabla,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number | string) {
    this.http
      .get(this.urlApi + '/api/proveedor/obra/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedProveedorObra = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProveedorObra.convertFromObject(normalizedProveedorObra),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(proveedorobra: iProveedorObra) {
    this.http
      .post(
        this.urlApi + '/api/proveedor/obra',
        proveedorobra,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [proveedorobra],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(proveedorobra: iProveedorObra) {
    this.http
      .put(
        this.urlApi + '/api/proveedor/obra/' + proveedorobra.id,
        proveedorobra,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [proveedorobra],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'proveedorObras_ids');
    this.http
      .delete(
        this.urlApi + '/api/proveedor/obra',
        this.sendBodyOptions(idsObjs)
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
