import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../Api/Login.service';

@Injectable({
  providedIn: 'root'
})
export class RouterRedirectService implements CanActivate  {

  constructor(public router:Router, public loginSe:LoginService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    let roles = this.loginSe.getRoles();
    if(roles == null || roles.length == 0){
      this.router.navigate(['/login']);
    }else{
       
      if(roles.includes("ROLE_ADMIN")){
        this.router.navigate(['/admin/obras']);
      }else if(roles.includes("ROLE_JEFE_OBRA")){
        this.router.navigate(['/jefe/obras']);
      }else if(roles.includes("ROLE_TECNICO")){
        this.router.navigate(['/tecnico/dashboard']);
      }else if(roles.includes("ROLE_PROVEEDOR")){
        this.router.navigate(['/proveedores/dashboard']);
      }else if(roles.includes("ROLE_USER")){
        this.router.navigate(['/operario/dashboard']);
      }
    }
    return true;
  }

}
