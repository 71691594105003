import { Component, OnInit,  } from '@angular/core';
import { iDate, iOptionsSelector,  iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoParteComponentComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoParteComponent/NuevoParteComponent.component';
import { DescargaPartePopupComponent } from 'src/app/Popups/DescargaPartePopup/DescargaPartePopup.component';
import { EditPartesPopupComponent } from 'src/app/Popups/EditPopup/EditPartesPopup/EditPartesPopup.component';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { iDataTableColumns, iDataTableOptions } from 'src/app/Shared/Interfaces/iDataTable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'JefePartesValidar',
  templateUrl: './JefePartesValidar.component.html',
  styleUrls: ['./JefePartesValidar.component.scss']
})
export class JefePartesValidarComponent extends iUnsubscribeDestroy implements OnInit {

  datePartes: Array<any> = [];
  datePartes2: Array<any> = [];
  isLoading: boolean = false;
  vehiculos: Array<any> = [];
  empleados: Array<any> = [];
  obras: Array<any> = [];
  estados: Array<any> = [];

  selectVehiculo: any = null;
  selectEmpleado: any = null;
  selectObra: any = null;
  selectEstado: any = null;
  dataPartes: Array<any> = [];
  isLoadingPartes: boolean = false;

  allUsers: Array<any> = [];
  allVehicles: Array<any> = [];
  allObras: Array<any> = [];

  filterText = {
    placeholder: 'Busca por Nº Obra o Horario',
    model: '',
  }

  // Selectores
  optionsVehiculo: iOptionsSelector = {
    items: this.vehiculos,
    clearable: true,
    bindLabel: 'nombreVehiculo',
    placeholder: 'Vehículo'
  }
  optionsEmpleado: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Empleados'
  }
  optionsObra: iOptionsSelector = {
    items: this.obras,
    clearable: true,
    bindLabel: 'nombreObra',
    placeholder: 'Obra'
  }
  optionsEstado: iOptionsSelector = {
    items: [
      { id: 'pendiente', label: 'Pendiente' },
      { id: 'validado', label: 'Validado' },
    ],
    clearable: true,
    placeholder: 'Estado'
  }

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;


  //Tabla
  displayedColumnsPartes: Array<iDataTableColumns> = [
    { label: 'Fecha', property: 'date' },
    { label: 'Nº Obra', property: 'numero', align: "center" },
    { label: 'Proyecto', property: 'obra', align: "center" },
    { label: 'Empleado', property: 'empleado' },
    { label: 'Tarea', property: 'tarea' },
    { label: 'Horario', property: 'horario', align: "center", classList: "" },
    { label: 'Vehículo', property: 'nombre', align: "center" },
    { label: 'Adjuntos', property: 'url', align: "center" },
    { label: 'Estado', property: 'estado', align: "center", classList: 'tableEstado tableEstadoPartes' }
  ];

  optionsPartes: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead: [{ callback: () => { this.exportexcel() }, icon: "download" }],
    
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }

  constructor(public usuariosSe: UserService, private popup: PopupService, private parteSe: ParteService, private notificationSe: NotificationsService, private vehiculoSe: VehiculoService, private obrasSe: ObraService) {
    super();
  }

  ngOnInit() {
    this.updateQuery()
    this.getFilters()
  }

  cambiarEstado(key: string) {
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows: any = [];
    rows.forEach((row, index) => {
      if (row.querySelector('.checkboxContainer mat-checkbox label span input ')!.getAttribute('aria-checked') === "true") {
        filteredRows.push(this.datePartes[index].id)
      }
    })
    if (filteredRows.length > 0) {
      if (key === 'validar') {
        let ObjectToPass:any = {
          ids: filteredRows,
          estado: 'validado'
        } 
        this.editEstado(ObjectToPass);
      }
      if (key === 'rechazar') { 
        let ObjectToPass:any = {
          ids: filteredRows,
          estado: 'rechazado'
        } 
        this.editEstado(ObjectToPass);
       }

    } else {
      this.notificationSe.showWarning('Selecciona al menos un parte para cambiar su estado')
    }

  }

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.classList.contains('mat-checkbox-checked')
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Partes.xlsx');

  }
  /**
* NEXT PAGE
*/

  nextPage() {
    if (this.numMax === this.paginacionTotal) { return }
    this.pagina++
    this.numMin = this.numMin + this.maximo
    this.numMax = (this.numMax + this.maximo < this.paginacionTotal) ? this.numMax + this.maximo : this.paginacionTotal
    this.updateQuery()
  }

  /**
   * PREVIOUS PAGE
  */

  previousPage() {
    if (this.numMin === 1) { return }
    this.numMin = this.numMin - this.maximo;
    this.numMax = (this.numMax - this.maximo < this.maximo) ? this.maximo : this.numMax - this.maximo
    this.pagina--
    this.updateQuery()
  }

  updateQuery() {
    let sendQuery: any = {};
    if (this.selectVehiculo != null) { sendQuery["vehiculo"] = this.selectVehiculo.id }
    if (this.selectEmpleado != null) { sendQuery["empleado"] = this.selectEmpleado.id }
    if (this.selectObra != null) { sendQuery["obra"] = this.selectObra.id }
    if (this.selectEstado != null) { sendQuery["estado"] = this.selectEstado.id }
    if (this.filterText.model != null && this.filterText.model != '') { sendQuery["busqueda"] = this.filterText.model }
    sendQuery['num_devoluciones'] = this.maximo;
    sendQuery['pagina'] = this.pagina;

    this.getEmpleadosPartes(sendQuery);
  }

  OnclickDeleteButton(event: any): void {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "el parte" })
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      this.deleteParte(event.id)
      this._unsubInd2.next("")
    })
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "Los partes seleccionados" });
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let ids = event.map((e: any) => e.id)
      this.deleteParte(ids)
      this._unsubInd.next("")
    })
  }

  OnclickEditButton(event: any): void {
    this.popup.openPopup(EditPartesPopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return }
      this.getEmpleadosPartes()
      this._unsubInd.next("")
    })
  }

  deleteParte(id: any): void {
    this.parteSe.delete(id);
    this.parteSe.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showFeedBack("El parte ha sido borrado correctamente")
      this.updateQuery()
      this._unsubInd3.next("")
    })
    this.parteSe.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showError(`${res.message}`)
      this._unsub.next("")
    })

  }

  openParte(event: any) {
    this.popup.openPopup(DescargaPartePopupComponent, event, 'fullSizePopup')
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null || res['dataReturn'] !== 'refresh') { return; }
      this.getEmpleadosPartes()
      this._unsubInd.next("")
    })
  }

  nuevoParte(): void {
    this.popup.openPopup(NuevoParteComponentComponent, { tipo: "nuevo", admin:false, jefeDeObra:Number(localStorage.getItem('nmsr')!) })
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      this.getEmpleadosPartes()
      this._unsubInd.next("")
    })
  }

  /**
   * API CALLS
   */

  editEstado(event: any) {
    this.parteSe.changeEstado(event);
    this.parteSe.getResultUpdate().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showFeedBack("El estado del parte ha sido actualizado correctamente")
      this.updateQuery()
      this._unsubInd4.next("")
    })
    this.parteSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSe.showError(`${res.message}`)
      this._unsub.next("")
    })
  }

  getEmpleadosPartes(sendQuery: any = null) {
    this.isLoadingPartes = true;
    this.parteSe.getAllPartesByJefe(Number(localStorage.getItem('nmsr')!),this.pagina, this.maximo, sendQuery);
    this.parteSe.getResultAllPartesByJefe().pipe(takeUntil(this._unsubInd9)).subscribe((res: any) => {
      if (res == null) { return; };
      let { data, total } = res.data;
      this.paginacionTotal = total
      this.numMaxPag = Math.ceil(total / this.maximo)
      if (total < this.maximo) this.numMax = total

      let a = data?.map((e: any) => {
        let obAppend = e;
        obAppend.date = iDate.phpConvert(e.fecha);
        obAppend.horario = obAppend.horario.split("-").map((e: any) => {
          let time = e.split(":");
          return time[0] + ":" + time[1];
        }).join(" - ");
        if (obAppend.nombre == null) { obAppend.nombre = "-"; }
        if (obAppend.url == null) { obAppend.url = "-"; }
        if (obAppend.url !== '-') { obAppend.url = "Adjunto"; }
        return obAppend;
      })
      this.datePartes = a;
      this.isLoadingPartes = false;
      this._unsubInd9.next("")
    })
  }

  getFilters() {
    this.usuariosSe.getOperarios();
    this.usuariosSe.getResultAll().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return }
      this.optionsEmpleado.items = res["data"].map((e: any) => { return { ...e, label: e.nombre + " " + e.apellidos } });
      this._unsubInd2.next('')
    })

    this.vehiculoSe.getAll();
    this.vehiculoSe.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (res == null) { return }
      this.optionsVehiculo.items = res["data"].map((e: any) => {
        let veSend = e;
        veSend.nombreVehiculo = veSend.matricula  + " - " + veSend.nombre;
        return veSend;
      });
      this._unsubInd3.next('')
    })

    this.obrasSe.getAll();
    this.obrasSe.getResult().pipe(takeUntil(this._unsubInd4)).subscribe((res: any) => {
      if (res == null) { return }
      this.optionsObra.items = res["data"].map((e: any) => {
        let obSend = e;
        obSend.nombreObra = obSend.numero  + " " + obSend.ubicacion + " - " + obSend.cliente.nombre;
        return obSend;
      });
      this._unsubInd4.next("")
    })

  }
}
