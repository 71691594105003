<FullContainer>
  <div class="mainBlock">

    <div class="sectionTitleContainer">
      <mat-icon class="material-icons-circle" (click)="returnST()">arrow_back</mat-icon>
      <h2 class="sectionTitleCustom">{{concepto || ""}}</h2>
    </div>

    <div class="mainContainer">


      <div class="containerInfo">

        <div class="iconos">
          <ng-container>
            <mat-icon class="icon" (click)="edit(dataST)">edit</mat-icon>
            <mat-icon class="icon" (click)="delete(dataST)">delete</mat-icon>
          </ng-container>
        </div>

        <div class="clienteBlock">
          <p class="header">Pedido por</p>
          <p class="content">{{pedidoPor || ""}}</p>
        </div>
        <div class="fechaVisitaBlock">
          <p class="header">Fecha Entrada</p>
          <p class="content">{{fechaEntrada | date:'dd MMMM yyyy' || ""}}</p>
        </div>
        <div>
          <p class="header">Fecha Objetivo</p>
          <p class="content">{{fechaObjetivo | date:'dd MMMM yyyy' || ""}}</p>
        </div>
        <div class="tecnicoEncargadoBlock">
          <p class="header">Técnico Encargado</p>
          <p class="content">{{tecnico || ""}}</p>
        </div>
        <div class="estadoBlock">
          <p class="header">Estado</p>
          <span class="content"
            [ngClass]="{'contactado': estado === 'Contactado', 'visitado': estado === 'Visitado', 'pendiente': estado === 'Pendiente' || estado === 'pendiente', 'enviado': estado === 'Enviado'}">{{estado
            || ""
            | titlecase}}</span>
        </div>
        <div class="fechaEntrada">
          <p class="header">Fecha Visita</p>
          <p class="content">{{fechaVisita | date:'dd MMMM yyyy' || ""}}</p>
        </div>
        <div class="fechaEntrada">
          <p class="header">Hora Visita</p>
          <p class="content">{{horaVisita || ""}}</p>
        </div>
        <div class="comentarioBlock">
          <p class="header">Comentarios</p>
          <p class="content">{{comentarios || ""}}</p>
        </div>
        <div class="ubicacionBlock">
          <p class="header">Ubicación</p>
          <div class="d-flex">
            <ng-container>
              <mat-icon class="icon">pin_drop</mat-icon>
            </ng-container>
            <p class="contentUbicacion" (click)="abrirGoogleMaps()">{{ubicacion || ""}}</p>
          </div>
        </div>

        <div class="contactoBlock">
          <p class="header">Contacto</p>
          <p class="content">{{contacto || ""}}</p>
        </div>

        <div class="telefonoBlock">
          <p class="header">Telefono</p>
          <p class="content">{{telefono || ""}}</p>
        </div>


      </div>

      <div class="containerPictures">
        <div class="contentContainerPictures">
          <div class="otherPictures">


            <div class="smallPictureContainer" *ngFor="let imagen of imagenes">
              <img class="imagen" src="{{imagen?.url}}" alt="imagenSeguimientoTecnico"
                onerror="this.src='assets/Images/Placeholder.jpg'">
            </div>
          </div>

        </div>
        <div class="button">
          <div class="buttonContainer">
            <btn classList="finisimo" (click)="verGaleria()">
              <p>Ver Galería</p>
            </btn>
            <btn classList="finisimo" (click)="generateZipFromImageUrls('images.zip')">
              <p>descargar imágenes</p>
            </btn>
          </div>
        </div>
      </div>
    </div>
  </div>




</FullContainer>