import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';

@Component({
  selector: 'app-NuevaCarpetaPopup',
  templateUrl: './NuevaCarpetaPopup.component.html',
  styleUrls: ['./NuevaCarpetaPopup.component.scss']
})
export class NuevaCarpetaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  // Input

  nombre:string ="";
  descripcion:string ="";


  constructor(private notificationSE:NotificationsService ,private carpetaSE:CarpetaService ,public dialogRef: MatDialogRef<NuevaCarpetaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {
    super();
   }

  ngOnInit() {
  }

  // Close

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // Inputs

  getDataFromInput(key:any){
     
  }




  // Submit

  submitNuevaCarpeta(){
    if(this.nombre == "" || this.descripcion ==""){return}

    let objectToPass = {
      nombre:this.nombre,
      descripcion: this.descripcion
    }

    this.carpetaSE.create(objectToPass);
    this.carpetaSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res===null){return}
      let {data} = res
       
      this._unsubInd.next('');
    })
    this.carpetaSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res=>{
      if(res === null){return}
      this.notificationSE.showError(res.message);
      this._unsub.next('')
    })
    this.closePopup("submitted")
  }
}
