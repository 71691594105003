import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-AsegurarDeletePopup',
  templateUrl: './AsegurarDeletePopup.component.html',
  styleUrls: ['./AsegurarDeletePopup.component.scss']
})
export class AsegurarDeletePopupComponent implements OnInit {

  accion:string = ""  
  dataFromChild = this.modalData
  nameToPass: string = "" 

  constructor(public dialogRef: MatDialogRef<AsegurarDeletePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {

     
    this.nameToPass = this.dataFromChild.nombre
    if(this.modalData.tipo == "bloquear"){
      this.accion = "bloquear"
    }
    if(this.modalData.tipo == "desbloquear"){
      this.accion = "desbloquear"
    }
    if(this.modalData.tipo == "estado"){
      this.accion = "cambiar el estado"
    }
    if(!this.modalData.tipo){
      this.accion = "eliminar"
    }
  }
  closePopup(dataReturn?:any){
    let p={dataReturn:dataReturn};
    this.dialogRef.close(p);
  }
  forceClosePopup(){
    this.dialogRef.close();
  }
}
