<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVO CLIENTE</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="nombre" >
          </CustomInput>
        </div>
        <!-- <div class="inputForm">
          <label>Mail</label>
          <CustomInput placeholder="mail@mail.com" [(ngModel)]="mail" >
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Teléfono</label>
          <CustomInput placeholder="000000000" [(ngModel)]="telefono" >
          </CustomInput>
        </div> -->

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevoCliente()">Añadir Nuevo Cliente</btn>
    </div>
  </div>
</div>