<div class="popup">
  <div class="popupHeader">
    <h3>¿Estás seguro de que quieres {{accion}} {{nameToPass}}?</h3>
  </div>
  <div class="popupButtonsDiv">
    <div class="buttonsDiv">
      <btn classList="generalbutton close sizeButton" (click)="forceClosePopup()">
        <ng-container>
          <mat-icon>close</mat-icon>
        </ng-container>
      </btn>
      <btn classList="generalbutton okay sizeButton" (click)="closePopup(dataFromChild)">
        <ng-container>
          <mat-icon>check</mat-icon>
        </ng-container>
      </btn>
    </div>
  </div>
</div>