import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminClientesComponent } from './Pages/Section-Admin/AdminClientes/AdminClientes.component';
import { AdminDashboardComponent } from './Pages/Section-Admin/AdminDashboard/AdminDashboard.component';
import { AdminDocumentosComponent } from './Pages/Section-Admin/AdminDocumentos/AdminDocumentos.component';
import { AdminEmpleadosComponent } from './Pages/Section-Admin/AdminEmpleados/AdminEmpleados.component';
import { AdminObrasComponent } from './Pages/Section-Admin/AdminObras/AdminObras.component';
import { ObraDetailViewComponent } from './Pages/Section-Admin/AdminObras/ObraDetailView/ObraDetailView.component';
import { AdminPartesComponent } from './Pages/Section-Admin/AdminPartes/AdminPartes.component';
import { AdminProveedoresComponent } from './Pages/Section-Admin/AdminProveedores/AdminProveedores.component';
import { AdminSegtoTecnicoComponent } from './Pages/Section-Admin/AdminSegtoTecnico/AdminSegtoTecnico.component';
import { SeguimientoTecnicoDetailViewComponent } from './Pages/Section-Admin/AdminSegtoTecnico/SeguimientoTecnicoDetailView/SeguimientoTecnicoDetailView.component';
import { AdminVehiculosComponent } from './Pages/Section-Admin/AdminVehiculos/AdminVehiculos.component';
import { VehiculoDetailComponent } from './Pages/Section-Admin/AdminVehiculos/VehiculoDetail/VehiculoDetail.component';
import { JefeDashboardComponent } from './Pages/Section-Jefe/JefeDashboard/JefeDashboard.component';
import { JefeDocumentosComponent } from './Pages/Section-Jefe/JefeDocumentos/JefeDocumentos.component';
import { JefeDocumentosObrasComponent } from './Pages/Section-Jefe/JefeDocumentos/JefeDocumentosObras/JefeDocumentosObras.component';
import { JefeFacturasComponent } from './Pages/Section-Jefe/JefeFacturas/JefeFacturas.component';
import { JefeObrasComponent } from './Pages/Section-Jefe/JefeObras/JefeObras.component';
import { PartesObrasComponent } from './Pages/Section-Jefe/JefeObras/PartesObras/PartesObras.component';
import { JefePartesComponent } from './Pages/Section-Jefe/JefePartes/JefePartes.component';
import { ForgotPasswordPageComponent } from './Pages/Section-Login/ForgotPasswordPage/ForgotPasswordPage.component';
import { LoginPageComponent } from './Pages/Section-Login/LoginPage/LoginPage.component';
import { RecoverPasswordPageComponent } from './Pages/Section-Login/RecoverPasswordPage/RecoverPasswordPage.component';
import { OperarioDashboardComponent } from './Pages/Section-Operario/OperarioDashboard/OperarioDashboard.component';
import { OperarioDocumentosComponent } from './Pages/Section-Operario/OperarioDocumentos/OperarioDocumentos.component';
import { OperarioDocumentosObraIdComponent } from './Pages/Section-Operario/OperarioDocumentos/OperarioDocumentosObraId/OperarioDocumentosObraId.component';
import { OperarioPofileComponent } from './Pages/Section-Operario/OperarioPofile/OperarioPofile.component';
import { ProveedoresDashboardComponent } from './Pages/Section-Proveedores/ProveedoresDashboard/ProveedoresDashboard.component';
import { ProveedoresDashboarDocumentosComponent } from './Pages/Section-Proveedores/ProveedoresDashboarDocumentos/ProveedoresDashboarDocumentos.component';
import { ProveedoresFacturasComponent } from './Pages/Section-Proveedores/ProveedoresFacturas/ProveedoresFacturas.component';
import { ProveedoresProveedoresComponent } from './Pages/Section-Proveedores/ProveedoresProveedores/ProveedoresProveedores.component';
import { TecnicoDashboardComponent } from './Pages/Section-Tecnico/TecnicoDashboard/TecnicoDashboard.component';
import { EmpleadoSeeEditComponent } from './Pages/SharedComponents/EmpleadoSeeEdit/EmpleadoSeeEdit.component';
import { ProveedorSeeEditComponent } from './Pages/SharedComponents/ProveedorSeeEdit/ProveedorSeeEdit.component';
import { UserProfileComponent } from './Pages/SharedComponents/UserProfile/UserProfile.component';
import { CanActivateAdminGuard } from './Services/Utils/Guards/can-activate-admin.guard';
import { CanActivateJefeObraGuard } from './Services/Utils/Guards/can-activate-jefe-obra.guard';
import { CanActivateOperarioGuard } from './Services/Utils/Guards/can-activate-operario.guard';
import { CanActivateProveedorGuard } from './Services/Utils/Guards/can-activate-proveedor.guard';
import { CanActivateTecnicoGuard } from './Services/Utils/Guards/can-activate-tecnico.guard';
import { RouterRedirectService } from './Services/Utils/RouterRedirect.service';
import { AdminAlertasComponent } from './Pages/Section-Admin/AdminAlertas/AdminAlertas.component';

const routes: Routes = [
  { path: '', component: AppComponent, canActivate: [RouterRedirectService] },
  // Section Login
  { path: 'login', component: LoginPageComponent, data: { title: 'Login account', state: "Login account" } },
  { path: 'forgotPass', component: ForgotPasswordPageComponent, data: { title: 'Forgot password', state: "Forgot password" } },
  { path: 'recoverPass', component: RecoverPasswordPageComponent, data: { title: 'Recover password', state: "Recover password" } },
  // Shared page
  { path: 'perfil', component: UserProfileComponent, data: { title: 'Perfil', state: "Perfil" } },
  { path: 'empleado/:id', component: EmpleadoSeeEditComponent, data: { title: 'Empleado', state: "Empleado" } },
  { path: 'proveedor/:id', component: ProveedorSeeEditComponent, data: { title: 'Proveedor', state: "Proveedor" } },
  // Section Admin
  {
    path: 'admin', data: { title: 'Dashboard', state: "Dashboard" }, canActivate:[CanActivateAdminGuard], children: [
      { path: '', redirectTo: '/admin/obras', pathMatch: 'full' },
      // { path: 'dashboard', component: AdminDashboardComponent, data: { title: 'Dashboard', state: "Dashboard" } },
      { path: 'obras', component: AdminObrasComponent, data: { title: 'Obras', state: "Obras" }},
      { path: 'obras/:id', component: ObraDetailViewComponent, data: { title: 'Obra detalle', state: "Obra detalle" }},
      { path: 'seguimiento', component: AdminSegtoTecnicoComponent, data: { title: 'Sgto. Técnico', state: "Sgto. Técnico" } },
      { path: 'seguimiento/:id', component: SeguimientoTecnicoDetailViewComponent, data: { title: 'Sgto. Técnico detalle', state: "Sgto. Técnico detalle" } },
      { path: 'documentos', component: AdminDocumentosComponent, data: { title: 'Documentos', state: "Documentos" } },
      { path: 'empleados', component: AdminEmpleadosComponent, data: { title: 'Empleados', state: "Empleados" } },
      { path: 'proveedores', component: AdminProveedoresComponent, data: { title: 'Proveedores', state: "Proveedores" } },
      { path: 'clientes', component: AdminClientesComponent, data: { title: 'Clientes', state: "Clientes" } },
      { path: 'vehiculos', component: AdminVehiculosComponent, data: { title: 'Vehiculos', state: "Vehiculos" } },
      { path: 'vehiculos/:id', component: VehiculoDetailComponent, data: { title: 'Vehiculos detalle', state: "Vehiculos" } },
      { path: 'partes', component: AdminPartesComponent, data: { title: 'Partes', state: "Partes" } },
      { path: 'alerts', component: AdminAlertasComponent, data: { title: 'Alertas', state: "Alertas" } },
    ]
  },
  // Section Jefe de obra
  {
    path: 'jefe', data: { title: 'Dashboard', state: "Dashboard" }, canActivate:[CanActivateJefeObraGuard] ,children: [
      { path: '', redirectTo: '/jefe/obras', pathMatch: 'full' },
      // { path: 'dashboard', component: JefeDashboardComponent, data: { title: 'Dashboard', state: "Dashboard" } },
      { path: 'obras', component: JefeObrasComponent, data: { title: 'Obras', state: "Obras" } },
      { path: 'obras/:id', component: PartesObrasComponent, data: { title: 'Obras-Partes', state: "Obras-Partes" } },
      { path: 'partes', component: JefePartesComponent, data: { title: 'Partes', state: "Partes" } },
      // { path: 'documentos', component: JefeDocumentosComponent, data: { title: 'Documentos', state: "Documentos" } },
      { path: 'facturas', component: JefeFacturasComponent, data: { title: 'Facturas', state: "Facturas" } },
      { path: 'documentos/obra/:id', component: JefeDocumentosObrasComponent, data: { title: 'Documentos-Obra', state: "Documentos-Obra" } },
      
    ]
  },
  // Section Tecnico estructura
  {
    path: 'tecnico', data: { title: 'Dashboard', state: "Dashboard" }, canActivate:[CanActivateTecnicoGuard] , children: [
      { path: '', redirectTo: '/tecnico/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: TecnicoDashboardComponent, data: { title: 'Dashboard', state: "Dashboard" } },
      { path: 'seguimiento/:id', component: SeguimientoTecnicoDetailViewComponent, data: { title: 'Sgto. Técnico detalle', state: "Sgto. Técnico detalle" } },

    ]
  },
  // Section Proveedores
  {
    path: 'proveedores', data: { title: 'Dashboard', state: "Dashboard" },canActivate:[CanActivateProveedorGuard] , children: [
      { path: '', redirectTo: '/proveedores/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: ProveedoresDashboardComponent, data: { title: 'Dashboard', state: "Dashboard" } },
      { path: 'proveedores', component: ProveedoresProveedoresComponent, data: { title: 'Proveedores', state: "Proveedores" } },
      { path: 'facturas', component: ProveedoresFacturasComponent, data: { title: 'Facturas', state: "Facturas" } },
      { path: 'obras/:id', component: ProveedoresDashboarDocumentosComponent, data: { title: 'Obras Documentos', state: "Obras Documentos" } },
    ]
  },
  // Section Operario
  {
    path: 'operario', data: { title: 'Dashboard', state: "Dashboard" },canActivate:[CanActivateOperarioGuard] , children: [
      { path: '', redirectTo: '/operario/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: OperarioDashboardComponent, data: { title: 'Dashboard', state: "Dashboard" } },
      { path: 'obras', component: OperarioDocumentosComponent, data: { title: 'Obras', state: "Obras" } },
      { path: 'profile', component: OperarioPofileComponent, data: { title: 'Profile', state: "Profile" } },
      { path: 'obras/:id', component: OperarioDocumentosObraIdComponent, data: { title: 'Documentos de obra', state: "Documentos de obra" } },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
