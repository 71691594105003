import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevoParteComponentComponent } from '../AñadirNuevaPopup/NuevoParteComponent/NuevoParteComponent.component';

@Component({
  selector: 'app-PartesFaltantesPopup',
  templateUrl: './PartesFaltantesPopup.component.html',
  styleUrls: ['./PartesFaltantesPopup.component.scss']
})
export class PartesFaltantesPopupComponent extends iUnsubscribeDestroy implements OnInit {

  names!: any[];

  constructor(private dialogRef: MatDialogRef<PartesFaltantesPopupComponent>,@Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService, private popup:PopupService) {
    super();
  }

  ngOnInit() {
    this.getNombres();
     
  }

  getNombres(){
    this.names = this.modalData.data
  }

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  openNewPartePopup(id: number, nombre:string){
    this.popup.openPopup(NuevoParteComponentComponent, {tipo:"calendario",id:id,fecha:this.modalData.fecha, nombre:nombre})
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res) return;
      this.forceClosePopup(res)
      this._unsubInd.next("");
    })

  }



}
