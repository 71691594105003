import { Component, OnInit } from '@angular/core';
import { iButton, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { Subject } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'app-ForgotPasswordPage',
  templateUrl: './ForgotPasswordPage.component.html',
  styleUrls: ['./ForgotPasswordPage.component.scss']
})
export class ForgotPasswordPageComponent extends iUnsubscribeDestroy implements OnInit {

  email: string = ''

  constructor(private loginSE: LoginService, private notificationSE: NotificationsService) {
    super();
  }

  ngOnInit() {
  }
  
  sendCredentials(){
    this.loginSE.recuperarCuenta(this.email);
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return;}
      this.notificationSE.showFeedBack('Se ha enviado un correo a tu cuenta de correo electrónico')
      this._unsubInd.next('');
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return;}
      this.notificationSE.showError(res)
      this._unsub.next('');
    })
  }

}
