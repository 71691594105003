import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iDate,
  iOptionsSelector,
  iStaticUtilities,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaNominaPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaNominaPopup/NuevaNominaPopup.component';
import { EditNominaPopupComponent } from 'src/app/Popups/EditPopup/EditNominaPopup/EditNominaPopup.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { NominasService } from 'src/app/Services/Api/Nominas.service';
import {
  iDataTableColumns,
  iDataTableOptions,
} from 'src/app/Shared/Interfaces/iDataTable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-AdminEmpleadosNominas',
  templateUrl: './AdminEmpleadosNominas.component.html',
  styleUrls: ['./AdminEmpleadosNominas.component.scss'],
})
export class AdminEmpleadosNominasComponent
  extends iUnsubscribeDestroy
  implements OnInit, OnChanges
{
  @Input('dataTable') dateNominas: Array<any> = [];
  @Input('mesesSel') mesesSel: boolean = false;
  @Input('isLoading') isLoading: boolean = false;
  @Output('searchChange') searchChange = new EventEmitter<any>();
  @Output('refreshData') refreshData = new EventEmitter<any>();
  @Output('refreshMonth') refreshMonth = new EventEmitter<any>();

  filterText = {
    placeholder: 'Busca por nombre o contacto',
    model: '',
  };
  _realDataTable: any[] = [];
  isReloadingTable = true;

  //Tabla
  displayedColumnsNominas: Array<iDataTableColumns> = [
    { label: 'Mes', property: 'mesLabel' },
    { label: 'Nº Nóminas', property: 'numeroNominas' },
  ];

  optionsNominas: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };
  displayedColumnsNominasMonth: Array<iDataTableColumns> = [
    { label: 'Empleado', property: 'empleado' },
    { label: 'Fecha', property: 'date' },
  ];
  optionsNominasMonth: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    isSelectable: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    canEdit: true,
    canSee: true,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private notificacionSE: NotificationsService,
    private nominaSE: NominasService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateNominas'] != null) {
      if (this.mesesSel) {
        this._realDataTable = this.dateNominas.map((e) => {
          let obSend = e;
          obSend.date = new iDate(e.fecha);
          return obSend;
        });
      } else {
        this.filterText.model = '';
        let months: any[] = [];
        this.dateNominas.map((e) => {
          let indexExists = -1;
          let isExists = months.filter((val, index) => {
            if (val.mes == e.mes) {
              indexExists = index;
            }
            return val.mes == e.mes;
          });
          if (isExists.length > 0) {
            months[indexExists].numeroNominas =
              months[indexExists].numeroNominas + 1;
          } else {
            let date = new Date();
            date.setMonth(e.mes + 1);
            let month = {
              mesLabel: this.getLabelMonth(e.mes),
              mes: e.mes,
              numeroNominas: 1,
            };
            months.push(month);
          }
        });
        this._realDataTable = months;
      }
    }

    // FIX BUG TO HEAD TABLE RELOAD
    this.isReloadingTable = !this.isReloadingTable;
    setTimeout(() => {
      this.isReloadingTable = !this.isReloadingTable;
    }, 1);
  }

  getLabelMonth(month: number) {
    let months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return months[month];
  }

  filterSend() {
    this.searchChange.emit(this.filterText.model);
  }

  /**
   * NUEVA NÓMINA
   */

  nuevaNomina() {
    this.popupSE.openPopup(NuevaNominaPopupComponent);
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshMonth.emit(true);
        this._unsubInd2.next('');
      });
  }

  /**
   * EDIT
   */

  editNomina(event: any) {
    this.popupSE.openPopup(EditNominaPopupComponent, event);
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshData.emit(true);
        this._unsubInd2.next('');
      });
  }

  /**
   * DELETE
   */

  deleteSelected(event: any) {
    this.popupSE.openPopup(AsegurarDeletePopupComponent, {
      nombre: `${event.length} nóminas`,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.delete(ids);
        this._unsubInd4.next('');
      });
  }

  deleteNomina(data: any) {
    this.popupSE.openPopup(AsegurarDeletePopupComponent, {
      nombre: `La nómina de ${data.empleado}`,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.delete([data.id]);
        this._unsubInd2.next('');
      });
  }

  delete(data: any) {
    this.nominaSE.delete([data]);
    this.nominaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificacionSE.showFeedBack('Nómina eliminada correctamente');
        this.refreshData.emit(true);
        this._unsubInd3.next('');
      });
    this.nominaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificacionSE.showError(res.message);
        this._unsub.next('');
      });
  }

  nominaDetalle(event: any) {
    this.popupSE.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Nominas.xlsx');
  }
}
