<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle textCenter">AÑADIR NUEVA OBRA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">

        <div class="inputForm">
          <label>Número Obra</label>
          <CustomInput placeholder="Número" [type]="'number'" [(ngModel)]="numeroObra" (change)="getDataFromInput('numeroObra')"></CustomInput>
        </div>

        <div class="inputForm">
          <label>Proyecto</label>
          <CustomInput placeholder="Proyecto" [(ngModel)]="concepto" (change)="getDataFromInput('concepto')"></CustomInput>
        </div>
  
        
        <div class="inputForm">
          <label>Calle</label>
          <CustomInput placeholder="Calle" [(ngModel)]="calle"(change)="getDataFromInput('calle')"></CustomInput>
        </div>
        
        <div class="inputForm">
          <label>Número</label>
          <CustomInput placeholder="Número" [(ngModel)]="numero"(change)="getDataFromInput('numero')"></CustomInput>
        </div>
        
        <div class="inputForm">
          <label>Población</label>
          <CustomInput placeholder="Población" [(ngModel)]="poblacion"(change)="getDataFromInput('poblacion')"></CustomInput>
        </div>
        
        <div class="inputForm">
          <label>Código Postal</label>
          <CustomInput placeholder="Código Postal" [(ngModel)]="cp"(change)="getDataFromInput('cp')"></CustomInput>
        </div>


        
        <div class="inputForm">
          <label>Estado</label>
          <selector class="sizeInput" [options]="optionEstado" placeholder="Estado" [(ngModel)]="estado" ></selector>
        </div>
        
        <!-- <div class="separatorGrid"></div> -->
        
        <div class="inputForm">
          <label>Cliente</label>
          <selector class="sizeInput" [options]="optionCliente" (change)="getClienteFromInput($event)"></selector>
        </div>

        <!-- Quitado añadir operarios -->

        <!-- <div class="inputForm">
          <label>Operario</label>
          <selector class="sizeInput" [options]="optionOperario" [(ngModel)]="operario" ></selector>
        </div> -->

        <div class="inputForm">
          <label>Proveedor</label>
          <selector class="sizeInput" [options]="optionProveedor" [(ngModel)]="proveedor" ></selector>
        </div>
        <div class="inputForm">
          <label>Imagen</label>
          <button class="generalbutton" [multiple]="true" fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="imagen"
          (change)="addImage(imagen) " (click)="isloading = true">Añadir Imagen</button>
          <ng-container *ngIf="imagen">
            <mat-icon class="green">check_circle</mat-icon>
          </ng-container>
          <ng-container *ngIf="!imagen && isloading">
            <mat-icon class="yellow">refresh</mat-icon>
          </ng-container>
        </div>
        <!-- <div class="separatorGrid"></div> -->
        
      
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevaObra()">Añadir Nueva Obra</btn>
    </div>
  </div>
</div>