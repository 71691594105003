import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { UserService } from 'src/app/Services/Api/User.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-EditarProfileMovil',
  templateUrl: './EditarProfileMovil.component.html',
  styleUrls: ['./EditarProfileMovil.component.scss'],
})
export class EditarProfileMovilComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  tallaCam: string = this.modalData.infoEmpleado.tallaCam;
  tallaBota: number = this.modalData.infoEmpleado.tallaBota;
  tallaPan: number = this.modalData.infoEmpleado.tallaPan;

  optionsTallaCam: iOptionsSelector = {
    items: ['XXL', 'XL', 'L', 'M', 'S', 'XS', 'XXS'],
    append: true,
    clearable: false,
  };

  objectToPass: any = {
    id: this.modalData.id,
  };

  constructor(
    private dialogRef: MatDialogRef<EditarProfileMovilComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService,
    private userSE: UserService
  ) {
    super();
  }

  ngOnInit() {}

  // CLOSE
  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // Inputs

  getImputFromTallaCam($event: any) {
    this.objectToPass['tallaCam'] = $event;
    this.editUser(this.objectToPass);
  }

  getFromInput(key: string, event: any) {
    this.objectToPass[key] = event;
    this.editUser(this.objectToPass);
  }

  /**
   * API CALLS
   */

  editUser(obj: any) {
    this.userSE.update(obj);
    this.userSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSer.showFeedBack('Se ha actualizado el usuario');
        this._unsubInd.next('');
      });
    this.userSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }
}
