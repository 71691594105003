import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';

@Component({
  selector: 'app-VerVersionesDocumentosPopup',
  templateUrl: './VerVersionesDocumentosPopup.component.html',
  styleUrls: ['./VerVersionesDocumentosPopup.component.scss']
})
export class VerVersionesDocumentosPopupComponent extends iUnsubscribeDestroy implements OnInit {

  documentoId: any = null;
  isLoadingTabla = false

  dataVersiones: any[] = [];

  displayedColumnsVersiones: Array<iDataTableColumns> = [
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Usuario', property: 'user', align: 'center' },
    { label: 'Estado', property: 'valor', align: 'center', classList: 'tableEstado tableEstadoDocumentos' },
  ];

  optionsVersiones: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }

  constructor(private popup: PopupService, private documentoSE: DocumentoService, public dialogRef: MatDialogRef<VerVersionesDocumentosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.documentoId = (this.modalData.documentoId) ? this.modalData.documentoId : this.modalData.id;

    this.getDocumentoById(this.documentoId)
  }

    /**
   * Close
   */

  closePopup() {
    this.dialogRef.close();
  } 



  /**
   * API CALLS
   */

  getDocumentoById(id: any) {
    this.isLoadingTabla =true
    this.documentoSE.getSingle(id)
    this.documentoSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.dataVersiones = data.versionDocs.map((item: any) => {
        return {...item, fecha: iDate.javascriptConvert(new Date(item.fecha)).toStringDate('EU'), user: `${item.usuario.nombre} ${item.usuario.apellidos}`}
      });
      this.isLoadingTabla = false;
      this._unsubInd.next("");
    })
  }

}
