import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iUnsubscribeDestroy, LoaderService, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { EditSeguimientoTecnicoPopupComponent } from 'src/app/Popups/EditPopup/EditSeguimientoTecnicoPopup/EditSeguimientoTecnicoPopup.component';
import { GaleriaFotosComponent } from 'src/app/Popups/GaleriaFotos/GaleriaFotos.component';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { environment } from 'src/environments/environment';
// ZIP
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-SeguimientoTecnicoDetailView',
  templateUrl: './SeguimientoTecnicoDetailView.component.html',
  styleUrls: ['./SeguimientoTecnicoDetailView.component.scss']
})
export class SeguimientoTecnicoDetailViewComponent extends iUnsubscribeDestroy implements OnInit {

  // Bindeos

  pedidoPor: string = "";
  tecnico: string = "";
  estado: string = "";
  horaVisita: string = "";
  fechaEntrada: string = "";
  fechaVisita: string = "";
  fechaObjetivo: string = "";
  ubicacion: string = "";
  comentarios: string = "";
  contacto: string = "";
  telefono: string = "";
  imagenes: Array<any> = [];
  concepto: string = "";

  // DATA Seguimiento Tecnico

  dataST: any = "";




  constructor(private loader: LoaderService, private activatedRoute: ActivatedRoute, private seguimientoTecnicoSE: SeguimientoTecnicoService, private popup: PopupService, private notificationSE: NotificationsService, private router: Router, private loginSE: LoginService) {
    super();
  }

  ngOnInit() {
    this.loader.open()
    this.getParams()
  }


  getParams() {
    let id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getSeguimientoTecnico(id)
  }

  returnST() {
    let roles = this.loginSE.getRoles()
    if (roles!.includes('ROLE_ADMIN')) {
      this.router.navigateByUrl("/admin/seguimiento")
      return
    }
    if (roles!.includes('ROLE_TECNICO')) {
      this.router.navigateByUrl("/tecnico/dashboard")
      return
    }
  }

  // SETTERS

  setter(data: any) {
    this.pedidoPor = data?.ordenadoPor;
    this.concepto = data?.concepto;
    this.tecnico = (data?.encargado?.nombre && data?.encargado?.apellidos) ? `${data?.encargado?.nombre} ${data?.encargado?.apellidos}` : '';
    this.estado = data?.estado;
    this.fechaEntrada = data?.fechaEntrada;
    this.fechaVisita = data?.fechaVisita;
    this.fechaObjetivo = data?.fechaObjetivo;
    this.ubicacion = data?.ubicacion;
    this.comentarios = data?.comentario;
    this.contacto = data?.contacto;
    this.telefono = data?.telefono;
    this.imagenes = data?.imagenSeguimiento;
    this.horaVisita = data?.horaVisita;
    this.dataST = data;
  }

  /**
   * Ver Galeria
   */

  verGaleria() {
    this.popup.openPopup(GaleriaFotosComponent, this.imagenes, 'fullSizePopup');
    this.popup.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
      if (res === null) { return }
      this.getSeguimientoTecnico(this.dataST.id)
      this._unsubInd9.next("")
    })
  }

  /**
   * EDITAR
   */

  edit(id: any) {
    this.popup.openPopup(EditSeguimientoTecnicoPopupComponent, this.dataST);
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return }
      this.notificationSE.showFeedBack("Se ha editado correctamente")
      this.ngOnInit();
      this._unsubInd2.next("");
    })
  }

  /**
   * DELETE
   */

  delete(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "Seguimiento Tecnico" });
    this.popup.returnData().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      let idFromButton = [this.dataST.id]
      this.deleteST(idFromButton)
      this._unsubInd3.next("")
    })
  }

  deleteST(obj: any) {
    this.seguimientoTecnicoSE.delete(obj);
    this.seguimientoTecnicoSE.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSE.showFeedBack("El Seguimiento Tecnico ha sido borrado correctamente")
      this.router.navigate(['/admin/seguimiento'])
      this._unsubInd4.next("")
    })
    this.seguimientoTecnicoSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSE.showError(`${res.message}`)
      this._unsub.next("")
    })
  }

  // ZIP

async fetchImageAsArrayBuffer(url: string): Promise<ArrayBuffer> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch image from \${url}`);
  }
  return await response.arrayBuffer();
}
  async generateZipFromImageUrls(zipFilename: string) {
    let imageUrls = this.imagenes.map((item: any) => item.url)
    const zip = new JSZip();
  
    // Add each image to the zip file
    for (const imageUrl of imageUrls) {
      const filename = imageUrl.split('/').pop() || 'image';
      const imageData = await this.fetchImageAsArrayBuffer(imageUrl);
      zip.file(filename, imageData);
    }
  
    // Generate the zip file and save it
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, zipFilename);
  }

  
  /**
   * Google Maps
   */

  abrirGoogleMaps() {
    let { lan, lon } = this.dataST;
    window.open(`https://www.google.com/maps/search/?api=1&query=${lan},${lon}`, '_blank');
  }


  /**
   * API CALLS
   */

  getSeguimientoTecnico(id: any) {
    this.seguimientoTecnicoSE.getSingle(id);
    this.seguimientoTecnicoSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return }
      let { data } = res;

      this.setter(data)
      this.loader.close();
      this._unsubInd.next("");
    })
  }

}
