<div class="fullView">
  <div class="mainBlock">
    <div class="header">
      <p class="headerText">PARTES</p>
      <toggleSubmenu [elements]="submenuNavegation" [(ngModel)]="submenuSel"></toggleSubmenu>
    </div>
    <ng-container *ngIf="submenuSel === 0">
      <div class="contentBlock">
        <div class="whiteblock">
          <div class="calendario">
            <MobilCalendar [reloadFlag]="reloadFlag" [userId]="userId" (parteDays)="parteDays($event)" (fechaSelected)="fechaSelected($event)"></MobilCalendar>
          </div>
          <div class="fecha">
            <p>Fecha : {{fecha}}</p>
          </div>
          <div class="button">
            <btn classList="fullWidth" [ngClass]="{backwardDisabled: controller}" (click)="crearParte()">
            <!-- <btn classList="fullWidth" (click)="crearParte()"> -->
              <p>CREAR PARTE</p>
            </btn>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="submenuSel === 1">
      <filterActions [activeBtn]="false" (searchSend)="buscador($event)" class="d-flex">
        <ng-container filterContent>
          <div class="containerSelectores">
            <div class="desde">
              <p>Desde</p>
              <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni')"></DatePicker>
            </div>
            <div class="desde">
              <p>Hasta</p>
              <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin')"></DatePicker>
            </div>
          </div>
        </ng-container>
      </filterActions>
      <div class="contentBlock">
        <span class="buttonMas" (click)="submenuSel = 0">+</span>
        <div class="whiteblock">
          <div class="eachParte" *ngFor="let parte of dataPartes; let index = index" id="{{parte.id}}">
            <div class="lines" id="{{index}}" (click)="openParte($event)">
              <p class="lineaParte truncate">{{parte.fecha}}</p>
              <p class="lineaParte truncate">{{parte.numeroObra}} - {{parte.nombre}}</p>
              <p class="lineaParte truncate">Imágenes : {{parte.adjuntos}}</p>
            </div>
            <div class="eliminar" id="{{index}}" (click)="deleteParte($event)" *ngIf="parte.estado === 'pendiente'">
              <ng-container>
                <mat-icon>
                  delete
                </mat-icon>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <Footer></Footer>
  </div>
</div>