<FullContainer>
  <br>
  <h2 class="sectionTitle">obras</h2>
  <filterActions [filterOpen]="isFilterOpen" [activeBtn]="false" [inputOptions]="filterText" [searchOpen]="isSearchOpen"
    (filterOpenChange)="changeFilterOpen($event)" (searchSend)="respuestaBusqueda($event)"
    (searchOpenChange)="changeSearchOpen($event)">
    <ng-container filterContent>
      <div class="containerSelectores">
        <selector [options]="optionConcepto" (change)="getConceptoFromSelector($event)"></selector>
        <selector [options]="optionCliente" (change)="getClienteFromSelector($event)"></selector>
        <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
      </div>
    </ng-container>
  </filterActions>
    <br>
    <pill classList="tablePill">
      <dataTable [isLoading]="isLoading" [data]="dataObras" (clickRow)="navigateToPartesObras($event)"
        [displayedColumns]="displayedColumnsObras" (edit)="editObra($event)" (delete)="deleteObra($event, 'single')"
        (deleteAll)="deleteObra($event, 'multiple')" (deleteSelected)="deleteObra($event, 'multipleSelected')"
        [options]="optionsObras"></dataTable>
    </pill>

</FullContainer>