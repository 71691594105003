<div class="customInputContainer" [class.disabled]="disabled" [class.readOnly]="readOnly">
  <div class="labelContainer">
    <label *ngIf="label != ''" for="">{{label}}</label>
    <label class="caracteres" *ngIf="cantidadCaracteres != null && cantidadMax != null">
      <ng-container *ngIf="cantidadCaracteres != null">{{cantidadCaracteres}}</ng-container>
      <ng-container *ngIf="cantidadCaracteres == null">0</ng-container>
      -
      {{cantidadMax}}
      Carácteres
    </label>
  </div>
  <div class="inputContainer"  [class.error]="error">
    <textarea rows="10" [ngModel]="model" [placeholder]="placeHolder" (ngModelChange)="onModelChange($event)" [placeholder]="placeHolder"></textarea>
  </div>
</div>
