import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { Subject } from 'rxjs';

@Component({
  selector: 'sectionLogin-containerLogin',
  templateUrl: './containerLogin.component.html',
  styleUrls: ['./containerLogin.component.scss']
})
export class ContainerLoginComponent extends iUnsubscribeDestroy implements OnInit {

  isAnim=false;

  @Input() toggleAnim: Subject<boolean> = new Subject<boolean>();
  @Output() finishAnim: EventEmitter<any> = new EventEmitter<any>();

  constructor() { 
    super();
  }

  ngOnInit() {
    this.toggleAnim.subscribe(val=>{
      this.isAnim = val;
      setTimeout(() => {
        this.finishAnim.emit("");
      }, 500);
    })
  }

}
