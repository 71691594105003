import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iResultHttp, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { takeUntil } from 'rxjs';
import { EditVehiculoPopupComponent } from '../EditVehiculoPopup/EditVehiculoPopup.component';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';

@Component({
  selector: 'app-EditObrasPopup',
  templateUrl: './EditObrasPopup.component.html',
  styleUrls: ['./EditObrasPopup.component.scss']
})
export class EditObrasPopupComponent extends iUnsubscribeDestroy implements OnInit {

  // DATA
  dataFromObras: any = this.modalData

  // BINDINGS
  concepto: string = this.dataFromObras.concepto;
  numero: string = ""
  ubicacion: string = this.dataFromObras.ubicacion;
  operario:any = [] 
  cliente:any = null
  imagen: any = null;
  imagenes: Array<any> = []
  estado: string = (this.dataFromObras.estado) ? "Activa" : "Inactiva";
  proveedores:any = []
  // OBJECT TO PASS
  obj: any = {
    id: this.dataFromObras.id
  }

  // Opciones del selector
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: this.dataFromObras.nombreCliente,
    append: true
  }

  optionOperario: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "",
    append: true,
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios operarios seleccionados"
    }
  }
  optionProveedor: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "",
    append: true,
    search: true,
    bindLabel: "nombre",
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios proveedores seleccionados"
    }
  }

  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Activa' },
      { id: 2, label: 'Finalizada' },
    ],
    clearable: false,
    
  }


  constructor(private notificationSE:NotificationsService ,private userSe:UserService ,private clienteSe: ClienteService ,public obraSe: ObraService ,public dialogRef: MatDialogRef<EditVehiculoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService, private proveedorSE: ProveedorObraService) {
    super();
  }

  ngOnInit() {     
    this.getObraById()
    this.getCliente()
    this.getUser()
    this.getProveedor()
  }

  // GETTERS

  getObraById(){
    this.obraSe.getSingle(this.dataFromObras.id);
    this.obraSe.getResultIndividual().pipe(takeUntil(this._unsubInd4)).subscribe((res:any)=>{
      if(res === null){return;}
      let {data} = res
       let {proveedorObras} = data;
       let {operario} = data
       this.numero = data.numero
       this.cliente = {id: data.cliente.id, label:data.cliente.nombre}
       let mappedDataOperario = operario.map((o:any)=>{
         return{id:o.id, label: `${o.nombre} ${o.apellidos}`}
        }) 
        this.operario = mappedDataOperario 
        this.proveedores = proveedorObras.map((p:any)=>{
          return{id:p.usuario.id, nombre: p.usuario.nombre}
        });
      this._unsubInd4.next("")
    })
  }

  getCliente():void{
    this.clienteSe.getAll();
    this.clienteSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      let { data } = res;
       
      let mappedClientes = data.map((d:any)=>{
        return{id: d.id, label:d.nombre}
      })
      this.optionCliente.items = mappedClientes
      this._unsubInd.next("")
    })
  }

  getUser():void{
    this.userSe.getAll();
    this.userSe.getResultAll().pipe(takeUntil(this._unsubInd3)).subscribe((res:any)=>{
      if(res === null){return;}
      let {data} = res.data
      let mappedData = data?.map((d:any)=>{
        return{ id:d.id, label: `${d.nombre} ${d.apellidos}`}
      })
      this.optionOperario.items = mappedData
      this._unsubInd3.next("")
    })
  }

  getProveedor():void{
    this.userSe.getProveedoresList();
    this.userSe.getResult().pipe(takeUntil(this._unsubInd5)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      let {data} = res
      this.optionProveedor.items = data
      this._unsubInd5.next('')
    })
    this.userSe.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          this.notificationSE.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          this.notificationSE.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          this.notificationSE.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  // GET FROM INPUT

  getFromInput(element: string, value: any): void {
     
    this.obj[element] = value;
    this.updateObra(this.obj)
  }

  // SELECTORES

  estadoFromSelector(event:any){
     
    let boolean = (event.label === "Activa")
    this.obj["estado"] = boolean
    
    this.updateObra(this.obj);
  }

  clienteFromSelector(event:any){
    this.obj["cliente"] = event.id
     
    this.updateObra(this.obj);
  }
  
  proveedoresFromSelector(event:any){
    let ids = event.map((e:any)=>{return e.id})
    this.obj['proveedor']= ids;
    this.updateObra(this.obj);
  }

  operarioFromSelector(event:any){
     
    let objetoVincularOperarios = { id: [this.dataFromObras.id], users: event.map((e: any) => { return e.id }) }
     
    this.userSe.vincularUsuarios(objetoVincularOperarios)
    this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
      if (res === null) { return }
      this.notificationSer.showFeedBack("La obra ha sido actualizada correctamente")
      this._unsubInd9.next("")
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })



  }

  addImagen(event:any){
     

    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.imagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.imagenes.length == 0) {
          this.imagenes.push(obj)
        } else {
          this.imagenes.push(obj)
        }
         
        this.obj["imagen"] = this.imagenes
        this.updateObra(this.obj);
      });

    }
  }

  // UPDATE

  updateObra(obj:any):void{
    this.obraSe.update(obj);
    this.obraSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("La obra ha sido modificada correctamente")
      this._unsubInd2.next("")
    })
    this.obraSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })

  }

  // CLOSE
  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
}
