import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";
 

export interface iNominas {
    id: number,
    url: string,
    fecha: iDate,
    anyo: number,
    mes: number,
    user: iUser,
}
export class iNominas extends iBaseObject {
    static convertFromCollection(ob: any): Array<iNominas> {
        let iNominasCollection: Array<iNominas> = [];
        ob.forEach((element: any) => {
            iNominasCollection.push(this.convertFromObject(element));
        });
        return iNominasCollection;
    }

    static convertFromObject(ob: any): iNominas {
        let iNominasObj = new iNominas();
        iNominasObj.id = ob.id;
        iNominasObj.url = ob.url;
        iNominasObj.fecha = iDate.phpConvert(ob.fecha);
        iNominasObj.anyo = ob.anyo;
        iNominasObj.mes = ob.mes;
        iNominasObj.user = iUser.convertFromObject(ob.user);
        return iNominasObj;
    }
}