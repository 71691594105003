import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-NuevoDepartamentoPopup',
  templateUrl: './NuevoDepartamentoPopup.component.html',
  styleUrls: ['./NuevoDepartamentoPopup.component.scss'],
})
export class NuevoDepartamentoPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // NG-Model

  nombre: string = '';
  encargado: number = 0;

  // Opciones Selectores

  optionEncargados: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Encargado',
    append: true,
    search: true,
  };

  constructor(
    private departamentoSE: DepartamentosService,
    private userSE: UserService,
    private notificationSE: NotificationsService,
    public dialogRef: MatDialogRef<NuevoDepartamentoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }

  ngOnInit() {
    this.getAllEncargados();
  }

  // Close

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // Selector

  getDataFromEncargado(event: any) {
    this.encargado = event.id;
  }

  submitNuevoDepartamento() {
    if (this.nombre === '') {
      this.notificationSE.showWarning(
        'Por favor, rellene todos los datos antes de enviar'
      );
      return;
    }
    let nuevoDepartamento = {
      nombre: this.nombre,
      // encargado: this.encargado
    };

    this.departamentoSE.create(nuevoDepartamento);
    this.departamentoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        this.notificationSE.showFeedBack(
          'La tarea ha sido creada correctamente'
        );
        this.closePopup(data);
        this._unsubInd.next('');
      });
    this.departamentoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getAllEncargados() {
    this.userSE.getEmpleados();
    this.userSE.getResultEmpleados().subscribe((res: any) => {
      if (res === null) {
        return;
      }
      let { data } = res.data;
      let mappedEncargados = data.map((item: any) => {
        return { id: item.id, label: item.nombre };
      });
      this.optionEncargados.items = mappedEncargados;
    });
  }
}
