import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoProveedorPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoProveedorPopup/NuevoProveedorPopup.component';
import { EditarProveedorPopupComponentComponent } from 'src/app/Popups/EditPopup/EditarProveedorPopupComponent/EditarProveedorPopupComponent.component';
import { EmpleadoPopupComponent } from 'src/app/Popups/EmpleadoPopup/EmpleadoPopup.component';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { ICryptID } from 'src/app/Shared/Interfaces/iCryptID';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-AdminProveedores',
  templateUrl: './AdminProveedores.component.html',
  styleUrls: ['./AdminProveedores.component.scss'],
})
export class AdminProveedoresComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // forceUpdateWidth
  forceUpdateWidth = new Subject();
  // Propiedad para pasarle a la tabla
  cursorPointer: string[] = ['cursor-pointer'];

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  // Busqueda

  busqueda: string = '';

  dataProveedores = [];
  isLoading: boolean = false;

  filterText = {
    placeholder: 'Busca por nombre, contacto, CIF o teléfono',
    model: '',
  };

  //Tabla
  displayedColumnsProveedores: Array<iDataTableColumns> = [
    { label: 'Razón social', property: 'nombre' },
    { label: 'CIF', property: 'dni', align: 'center' },
    { label: 'Teléfono de contacto', property: 'telefono', align: 'center' },
    { label: 'Nº Documentos', property: 'documentos', align: 'center' },
    { label: 'Alertas', property: 'avisos', align: 'center' },
  ];

  optionsProveedores: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canEdit: true,
    moreIconsRow: [
      {
        callback: (event: any) => {
          this.bloquearUsuario(event);
        },
        icon: 'lock',
      },
    ],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private popup: PopupService,
    public router: Router,
    private proveedorSe: ProveedorObraService,
    private popupSE: PopupService,
    private notificacionSE: NotificationsService,
    private userSE: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllProveedores();
  }

  updateQuery() {
    this.getAllProveedores();
  }

  nuevoProveedor() {
    this.popup.openPopup(NuevoProveedorPopupComponent, 'proveedor');
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  getAllProveedores(): void {
    this.isLoading = true;
    this.proveedorSe.getAllTabla(this.pagina, this.maximo, this.busqueda);
    this.proveedorSe
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data, total } = res.data;
        this.dataProveedores = data;
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 10);
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }

  navigateToProveedores(proveedor: any) {
    let crypt = ICryptID.cryptID(proveedor.id);
    this.router.navigate(['/proveedor/' + crypt]);
  }

  popupEditProveedores(event: any) {
    this.popup.openPopup(EditarProveedorPopupComponentComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  /**
   *
   * BLOQUEAR PROVEEDOR
   */

  bloquearUsuario(event: any) {
    console.log(event);
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: ' al proveedor',
      tipo: event.bloqueado ? 'desbloquear' : 'bloquear',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.bloquearEmpleado(event.id, !event.bloqueado);
        this._unsubInd.next('');
      });
  }

  bloquearEmpleado(id: any, bloqueado: boolean) {
    let obj = { id: id, bloqueado: bloqueado };
    this.userSE.update(obj);
    this.userSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificacionSE.showFeedBack(
          'Estado del usuario cambiado correctamente'
        );
        this.updateQuery();
        this._unsubInd2.next('');
      });
    this.userSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificacionSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   *
   * BUSQUEDA
   */

  respuestaBusqueda(event: any) {
    this.busqueda = event;
    this.getAllProveedores();
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getAllProveedores();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getAllProveedores();
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Proveedores.xlsx');
  }
}
