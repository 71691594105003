import { iBaseObject } from '@quasar_dynamics/basic-designsystem';

export interface iSeguimientoTecnicoTabla {
  id: number;
  concepto: string;
  ubicacion: string;
  contacto: string;
  telefono: number;
  fechaVisita: string;
  estado: string;
  comentario: string;
  fechaEntrada: string;
  clienteNombre: string;
  presupuesto: number;
  encargadoNombre: string;
  imagenSeguimiento?: string;
  ordenadoPor?: string;
  lon?: number;
  lan?: number;
  images: Array<string>;
}

export class iSeguimientoTecnicoTabla extends iBaseObject {
  static convertFromCollection(ob: any): Array<iSeguimientoTecnicoTabla> {
    let iSeguimientoTecnicoCollection: Array<iSeguimientoTecnicoTabla> = [];
    ob.forEach((element: any) => {
      iSeguimientoTecnicoCollection.push(this.convertFromObject(element));
    });
    return iSeguimientoTecnicoCollection;
  }

  static convertFromObject(ob: any): iSeguimientoTecnicoTabla {
    let iSeguimientoTecnicoObj = new iSeguimientoTecnicoTabla();
    iSeguimientoTecnicoObj.id = ob.id;
    iSeguimientoTecnicoObj.concepto = ob.concepto;
    iSeguimientoTecnicoObj.ubicacion = ob.ubicacion;
    iSeguimientoTecnicoObj.contacto = ob.contacto;
    iSeguimientoTecnicoObj.telefono = ob.telefono;
    iSeguimientoTecnicoObj.fechaVisita = ob.fechaVisita;
    iSeguimientoTecnicoObj.estado = ob.estado;
    iSeguimientoTecnicoObj.comentario = ob.comentario;
    iSeguimientoTecnicoObj.presupuesto = ob.presupuesto;
    iSeguimientoTecnicoObj.fechaEntrada = ob.fechaEntrada;
    iSeguimientoTecnicoObj.clienteNombre = ob.cliente;
    iSeguimientoTecnicoObj.encargadoNombre = ob.encargado;
    if (ob.imagenSeguimiento) {
      iSeguimientoTecnicoObj.imagenSeguimiento = ob.imagenSeguimiento;
    }
    if (ob.lon) {
      iSeguimientoTecnicoObj.lon = ob.lon;
    }
    if (ob.lan) {
      iSeguimientoTecnicoObj.lan = ob.lan;
    }
    if (ob.images) {
      iSeguimientoTecnicoObj.images = ob.images;
    }
    if (ob.ordenadoPor) {
      iSeguimientoTecnicoObj.ordenadoPor = ob.ordenadoPor;
    }
    return iSeguimientoTecnicoObj;
  }
}
