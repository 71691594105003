<filterActions [activeBtn]="false" [disableSearch]="true">
  <ng-container filterContent>
    <div class="containerSelectores">
      <div class="desde">
    <p>Desde</p>
    <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni')"></DatePicker>
  </div>
  <div class="hasta">
    <p>Hasta</p>
    <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin')"></DatePicker>
  </div>
  <div>
    <p>Estado</p>
    <selector [options]="optionEstado" (change)="getFromSelector($event, 'estado')"></selector>
  </div>
    </div>
  </ng-container>
</filterActions>
<pill class="tablePil" classList="tablePill">
  <dataTable [isLoading]="isLoading" [data]="documentosSinFirma" [displayedColumns]="displayedColumnsDocumentos"
    [options]="optionsDocumentos" (see)="seeDocumento($event)" (clickRow)="seeDocumento($event)"></dataTable>
</pill>
