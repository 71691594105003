<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR OBRA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Número</label>
          <CustomInput  [type]="'number'" placeholder="numero" [(ngModel)]="numero" (change)="getFromInput('numero', numero)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Proyecto</label>
          <CustomInput placeholder="concepto" [(ngModel)]="concepto" (change)="getFromInput('concepto', concepto)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Ubicacion</label>
          <CustomInput placeholder="ubicacion" [(ngModel)]="ubicacion" (change)="getFromInput('ubicacion', ubicacion)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Estado</label>
          <selector class="sizeInput" [(ngModel)]="estado" [options]="optionEstado" (change)="estadoFromSelector($event)"></selector>
        </div>
        <div class="separatorGrid"></div>
        <div class="inputForm">
          <label>Cliente</label>
          <selector class="sizeInput" [options]="optionCliente" [(ngModel)]="cliente" (change)="clienteFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Imagen</label>
          <button type="button" class="generalbutton" fileInput [multiple]="true" [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="imagen"
          (change)="addImagen(imagen)">Añadir Imagen</button>
        </div>
        <div class="inputForm">
          <label>Operario</label>
          <selector class="sizeInput" [options]="optionOperario" [(ngModel)]="operario" (change)="operarioFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Proveedor</label>
          <selector class="sizeInput" [options]="optionProveedor" [(ngModel)]="proveedores" (change)="proveedoresFromSelector($event)"></selector>
        </div>
        <div class="separatorGrid"></div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>