import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iParteTabla {
  id: number,
  estado: string,
  horas:number,
  numero?:number,
  obra?:string,
  operario?:string,
  tarea?:string,
  fecha?:string,
  images?:number,
  nombre?:string,
  redactor?:string,
  vehiculo?:string,
  cargadoBBDD?:boolean,
  horaInicio?:string,
  horaFin?:string,

}
export class iParteTabla extends iBaseObject {
  static convertFromCollection(ob: any): Array<iParteTabla> {
      let iParteTablaCollection: Array<iParteTabla> = [];
      ob.forEach((element: any) => {
          iParteTablaCollection.push(this.convertFromObject(element));
      });
      return iParteTablaCollection;
  }

  static convertFromObject(ob: any): iParteTabla {
      let iParteTablaObj = new iParteTabla();
      iParteTablaObj.id = ob.id;
      if(ob.vehiculo){
        iParteTablaObj.vehiculo = ob.vehiculo;
      }
      if(ob.redactor){
        iParteTablaObj.redactor = ob.redactor;
      }
      if(ob.nombre){
        iParteTablaObj.nombre = ob.nombre;
      }
      if(ob.cargadoBBDD){
        iParteTablaObj.cargadoBBDD = ob.cargadoBBDD;
      }
      if(ob.estado !== undefined){
        iParteTablaObj.estado = ob.estado;
      }
      if(ob.fecha !== undefined){
        iParteTablaObj.fecha = ob.fecha;
      }
      if(ob.images !== undefined){
        iParteTablaObj.images = ob.images
      }
      if(ob.horas !== undefined){
      iParteTablaObj.nombre = ob.nombre
      }
      if(ob.horas !== undefined){
      iParteTablaObj.redactor = ob.redactor
      }
      if(ob.horas !== undefined){
      iParteTablaObj.vehiculo = ob.vehiculo
      }
      if(ob.horas !== undefined){
        iParteTablaObj.horas = ob.horas;
      }
      if(ob.tarea !== undefined){
        iParteTablaObj.tarea = ob.tarea;
      }
      if(ob.numero !== undefined){
        iParteTablaObj.numero = ob.numero;
      }
      if(ob.obra !== undefined){
        iParteTablaObj.obra = ob.obra;
      }
      if(ob.operario !== undefined){
        iParteTablaObj.operario = ob.operario;
      }
      if(ob.horaInicio !== undefined){
        iParteTablaObj.horaInicio = ob.horaInicio;
      }
      if(ob.horaFin !== undefined){
        iParteTablaObj.horaFin = ob.horaFin;
      }
      return iParteTablaObj;
  }
}