<div class="filterActionsContainer">
  <btn *ngIf="checkBoolean(activeBtn)" classList="iconBtn plusBtn" (click)="sendAddNew()">
    <i class="material-icons">add</i>
    <span>{{placeholderAdd}}</span>
  </btn>
  <btn [classList]="classFilter" class="widthMovil" (click)="toggleFilter()" *ngIf="!isFilterDisabled">
    <img class="img-icon" src="assets/Icons/FilterIcon.svg">
    <span>filtrar</span>
  </btn>
  <btn [classList]="classSearch" class="widthMovil" (click)="toggleSearch()" *ngIf="!isSeachDisabled">
    <i class="material-icons">search</i>
    <span>buscar</span>
  </btn>
  <div class="filterContainer" *ngIf="isFilterActive&&!isFilterDisabled">
    <ng-content select="[filterContent]"></ng-content>
  </div>
  <div class="searchContainer" *ngIf="isSearchActive&&!isSeachDisabled">
    <input [placeholder]="inputOptions.placeholder ?? '' " (keyup)="updateInputVal()" [(ngModel)]="inputOptions.model" (keyup.enter)="sendInputVal()">
    <btn classList="onlyIcon removeBorderLeft" class="widthMovil" (click)="sendInputVal()"><i class="material-icons">search</i></btn>
  </div>
</div>