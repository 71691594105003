import { iBaseObject } from '@quasar_dynamics/basic-designsystem';

export interface iProveedorObraTabla {
  id: number;
  avisos: string;
  dni: number | string;
  documentos: number;
  bloqueado: boolean;
  nombre: string;
  telefono: number | string;
}
export class iProveedorObraTabla extends iBaseObject {
  static convertFromCollection(ob: any): Array<iProveedorObraTabla> {
    let iProveedorObraCollection: Array<iProveedorObraTabla> = [];
    ob.forEach((element: any) => {
      iProveedorObraCollection.push(this.convertFromObject(element));
    });
    return iProveedorObraCollection;
  }

  static convertFromObject(ob: any): iProveedorObraTabla {
    let iProveedorObraObj = new iProveedorObraTabla();
    iProveedorObraObj.id = ob.id;
    iProveedorObraObj.avisos = ob.avisos;
    iProveedorObraObj.dni = ob.dni;
    iProveedorObraObj.documentos = ob.documentos;
    iProveedorObraObj.bloqueado = ob.bloqueado;
    iProveedorObraObj.nombre = ob.nombre;
    iProveedorObraObj.telefono = ob.telefono;

    return iProveedorObraObj;
  }
}
