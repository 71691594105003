import { iStaticUtilities } from "@quasar_dynamics/basic-designsystem";

export class ICryptID {
  public static cryptID(crypt:any){
    let letters=['J','M','Z','E','Y','N']
    let str = '' + crypt;
    let index = 0;
    while (str.length <= 6) {
      str = letters[index] + str;
      index ++;
    }
    return iStaticUtilities.simpleCrypt(str).toUpperCase();
  }

  public static decryptID(decrypt:any){
    let j:string = iStaticUtilities.simpleDecrypt(decrypt);
    return j.replace(new RegExp(/[a-zA-Z\W]+/g),"");
  }
}
