<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR DOCUMENTO</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Estado</label>
          <selector class="sizeInput" [options]="optionEstado" [(ngModel)]="estado" (change)="estadoFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Título</label>
          <CustomInput  [(ngModel)]="titulo" (change)="getDataFromInput('titulo')">
          </CustomInput>
        </div>
        <div class="inputForm" *ngIf="dataFromDocumentos === 'carpeta'">
          <label>Carpeta</label>
          <selector class="sizeInput" [options]="optionCarpeta" (change)="carpetaFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>Fecha</label>
          <DatePicker (dataFromPicker)="getDataFromFecha($event)" [dataPlaceholder]="fecha"></DatePicker>
        </div>
        <div class="inputForm">
          <label>Fecha de caducidad</label>
          <DatePicker (dataFromPicker)="getDataFromFechaDeCaducidad($event)" [dataPlaceholder]="fechaDeCaducidad">
          </DatePicker>
        </div>
        <div class="separatorGrid"></div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>