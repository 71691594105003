import { Component, OnInit, SimpleChange } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { DocumentoRequeridosService } from 'src/app/Services/Api/DocumentoRequeridos.service';

@Component({
  selector: 'app-ProveedoresProveedores',
  templateUrl: './ProveedoresProveedores.component.html',
  styleUrls: ['./ProveedoresProveedores.component.scss']
})
export class ProveedoresProveedoresComponent extends iUnsubscribeDestroy implements OnInit {

  submenuNavegation: any = ['PENDIENTES','DOCUMENTOS']
  submenuSel = 0;
  userId!: number;
  pendienteDeFirma: any = []
  documentosSinFirma: any = []
  isLoading:boolean = false

  constructor(private popup: PopupService, private documentoSE: DocumentoService) {
    super();
  }

  ngOnInit() {
    this.getIdFromLocalStorage()
  }

  refreshData(event:any){
    if(event){
      this.getDocumentos()
    }
  }

/***
 * filters
 */

getFilters(event:any){
   
  this.getDocumentos(event.fechaIni,event.fechaFin,event.estado)
}


  getIdFromLocalStorage(){
    this.userId = parseInt(localStorage.getItem('nmsr')!)
    this.getDocumentos()
  }

  /**
   * API CALLS
   */

  getDocumentos(fechaIni="",fechaFin="",estado=""){
    this.isLoading = true
    this.documentoSE.getAllDocumentByProveedor(this.userId,fechaIni,fechaFin,estado);
    this.documentoSE.getResultDocumentosProveedoresObras().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res){return};
      let {data} = res;
      this.pendienteDeFirma = data.filter((item:any)=>item.estado === 'Pendiente de firma')
      this.documentosSinFirma = data.filter((item:any)=>item.estado !== 'Pendiente de firma')
       
      this.isLoading = false
      this._unsubInd.next('')
    })

  }

}
