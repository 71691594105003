import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { HandlerService } from 'src/app/Services/Utils/Handler.service';
import { iGoogleMapsMark, MapStyle } from 'src/app/Shared/Interfaces/iGoogleMapsMark';

@Component({
  selector: 'AdminSegtoTecnicoMapa',
  templateUrl: './AdminSegtoTecnicoMapa.component.html',
  styleUrls: ['./AdminSegtoTecnicoMapa.component.scss']
})
export class AdminSegtoTecnicoMapaComponent extends iUnsubscribeDestroy implements OnInit {

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;


  @Input("dataMap") dataMap: any[] = [];
  @Input("estados") estados: any[] = [
    'Pendiente',
    'Contactado',
    'Visitado',
    'Entregado'
  ];

  // Flag

  flag: number = 0;

  // Data

  dataST: any[] = [];

  // Filtros

  filterPerson = "";
  filterStatus = "";

  // Array de técnicos

  tecnicosArray: any[] = [];


  // Data para el popup

  openedWindow: boolean = false;
  STToShow: any = { concepto: "Cargando", encargadoNombre: "Cargando", ubicacion: "Cargando" }

  markersNoFilter: any[] = [];

  center = { lat: 39.5, lng: -0.4 };
  zoom = 12;
  markers: any = [];
  optionsMap: google.maps.MapOptions = {
    center: { lat: 39.5, lng: -0.4 },
    zoom: 12,
    styles: HandlerService.getMapStyle(),
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false
  };
  styleMap = "calc(100vh - 78px - 4em - 106px)";


  imgList = [
    '/assets/Icons/Formas/Triangulo.svg',
    '/assets/Icons/Formas/Circulo.svg',
    '/assets/Icons/Formas/Cuadrado.svg',
    '/assets/Icons/Formas/CuadradoBordeado.svg',
    '/assets/Icons/Formas/Estrella.svg',
    '/assets/Icons/Formas/Heptagono.svg',
    '/assets/Icons/Formas/Hexagono.svg',
    '/assets/Icons/Formas/Octagono.svg',
    '/assets/Icons/Formas/Pentagono.svg',
    '/assets/Icons/Formas/Rombo.svg',
  ]

  imgListRojo = [
    "/assets/Icons/Formas/Rojo/Triangulo-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Circulo-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Cuadrado-Rojo.svg",
    "/assets/Icons/Formas/Rojo/CuadradoBordeado-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Estrella-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Heptagono-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Hexagono-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Octagono-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Pentagono-Rojo.svg",
    "/assets/Icons/Formas/Rojo/Rombo-Rojo.svg",
  ]
  imgListVerde = [
    "/assets/Icons/Formas/Verde/Triangulo-Verde.svg",
    "/assets/Icons/Formas/Verde/Circulo-Verde.svg",
    "/assets/Icons/Formas/Verde/Cuadrado-Verde.svg",
    "/assets/Icons/Formas/Verde/CuadradoBordeado-Verde.svg",
    "/assets/Icons/Formas/Verde/Estrella-Verde.svg",
    "/assets/Icons/Formas/Verde/Heptagono-Verde.svg",
    "/assets/Icons/Formas/Verde/Hexagono-Verde.svg",
    "/assets/Icons/Formas/Verde/Octogono-Verde.svg",
    "/assets/Icons/Formas/Verde/Pentagono-Verde.svg",
    "/assets/Icons/Formas/Verde/Rombo-Verde.svg",
  ]
  imgListAmarillo = [
    "/assets/Icons/Formas/Amarillo/Triangulo-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Circulo-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Cuadrado-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/CuadradoBordeado-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Estrella-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Heptagono-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Hexagono-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Octogono-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Pentagono-Amarillo.svg",
    "/assets/Icons/Formas/Amarillo/Rombo-Amarillo.svg",
  ]
  imgListNaranja = [
    "/assets/Icons/Formas/Naranja/Triangulo-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Circulo-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Cuadrado-Naranja.svg",
    "/assets/Icons/Formas/Naranja/CuadradoBordeado-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Estrella-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Heptagono-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Hexagono-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Octogono-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Pentagono-Naranja.svg",
    "/assets/Icons/Formas/Naranja/Rombo-Naranja.svg",
  ]

  constructor(private router: Router, private seguimientoTecnicoSE: SeguimientoTecnicoService, private loginSE:LoginService) {
    super();
  }

  ngOnInit() {
    this.getDataST();
  }





  getStyleMapSection() {
    let ob: any = {};
    ob['min-height.px'] = "500";
    ob['height'] = this.styleMap;
    return ob;
  }

  changeFilterPerson(type: any) {
    if (this.filterPerson == type) { this.filterPerson = ""; }
    else { this.filterPerson = type }
    this.getDataST()
  }

  changeFilterStatus(type: any) {

    if (this.filterStatus == type) { this.filterStatus = ""; }
    else { this.filterStatus = type }
    this.getDataST()

  }

  getImageIndex(index: any) {
    return this.imgList[index];
  }

  abrirSeguimientoTecnico(id: any) {
    let roles = this.loginSE.getRoles()
    if (roles!.includes('ROLE_ADMIN')) {
      this.router.navigateByUrl("/admin/seguimiento/" + id)
      return
    }
    if (roles!.includes('ROLE_TECNICO')) {
      this.router.navigateByUrl("/tecnico/seguimiento/" + id)
      return
    }
  }

  /**
   * SET POINTERS
   */

  setPointers(data: any) {
    this.markers = [];
    let listaIconos:any = [];
    data.map((ST: any, index: number) => {
      switch (ST.estado.toLowerCase()) {
        case "pendiente":
          listaIconos = this.imgListRojo;
          break;
        case "contactado":
          listaIconos = this.imgListNaranja;
          break;
        case "visitado":
          listaIconos = this.imgListAmarillo;
          break;
        case "entregado":
          listaIconos = this.imgListVerde;
          break;
        }

      let marker = new google.maps.Marker(
        {
          position: new google.maps.LatLng(ST.lan, ST.lon),
          icon: listaIconos[this.tecnicosArray.indexOf(ST.encargadoNombre)],
          title: ST.id.toString(),
        },
      );
      this.markers.push(marker);
      this.markersNoFilter.push(marker);
    })
  }

  /**
   * GET ARRAY TECNICOS
   */

  getArrayTecnicos(data: any) {
    this.tecnicosArray = data.map((ST: any) => {
      return ST.encargadoNombre;
    });
    this.tecnicosArray = [...new Set(this.tecnicosArray)];
  }

  openInfo(marker: any) {
    let ST = this.dataST.filter((ST: any) => {
      return ST.id == marker.marker.title
    });
    this.STToShow = ST[0]
    this.infoWindow.open(marker);
  }

  /**
   * API CALLS
   */

  getDataST(): void {
    this.seguimientoTecnicoSE.getAllTabla(1, 50, "", this.filterPerson, "", this.filterStatus);
    this.seguimientoTecnicoSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res.data;
      this.dataST = data;
      if (this.flag == 0) {
        this.getArrayTecnicos(data);
      }
      this.setPointers(data);
      this.flag++;
      this._unsubInd.next("");
    })
  }



}
