import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AlertaService } from 'src/app/Services/Api/Alerta.service';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { InfoEmpleadoService } from 'src/app/Services/Api/InfoEmpleado.service';

@Component({
  selector: 'app-AdjuntarDocumentosUsuarioPopup',
  templateUrl: './AdjuntarDocumentosUsuarioPopup.component.html',
  styleUrls: ['./AdjuntarDocumentosUsuarioPopup.component.scss'],
})
export class AdjuntarDocumentosUsuarioPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  dataFromPerfil: any = null;
  tipo: string = '';
  modalAddEditar: string = this.modalData.crear === 'si' ? 'AÑADIR' : 'EDITAR';

  dni: string = '';

  imagen: any = null;
  imagenes: any[] = [];

  dniAnversoImagen: any = '';
  dniAnversoArrayImagen: any[] = [];

  dniReversoImagen: any = '';
  dniReversoArrayImagen: any[] = [];

  carnetDeConducirReversoImagen: any = '';
  carnetDeConducirReversoArrayImagen: any[] = [];

  carnetDeConducirAnversoImagen: any = '';
  carnetDeConducirAnversoArrayImagen: any[] = [];

  caducidadDNI = '';
  caducidadCarnetDeConducir = '';

  dniAnverso: any = {
    titulo: `DNI Anverso ${this.modalData.data.nombre} ${this.modalData.data.apellidos}`,
    tipoDocumento: 1,
    estado: 'pendiente',
    usuario: this.modalData.data.id,
    url: '',
    fechaCaducidad: '',
    id: this.modalData.documentos.id,
  };
  dniReverso: any = {
    titulo: `DNI Reverso ${this.modalData.data.nombre} ${this.modalData.data.apellidos}`,
    tipoDocumento: 2,
    estado: 'pendiente',
    usuario: this.modalData.data.id,
    url: '',
    fechaCaducidad: '',
    id: this.modalData.documentos.id,
  };
  conducirAnverso: any = {
    titulo: `Carnet de conduir anverso ${this.modalData.data.nombre} ${this.modalData.data.apellidos}`,
    tipoDocumento: 3,
    estado: 'pendiente',
    usuario: this.modalData.data.id,
    url: '',
    fechaCaducidad: '',
    id: this.modalData.documentos.id,
  };
  conducirReverso: any = {
    titulo: `Carnet de conducir reverso ${this.modalData.data.nombre} ${this.modalData.data.apellidos}`,
    tipoDocumento: 4,
    estado: 'pendiente',
    usuario: this.modalData.data.id,
    url: '',
    fechaCaducidad: '',
    id: this.modalData.documentos.id,
  };

  constructor(
    public dialogRef: MatDialogRef<AdjuntarDocumentosUsuarioPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public notificacionesSE: NotificationsService,
    private documentoSE: DocumentoService,
    private alertaSE: AlertaService
  ) {
    super();
  }

  ngOnInit() {
    this.setter();

    this.checkIfDateIsNeeded();
  }

  setter() {
    this.dataFromPerfil = this.modalData;
    this.tipo = this.dataFromPerfil.tipo;
  }

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  checkIfDateIsNeeded() {
    switch (this.tipo) {
      case 'DNIReverso':
        break;
      case 'carnetReverso':
        break;

      default:
        break;
    }
  }

  /**
   * Data picker
   */

  getDataFromDNICaducidad(event: any) {
    this.dniAnverso['fechaCaducidad'] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    this.dniReverso['fechaCaducidad'] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
  }
  getDataFromCarnetDeConducirCaducidad(event: any) {
    this.conducirReverso['fechaCaducidad'] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    this.conducirAnverso['fechaCaducidad'] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
  }

  /**
   * Imagenes
   */

  addImageDNIReverso(event: any) {
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificacionesSE.showWarning('Formato de archivo no admitido');
      this.dniReversoImagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64,
        };
        if (this.dniReversoArrayImagen.length == 0) {
          this.dniReversoArrayImagen.push(obj);
        } else {
          this.dniReversoArrayImagen.push(obj);
        }
        this.dniReverso['url'] = this.dniReversoArrayImagen[0].url;
        if (this.modalAddEditar === 'EDITAR') {
          this.editarDocumento(this.dniReverso, 'DNIreverso');
        } else {
          delete this.dniReverso.id;
          this.createDocument(this.dniReverso);
        }
      });
    }
  }
  addImageDNIAnverso(event: any) {
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificacionesSE.showWarning('Formato de archivo no admitido');
      this.dniAnversoImagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64,
        };
        if (this.dniAnversoArrayImagen.length == 0) {
          this.dniAnversoArrayImagen.push(obj);
        } else {
          this.dniAnversoArrayImagen.push(obj);
        }
        this.dniAnverso['url'] = this.dniAnversoArrayImagen[0].url;
        if (this.modalAddEditar === 'EDITAR') {
          this.editarDocumento(this.dniAnverso, 'DNIanverso');
        } else {
          delete this.dniAnverso.id;
          this.createDocument(this.dniAnverso);
        }
      });
    }
  }
  addImageConducirReverso(event: any) {
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificacionesSE.showWarning('Formato de archivo no admitido');
      this.carnetDeConducirReversoImagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64,
        };
        if (this.carnetDeConducirReversoArrayImagen.length == 0) {
          this.carnetDeConducirReversoArrayImagen.push(obj);
        } else {
          this.carnetDeConducirReversoArrayImagen.push(obj);
        }
        this.conducirReverso['url'] =
          this.carnetDeConducirReversoArrayImagen[0].url;
        if (this.modalAddEditar === 'EDITAR') {
          this.editarDocumento(this.conducirReverso, 'ConducirReverso');
        } else {
          delete this.conducirReverso.id;
          this.createDocument(this.conducirReverso);
        }
      });
    }
  }
  addImageConducirAnverso(event: any) {
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificacionesSE.showWarning('Formato de archivo no admitido');
      this.carnetDeConducirAnversoImagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64,
        };
        if (this.carnetDeConducirAnversoArrayImagen.length == 0) {
          this.carnetDeConducirAnversoArrayImagen.push(obj);
        } else {
          this.carnetDeConducirAnversoArrayImagen.push(obj);
        }
        this.conducirAnverso['url'] =
          this.carnetDeConducirAnversoArrayImagen[0].url;
        if (this.modalAddEditar === 'EDITAR') {
          this.editarDocumento(this.conducirAnverso, 'ConducirAnverso');
        } else {
          delete this.conducirAnverso.id;
          this.createDocument(this.conducirAnverso);
        }
      });
    }
  }

  /**
   * EDITAR INFO EMPLEADO
   */

  editarDocumento(obj: any, tipo: string) {
    this.documentoSE.update(obj);
    this.documentoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificacionesSE.showFeedBack(
          'Información actualizada correctamente'
        );
        this.closePopup('actualizado');
        this.disableAviso(tipo);
        this._unsubInd.next('');
      });
    this.documentoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificacionesSE.showError(res.message);
        this._unsub.next('');
      });
  }

  createDocument(obj: any) {
    this.documentoSE.create(obj);
    this.documentoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificacionesSE.showFeedBack(
          'Información actualizada correctamente'
        );
        this.closePopup('actualizado');
        this._unsubInd.next('');
      });
    this.documentoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificacionesSE.showError(res.message);
        this._unsub.next('');
      });
  }

  disableAviso(tipo: string) {
    let toDelete = this.modalData.data.avisos.filter((aviso: any) => {
      return aviso.tipo === tipo;
    });
    this.alertaSE.editAviso({ id: toDelete[0]?.id, activo: false });
    this.alertaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificacionesSE.showFeedBack('Aviso desactivado correctamente');
        this._unsubInd2.next('');
      });
    this.alertaSE
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificacionesSE.showError(res.message);
        this._unsub.next('');
      });
  }
}
