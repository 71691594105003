import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  LoaderService,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { Subject, takeUntil } from 'rxjs';
import { DescargaPartePopupComponent } from 'src/app/Popups/DescargaPartePopup/DescargaPartePopup.component';
import * as XLSX from 'xlsx';
import { table } from 'console';
import { EditPartesPopupComponent } from 'src/app/Popups/EditPopup/EditPartesPopup/EditPartesPopup.component';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { GaleriaFotosComponent } from 'src/app/Popups/GaleriaFotos/GaleriaFotos.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { EditDocumentosPopupComponent } from 'src/app/Popups/EditPopup/EditDocumentosPopup/EditDocumentosPopup.component';
import { NuevoDocumentoPorObraComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoPorObra/NuevoDocumentoPorObra.component';
import { EditParteValidarRechazarComponent } from 'src/app/Popups/EditPopup/EditParteValidarRechazar/EditParteValidarRechazar.component';
import { filter } from 'rxjs';

@Component({
  selector: 'app-PartesObras',
  templateUrl: './PartesObras.component.html',
  styleUrls: ['./PartesObras.component.scss'],
})
export class PartesObrasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  nombre: string = 'Cargando...';
  dataPartes: any = [];
  dataDocumentosTabla: any = [];
  isLoading: boolean = true;
  isLoadingTabla: boolean = false;
  galeria: Array<any> = [];
  submenuSel = 0;
  // Filtros
  filtro: any = {
    fechaIni: '',
    fechaFin: '',
    estado: '',
    redactor: '',
    busqueda: '',
  };
  filtroDocumento: any = {
    fechaIni: '',
    fechaFin: '',
    estado: '',
    redactor: '',
    busqueda: '',
  };

  submenuNavegation = ['Partes', 'Documentos'];

  forceUpdateWidthDocumentos = new Subject();

  displayedColumnsDocumentos: Array<iDataTableColumns> = [
    { label: 'Documento', property: 'titulo', align: 'center' },
    { label: 'Tipo de doc.', property: 'tipoDocumento', align: 'center' },
    { label: 'Fecha doc.', property: 'fecha', align: 'center' },
    { label: 'Version', property: 'version', align: 'center' },
    { label: 'Fecha caducidad', property: 'fechaCaducidad', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  displayedColumnsPartes: Array<iDataTableColumns> = [
    { label: 'Fecha', property: 'fecha' },
    { label: 'Nº Doc', property: 'id', align: 'center' },
    { label: 'Nombre', property: 'nombre', align: 'center' },
    { label: 'Redactor', property: 'redactor', align: 'center' },
    { label: 'Adjuntos', property: 'images', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  optionsEstado: iOptionsSelector = {
    items: [
      { label: 'Validado', id: 0 },
      { label: 'Pendiente', id: 1 },
    ],
    clearable: true,
    placeholder: 'Estado',
  };
  optionEstadoDocumento: iOptionsSelector = {
    items: [
      { label: 'Aprobado', id: 0 },
      { label: 'Pendiente', id: 1 },
      { label: 'Rechazado', id: 2 },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  objectToPass: any = {
    ids: null,
  };

  optionsRedactor: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Redactor',
  };

  optionsPartes: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel('partes');
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  optionsDocumentos: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel('documentos');
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  isReloadingTable = true;

  constructor(
    private notificationSer: NotificationsService,
    private documentosSe: DocumentoService,
    private obraSE: ObraService,
    private router: Router,
    private popup: PopupService,
    private notificaciones: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private loaderSE: LoaderService,
    private parteSE: ParteService
  ) {
    super();
  }
  ngOnInit() {
    this.getSingleObra(this.activatedRoute.snapshot.paramMap.get('id')!);
    this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
    this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
  }
  downloadParte(event: any) {
    this.popup.openPopup(
      DescargaPartePopupComponent,
      { id: event.id },
      'fullSizePopup'
    );
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.isReloadingTable = false;
        this.dataPartes = [];
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        this._unsubInd9.next('');
      });
  }
  verGaleria() {
    this.getObraImages();
  }

  openPopupGaleria() {
    this.popup.openPopup(GaleriaFotosComponent, this.galeria, 'fullSizePopup');
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.isReloadingTable = false;
        this.dataPartes = [];
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        this._unsubInd9.next('');
      });
  }

  editParte(event: any) {
    this.popup.openPopup(EditParteValidarRechazarComponent, { id: event.id });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.isReloadingTable = false;
        this.dataPartes = [];
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        this._unsubInd9.next('');
      });
  }

  validateParte() {
    const ids = this.getCheckedLinesId();
    if (ids.length === 0 || !ids) return;
    this.objectToPass.ids = ids;
    this.objectToPass.estado = 'Validado';
    this.editStateParte(this.objectToPass);
  }
  rejectParte() {
    const ids = this.getCheckedLinesId();
    if (ids.length === 0 || !ids) return;
    this.objectToPass.ids = ids;
    this.objectToPass.estado = 'Rechazado';
    this.editStateParte(this.objectToPass);
  }

  getCheckedLinesId() {
    let lines: NodeListOf<Element> =
      document.querySelectorAll('table tbody tr');
    let selectedLines = Array.from(lines).filter(
      (e: any) => e.querySelector('input[type=checkbox]').checked
    );
    let ids = selectedLines.map(
      (e: any) => e.querySelector("td[columntable='id']").textContent
    );
    return ids;
  }

  /**
   * Delete Documento
   */

  openDeletePopupDocumento(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el documento',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteDocumento([event.idDocumento]);
        this._unsubInd.next('');
      });
  }

  deleteSelectedDocuments(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los documentos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let ids = event.map((e: any) => e.idDocumento);
        this.deleteDocumento(ids);
        this._unsubInd.next('');
      });
  }

  deleteDocumento(id: any[]) {
    this.documentosSe.delete(id);
    this.documentosSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }

        this.notificationSer.showFeedBack(
          'El documento ha sido borrado correctamente'
        );
        this.getDocumentosObra(
          this.activatedRoute.snapshot.paramMap.get('id')!
        );
        this._unsubInd2.next('');
      });
    this.documentosSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * EDIT Documento
   */

  editDocumento(event: any) {
    this.popup.openPopup(EditDocumentosPopupComponent, {
      tipo: 'obra',
      data: event,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.getDocumentosObra(
          this.activatedRoute.snapshot.paramMap.get('id')!
        );
        this._unsubInd9.next('');
      });
  }

  /**
   * Filtros
   */

  changeSelector(event: any, key: string, option: string, filtro: string) {
    if (filtro === 'filtro') {
      if (!event) {
        this.filtro[key] = '';
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        return;
      }
      this.filtro[key] = event[option].toLowerCase();
      this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
      return;
    }
    if (!event) {
      this.filtroDocumento[key] = '';
      this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
      return;
    }
    this.filtroDocumento[key] = event[option].toLowerCase();
    this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
    return;
  }

  busqueda(event: any) {
    if (!event) {
      this.filtro['busqueda'] = '';
      this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
      return;
    }
    this.filtro['busqueda'] = event;
    this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
  }

  busquedaDocumento(event: any) {
    if (!event) {
      this.filtroDocumento['busqueda'] = '';
      this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
      return;
    }
    this.filtroDocumento['busqueda'] = event;
    this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
  }

  // DatePicker

  dataFromPicker(event: any, key: string, filtro: string) {
    if (filtro === 'filtro') {
      if (!event) {
        this.filtro[key] = '';
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        return;
      }
      this.filtro[key] = event.value;
      this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
    }
    if (!event) {
      this.filtroDocumento[key] = '';
      this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
      return;
    }
    this.filtroDocumento[key] = event.value;
    this.getDocumentosObra(this.activatedRoute.snapshot.paramMap.get('id')!);
  }

  /**
   * Add new Documento
   */

  addNewDocumento() {
    this.popup.openPopup(
      NuevoDocumentoPorObraComponent,
      this.activatedRoute.snapshot.paramMap.get('id')
    );
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        setTimeout(() => {
          this.getDocumentosObra(
            this.activatedRoute.snapshot.paramMap.get('id')!
          );
        }, 1000);
        this._unsubInd9.next('');
      });
  }

  /**
   *
   * SEE DOCUMENTO
   *
   */

  seeDocumento(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }
  exportexcel(tipo: string): void {
    let table = document.createElement('table');
    if (tipo == 'partes') {
      table.append(
        (
          document.querySelectorAll(
            'dataTable .tableHeader thead'
          )[0] as HTMLElement
        ).cloneNode(true)
      );
      table.append(
        (
          document.querySelectorAll(
            'dataTable .tableBody tbody'
          )[0] as HTMLElement
        ).cloneNode(true)
      );
    }
    if (tipo == 'documentos') {
      table.append(
        (
          document.querySelectorAll(
            'dataTable .tableHeader thead'
          )[1] as HTMLElement
        ).cloneNode(true)
      );
      table.append(
        (
          document.querySelectorAll(
            'dataTable .tableBody tbody'
          )[1] as HTMLElement
        ).cloneNode(true)
      );
    }
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();

    table.append(
      (
        document.querySelector('dataTable .tableBody tbody') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
      element.remove();
    });
    table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
      element.remove();
    });
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Obras_' + this.nombre + '.xlsx');
  }
  deleteParte(event: any, type: string) {
    switch (type) {
      case 'single':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'el parte',
        });
        break;
      case 'multiple':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'los partes',
        });
        break;
      case 'multipleSelected':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'los partes seleccionados',
        });
        break;
    }
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        switch (type) {
          case 'single':
            this.removeParte(event['id']);
            break;
          case 'multiple':
            var ids = event.map((elem: any) => {
              return elem.id;
            });
            this.removeParte(ids);
            break;
          case 'multipleSelected':
            var ids = event.map((elem: any) => {
              return elem.id;
            });
            this.removeParte(ids);
            break;
        }
        this._unsubInd2.next('');
      });
  }
  /**
   * CALLS TO API
   */
  getPartesObra(id: any) {
    this.isLoading = true;
    this.parteSE.getAllTablaObras(
      id,
      this.filtro.busqueda,
      this.filtro.fechaIni,
      this.filtro.fechaFin,
      this.filtro.estado,
      this.filtro.redactor
    );
    this.parteSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;

        this.dataPartes = data;
        this._unsubInd2.next('');
        this.isLoading = false;
      });
  }
  getDocumentosObra(id: any) {
    this.isLoadingTabla = true;
    this.documentosSe.getAllDocumentByObra(
      id,
      this.filtroDocumento.busqueda,
      this.filtroDocumento.estado,
      this.filtroDocumento.fechaIni,
      this.filtroDocumento.fechaFin
    );
    this.documentosSe
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;

        this.dataDocumentosTabla = data.map((elem: any) => {
          return {
            ...elem,
            fecha: iDate
              .javascriptConvert(new Date(elem.fecha))
              .toStringDate('EU'),
            fechaCaducidad: iDate
              .javascriptConvert(new Date(elem.fechaCaducidad))
              .toStringDate('EU'),
          };
        });
        this.isLoadingTabla = false;
        this._unsubInd3.next('');
      });
  }

  getSingleObra(id: any) {
    this.obraSE.getSingle(id);
    this.isLoading = true;
    this.obraSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: iResultHttp) => {
        if (res == null) {
          return;
        }
        this.nombre = res.data.concepto + ' - ' + res.data.ubicacion;
        this.isLoading = false;
        this.isReloadingTable = true;
        this.galeria = res.data.imagen;
        this._unsubInd.next('');
      });
    this.obraSE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (res == null) {
          return;
        }
        this.isLoading = false;
        this.notificaciones.showError(res.message);
        this._unsub.next('');
      });
  }
  removeParte(id: string[] | number[]): void {
    this.parteSE.delete(id);
    this.parteSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificaciones.showFeedBack(
          'El parte ha sido borrado correctamente'
        );
        this.dataPartes = [];
        this.isReloadingTable = true;
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        this._unsubInd4.next('');
      });
    this.parteSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificaciones.showError(res.message);
        this._unsub.next('');
      });
  }

  editStateParte(objectToPass: any) {
    this.parteSE.changeEstado(objectToPass);
    this.parteSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El estado del parte se ha actualizado correctamente'
        );
        this.dataPartes = [];
        this.isReloadingTable = true;
        this.getPartesObra(this.activatedRoute.snapshot.paramMap.get('id')!);
        this._unsubInd5.next('');
      });
    this.parteSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  getObraImages() {
    this.obraSE.getObraImages(
      Number(this.activatedRoute.snapshot.paramMap.get('id')!)
    );
    this.obraSE
      .getResult()
      .pipe(
        takeUntil(this._unsubInd6),
        filter((res) => res)
      )
      .subscribe((res) => {
        this.galeria = res.data;
        this.openPopupGaleria();
        this._unsubInd6.next('');
      });
  }
}
