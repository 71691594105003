import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iDate, iOptionsSelector, PopupService } from '@quasar_dynamics/basic-designsystem';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';

@Component({
  selector: 'ProveedoresProveedoresDocumentos',
  templateUrl: './ProveedoresProveedoresDocumentos.component.html',
  styleUrls: ['./ProveedoresProveedoresDocumentos.component.scss']
})
export class ProveedoresProveedoresDocumentosComponent implements OnInit {

  @Input('documentosSinFirma') documentosSinFirma = [];
  @Input('isLoading') isLoading:boolean = false;
  @Output('filters') filters = new EventEmitter<any>();

  displayedColumnsDocumentos: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
    { label: 'Fecha', property: 'fecha' },
    { label: 'Fecha Caducidad', property: 'fechaCaducidad' },
    { label: 'Estado', property: 'estado', classList: 'tableEstado tableEstadoDocumentos' },
  ];

  optionsDocumentos:iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  }

  optionEstado: iOptionsSelector = {
    items: [
      {id:1,label:"Aprobado"},
      {id:2,label:"Pendiente"},
      {id:3,label:"Rechazado"},
    ],
    clearable: true,
    placeholder: 'Estado'
  }

  objFilters: any = {
    estado: "",
    busqueda: "",
    fechaIni: "",
    fechaFin: ""
  }

  isLoadingTabla:boolean = false;

  constructor(private popup:PopupService) { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['documentosSinFirma']){
      this.documentosSinFirma = changes['documentosSinFirma'].currentValue.map((item:any) => {return{...item, fecha: iDate.javascriptConvert(new Date(item.fecha)).toStringDate('EU'), fechaCaducidad: iDate.javascriptConvert(new Date(item.fechaCaducidad)).toStringDate('EU')}})
    }
  }

  /**
   * FILTERS
  */

  getFromSelector(event: any, key:string){
    (event === null) ? this.objFilters[key] = "" : this.objFilters[key] = event.label.toLowerCase();
    this.filters.emit(this.objFilters);
  }
  dataFromPicker(event: any, key: string) {
    if (!event) { this.objFilters[key] = ""; this.filters.emit(this.objFilters);; return; }
    this.objFilters[key] = iDate.javascriptConvert(new Date(event.value)).toStringDate('JAP');
    this.filters.emit(this.objFilters);
  }

    /**
 * 
 * SEE DOCUMENTO
 *  
 */

    seeDocumento(event: any) {
       
      if (event.url[event.url.length - 1] === "f") {
        this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup')
      }
      if (event.url[event.url.length - 1] === "g") {
        this.popup.openPopup(VisorDeDocumentoImagenComponent, event)
      }
    }

}
