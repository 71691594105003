import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iAlerta {
  id:number,
  descripcion:string,
  metadata:any,
  tipo:string,
}

export class iAlerta extends iBaseObject {
  static convertFromCollection(ob: any): Array<iAlerta> {
      let iAlertaCollection: Array<iAlerta> = [];
      ob.forEach((element: any) => {
          iAlertaCollection.push(this.convertFromObject(element));
      });
      return iAlertaCollection;
  }

  static convertFromObject(ob: any): iAlerta {
      let iAlertaObj = new iAlerta();
      iAlertaObj.id = ob.id;
      iAlertaObj.descripcion = ob.descripcion;
      iAlertaObj.metadata = ob.metadata;
      iAlertaObj.tipo = ob.tipo;

      return iAlertaObj;
  }

  static empty(): iAlerta {
      let iAlertaObj = new iAlerta();
      iAlertaObj.id = -1;
      iAlertaObj.metadata = '';
      iAlertaObj.descripcion = '';
      iAlertaObj.tipo = '';
      return iAlertaObj;
  }
}