import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iUnsubscribeDestroy,
  LoaderService,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { takeUntil } from 'rxjs';
import { EditVehiculoPopupComponent } from 'src/app/Popups/EditPopup/EditVehiculoPopup/EditVehiculoPopup.component';
import { EditIncidenciaPopupComponent } from 'src/app/Popups/EditPopup/EditIncidenciaPopup/EditIncidenciaPopup.component';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaIncidenciaPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaIncidenciaPopup/NuevaIncidenciaPopup.component';
import { IncidenciaService } from 'src/app/Services/Api/Incidencia.service';
import { AdjuntarDocumentosVehiculosPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/AdjuntarDocumentosVehiculosPopup/AdjuntarDocumentosVehiculosPopup.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';

@Component({
  selector: 'app-VehiculoDetail',
  templateUrl: './VehiculoDetail.component.html',
  styleUrls: ['./VehiculoDetail.component.scss'],
})
export class VehiculoDetailComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  dataSingle: any = {
    nombre: 'Cargando...',
    matricula: 'Cargando...',
    usuario: {
      nombre: 'Cargando...',
      apellidos: 'Cargando...',
    },
    caducidadSeguro: iDate.javascriptConvert(new Date()),
    caducidadITV: iDate.javascriptConvert(new Date()),
  };

  itvDocumentosArray: Array<any> = [];
  seguroDocumentosArray: Array<any> = [];
  isWarningItv = false;
  textWarningItv = '';
  isWarningSeguro = false;
  textWarningSeguro = '';
  documentosRequeridos: any = {
    itv: [],
    seguro: [],
  };

  idVehiculo: any = '';

  dataIncidencias: any = [];

  isLoadingTabla: boolean = false;

  displayedColumnsIncidencias: Array<iDataTableColumns> = [
    { label: 'Incidencia', property: 'descripcion', align: 'center' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  optionsIncidencias: iDataTableOptions = {
    isSelectable: true,
    canDelete: true,
    canEdit: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private incidenciaSE: IncidenciaService,
    private activatedRoute: ActivatedRoute,
    private vehiculoSE: VehiculoService,
    private loader: LoaderService,
    private popup: PopupService,
    private notificationSE: NotificationsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.idVehiculo = this.activatedRoute.snapshot.paramMap.get('id');

    this.getVehicleId();
  }

  returnVehiculos() {
    this.router.navigateByUrl('/admin/vehiculos');
  }

  getDocumentos(documentos: any) {
    documentos.forEach((documento: any) => {
      if (documento.tipoDocumento.nombre === 'ITV') {
        this.itvDocumentosArray.push(documento);
      }
      if (documento.tipoDocumento.nombre === 'Seguro vehiculo') {
        this.seguroDocumentosArray.push(documento);
      }
    });
    this.checkDocumentos();
  }

  checkDocumentos() {
    if (this.itvDocumentosArray.length === 0) {
      this.isWarningItv = true;
      this.textWarningItv = 'No hay documentos de ITV';
    }
    if (this.seguroDocumentosArray.length === 0) {
      this.isWarningSeguro = true;
      this.textWarningSeguro = 'No hay documentos de Seguro';
    }
    if (this.itvDocumentosArray.length > 0) {
      this.isWarningItv = false;
      this.textWarningItv = '';
      this.documentosRequeridos.itv = this.itvDocumentosArray.reduce(
        (r: any, a: any) => {
          return r.date > a.date ? r : a;
        }
      );
    }
    if (this.seguroDocumentosArray.length > 0) {
      this.isWarningSeguro = false;
      this.textWarningSeguro = '';
      this.documentosRequeridos.seguro = this.seguroDocumentosArray.reduce(
        (r: any, a: any) => {
          return r.date > a.date ? r : a;
        }
      );
    }
  }

  editVehiculo(event: any) {
    this.popup.openPopup(EditVehiculoPopupComponent, this.dataSingle);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res !== null) {
          this.ngOnInit();
          this._unsubInd2.next('');
        }
      });
  }

  /**
   * Edit Incidencia
   */

  editIncidencia(event: any) {
    this.popup.openPopup(EditIncidenciaPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  /**
   * DELETE INCIDENCIA
   */

  deleteIncidenciaPopup(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'la incidencia',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.isLoadingTabla = true;
        this.deleteIncidencia([event.id]);
        this.isLoadingTabla = false;
        this._unsubInd2.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'las incidencias',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((event: any) => {
          return event.id;
        });
        this.isLoadingTabla = true;
        this.deleteIncidencia(ids);
        this.isLoadingTabla = false;
        this._unsubInd2.next('');
      });
  }

  deleteIncidencia(ids: any[]) {
    this.incidenciaSE.delete(ids);
    this.incidenciaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Incidencia eliminada correctamente');
        this.ngOnInit();
        this._unsubInd3.next('');
      });
    this.incidenciaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * See document
   */

  seeDocument(event: any) {
    let url = '';
    event === 'itv'
      ? (url = this.documentosRequeridos.itv.url)
      : (url = this.documentosRequeridos.seguro.url);
    if (url[url.length - 1] === 'f') {
      this.popup.openPopup(
        VisorDePdfPopupComponent,
        { url: url },
        'fullSizePopup'
      );
    }
    if (url[url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, { url: url });
    }
  }

  /**
   * ADJUNTAR DOCUMENTOS
   */

  adjuntarDocumentosVehiculos(key: any) {
    let tipoDocumento = 0;
    let edit = 'no';
    if (this.documentosRequeridos[key]?.id) {
      edit = 'edit';
    }
    key === 'itv' ? (tipoDocumento = 7) : (tipoDocumento = 6);
    this.popup.openPopup(AdjuntarDocumentosVehiculosPopupComponent, {
      key: key,
      tipoDocumento: tipoDocumento,
      idVehiculo: this.idVehiculo,
      edit: edit,
      idDocumento: this.documentosRequeridos[key]?.id,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  /**
   * NUEVA INCIDENCIA
   */

  nuevaIncidencia() {
    this.popup.openPopup(NuevaIncidenciaPopupComponent, this.dataSingle);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  /**
   * API CALL
   */

  getVehicleId() {
    this.isLoadingTabla = true;
    this.vehiculoSE.getSingle(this.idVehiculo);
    this.vehiculoSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        let { documentos } = data;
        this.getDocumentos(documentos);
        this.dataSingle = data;
        this.dataIncidencias = data.incidencias.map((incidencia: any) => {
          return { ...incidencia, fecha: incidencia.fecha.split('T')[0] };
        });
        this.isLoadingTabla = false;
        this._unsubInd.next('');
      });
  }
}
