import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
  sha256,
  TokenManagmentService,
} from '@quasar_dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends DefaultBaseService {
  res = new iResultHttp();

  hashRoles = {
    ROLE_ADMIN: sha256('ROLE_ADMIN'),
    ROLE_JEFE_OBRA: sha256('ROLE_JEFE_OBRA'),
    ROLE_TECNICO: sha256('ROLE_TECNICO'),
    ROLE_CLIENTE: sha256('ROLE_CLIENTE'),
    ROLE_PROVEEDOR: sha256('ROLE_PROVEEDOR'),
    ROLE_USER: sha256('ROLE_USER'),
  };

  constructor(public http: HttpClient, public router: Router) {
    super();
  }

  async checkLogin(user: any, pass: any) {
    let credentials = {
      username: user,
      password: sha256(pass),
    };
    this.http.post(this.urlApi + '/login/user', credentials).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: data['status'],
          ok: true,
          message: 'Successfully logged in',
        });
        let normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.setToken(normalizedTokens['token']);
        this.setRefreshToken(normalizedTokens['refreshToken']);

        let roles: Array<any> = TokenManagmentService.getParseToken(
          localStorage.getItem('token')
        ).roles;
        let cryptRoles: Array<string> = [];
        roles.forEach((e) => {
          let crypt = sha256(e);
          if (crypt) {
            cryptRoles.push(crypt);
          }
        });

        localStorage.setItem('roleUser', JSON.stringify(cryptRoles));

        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultError', this.res, {
          method: this.checkLogin,
          args: [credentials],
        });
        this.checkStatusError(error);
      },
    });
  }
  refreshToken() {
    this.http
      .post(this.urlApi + '/api/refreshToken', {
        refresh_token: localStorage.getItem('refreshToken'),
      })
      .subscribe({
        next: (data: any) => {
          let normalizedTokens = iStaticUtilities.normalizeNames(data);
          this.setToken(normalizedTokens['token']);
          this.setRefreshToken(normalizedTokens['refreshToken']);
          TokenManagmentService.completeRefreshToken();
        },
        error: (error) => {
          TokenManagmentService.tokenLost();
        },
      });
  }
  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('myUser');
    localStorage.removeItem('userType');
    localStorage.removeItem('roleUser');
    this.router.navigate(['/']);
  }

  getRoles(): Array<string> | null {
    let roles = localStorage.getItem('roleUser');
    if (roles == null || roles == '') {
      return null;
    } else {
      let sendRoles: Array<string> = [];
      roles = JSON.parse(roles);

      if (roles?.includes(this.hashRoles.ROLE_ADMIN!)) {
        sendRoles.push('ROLE_ADMIN');
      } else if (roles?.includes(this.hashRoles.ROLE_JEFE_OBRA!)) {
        sendRoles.push('ROLE_JEFE_OBRA');
      } else if (roles?.includes(this.hashRoles.ROLE_TECNICO!)) {
        sendRoles.push('ROLE_TECNICO');
      } else if (roles?.includes(this.hashRoles.ROLE_PROVEEDOR!)) {
        sendRoles.push('ROLE_PROVEEDOR');
      } else if (roles?.includes(this.hashRoles.ROLE_USER!)) {
        sendRoles.push('ROLE_USER');
      }
      return sendRoles;
    }
  }

  recuperarCuenta(email: string) {
    this.http
      .post(this.urlApi + '/recuperarCuenta', { email: email })
      .subscribe({
        next: (data: any) => {
          let normalizedResponse = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: normalizedResponse['message'],
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.recuperarCuenta,
            args: [email],
          });
          this.checkStatusError(error);
        },
      });
  }
  cambiarPassword(obj: any) {
    this.http.post(this.urlApi + '/cambiarPassword', obj).subscribe({
      next: (data: any) => {
        let normalizedResponse = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: normalizedResponse['message'],
        });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultError', this.res, {
          method: this.cambiarPassword,
          args: [obj],
        });
        this.checkStatusError(error);
      },
    });
  }
}
