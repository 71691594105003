import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NominasService } from 'src/app/Services/Api/Nominas.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-EditNominaPopup',
  templateUrl: './EditNominaPopup.component.html',
  styleUrls: ['./EditNominaPopup.component.scss'],
})
export class EditNominaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  listMonth: any[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  objectToPass: any = {
    id: this.modalData.id,
  };
  // Selectores
  optionAnyo: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: this.modalData.anyo,
    multiple: {
      isMultiple: false,
      checkbox: true,
    },
    search: true,
    append: true,
  };

  optionMes: iOptionsSelector = {
    items: [
      { id: 0, label: 'Enero' },
      { id: 1, label: 'Febrero' },
      { id: 2, label: 'Marzo' },
      { id: 3, label: 'Abril' },
      { id: 4, label: 'Mayo' },
      { id: 5, label: 'Junio' },
      { id: 6, label: 'Julio' },
      { id: 7, label: 'Agosto' },
      { id: 8, label: 'Septiembre' },
      { id: 9, label: 'Octubre' },
      { id: 10, label: 'Noviembre' },
      { id: 11, label: 'Diciembre' },
    ],
    clearable: true,
    placeholder: this.listMonth[this.modalData.mes - 1],
    multiple: {
      isMultiple: false,
      checkbox: true,
    },
    search: true,
    append: true,
  };

  optionEmpleado: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: this.modalData.empleado,
    multiple: {
      isMultiple: false,
      checkbox: true,
    },
    search: true,
    append: true,
  };

  // NG MODEL

  fecha = iDate
    .javascriptConvert(new Date())
    .toStringDate('EU')
    .replace('-', '/')
    .replace('-', '/');
  nomina!: string;
  year: any = { id: this.modalData.anyo, label: this.modalData.anyo };
  month: any = this.optionMes.items.filter(
    (item: any) => item.id === this.modalData.mes
  );

  constructor(
    private nominaSE: NominasService,
    private userSE: UserService,
    public dialogRef: MatDialogRef<EditNominaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSE: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getAnyo();
    this.getEmpleados();
  }

  /**
   * FECHA
   */

  getDataFromFecha(event: any, key: string) {
    this.objectToPass[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('EU');
    this.editarNomina(this.objectToPass);
  }

  /**
   * INPUTS
   */

  anyoFromSelector(event: any, key: string) {
    this.objectToPass[key] = event.label;
    this.editarNomina(this.objectToPass);
  }

  mesFromSelector(event: any, key: string) {
    this.objectToPass[key] = event.id;
    this.editarNomina(this.objectToPass);
  }

  empleadoFromSelector(event: any, key: string) {
    this.objectToPass[key] = event.id;
    this.editarNomina(this.objectToPass);
  }

  addNomina(event: any, key: string) {
    if (
      event[0].file.type != 'image/png' &&
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/jpg'
    ) {
      this.notificationSE.showWarning(
        'Formato de archivo no admitido, por favor suba un archivo .png o .jpg'
      );
      return;
    }
    this.objectToPass[key] = event[0].base64;
    this.editarNomina(this.objectToPass);
  }

  /**
   * FUNCTIONALITY
   */

  getAnyo() {
    let anyo = new Date().getFullYear() - 2;
    let anyos = [];
    for (let i = 0; i < 5; i++) {
      anyos.push({ label: anyo });
      anyo++;
    }
    this.optionAnyo.items = anyos;
  }

  /**
   * CLOSE POPUP
   */

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  /**
   * EDITAR
   */

  editarNomina(obj: any) {
    this.nominaSE.update(obj);
    this.nominaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack('Nomina editada correctamente');
        this._unsubInd2.next('');
      });
    this.nominaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getEmpleados() {
    this.userSE.getEmpleados();
    this.userSE
      .getResultEmpleados()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        let empleados = data.map((empleado: any) => {
          return { id: empleado.id, label: empleado.nombre };
        });
        this.optionEmpleado.items = empleados;
        this._unsubInd.next('');
      });
  }
}
