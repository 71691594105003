import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";
import { iTipoDocumento } from "./iTipoDocumento";
 

export interface iDocumentoRequeridos {
    id: number,
    nombre?: string,
    usuario?: iUser,
    tipoDocumento?: iTipoDocumento,
    idTipoDocumento?: number,
}
export class iDocumentoRequeridos extends iBaseObject {
    static convertFromCollection(ob: any): Array<iDocumentoRequeridos> {
        let iDocumentoRequeridosCollection: Array<iDocumentoRequeridos> = [];
        ob.forEach((element: any) => {
            iDocumentoRequeridosCollection.push(this.convertFromObject(element));
        });
        return iDocumentoRequeridosCollection;
    }

    static convertFromObject(ob: any): iDocumentoRequeridos {
        let iDocumentoRequeridosObj = new iDocumentoRequeridos();
        iDocumentoRequeridosObj.id = ob.idDocumentoRequerido;
        if(ob.nombre){
            iDocumentoRequeridosObj.nombre = ob.nombre;
        }
        if (ob.usuario){
            iDocumentoRequeridosObj.usuario = iUser.convertFromObject(ob.usuario);
        }
        if(ob.tipoDocumento){
            iDocumentoRequeridosObj.tipoDocumento = iTipoDocumento.convertFromObject(ob.tipoDocumento);
        }
        if(ob.idTipoDocumento){
            iDocumentoRequeridosObj.idTipoDocumento = ob.idTipoDocumento;
        }
        return iDocumentoRequeridosObj;
    }
}