<FullContainer>

    <div class="sectionTitleContainer">
      <i class="material-icons goBack" (click)="returnCarpetas()">arrow_back</i>
      <h2 class="sectionTitle">DOCUMENTOS / <span>{{nombre}}</span></h2>
    </div>
    <filterActions (searchSend)="respuestaBusqueda($event)"  [filterOpen]="isFilterOpen" [inputOptions]="filterText"  [searchOpen]="isSearchOpen" (filterOpenChange)="changeFilterOpen($event)" (searchOpenChange)="changeSearchOpen($event)" (addNew)="addNewDopcument()" >
      <ng-container filterContent>
        <div class="containerSelectores">
          <selector [options]="optionTipoDoc" (change)="getTipoDocFromSelector($event)"></selector>
          <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
        </div>
      </ng-container>
    </filterActions>
    <pill class="tablePil tableDocumentos" classList="tablePill">
      <dataTable   *ngIf="isReloadingTable" [forceUpdateWidth]="forceUpdateWidthDocumentos" [isLoading]="isLoading" [data]="dataDocumentos" [displayedColumns]="displayedColumnsDocumentos" [options]="optionsDocumentos" (edit)="openEditPopupDocumento($event)"></dataTable>
      <dataTable  *ngIf="!isReloadingTable" [forceUpdateWidth]="forceUpdateWidthDocumentos" [isLoading]="isLoading" [data]="dataDocumentos" [displayedColumns]="displayedColumnsDocumentos" [options]="optionsDocumentos" (edit)="openEditPopupDocumento($event)"></dataTable>
    </pill>
</FullContainer>
