import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface IObraTabla {

  id:number,
  concepto: string,
  num_obra: number,
  ubicacion: string,
  estado: string,
  cliente: string,
  lan:string,
  lon:string,

}
export class IObraTabla extends iBaseObject {
  static convertFromCollection(ob: any): Array<IObraTabla> {
      let iObraCollection: Array<IObraTabla> = [];
      ob.forEach((element: any) => {
          iObraCollection.push(this.convertFromObject(element));
      });
      return iObraCollection;
  }

  static convertFromObject(ob: any): IObraTabla {
      let iObraTablaObj = new IObraTabla();
      iObraTablaObj.id = ob.id;
      iObraTablaObj.num_obra = ob.num_obra;
      iObraTablaObj.concepto = ob.concepto;
      iObraTablaObj.ubicacion = ob.ubicacion;
      iObraTablaObj.estado = ob.estado;
      iObraTablaObj.cliente = ob.cliente
      iObraTablaObj.lan = ob.lan
      iObraTablaObj.lon = ob.lon
      return iObraTablaObj;
  }
  static empty(){
      let iObraTablaObj = new IObraTabla();
      iObraTablaObj.id = -1;
      iObraTablaObj.concepto = '';
      iObraTablaObj.num_obra = -1;
      iObraTablaObj.ubicacion = '';
      iObraTablaObj.estado = "";
      iObraTablaObj.cliente = "";
      iObraTablaObj.lon = "";
      iObraTablaObj.lan = "";
      return iObraTablaObj;
  }
}