<div class="mapSection" [ngStyle]="getStyleMapSection()">
  <div class="map">

    <google-map id="map" class="mapsborders fullWidth fullHeight" [options]="optionsMap" [zoom]="zoom"
      [center]="center">

      <map-info-window>
        <div class="infoWindow">
          <div class="ubicacionBlock">
            <div class="icon">
              <ng-container>
                <mat-icon>
                  location_on
                </mat-icon>
              </ng-container>
            </div>
            <div class="text">
              <p class="lineaText">{{STToShow.concepto}}</p>
              </div>
          </div>
          <p class="linea">{{STToShow.encargadoNombre}}</p>
          <p class="linea">{{STToShow.ubicacion}}</p>
          <btn classList="fullWidth buttonMapa" (click)="abrirSeguimientoTecnico(STToShow.id)">
            <p>Abrir</p>
          </btn>
        </div>
      </map-info-window>

      <map-marker #marker="mapMarker" *ngFor="let mapsMark of markers" [id]="mapsMark.title" [title]="mapsMark.title" [position]="mapsMark.position"
        [icon]="mapsMark.icon" [options]="mapsMark.options" 
        (mapClick)="openInfo(marker)">
      </map-marker>
    </google-map>

  </div>
  <div class="mapInfo">
    <pill classList="fullHeight">
      <div class="filterMapContainer">
        <h4>Filtrar por técnico: </h4>
        <p (click)="changeFilterPerson('')" class="cursor-pointer">Ver todos</p>
        <div class="gridContainer">
          <div *ngFor="let tec of tecnicosArray; let index=index" class="filterMap"
            [ngClass]="{isFilter: filterPerson == tec?.id}" tabindex="0" (click)="changeFilterPerson(tec)"
            (keyup.enter)="changeFilterPerson(tec)">
            <img class="img-icon" [src]="getImageIndex(index)">
            <span>{{tec}}</span>
          </div>
        </div>
      </div>
      <div class="filterMapContainer">
        <h4>Filtrar por estado: </h4>
        <p (click)="changeFilterStatus('')" class="cursor-pointer">Ver todos los no entregados</p>
        <div class="gridContainer">
          <div *ngFor="let est of estados; let index=index" class="filterMap"
            [ngClass]="{isFilter: filterStatus == est}" tabindex="0" (click)="changeFilterStatus(est)"
            (keyup.enter)="changeFilterStatus(est.id)">
            <i class="material-icons" [ngClass]="est.toLowerCase()">circle</i>
            <span>{{est}}</span>
          </div>
        </div>
      </div>


    </pill>
  </div>
</div>