import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDate, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { NuevoDocumentoPorCarpetaComponent } from '../NuevoDocumentoPorCarpeta/NuevoDocumentoPorCarpeta.component';
import { takeUntil } from 'rxjs';
import { DocumentoRequeridosService } from 'src/app/Services/Api/DocumentoRequeridos.service';

@Component({
  selector: 'app-AddDocumentoRequeridoSolicitadoPopup',
  templateUrl: './AddDocumentoRequeridoSolicitadoPopup.component.html',
  styleUrls: ['./AddDocumentoRequeridoSolicitadoPopup.component.scss']
})
export class AddDocumentoRequeridoSolicitadoPopupComponent extends iUnsubscribeDestroy implements OnInit {

  placeholderFechaCaducidad: string = new Date().toISOString().split('T')[0];
  documento: any = null;
  objectToPass:any = {
    url: "",
    carpeta: "22", // ID de la carpeta. Hardcodeada porque siempre va a ser la misma
    tipoDocumento: this.modalData.idTipoDocumento,
    estado: "Pendiente",
    usuario: localStorage.getItem("nmsr"),
    titulo: this.modalData.idTipoDocumento,
    fecha:iDate.javascriptConvert(new Date()).toStringDate('EU')

  }
  fechaCaducidad: string = "";
  isloading:boolean = false;

  constructor(public dialogRef: MatDialogRef<NuevoDocumentoPorCarpetaComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService, private documentoSE:DocumentoService, private documentoReqSE:DocumentoRequeridosService) {
    super();
  }

  ngOnInit() {
  
  }

  dataFromPicker(event:any,key:string){
    if(key == "fechaCaducidad"){
      this.fechaCaducidad = iDate.javascriptConvert(new Date(event.value)).toStringDate('JAP');
      return
    }
    this.objectToPass[key] = iDate.javascriptConvert(new Date(event.value)).toStringDate('JAP');
  }


  /**
   * AÑADIR DOCUMENTO
   */

  addDocumento(event:any){
    if ( event[0].file.type != "image/png" && event[0].file.type != "image/jpeg"&& event[0].file.type != "image/jpg" && event[0].file.type != "application/pdf") {
      this.notificationSer.showWarning("Formato de archivo no admitido, por favor suba un archivo .png, .pdf o .jpg")
      this.documento = null;
    } else {
      this.isloading = true
      event.forEach((element:any) => {
        this.objectToPass.url = element.base64
      });
      this.isloading = false
    }
  }


  /**
   * CLOSE
   */

  closePopup(dataReturn?:any){
    let p={dataReturn:dataReturn};
    this.dialogRef.close(p);
  }
  forceClosePopup(){
    this.dialogRef.close();
  }

  /**
   * SUBMIT
   */

  submitNuevoDocumento(){
    if( this.objectToPass.tipoDocumento == "" || this.objectToPass.estado == "" ||  this.objectToPass.url == "" || !this.objectToPass.fecha ){this.notificationSer.showWarning("Por favor, rellene todos los campos");return;}
    if(this.fechaCaducidad)this.objectToPass.fechaCaducidad = this.fechaCaducidad
    this.createNuevoDocumento()
  }

  /**
   * API CALLS
   */

  createNuevoDocumento(){
    this.documentoSE.create(this.objectToPass);
    this.documentoSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res){return};
      this.notificationSer.showFeedBack('Documento creado correctamente');
      this.deleteDocReq()
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.documentoSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res=>{
      if(!res){return};
      this.notificationSer.showError(res.message);
      this._unsub.next('')
    })
  }

  deleteDocReq(){
    this.documentoReqSE.delete([this.modalData.id]);
    this.documentoReqSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res){return};
      this.closePopup('submitted')
      this._unsubInd.next('')
    })
    this.documentoReqSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res=>{
      if(!res){return};
      this.notificationSer.showError(res.message);
      this._unsub.next('')
    })
  }

}
