import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iUnsubscribeDestroy,
  LoaderService,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoDocumentoAFirmarComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoAFirmar/NuevoDocumentoAFirmar.component';
import { NuevoDocumentoPorCarpetaComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoPorCarpeta/NuevoDocumentoPorCarpeta.component';
import { NuevoDocumentoRequeridoComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoRequerido/NuevoDocumentoRequerido.component';
import { EditDocumentosPopupComponent } from 'src/app/Popups/EditPopup/EditDocumentosPopup/EditDocumentosPopup.component';
import { VerVersionesDocumentosPopupComponent } from 'src/app/Popups/VerVersionesDocumentosPopup/VerVersionesDocumentosPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ProveedorSeeEditDocumentos',
  templateUrl: './ProveedorSeeEditDocumentos.component.html',
  styleUrls: ['./ProveedorSeeEditDocumentos.component.scss'],
})
export class ProveedorSeeEditDocumentosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() dataFromSingle: any = null;
  @Output() refreshData = new EventEmitter<boolean>();

  // Data

  nombreCompleto: string = '';
  mail: string = '';
  telefono: string = '';
  cif: string = '';

  // Documentos

  dateProveedores = [];
  isLoading: boolean = true;

  // ForceUpdateWidth
  forceUpdateWidth = new Subject();

  //Tabla
  displayedColumnsProveedores: Array<iDataTableColumns> = [
    { label: 'Documento', property: 'documento' },
    { label: 'Tipo de doc.', property: 'tipoDoc', align: 'center' },
    { label: 'Comentario', property: 'comentario', align: 'center' },
    // { label: 'Proyecto asociado', property: 'obra', align: 'center' },
    { label: 'Versión', property: 'version', align: 'center' },
    { label: 'Fecha caducidad', property: 'fechaCaducidad', align: 'center' },
    { label: 'Avisos', property: 'aviso', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoDocumentos',
    },
  ];

  optionsProveedores: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    moreIconsRow: [
      {
        callback: (event) => {
          this.seeVersiones(event);
        },
        icon: 'schedule',
      },
    ],
    canEdit: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };
  constructor(
    private loader: LoaderService,
    private popup: PopupService,
    private documentSE: DocumentoService,
    private notificationSE: NotificationsService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataFromSingle'] != undefined) {
      if (Object.keys(changes['dataFromSingle'].currentValue).length > 0) {
        this.dataFromSingle = changes['dataFromSingle'].currentValue;

        this.nombreCompleto = `${this.dataFromSingle?.user?.nombre} ${this.dataFromSingle?.user?.apellidos}`;
        this.mail = this.dataFromSingle?.user?.mail;
        this.telefono = this.dataFromSingle?.user?.telefono;
        let proyectoAsociado!: any;
        this.dateProveedores = this.dataFromSingle?.user?.documentos?.map(
          (element: any) => {
            if (element.obra) {
              proyectoAsociado = `${element.obra.concepto} ${element.obra.ubicacion}`;
            }
            if (element.vehiculo) {
              proyectoAsociado = element.vehiculo.nombre;
            }
            if (!element.obra && !element.vehiculo) {
              proyectoAsociado = this.nombreCompleto;
            }
            return {
              ...element,
              tipoDoc: element.tipoDocumento.nombre,
              documento: element.titulo,
              obra: proyectoAsociado,
              fechaCaducidad: iDate
                .javascriptConvert(new Date(element.fechaCaducidad))
                .toStringDate('EU'),
              version: `Versión ${element.versionDocs.length}`,
              url: element.url,
              id: element.id,
              aviso: element.avisos.length > 0 ? 'Nuevo' : '-',
            };
          }
        );
        this.cif = this.dataFromSingle?.cif;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1000);
        this.isLoading = false;
      }
    }
  }

  ngOnInit() {}

  navigateToProveedores(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   * ADD DOCUMENTOS REQUERIDOS
   */

  addDocumentosRequeridos() {
    this.popup.openPopup(NuevoDocumentoRequeridoComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this._unsubInd.next('');
      });
  }

  /**
   * NUEVO DOCUMENTO A FIRMAR
   */

  nuevoDocumentoAFirmar() {
    this.popup.openPopup(NuevoDocumentoAFirmarComponent, this.dataFromSingle);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.refreshData.emit(true);
        this._unsubInd.next('');
      });
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'ProveedoresDocumentos.xlsx');
  }

  /**
   *
   * VERSIONES
   */

  seeVersiones(event: any) {
    this.popup.openPopup(VerVersionesDocumentosPopupComponent, event);
  }

  /**
   *
   * EDIT
   */

  openEditPopupDocumento(event: any) {
    this.popup.openPopup(EditDocumentosPopupComponent, {
      tipo: 'proveedor',
      data: event,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshData.emit(true);

        this._unsubInd.next('');
      });
  }

  /**
   * DELETE
   */

  openDeletePopup(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el documento',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteDocument(event.id);
        this._unsubInd2.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los documentos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        let ids = event.map((e: any) => e.id);
        this.deleteDocument(ids);
        this._unsubInd2.next('');
      });
  }

  deleteDocument(ids: any[]) {
    this.documentSE.delete(ids);
    this.documentSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Documento eliminado correctamente');
        this.refreshData.emit(true);
        this._unsubInd.next('');
      });
    this.documentSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }
}
