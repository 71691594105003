<div class="fullView">
  <div class="headerBlock">
    <p class="headerText">OBRAS</p>
  </div>
  <div class="contentBlock">
    <div class="whiteblock">
      <div class="eachParte" id="{{index}}" (click)="openObra(obra)" *ngFor="let obra of obras; let index = index" >
        <div class="lines">
          <p class="lineaParte fw-600">{{obra.concepto}}</p>
          <p class="lineaParte">{{obra.ubicacion}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="footerBlock">
    <Footer></Footer>
  </div>
</div>