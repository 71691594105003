import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { iDocumentoRequeridos } from 'src/app/Shared/Interfaces/Api/iDocumentoRequeridos';

@Injectable({
  providedIn: 'root',
})
export class DocumentoRequeridosService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    this.http
      .get(this.urlApi + '/api/documento/requeridos', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedDocumentoRequeridos =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDocumentoRequeridos.convertFromCollection(
              normalizedDocumentoRequeridos
            ),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getDocumentosRequeridosUserId(id: number) {
    this.http
      .get(
        this.urlApi +
          `/api/documento/requeridos/usuario/${id}?num_devoluciones=100000000&num_pagina=1`,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedDocumentoRequeridos =
            iStaticUtilities.normalizeNamesCollection(data.data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iDocumentoRequeridos.convertFromCollection(
                normalizedDocumentoRequeridos
              ),
              total: data.total,
            },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getDocumentosRequeridosUserId,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/documento/requeridos/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedDocumentoRequeridos =
            iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDocumentoRequeridos.convertFromObject(
              normalizedDocumentoRequeridos
            ),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(documentorequeridos: iDocumentoRequeridos) {
    this.http
      .post(
        this.urlApi + '/api/documento/requeridos',
        documentorequeridos,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [documentorequeridos],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(documentorequeridos: iDocumentoRequeridos) {
    this.http
      .put(
        this.urlApi + '/api/documento/requeridos/' + documentorequeridos.id,
        documentorequeridos,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [documentorequeridos],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'documentoRequeridos_ids');
    this.http
      .delete(
        this.urlApi + '/api/documento/requeridos',
        this.sendBodyOptions(idsObjs)
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
