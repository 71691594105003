import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AlertaService } from 'src/app/Services/Api/Alerta.service';

@Component({
  selector: 'app-VerTodoAlertasPopup',
  templateUrl: './VerTodoAlertasPopup.component.html',
  styleUrls: ['./VerTodoAlertasPopup.component.scss']
})
export class VerTodoAlertasPopupComponent extends iUnsubscribeDestroy implements OnInit {

  isLoadingTabla = false;

  dataAlertas:any[] = [];

    /**
   * TABLAS
   */

    displayedColumnsAlertas:  Array<iDataTableColumns> = [
      { label: 'Nº Alerta', property: 'id' , align:'center'},
      { label: 'Descripción', property: 'descripcion', align:'center' },
    ];
  
    optionsAlertas: iDataTableOptions = {
      minHeight: 350,
      maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
      maxHeightType: 'calc'
    }

  constructor(public dialogRef: MatDialogRef<VerTodoAlertasPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private alertaSE:AlertaService) {
    super();
  }

  ngOnInit() {
    this.getAlertas()
  }

  closePopup() {
    this.dialogRef.close();
  } 



  /**
   * API CALL
   */

  getAlertas(){
    this.isLoadingTabla=true;
    this.alertaSE.getAll()
    this.alertaSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      let {data} = res;
       
      this.dataAlertas = data;
      this.isLoadingTabla=false;
      this._unsubInd.next("");
    })
  }
}
