import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";
import { iVehiculo } from "./iVehiculo";
import { iObra } from "./iObra";
import { iImagen } from "./iImagen";


export interface iParte {
    id?: number,
    fecha: iDate,
    nombre: string,
    estado: string,
    comentario: string,
    cargadoBBDD: boolean,
    horaInicio: iDate,
    horaFin: iDate,
    horas: number,
    tarea: string,
    usuario?: iUser,
    vehiculo: iVehiculo,
    obra?: iObra,
    imagenParte?: iImagen[],
}
export class iParte extends iBaseObject {
    static convertFromCollection(ob: any): Array<iParte> {
        let iParteCollection: Array<iParte> = [];
        ob.forEach((element: any) => {
            iParteCollection.push(this.convertFromObject(element));
        });
        return iParteCollection;
    }

    static convertFromObject(ob: any): iParte {

        let iParteObj = new iParte();
        if(ob.id !== undefined){
            iParteObj.id = ob.id;
        }
        iParteObj.fecha = iDate.phpConvert(ob.fecha);
        iParteObj.nombre = ob.nombre;
        iParteObj.estado = ob.estado;
        iParteObj.comentario = ob.comentario;
        iParteObj.cargadoBBDD = ob.cargadoBBDD;
        iParteObj.horaInicio = iDate.phpConvert(ob.horaInicio);
        iParteObj.horaFin = iDate.phpConvert(ob.horaFin);
        iParteObj.horas = ob.horas;
        iParteObj.tarea = ob.tarea;
        if(ob.usuario != null && ob.usuario != undefined){
            iParteObj.usuario = iUser.convertFromObject(ob.usuario);
        }
        if (ob.vehiculo != null &&ob.vehiculo != undefined ) {
            iParteObj.vehiculo = iVehiculo.convertFromObject(ob.vehiculo);

        }
        if(ob.obra != null && ob.obra != undefined){
            iParteObj.obra = iObra.convertFromObject(ob.obra);
        }
        if (ob.imagenParte != null && ob.imagenParte != undefined) {
            iParteObj.imagenParte = iImagen.convertFromCollection(ob.imagenParte);
        }
        return iParteObj;
    }
}
