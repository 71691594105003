import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'header',
  templateUrl: './Header.component.html',
  styleUrls: ['./Header.component.scss']
})
export class HeaderComponent extends iUnsubscribeDestroy implements OnInit {

  // @Input() personName = "Persona";

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.checkScreenWidth();
  }

  screenWidth!: number;

  personName: string = ""

  isMobile: boolean = false;
  istoggleMenuUp: boolean = false;

  navegationContainer: any[] = [];
  navegationAdmin = [
    { label: 'Obras', icon: 'business', router: '/admin/obras' },
    { label: 'Sgto. Técnico', image: '/assets/Icons/Receipt.svg', router: '/admin/seguimiento' },
    { label: 'Documentos', icon: 'file_copy', router: '/admin/documentos' },
    { label: 'Proveedores', image: '/assets/Icons/Dolly.svg', router: '/admin/proveedores' },
    { label: 'Empleados', image: '/assets/Icons/GroupsHelmet.svg', router: '/admin/empleados' },
    { label: 'Clientes', icon: 'groups', router: '/admin/clientes' },
    { label: 'Vehículos', icon: 'local_shipping', router: '/admin/vehiculos' },
    { label: 'Partes trabajo', image: '/assets/Icons/Database.svg', router: '/admin/partes' },
    { label: 'Alertas', icon: 'info', router: '/admin/alerts' },
    { label: this.personName, icon: 'person', router: '/perfil' }
  ];
  navegationJefe = [
    // { label: 'Inicio', icon: 'home', router: '/jefe/dashboard' },
    { label: 'Obras', icon: 'business', router: '/jefe/obras' },
    { label: 'Partes', image: '/assets/Icons/Database.svg', router: '/jefe/partes' },
    { label: 'Facturas', image: '/assets/Icons/Receipt.svg', router: '/jefe/facturas' },
    { label: this.personName, icon: 'person', router: '/perfil' }
  ];
  navegationTecnico = [
    { label: 'Sgto. Técnico', image: '/assets/Icons/Receipt.svg', router: '/tecnico/dashboard' },
    { label: this.personName, icon: 'person', router: '/perfil' }
  ];
  navegationProveedor = [
    { label: 'Obras', icon: 'business', router: '/proveedores/dashboard' },
    { label: 'Documentos', icon: 'file_copy', router: '/proveedores/proveedores' },
    { label: 'Facturas', image: '/assets/Icons/Receipt.svg', router: '/proveedores/facturas' },
    { label: this.personName, icon: 'person', router: '/perfil' }
  ];

  constructor(public loginSe: LoginService, public userSe: UserService) {
    super();
  }

  ngOnInit() {
    let roles = this.loginSe.getRoles();
    if (roles != null && roles.length != 0) {
      if (roles.includes("ROLE_ADMIN")) {
        this.navegationContainer = this.navegationAdmin;
      } else if (roles.includes("ROLE_JEFE_OBRA")) {
        this.navegationContainer = this.navegationJefe;
      } else if (roles.includes("ROLE_TECNICO")) {
        this.navegationContainer = this.navegationTecnico;
      } else if (roles.includes("ROLE_PROVEEDOR")) {
        this.navegationContainer = this.navegationProveedor;
      } else if (roles.includes("ROLE_USER")) {
      }
      this.screenWidth = window.innerWidth;
      this.checkScreenWidth();
    }


    this.userSe.getMe()
    this.userSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value === null) { return; }
      let user = value.data;
      this.navegationAdmin[this.navegationAdmin.length - 1].label = user.nombre;
      this.navegationJefe[this.navegationJefe.length - 1].label = user.nombre;
      this.navegationJefe[this.navegationJefe.length - 1].label = user.nombre;
      this.navegationTecnico[this.navegationTecnico.length - 1].label = user.nombre;
      this.navegationProveedor[this.navegationProveedor.length - 1].label = user.nombre;
      this._unsubInd.next("")
    })
  }

  navigateTo(nav: any) {

  }

  checkScreenWidth() {
    if(this.screenWidth < 1400){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
  }

  toggleMenu() {
    this.istoggleMenuUp = !this.istoggleMenuUp;
  }

}
