import { Component, OnInit } from '@angular/core';
import { iDate, iOptionsSelector, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AdjuntarDocumentosUsuarioPopupComponent } from 'src/app/Popups/EditPopup/AdjuntarDocumentosUsuarioPopup/AdjuntarDocumentosUsuarioPopup.component';
import { EditarContrasenyaPopupComponent } from 'src/app/Popups/EditPopup/EditarContrasenyaPopup/EditarContrasenyaPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { InfoEmpleadoService } from 'src/app/Services/Api/InfoEmpleado.service';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { iDataTableColumns, iDataTableOptions } from 'src/app/Shared/Interfaces/iDataTable';

@Component({
  selector: 'app-UserProfile',
  templateUrl: './UserProfile.component.html',
  styleUrls: ['./UserProfile.component.scss']
})
export class UserProfileComponent extends iUnsubscribeDestroy implements OnInit {

  isEditing = false;
  isLoadingTabla = false;
  rol: any = '';

  idProfile = -1;
  infoDocumentoId = -1;
  userData!: any

  // Documentos

  dniAnversoCaducado: boolean = false;
  dniAnversoACaducar: boolean = false;
  dniReversoCaducado: boolean = false;
  dniReversoACaducar: boolean = false;
  conducirAnversoCaducado: boolean = false;
  conducirAnversoACaducar: boolean = false;
  conducirReversoCaducado: boolean = false;
  conducirReversoACaducar: boolean = false;

  DNIANVERSO: any = []
  DNIREVERSO: any = []
  CONDUCIRANVERSO: any = []
  CONDUCIRREVERSO: any = []

  original: any = {
    nombre: '-',
    apellidos: '',
    mail: '-',
    departamento: '-',
    tallaCam: '-',
    tallaPan: '-',
    tallaBota: '-',
    password: '-',
  };
  editing: any = {
    nombre: '-',
    apellidos: '',
    mail: '-',
    departamento: '-',
    tallaCam: '-',
    tallaPan: '-',
    tallaBota: '-',
    password: '-',
  };

  documentosRequeridos: any = {
    dniReverso: '',
    dniAnverso: '',
    conducirReverso: '',
    conducirAnverso: ''
  }
  isWarningDniReverso = false;
  isWarningDniAnverso = false;
  isWarningConducirReverso = false;
  isWarningConducirAnverso = false;
  textWarningDniReverso = "test";
  textWarningDniAnverso = "test";
  textWarningConducirReverso = "tes";
  textWarningConducirAnverso = "tes";

  //Tabla
  displayedColumnsNominas: Array<iDataTableColumns> = [
    { label: 'Año', property: 'anyo', align: 'center' },
    { label: 'Mes', property: 'mes', align: 'center' },
    { label: 'Adjunto', property: 'url', align: 'center' },
  ];

  optionsNominas: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    minHeight: 100,
    minHeightType: '%',
    maxHeight: 'calc(100vh - 78px - 4em - 40px - 1em - 29px - 0.5em - 4em)',
    maxHeightType: 'calc',
  }
  optionsTallaCam: iOptionsSelector = {
    items: ['XXL', 'XL', 'L', 'M', 'S', 'XS', 'XXS'],
    append: true,
    clearable: false
  }

  dataNominas = []

  constructor(private popup: PopupService, public userSe: UserService, public loginSe: LoginService, public notificaciones: NotificationsService, public infoEmpleado: InfoEmpleadoService) {
    super();
  }

  ngOnInit() {
    this.isLoadingTabla = true
    this.rol = this.loginSe.getRoles();
    this.userSe.getMe();
    this.userSe.getResult().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return; }
      let user = value.data;
      this.userData = user;
       
      this.getAvisos(user.avisos)
      this.documentCheck()
       
      this.idProfile = user.id;
      if (user.infoEmpleado) {
        this.infoDocumentoId = user.infoEmpleado.id;
        this.original.tallaCam = user.infoEmpleado.tallaCam;
        this.original.tallaPan = user.infoEmpleado.tallaPan;
        this.original.tallaBota = user.infoEmpleado.tallaBota;
        this.editing.tallaCam = user.infoEmpleado.tallaCam;
        this.editing.tallaPan = user.infoEmpleado.tallaPan;
        this.editing.tallaBota = user.infoEmpleado.tallaBota;
      }
      this.userSe.getNominas();
      this.original.nombre = user.nombre;
      this.original.apellidos = user.apellidos;
      this.original.mail = user.mail;
      this.original.departamento = user.departamento;
      this.editing.nombre = user.nombre;
      this.editing.apellidos = user.apellidos;
      this.editing.mail = user.mail;
      this.editing.departamento = user.departamento;
    });

    this.userSe.getResultNominas().pipe(takeUntil(this._unsub)).subscribe(value => {
      if (value == null) { return }
      let mappedNomina = value.data.map((elem: any) => {
        let monthsArray: string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        return { anyo: elem.anyo, mes: monthsArray[elem.mes - 1], url: "Nómina" }
      })
      this.dataNominas = mappedNomina;
      this.isLoadingTabla = false
    })
  }

  documentCheck() {
    this.userData.documentos.forEach((documento: any) => {
      switch (documento.tipoDocumento.nombre) {
        case 'DNIreverso':
          this.DNIREVERSO.push(documento)
          break;
        case 'DNIanverso':
          this.DNIANVERSO.push(documento)
          break;
        case "ConducirReverso":
          this.CONDUCIRREVERSO.push(documento)
          break;
        case 'ConducirAnverso':
          this.CONDUCIRANVERSO.push(documento)
          break;
        default:
          break;
      }
    })

    this.divideDocumentos()

  }

  divideDocumentos() {
    if (this.DNIREVERSO.length == 0) {
      this.isWarningDniReverso = true;
      this.textWarningDniReverso = "No se ha subido el DNI en reverso"
    }
    if (this.DNIANVERSO.length == 0) {
      this.isWarningDniAnverso = true;
      this.textWarningDniAnverso = "No se ha subido el DNI en anverso"
    }
    if (this.CONDUCIRREVERSO.length == 0) {
      this.isWarningConducirReverso = true;
      this.textWarningConducirReverso = "No se ha subido el carnet de conducir en reverso"
    }
    if (this.CONDUCIRANVERSO.length == 0) {
      this.isWarningConducirAnverso = true;
      this.textWarningConducirAnverso = "No se ha subido el carnet de conducir en anverso"
    }
    if (this.DNIREVERSO.length > 0) {
      this.isWarningDniReverso = false;
      this.textWarningDniReverso = ""
      this.documentosRequeridos.dniReverso = this.DNIREVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })

    }
    if (this.DNIANVERSO.length > 0) {
      this.isWarningDniAnverso = false;
      this.textWarningDniAnverso = ""
      this.documentosRequeridos.dniAnverso = this.DNIANVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })

    }
    if (this.CONDUCIRREVERSO.length > 0) {
      this.isWarningConducirReverso = false;
      this.textWarningConducirReverso = ""
      this.documentosRequeridos.conducirReverso = this.CONDUCIRREVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })

    }
    if (this.CONDUCIRANVERSO.length > 0) {
      this.isWarningConducirAnverso = false;
      this.textWarningConducirAnverso = ""
      this.documentosRequeridos.conducirAnverso = this.CONDUCIRANVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })

    }

  }

  getAvisos(data: any) {
     
    data.forEach((elem: any) => {
      const tipo = elem.tipo;
      const caducado = elem.caducado;
      switch (tipo) {
        case 'DNIanverso':
          this.dniAnversoCaducado = caducado;
          this.dniAnversoACaducar = !caducado;
          break;
        case 'DNIreverso':
          this.dniReversoCaducado = caducado;
          this.dniReversoACaducar = !caducado;
          break;
        case 'ConducirAnverso':
          this.conducirAnversoCaducado = caducado;
          this.conducirAnversoACaducar = !caducado;
          break;
        case 'ConducirReverso':
          this.conducirReversoCaducado = caducado;
          this.conducirReversoACaducar = !caducado;
          break;
        default:
          break;
      }
    });
  }

  cancelEdit() {
    Object.keys(this.original).forEach((elem: any) => {
      this.editing[elem] = this.original[elem];
    });
    this.isEditing = false;
  }

  saveEdit() {
    Object.keys(this.editing).forEach((elem: any) => {
      this.original[elem] = this.editing[elem];
    });
    this.isEditing = false;
    let send = {
      id: this.idProfile,
      nombre: this.editing.nombre,
      apellidos: this.editing.apellidos,
      mail: this.editing.mail,
      infoEmpleado: {
        departamento: this.editing.departamento,
        tallaCam: this.editing.tallaCam,
        tallaPan: this.editing.tallaPan,
        tallaBota: this.editing.tallaBota
      }
    }
    this.userSe.update(send);
    this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showFeedBack(value.message);
      this._unsubInd.next("");
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe(value => {
      if (value == null) { return; }
      this.notificaciones.showError(value.message);
      this._unsubInd.next("");
    })
  }

  openDocumento(event: any) {
     
    if (!event) { this.notificaciones.showWarning('No hay documento para mostrar'); return }
    if (event.url[event.url.length - 1] === "f") {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup')
    }
    if (event.url[event.url.length - 1] === "g") {
       
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event)
    }
  }

  openNomina(event: any) {
    this.popup.openPopup(VisorDePdfPopupComponent, event, "fullSizePopup")
  }

  logout() {
    this.notificaciones.showInfo("Se ha cerrado la sesión actual");
    this.loginSe.logOut();
  }

  // AdjuntarDocumentos

  adjuntarFoto(tipo: string) {

    switch (tipo) {
      case "DNIReverso":
        this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.dniReverso, crear: (this.documentosRequeridos.dniReverso) ? 'no' : 'si' })
        break;
      case "DNIAnverso":
        this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.dniAnverso, crear: (this.documentosRequeridos.dniAnverso) ? 'no' : 'si' })
        break;
      case "carnetReverso":
        this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.conducirReverso, crear: (this.documentosRequeridos.conducirReverso != "") ? 'no' : 'si' })
        break;
      case "carnetAnverso":
        this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.conducirAnverso, crear: (this.documentosRequeridos.conducirAnverso != "") ? 'no' : 'si' })
        break;
    }

    // this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.userData.documentos })
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return; }
      this.ngOnInit();
      this._unsubInd.next("");
    })
  }

  getMesesBetweenDates(dateOne: iDate, dateTwo: iDate) {
    let years = Math.abs(dateOne.year - dateTwo.year)
    if (years <= 0) {
      return Math.abs(dateOne.month - dateTwo.month);
    }
    return years * 12;
  }

  openPasswordPopup() {
    this.popup.openPopup(EditarContrasenyaPopupComponent, { id: this.idProfile })
  }
}
