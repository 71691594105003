<FullContainer>
  <div class="sectionTitleContainer">
    <h2 class="sectionTitle">Cliente</h2>
  </div>
  <filterActions  [placeholderAdd]="'AÑADIR NUEVO'" [inputOptions]="filterText" (addNew)="anadirNuevoCliente($event)" (searchSend)="respuestaBusqueda($event)" [disableFilter]="true">
    <ng-container filterContent>
      <div class="containerSelectores">
        <selector [options]="optionProyectos" (change)="changeCliente($event)"></selector>
      </div>
    </ng-container>
  </filterActions>
  <div class="paginacion">
    <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
    <div class="arrows">
      <ng-container class="arrowLeft">
        <mat-icon (click)="previousPage()">
          chevron_left
        </mat-icon>
      </ng-container>
      <ng-container class="arrowRight">
        <mat-icon (click)="nextPage()">
          chevron_right
        </mat-icon>
      </ng-container>
    </div>
  </div>
  <pill class="tablePil" classList="tablePill">
    <dataTable [forceUpdateWidth]="forceUpdateWidth" [isLoading]="isLoadingTabla" [data]="dataClientes" [displayedColumns]="displayedColumnsClientes" [options]="optionsClientes" (edit)="editCliente($event)" (delete)="OnclickDeleteButton($event)"></dataTable>
  </pill>
</FullContainer>