import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'DatePicker',
  templateUrl: './DatePicker.component.html',
  styleUrls: ['./DatePicker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Output() dataFromPicker = new EventEmitter<any>();
  @Input() dataPlaceholder = ""
  data = this.dataPlaceholder
  


  constructor() { }

  ngOnInit() {
    this.checkPlaceholder()
  }

  checkPlaceholder(){
    if(this.dataPlaceholder === ""){
      this.dataPlaceholder = `${new Date().getDate()}/${new Date().getMonth() +1}/${new Date().getFullYear()}`
    }
  }

  clear(){
    this.data = ""
    this.dataFromPicker.emit(null)

  }



  dataPickerChange(event:any){
  let data = event;
  this.dataFromPicker.emit(data)
  }

}
