import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iCliente } from "./iCliente";
import { iUser } from "./iUser";
import { iImagen } from "./iImagen";
 

export interface iSeguimientoTecnico {
    id: number,
    concepto: string,
    ubicacion: string,
    contacto: string,
    telefono: number,
    fechaVisita: iDate,
    estado: string,
    comentario: string,
    fechaEntrada: iDate,
    cliente: iCliente,
    presupuesto:number,
    encargado: iUser,
    imagenSeguimiento?: iImagen[] | string,
    lan?:number,
    lon?:number,
}

export interface iSeguimientoTecnicoCreate {
    concepto: string;
    ubicacion: string;
    contacto: string;
    telefono: number;
    comentario: string;
    estado: string;
    cliente: number;
    encargado: number;
    fechaVisita: string;
    presupuesto: number;
    fechaEntrada: string;
    imagenSeguimiento: any;
}

export class iSeguimientoTecnico extends iBaseObject {
    static convertFromCollection(ob: any): Array<iSeguimientoTecnico> {
        let iSeguimientoTecnicoCollection: Array<iSeguimientoTecnico> = [];
        ob.forEach((element: any) => {
            iSeguimientoTecnicoCollection.push(this.convertFromObject(element));
        });
        return iSeguimientoTecnicoCollection;
    }

    static convertFromObject(ob: any): iSeguimientoTecnico {
        let iSeguimientoTecnicoObj = new iSeguimientoTecnico();
        iSeguimientoTecnicoObj.id = ob.id;
        iSeguimientoTecnicoObj.concepto = ob.concepto;
        iSeguimientoTecnicoObj.ubicacion = ob.ubicacion;
        iSeguimientoTecnicoObj.contacto = ob.contacto;
        iSeguimientoTecnicoObj.telefono = ob.telefono;
        iSeguimientoTecnicoObj.fechaVisita = iDate.phpConvert(ob.fechaVisita);
        iSeguimientoTecnicoObj.estado = ob.estado;
        iSeguimientoTecnicoObj.comentario = ob.comentario;
        iSeguimientoTecnicoObj.presupuesto = ob.presupuesto;
        iSeguimientoTecnicoObj.fechaEntrada = iDate.phpConvert(ob.fechaEntrada);
        iSeguimientoTecnicoObj.cliente = iCliente.convertFromObject(ob.cliente);
        iSeguimientoTecnicoObj.encargado = iUser.convertFromObject(ob.encargado);
        if(ob.imagenSeguimiento){
            iSeguimientoTecnicoObj.imagenSeguimiento = iImagen.convertFromCollection(ob.imagenSeguimiento);
        }
        if(ob.lan){
            iSeguimientoTecnicoObj.lan = ob.lan;
        }
        if(ob.lon){
            iSeguimientoTecnicoObj.lon = ob.lon;
        }
        return iSeguimientoTecnicoObj;
    }
}