import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoVehiculoPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoVehiculoPopup/NuevoVehiculoPopup.component';
import { EditVehiculoPopupComponent } from 'src/app/Popups/EditPopup/EditVehiculoPopup/EditVehiculoPopup.component';
import { IncidenciasVehiculosComponent } from 'src/app/Popups/Incidencias/IncidenciasVehiculos/IncidenciasVehiculos.component';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { iVehiculo } from 'src/app/Shared/Interfaces/Api/iVehiculo';
import { iVehiculoTable } from 'src/app/Shared/Interfaces/Api/iVehiculoTable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-AdminVehiculos',
  templateUrl: './AdminVehiculos.component.html',
  styleUrls: ['./AdminVehiculos.component.scss'],
})
export class AdminVehiculosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // Edit

  isEditing = false;

  // forceUpdateWidth

  forceUpdateWidth = new Subject();

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  filterText = {
    placeholder: '',
    model: '',
  };

  // Filtros

  ITVDesde: string = '';
  ITVHasta: string = '';
  seguroDesde: string = '';
  seguroHasta: string = '';
  asignado: string = '';
  busqueda: string = '';

  isLoadingTabla: boolean = false;
  idToDelete: string[] = [];

  // Datos de vehiculos
  dataVehicles: Array<iVehiculo | iVehiculoTable> = [];

  // Selectores

  optionAsignado: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Asignado a',
    multiple: {
      isMultiple: false,
      checkbox: true,
    },
  };

  selectedVehicles: any = null;

  //Tabla
  displayedColumnsVehiculos: Array<iDataTableColumns> = [
    { label: 'Vehiculo', property: 'nombre' },
    { label: 'Matrícula', property: 'matricula', align: 'center' },
    { label: 'Caducidad Seguro', property: 'caducidadSeguro', align: 'center' },
    { label: 'Caducidad ITV', property: 'caducidadITV', align: 'center' },
    // { label: 'Incidencias Reportadas', property: 'numIncidencias', align: 'center' },
  ];

  optionsVehiculos: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    // moreIconsRow: [{ callback: ($event) => { this.popupIncidencias($event) }, icon: "announcement" }],
    canDelete: true,
    isHovered: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private usuarioSE: UserService,
    private vehiculoSe: VehiculoService,
    private popup: PopupService,
    private notificationSer: NotificationsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.getAllVehicles();
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getAllVehicles();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getAllVehicles();
  }

  /**
   * AÑADIR NUEVO VEHICULO
   */

  anadirNuevoVehiculo(event: any) {
    this.popup.openPopup(NuevoVehiculoPopupComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res) => {
        if (res === null || res.dataReturn !== 'submitted') {
          return;
        }
        this.ngOnInit();
        this._unsubInd6.next('');
      });
  }

  getAllVehicles(): void {
    this.isLoadingTabla = true;
    this.vehiculoSe.getTable(this.pagina, this.maximo);
    this.vehiculoSe
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data, total } = res.data;
        this.dataVehicles = data;
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;
        setTimeout(() => {
          this.forceUpdateWidth.next('');
        }, 1);
        this.isLoadingTabla = false;
        this._unsubInd.next('');
      });
  }

  /**
   *
   * Delete
   */

  OnclickDeleteButton(event: any): void {
    this.popup.openPopup(AsegurarDeletePopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let idFromButton = [res.dataReturn.id];
        this.deleteVehicle(idFromButton);
        this._unsubInd2.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los vehículos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteVehicle(ids);
        this._unsubInd5.next('');
      });
  }

  deleteVehicle(idFromButton: string[] | number[]): void {
    this.vehiculoSe.delete(idFromButton);
    this.vehiculoSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El vehículo ha sido borrado correctamente'
        );
        this.ngOnInit();
        this._unsubInd3.next('');
      });
    this.vehiculoSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  updateQuery(): void {
    let sendOptions: any = {};
    if (this.selectedVehicles != null) {
      sendOptions.rol = this.selectedVehicles.id;
    }

    if (this.filterText.model != null && this.filterText.model != '') {
      sendOptions.busqueda = this.filterText.model;
    }
  }

  /**
   *
   * FILROS
   */

  getAsignadoFromSelector(event: any) {
    if (event === null) {
      this.asignado = '';
    }
    if (event !== null) {
      this.asignado = event.label;
    }
    this.getTablaForFilters();
  }

  dataFromPickerCaducidadSeguroDesde(event: any) {
    if (event === null) {
      this.seguroDesde = '';
    }
    if (event !== null) {
      this.seguroDesde = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }
  dataFromPickerCaducidadITVDesde(event: any) {
    if (event === null) {
      this.ITVDesde = '';
    }
    if (event !== null) {
      this.ITVDesde = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }
  dataFromPickerCaducidadSeguroHasta(event: any) {
    if (event === null) {
      this.seguroHasta = '';
    }
    if (event !== null) {
      this.seguroHasta = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }
  dataFromPickerCaducidadITVHasta(event: any) {
    if (event === null) {
      this.ITVHasta = '';
    }
    if (event !== null) {
      this.ITVHasta = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Vehiculos.xlsx');
  }

  popupIncidencias(event: any) {
    this.popup.openPopup(IncidenciasVehiculosComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res) => {
        if (res === null || res.dataReturn !== 'submitted') {
          return;
        }
        this.ngOnInit();
      });
    this._unsubInd7.next('');
  }

  respuestaBusqueda(event: any) {
    if (event === null) {
      this.busqueda = '';
    }
    if (event !== null) {
      this.busqueda = event;
    }
    this.getTablaForFilters();
  }

  getTablaForFilters() {
    this.vehiculoSe.getTable(
      this.pagina,
      this.maximo,
      this.busqueda,
      this.asignado,
      this.ITVDesde,
      this.ITVHasta,
      this.seguroDesde,
      this.seguroHasta
    );
    this.vehiculoSe
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        this.dataVehicles = data;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1);
        this._unsubInd4.next('');
      });
  }

  /**
   * Open detail view obra
   */

  openVehiculoDetailView(event: any) {
    let { id } = event;
    this.router.navigate([`admin/vehiculos/` + id]);
  }

  /**
   *
   * Editar Vehiculo
   */

  editVehicle(event: any): void {
    this.popup.openPopup(EditVehiculoPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd5;
      });
  }

  /**
   * API CALLS
   */
}
