<div class="fullPopup">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="mainBlock">
    <div class="header">
      <p>EDITAR USUARIO</p>
    </div>
    <div class="contentBlock">



      <div class="lineContent">
        <p>Talla Camiseta</p>
        <div class="content">
          <selector [(ngModel)]="tallaCam" [options]="optionsTallaCam" (change)="getImputFromTallaCam($event)"></selector>
        </div>
      </div>

      <div class="lineContent">
        <p>Talla Pantalon</p>
        <div class="content">
          <CustomInput [type]="'number'" placeholder="42" [(ngModel)]="tallaPan" (change)="getFromInput('tallaPan', tallaPan)">
          </CustomInput>
        </div>
      </div>

      <div class="lineContent">
        <p>Talla Bota</p>
        <div class="content">
          <CustomInput [type]="'number'" placeholder="42" [(ngModel)]="tallaBota" (change)="getFromInput('tallaBota', tallaBota)">
          </CustomInput>
        </div>
      </div>


    </div>

    <div class="popupButtons">
      <btn classList="contactButton" class="pb-1" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>


  </div>
</div>