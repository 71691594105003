import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
 

export interface iTipoDocumento {
    id: number,
    nombre: string,
    referencia: string,
}
export class iTipoDocumento extends iBaseObject {
    static convertFromCollection(ob: any): Array<iTipoDocumento> {
        let iTipoDocumentoCollection: Array<iTipoDocumento> = [];
        ob.forEach((element: any) => {
            iTipoDocumentoCollection.push(this.convertFromObject(element));
        });
        return iTipoDocumentoCollection;
    }

    static convertFromObject(ob: any): iTipoDocumento {
        let iTipoDocumentoObj = new iTipoDocumento();
        iTipoDocumentoObj.id = ob.id;
        iTipoDocumentoObj.referencia = ob.referencia;
        iTipoDocumentoObj.nombre = ob.nombre;
        return iTipoDocumentoObj;
    }
}