<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVA NÓMINA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">

        <div class="inputForm">
          <label>Empleado</label>
          <selector class="sizeInput" [options]="optionEmpleado" (change)="getEmpleadoFromInput($event, 'user')"></selector>
        </div>
        <div class="inputForm">
          <label>Año</label>
          <selector class="sizeInput" [options]="optionYear" [(ngModel)]="submit.anyo" (change)="getEmpleadoFromInput($event, 'anyo')"></selector>
        </div>
        <div class="inputForm">
          <label>Mes</label>
          <selector class="sizeInput" [options]="optionMes" [(ngModel)]="submit.mesToShow" (change)="getEmpleadoFromInput($event, 'mes')"></selector>
        </div>
        <div class="inputForm">
          <label>Nomina</label>
          <p>{{nomina}}</p>
          <button class="generalbutton" fileInput [accept]="['application/pdf']" [(ngModel)]="nomina"
            (change)="addNomina(nomina, 'url')">Añadir Nómina</button>
        </div>


      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevaObra()">Añadir Nueva Nómina</btn>
    </div>
  </div>
</div>