import { Component, OnInit } from '@angular/core';
import {
  iDate,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevoParteOperarioPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoParteOperarioPopup/NuevoParteOperarioPopup.component';
import { DescargaPartePopupComponent } from 'src/app/Popups/DescargaPartePopup/DescargaPartePopup.component';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'OperarioDashboard',
  templateUrl: './OperarioDashboard.component.html',
  styleUrls: ['./OperarioDashboard.component.scss'],
})
export class OperarioDashboardComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  submenuSel: number = 0;
  submenuNavegation: any = ['CALENDARIO', 'LISTA'];
  fecha: any = iDate.javascriptConvert(new Date()).toStringDate();
  dataMe: any = [];
  dataPartes: any = [];
  userId: number | null = null;
  controller: boolean = true;
  filter: any = {
    fechaIni: '',
    fechaFin: '',
  };
  reloadFlag: boolean = false;
  monthSelectedNumber!: number;
  yearSelectedNumber!: number;
  parteToDoDays: any = [];

  constructor(
    private popup: PopupService,
    private userSE: UserService,
    private parteSE: ParteService,
    private notificationSE: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getMe();
  }

  crearParte() {
    this.popup.openPopup(
      NuevoParteOperarioPopupComponent,
      { data: this.dataMe, fecha: this.fecha },
      'fullSizePopup'
    );
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.getAllPartesUser(this.userId!);
        this.reloadFlag = true;
        this._unsubInd9.next('');
        setTimeout(() => {
          this.reloadFlag = false;
        }, 1000);
      });
  }

  /**
   * OPEN PARTE
   */

  openParte(event: any) {
    let id = this.dataPartes[event.target.parentElement.id].id;
    this.popup.openPopup(
      DescargaPartePopupComponent,
      { id: id, tipo: 'movil' },
      'fullSizePopup'
    );
  }

  /**
   * Buscador
   */

  buscador(event: any) {
    if (!event) {
      this.filter.buscador = '';
      this.getAllPartesUser(this.userId!);
      return;
    }
    this.filter.buscador = event;
    this.getAllPartesUser(this.userId!);
  }

  /**
   * Partes que hacer
   */

  parteDays(event: any) {
    this.parteToDoDays = event;
  }

  /**
   * DATEPICKER
   */

  dataFromPicker(event: any, key: string) {
    if (!event) {
      this.filter[key] = '';
      this.getAllPartesUser(this.userId!);
      return;
    }
    this.filter[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    this.getAllPartesUser(this.userId!);
  }

  /**
   * Delete Parte
   */

  deleteParte(event: any) {
    let id = this.dataPartes[event.target.parentElement.id].id;

    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: 'el parte' });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.deleteThisParte([id]);
        this._unsubInd.next('');
      });
  }

  deleteThisParte(id: number[]) {
    this.parteSE.delete(id);
    this.parteSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Parte eliminado correctamente');
        this.getMe();
        this._unsubInd9.next('');
      });
    this.parteSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   *
   * SELECTOR DE FECHA
   */

  fechaSelected(event: any) {
    this.fecha = iDate.javascriptConvert(new Date(event)).toStringDate();
    // let fechaFiltered = this.parteToDoDays.filter((date:any)=>{ return new Date(date).toDateString() == new Date(event).toDateString()})
    this.controller =
      event > new Date();
      // || fechaFiltered.length <= 0
  }

  monthSelected(event: any) {
    this.monthSelectedNumber = event;
  }
  yearSelected(event: any) {
    this.yearSelectedNumber = event;
  }

  /**
   * API CALLS
   */

  getMe() {
    this.userSE.getMe();
    this.userSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.dataMe = data;
        this.userId = data.id;
        this.getAllPartesUser(data.id);
        this._unsubInd.next('');
      });
  }

  getAllPartesUser(id: number) {
    this.parteSE.getAllPartesUser(
      id,
      this.filter.fechaIni,
      this.filter.fechaFin,
      this.filter.buscador
    );
    this.parteSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.dataPartes = data.map((item: any) => {
          return {
            ...item,
            adjuntos: item.numImagenes,
            horaInicio: item.horaInicio.split(' ')[1].slice(0, 5),
            horaFin: item.horaFin.split(' ')[1].slice(0, 5),
          };
        });
        this._unsubInd2.next('');
      });
  }
}
