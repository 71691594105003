import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iVehiculo } from "./iVehiculo";

export interface iIncidencia {
    id: number,
    vehiculo:iVehiculo,
    fecha:string,
    descripcion:string,
    estado:string,
}
export interface iIncidenciaCrear {
    vehiculo:iVehiculo,
    fecha:string,
    descripcion:string,
    estado:string,
}

export class iIncidencia extends iBaseObject {
  static convertFromCollection(ob: any): Array<iIncidencia> {
      let iIncidenciaCollection: Array<iIncidencia> = [];
      ob.forEach((element: any) => {
          iIncidenciaCollection.push(this.convertFromObject(element));
      });
      return iIncidenciaCollection;
  }

  static convertFromObject(ob: any): iIncidencia {
      let iIncidenciaObj = new iIncidencia();
      iIncidenciaObj.id = ob.id;
      iIncidenciaObj.vehiculo = ob.vehiculo;
      iIncidenciaObj.fecha = ob.fecha;
      iIncidenciaObj.descripcion = ob.descripcion;
      iIncidenciaObj.estado = ob.estado;
      return iIncidenciaObj;
  }


}