import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";
 

export interface iDepartamento {
    id: number,
    nombre: string,
    encargado?: iUser,
    users?: Array<any>
}
export class iDepartamento extends iBaseObject {
    static convertFromCollection(ob: any): Array<iDepartamento> {
        let iDepartamentoCollection: Array<iDepartamento> = [];
        ob.forEach((element: any) => {            
            iDepartamentoCollection.push(this.convertFromObject(element));
        });
        return iDepartamentoCollection;
    }

    static convertFromObject(ob: any): iDepartamento {
        let iDepartamentoObj = new iDepartamento();
        iDepartamentoObj.id = ob.id;
        iDepartamentoObj.nombre = ob.nombre;
        iDepartamentoObj.encargado = ob.encargado ? iUser.convertFromObject(ob.encargado) : iUser.empty();
        if(ob.users != null){
            iDepartamentoObj.users = ob.users;
        }
        return iDepartamentoObj;
    }

    static empty(): iDepartamento {
        let iDepartamentoObj = new iDepartamento();
        iDepartamentoObj.id = -1;
        iDepartamentoObj.nombre = '';
        iDepartamentoObj.encargado = iUser.empty();
        iDepartamentoObj.users = [];
        return iDepartamentoObj;
    }
}