import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-EmpleadoPopup',
  templateUrl: './EmpleadoPopup.component.html',
  styleUrls: ['./EmpleadoPopup.component.scss']
})
export class EmpleadoPopupComponent extends iUnsubscribeDestroy implements OnInit {


  // Imagen

  imagen: any = null;
  imagenes: any[] = [];

  // NGModel

  nombre: string = "";
  apellidos: string = "";
  dni: string = "";
  dniCaducidad: string = "";
  tallaCamiseta: string = "";
  botas: string = "";
  pantalon: string = "";
  departamento!: string;
  role!: string;
  obrasUser: any[] = []

  dniAnverso: any = "";
  dniAnversoArray: any[] = [];
  dniAnversoName:null | string = null;

  dniReverso: any = "";
  dniReversoArray: any[] = [];
  dniReversoName:null | string = null;

  carnetDeConducirReverso: any = "";
  carnetDeConducirReversoArray: any[] = [];
  carnetDeConducirReversoName:null | string = null;

  carnetDeConducirAnverso: any = "";
  carnetDeConducirAnversoArray: any[] = [];
  carnetDeConducirAnversoName:null | string = null;

  carnetDeConducir: string = "";
  carnetDeConducirCaducidad: string = "";
  telefono: string = "";
  email: string = "";
  direccion: string = "";


  numberAttributes = [
    { attr: 'min', value: "0" }
  ];
  obras: Array<any> = [];
  selectedObras: any[] = [];
  selectedTallas: any[] = [];

  optionsTallas: iOptionsSelector = {
    items: ['XXL', 'XL', 'L', 'M', 'S', 'XS', 'XXS'],
    placeholder: 'Talla',
    append: true,
    clearable: false
  }
  optionsRole: iOptionsSelector = {
    items: [
      { id: 0, label: 'Operario', valor: 'ROLE_OPERARIO' },
      { id: 1, label: 'Administrador', valor: 'ROLE_ADMIN' },
      { id: 2, label: 'Técnico', valor: 'ROLE_TECNICO' },
      // { id: 3, label: 'Cliente', valor: 'ROLE_CLIENTE' },
      { id: 4, label: 'Jefe de Obra', valor: 'ROLE_JEFE_OBRA' },
    ],
    placeholder: 'Role',
    append: true,
    clearable: false
  }
  optionsDepartamento: iOptionsSelector = {
    items: [],
    placeholder: 'Departamento',
    append: true,
    clearable: false
  }

  optionsObras: iOptionsSelector = {
    items: this.obras,
    clearable: true,
    bindLabel: 'nombreObra',
    bindValue: 'id',
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 1,
      multipleTextReplacer: "Varias obras seleccionados"
    },
    placeholder: 'Obras'
  }

  constructor(private departamentoSE: DepartamentosService, private notificationSE: NotificationsService, private usuarioSE: UserService, public dialogRef: MatDialogRef<EmpleadoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, public obrasSe: ObraService) {
    super();
  }

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  ngOnInit() {
    this.obrasSe.getAll();
    this.obrasSe.getResult().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      let idObras: any = []
      this.obras = res["data"].map((e: any) => {
        let obSend = e;
        obSend.nombreObra = obSend.id + " - " + obSend.concepto + " " + obSend.ubicacion;
        idObras.push(obSend.id)
        return obSend;
      });
      this.optionsObras.items = this.obras;
      this.selectedObras = idObras;
      this.obrasUser = this.obras
    })
    this.getDepartamentos()
  }

  // Inputs

  getRoleFromSelector(event: any) {
    this.role = event.valor;
  }


  addImageDNIReverso(event: any) {
    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.dniAnverso = null;
    } else {
      this.dniReversoName = event[0].file.name;
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.dniReversoArray.length == 0) {
          this.dniReversoArray.push(obj)
        } else {
          this.dniReversoArray.push(obj)
        }
      });

    }
  }
  addImageDNIAnverso(event: any) {
    ;
    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.dniAnverso = null;
    } else {
      this.dniAnversoName = event[0].file.name;
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.dniAnversoArray.length == 0) {
          this.dniAnversoArray.push(obj)
        } else {
          this.dniAnversoArray.push(obj)
        }
      });

    }
  }
  addImageConducirReverso(event: any) {
    ;
    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.carnetDeConducirReverso = null;
    } else {
      this.carnetDeConducirReversoName = event[0].file.name;
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.carnetDeConducirReversoArray.length == 0) {
          this.carnetDeConducirReversoArray.push(obj)
        } else {
          this.carnetDeConducirReversoArray.push(obj)
        }
      });

    }
  }
  addImageConducirAnverso(event: any) {
    ;
    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.carnetDeConducirAnverso = null;
    } else {
      this.carnetDeConducirAnversoName = event[0].file.name;
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.carnetDeConducirAnversoArray.length == 0) {
          this.carnetDeConducirAnversoArray.push(obj)
        } else {
          this.carnetDeConducirAnversoArray.push(obj)
        }
      });

    }
  }

  /**
   * 
   * SELECTORES
   */

  getTallaCamisetaFromSelector(event: any) {

    this.tallaCamiseta = event
  }

  getDepartamentoFromSelector(event: any) {
    this.departamento = event.id;
  }
  // getObrasFromSelector(event:any){
  // this.obrasUser = event.map(event.id)
  // }

  // DatePickers

  getDataFromCarnetDeConducirCaducidad(date: any) {
    this.carnetDeConducirCaducidad = date.value;
  }
  getDataFromDNICaducidad(date: any) {
    this.dniCaducidad = date.value;

  }

  /**
   * SUBMIT
   */

  submit() {
    if (!this.dni) { 
      this.notificationSE.showWarning("El DNI es obligatorio")
      return }

    let objectToPass: any = {
      nombre: this.nombre,
      apellidos: this.apellidos,
      mail: this.email,
      telefono: this.telefono,
      departamento: this.departamento,
      dni: this.dni,
      role: this.role,
      obras: (this.obrasUser) ? this.obrasUser.map(item => item.id) : "",
      infoEmpleado: {}
    }

    if (this.tallaCamiseta) objectToPass['infoEmpleado']['tallaCam'] = this.tallaCamiseta;
    if (this.pantalon) objectToPass['infoEmpleado']['tallaPan'] = this.pantalon;
    if (this.botas) objectToPass['infoEmpleado']['tallaBota'] = this.botas;
    if (this.carnetDeConducirCaducidad && this.carnetDeConducirReversoArray) objectToPass['infoEmpleado']['conducirReverso'] = {
      titulo: "conducirReverso",
      fechaCaducidad: this.carnetDeConducirCaducidad,
      estado: "valido",
      tipoDocumento: 4,
      url: this.carnetDeConducirReversoArray[0]?.url
    };
    if (this.carnetDeConducirCaducidad && this.carnetDeConducirAnversoArray) objectToPass['infoEmpleado']['conducirAnverso'] = {
      titulo:"conducirAnverso",
      fechaCaducidad: this.carnetDeConducirCaducidad,
      estado: "valido",
      tipoDocumento: 3,
      url: this.carnetDeConducirAnversoArray[0]?.url
    };
    if (this.dniCaducidad && this.dniReversoArray) objectToPass['infoEmpleado']['dniReverso'] = {
      titulo:"dniReverso",
      fechaCaducidad: this.dniCaducidad,
      estado: "valido",
      tipoDocumento: 2,
      url: this.dniReversoArray[0]?.url
    };
    if (this.dniCaducidad && this.dniAnversoArray) objectToPass['infoEmpleado']['dniAnverso'] = {
      titulo:"dniAnverso",
      fechaCaducidad: this.dniCaducidad,
      estado: "valido",
      tipoDocumento: 1,
      url: this.dniAnversoArray[0]?.url
    };
    if (this.modalData === "proveedor") {
      objectToPass['role'] = "ROLE_PROVEEDOR"
    }
    this.usuarioSE.create(objectToPass);
    this.usuarioSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificationSE.showFeedBack("El usuario se ha creado correctamente")
      this.closePopup(res);
      this._unsubInd.next("");
    })
    this.usuarioSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificationSE.showError(res.message)
      this.closePopup(res);
      this._unsub.next("");
    })
  }

  /**
   * API CALLS
   */

  getDepartamentos() {
    this.departamentoSE.getAll();
    this.departamentoSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return }
      let { data } = res;

      let mappedData = data.map((element: any) => {
        return {
          id: element.id,
          label: element.nombre
        }
      })

      this.optionsDepartamento.items = mappedData;
      this._unsubInd2.next("");
    })
  }


}
