<FullContainer>
  <div class="sectionTitleContainer">
    <h2 class="sectionTitle">Vehículos</h2>
  </div>
  <filterActions [placeholderAdd]="'AÑADIR NUEVO'" (searchSend)="respuestaBusqueda($event)" [inputOptions]="filterText"
    (addNew)="anadirNuevoVehiculo($event)" >
    <ng-container filterContent>
      <div class="containerSelectores">
        <div class="d-flex m-b-1em">

          <div class="selectoresFechas">
            <div>
              <p>Fecha caducidad de seguro</p>            
              <div class="d-flex">
                <div>
                  <p>Desde</p>
                  <DatePicker (dataFromPicker)="dataFromPickerCaducidadSeguroDesde($event)"></DatePicker>
                </div>
                <div>
                  <p>Hasta</p>
                  <DatePicker (dataFromPicker)="dataFromPickerCaducidadSeguroHasta($event)"></DatePicker>
                </div>
              </div>
            </div>
            <div>
              <p>Fecha caducidad de la ITV</p>
              <div class="d-flex">
                <div>
                  <p>Desde</p>
                  <DatePicker (dataFromPicker)="dataFromPickerCaducidadITVDesde($event)"></DatePicker>
                </div>
                <div>
                  <p>Hasta</p>
                  <DatePicker (dataFromPicker)="dataFromPickerCaducidadITVHasta($event)"></DatePicker>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>
  </filterActions>

  <div class="paginacion">
    <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
    <div class="arrows"> 
      <ng-container class="arrowLeft">
        <mat-icon (click)="previousPage()">
          chevron_left
        </mat-icon>
      </ng-container>
      <ng-container class="arrowRight">
        <mat-icon (click)="nextPage()">
          chevron_right
        </mat-icon>
      </ng-container>
    </div>
  </div>


  <pill class="tablePil" classList="tablePill">
    <dataTable [isLoading]="isLoadingTabla" (see)="openVehiculoDetailView($event)" (clickRow)="openVehiculoDetailView($event)" [data]="dataVehicles" [displayedColumns]="displayedColumnsVehiculos"
      [options]="optionsVehiculos" [forceUpdateWidth]="forceUpdateWidth" (delete)="OnclickDeleteButton($event)" (edit)="editVehicle($event)" (deleteSelected)="deleteSelected($event)"></dataTable>
  </pill>
</FullContainer>