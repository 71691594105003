<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR CONTRASEÑA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>CONTRASEÑA ACTUAL</label>
          <CustomInput placeholder="ACTUAL" [type]="'password'" [(ngModel)]="passwordActual" (change)="getDataFromInput($event, 'passwordActual')">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>NUEVA CONTRASEÑA</label>
          <CustomInput placeholder="NUEVA" [type]="'password'" [(ngModel)]="password" (change)="getDataFromInput($event, 'password')">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>REPITA LA CONTRASEÑA</label>
          <CustomInput placeholder="REPITE NUEVA" [type]="'password'" [(ngModel)]="password2" (change)="getDataFromInput($event, 'password2')">
          </CustomInput>
        </div>

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="editarContrasenya()" [ngClass]="{forwardDisabled: disabled}">Editar Contraseña</btn>
    </div>
  </div>
</div>