import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iButton, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { LoginLoadingComponent } from 'src/app/Popups/LoginLoading/LoginLoading.component';
import { LoginService } from 'src/app/Services/Api/Login.service';

@Component({
  selector: 'app-LoginPage',
  templateUrl: './LoginPage.component.html',
  styleUrls: ['./LoginPage.component.scss']
})
export class LoginPageComponent extends iUnsubscribeDestroy implements OnInit {

  dni="";
  password="";

  isLogin = false;

  animSend=new Subject<boolean>();
  animActive = false;

  constructor(public loginSe:LoginService, public notificaciones:NotificationsService, public popup:PopupService, public route:Router) { 
    super();
  }

  ngOnInit() {
  }
  checkLogin(){
    let complet = this.checkSend();
    if(complet){
      this.loginSe.checkLogin(this.dni,this.password);
      
      this.loginSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe(val=>{
        if(val==null){return}
        this.animActive = true;
        this.animSend.next(true);
        this.isLogin = true;
        this._unsubInd.next('');
      })
      this.loginSe.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(val=>{
        if(val==null){return}
        this.notificaciones.showError('Usuario o contraseña incorrectos');
        this._unsubInd.next('');
      })
    }
  }
  checkSend(){
    let ret=true;
    if(this.dni.trim()==null||this.dni.trim()==""){
      ret = false;
    }
    if(this.password.trim()==null||this.password.trim()==""){
      ret = false;
    }
    return ret;
  }
  finishAnim(){
    if(this.isLogin && this.animActive){
      this.popup.openPopup(LoginLoadingComponent,null,["fullSizePopup","loginLoading"]);
      this.animActive = false;
      this.animSend.next(false);
      setTimeout(() => {
        this.route.navigate(['/']);
      }, 150);
    }else{
      this.animSend.next(false);
    }
  }

}
