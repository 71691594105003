import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/User.service';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';

@Component({
  selector: 'app-ProveedoresDashboard',
  templateUrl: './ProveedoresDashboard.component.html',
  styleUrls: ['./ProveedoresDashboard.component.scss']
})
export class ProveedoresDashboardComponent extends iUnsubscribeDestroy implements OnInit {

  // Filtros

  busqueda:string = "";
  estado:string = "";
  cliente:string = "";
  concepto:string = "";
  isFilterOpen = false;
  isSearchOpen = false;
  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: ''
  }
  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Nº Obra', property: 'numero', align: 'center' },
    { label: 'Cliente', property: 'cliente', align: 'center' },
    { label: 'Ubicación', property: 'ubicacion', align: 'center', linkAction: (rowElem) => { this.abrirGoogleMaps(rowElem) } },
    { label: 'Estado', property: 'estado', align: 'center', classList: 'tableEstado tableEstadoObras' }
  ];
  optionsObras: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,

  }
  // Selectores
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Cliente'
  }
  optionConcepto: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Concepto'
  }
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Activa' },
      { id: 0, label: 'Inactiva' },
    ],
    clearable: true,
    placeholder: 'Estado'
  }
  userID!: number;
  dataObras = []
  isLoading: boolean = true;
  constructor(private obraSE: ObraService, private router: Router, private popup: PopupService, private notificaciones: NotificationsService, private userSE: UserService, private clienteSE: ClienteService) {
    super();
  }
  ngOnInit() {
    this.getMe();
    this.getClientes();
  }
  /**
   * FUNCTIONALITIES
   */
  editObra(event: Event) {
     
    this.popup.openPopup(EditObrasPopupComponent, event);
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      this.isLoading = true;
      this.getAllObras()
      this._unsubInd2.next("")
    })
  }
  /**
 * FILTROS
 */
  respuestaBusqueda(event: any) {
    if (event === null) { this.busqueda = ""; }
    if (event !== null) { this.busqueda = event }
    this.getAllObras()
  }
  getClienteFromSelector(event: any) {
    if (event === null) { this.cliente = ""; }
    if (event !== null) { this.cliente = event.label }
    this.getAllObras()

  }
  getEstadoFromSelector(event: any) {
    if (event === null) { this.estado = ""; }
    if (event !== null) { this.estado = event.id }
    this.getAllObras()

  }
  getConceptoFromSelector(event: any) {
    if (event === null) { this.concepto = ""; }
    if (event !== null) { this.concepto = event.label }
     
    this.getAllObras()
  }
  changeFilterOpen(newVal: boolean) {
    this.isFilterOpen = newVal;
    this.updateTableMaxHeight();
  }
  changeSearchOpen(newVal: boolean) {
    this.isSearchOpen = newVal;
    this.updateTableMaxHeight();
  }
  updateTableMaxHeight() {
    let val = "";
    if (this.isFilterOpen) {
      val = val + " - 68px - 1em";
    }
    if (this.isSearchOpen) {
      val = val + " - 68px - 1em";
    }
     ;
    this.optionsObras.maxHeight = 'calc(100vh - 78px - 4em - 106px - 47px - 4em' + val + ')';
  }
  deleteObra(event: any, type: string) {
    switch (type) {
      case 'single':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "la obra" });
        break;
      case 'multiple':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras" });
        break;
      case 'multipleSelected':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras seleccionadas" });
        break;
    }
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      switch (type) {

        case 'single':
           
          this.removeObra(event["id"]);
          break;
        case 'multiple':
          this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras" });
          break;
        case 'multipleSelected':
          this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras seleccionadas" });
          break;
      }
      this._unsubInd2.next("")
    })
     
  }
  navigateToPartesObras(event: any) {
     
    this.router.navigate(["/proveedores/obras/" + event["id"]])
  }


  /**
   * GOOGLE MAPS
   */

  abrirGoogleMaps(event: any) {
     
    // let { lan, lon } =  ;
    // window.open(`https://www.google.com/maps/search/?api=1&query=${lan},${lon}`, '_blank');
  }

  /**
   * API Calls
   */

  getClientes() {
    this.clienteSE.getAll();
    this.clienteSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res
      let mappedCliente = data.map((elem: any) => { return { id: elem.id, label: elem.nombre } })
       
      this.optionCliente.items = mappedCliente
      this._unsubInd2.next("");
    })
  }

  getAllObras() {
    this.obraSE.getObrasUserId(this.userID,1,50,this.busqueda, this.concepto, this.estado, this.cliente);
    this.obraSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (res === null) { return }
       
      let { data } = res;
       
      this.optionConcepto.items = data.map((elem: any) => { return { id: elem.id, label: elem.concepto } })
      this.dataObras = data.map((elem: any) => { return { ...elem, estado: elem.estado === 1 ? "activa" : "inactiva" } });
      this.isLoading = false;
      this._unsubInd2.next("");
    })
  }
  getMe() {
    this.userSE.getMe();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (res === null) { return }
      let { data } = res;
       
      this.userID = data.id;
      localStorage.setItem("nmsr", data.id);
      this.getAllObras();
      this._unsubInd3.next("");
    })
  }
  removeObra(id: string[] | number[]): void {
     
    this.obraSE.delete(id);
    this.obraSE.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificaciones.showFeedBack("La obra ha sido borrado correctamente")
      this.ngOnInit();
      this._unsubInd4.next("")
    })
    this.obraSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificaciones.showError(res.message)
      this._unsub.next("")
    })
  }
}
