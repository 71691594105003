<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">Editar empleado {{nombre}} {{apellidos}}</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="nombre" [(ngModel)]="nombre" (change)="getFromInput('nombre', nombre)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Apellidos</label>
          <CustomInput placeholder="apellidos" [(ngModel)]="apellidos" (change)="getFromInput('apellidos', apellidos)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Teléfono</label>
          <CustomInput placeholder="telefono" [(ngModel)]="telefono" (change)="getFromInput('telefono', telefono)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email</label>
          <CustomInput placeholder="email" [(ngModel)]="mail" (change)="getFromInput('mail', mail)"></CustomInput>
        </div>
        <!-- <div class="separatorGrid"></div> -->
        <!-- <div class="inputForm">
          <label>Departamento</label>
          <selector class="sizeInput" [options]="optionsDepartamento" (change)="departamentoFromSelector($event)" [(ngModel)]="departamento"></selector>
        </div> -->
        <div class="inputForm">
          <label>Asignar obras</label>
          <selector class="sizeInput" [options]="optionsObras" [(ngModel)]="obras" (change)="obraFromSelector($event)"></selector>
        </div>
        <div class="inputForm">
          <label>ROLE</label>
          <selector class="sizeInput" [options]="optionsRole" [(ngModel)]="role" (change)="getRoleFromSelector($event)"></selector>
        </div>

        <!-- <div class="inputForm">
          <label>DNI</label>
          <CustomInput placeholder="DNI" [(ngModel)]="dni" (change)="getFromInput('dni', dni)"></CustomInput>
        </div> -->
        <div class="tallasGrid">
          <span></span>
          <span class="textCenter">Camiseta</span>
          <span class="textCenter">Botas</span>
          <span class="textCenter">Pantalones</span>
          <span class="tallasWidth">Tallas</span>
          <div class="textCenter">
            <selector class="maxWidth" [options]="optionsTallas" [(ngModel)]="selectedTallas" (change)="tallaCamisetaFromSelector($event)"></selector>
          </div>
          
          <div class="textCenter">
            <CustomInput class="maxWidth" classList="fullWidth" type="number" [extraAtributes]="numberAttributes" placeholder="42" [(ngModel)]="botas" (change)="updateBotasPantalones('tallaBota',botas)"></CustomInput>
          </div>
          <div class="textCenter">
            <CustomInput class="maxWidth" classList="fullWidth" type="number" [extraAtributes]="numberAttributes" placeholder="43.5" [(ngModel)]="pantalones" (change)="updateBotasPantalones('tallaPan',pantalones)"></CustomInput>
          </div>
        </div>
        <!-- <div class="separatorGrid"></div> -->

      </div>
      <br><hr><br>
      <!-- <h3 class="popupTitle secondTitle">CADUCIDAD DE DOCUMENTOS</h3>
      <div class="flexDivider">
        <div>
          <div class="documentTitle">
            <label>DNI</label>
            <DatePicker (dataFromPicker)="getDataFromDNI($event)"></DatePicker>

          </div>
        </div>
        <div>
          <div class="documentTitle">
            <label>Carnet de conducir</label>
            <div class="caducidadPicker">
              <DatePicker (dataFromPicker)="getDataFromCarnetDeConducir($event)"></DatePicker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br> -->
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>