import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iTipoDocumento } from "./iTipoDocumento";
import { iUser } from "./iUser";
import { iObra } from "./iObra";
import { iCarpeta } from "./iCarpeta";
 

export interface iDocumento {
    id?: number,
    fecha: iDate,
    fechaCaducidad: iDate,
    estado: string,
    url: string,
    titulo: string,
    tipoDocumento?: iTipoDocumento,
    usuario?: iUser,
    obra?: iObra,
    carpeta?: iCarpeta,
    versionDocs?: any,
    vehiculo?: any,
}

export interface iDocumentoCreate{
    fechaCaducidad: string;
    tipoDocumento: number;
    estado: string;
    obra: number | string;
    usuario: number | string;
    url: string;
}

export class iDocumento extends iBaseObject {
    static convertFromCollection(ob: any): Array<iDocumento> {
        let iDocumentoCollection: Array<iDocumento> = [];
        ob.forEach((element: any) => {
            iDocumentoCollection.push(this.convertFromObject(element));
        });
        return iDocumentoCollection;
    }

    static convertFromObject(ob: any): iDocumento {
        let iDocumentoObj = new iDocumento();
        if(ob.id !== undefined){
            iDocumentoObj.id = ob.id;
        }
        iDocumentoObj.fecha = iDate.phpConvert(ob.fecha);
        iDocumentoObj.fechaCaducidad = iDate.phpConvert(ob.fechaCaducidad);
        iDocumentoObj.estado = ob.estado;
        iDocumentoObj.url = ob.url;
        iDocumentoObj.titulo = ob.titulo;
        if(ob.tipoDocumento !== undefined){
            iDocumentoObj.tipoDocumento = iTipoDocumento.convertFromObject(ob.tipoDocumento);
        }
        if(ob.usuario){
            iDocumentoObj.usuario = iUser.convertFromObject(ob.usuario);
        }
        if(ob.obra){
            iDocumentoObj.obra = iObra.convertFromObject(ob.obra);
        }
        if(ob.carpeta){
            iDocumentoObj.carpeta = iCarpeta.convertFromObject(ob.carpeta);
        }
        if(ob.vehiculo){
            iDocumentoObj.vehiculo = ob.vehiculo;
        }
        if(ob.versionDocs !== undefined){
            iDocumentoObj.versionDocs = ob.versionDocs;
        }
        return iDocumentoObj;
    }
}