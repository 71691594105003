import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-OperarioDocumentos',
  templateUrl: './OperarioDocumentos.component.html',
  styleUrls: ['./OperarioDocumentos.component.scss']
})
export class OperarioDocumentosComponent extends iUnsubscribeDestroy implements OnInit {

  /**
   * 
   * DATA
   */

  dataMe: any = null;
  userId: number | null = null;
  obras: any = null;

  constructor(private userSE: UserService, private router:Router) {
    super();
  }

  ngOnInit() {
    this.getMe()
  }

  openObra(event: any) {
    this.router.navigateByUrl(`/operario/obras/${event.id}`)
  }


  /**
   * GET ME
   */

  getMe() {
    this.userSE.getMe();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.dataMe = data;
      this.userId = data.id;
      this.obras = data.obras
      this._unsubInd.next('')
    })
  }

}
