import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';

@Component({
  selector: 'app-EditVehiculoPopup',
  templateUrl: './EditVehiculoPopup.component.html',
  styleUrls: ['./EditVehiculoPopup.component.scss']
})
export class EditVehiculoPopupComponent extends iUnsubscribeDestroy implements OnInit {


  dataFromVehiculos = this.modalData


  initialDataSeguro!:string
  initialDataITV!:string
  seguro!:string
  caducidad!:string

  obj: any = {
    id: this.dataFromVehiculos.id
  }

  // Opciones del selector
  optionUsers: iOptionsSelector = {
    items: [],
    clearable: false,
    append: true,
    search:true,
    bindValue: "id",
    multiple:{
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer:2,
      multipleTextReplacer: 'Varios usuarios'

    }
  }

  // Inputs
  nombre: string = ""
  matricula: string = "" 
  asignado: string = ""

  constructor(private vehiculoSe: VehiculoService, private userSe: UserService, public dialogRef: MatDialogRef<EditVehiculoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getAllUsers()
    this.getVehiculoId(this.dataFromVehiculos.id)
  }

  getAllUsers(): void {
    this.userSe.getAll();
    this.userSe.getResultAll().pipe(takeUntil(this._unsubInd4)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res.data;
       
      let mappedUsers = data.map((d: any) => {
        return { id: d.id, label: `${d.nombre} ${d.apellidos}` }
      })
      this.optionUsers.items = mappedUsers;
      this._unsubInd4.next("")
    })
  }

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  obtainChange(element: string, value: any) {
    let obj: any = {
      id: this.dataFromVehiculos.id
    }
    obj[element] = value
    this.updateVehicle(obj);

  }

  updateVehicle(obj: any): void {
    this.vehiculoSe.update(obj);
    this.vehiculoSe.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("El vehiculo ha sido modificado correctamente")
      this._unsubInd3.next("")
    })
    this.vehiculoSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })
  }

  getDataFromSeguro(event: any): void {
    let dateToPass = this.getDateFormat(event)
    this.obj['caducidadSeguro'] = dateToPass
    this.updateVehicle(this.obj);
  }

  getDataFromITV(event: any) {
    let dateToPass = this.getDateFormat(event)
    this.obj['caducidadITV'] = dateToPass
    this.updateVehicle(this.obj);
  }

  getDateFormat(event: any): string {
    let { value } = event;
    let date = new Date((value))
    let dateToPass = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    return dateToPass
  }

  obtenerUsuario(event: any): void {
    this.obj['usuario'] = event.id
    this.updateVehicle(this.obj);


  }

  getVehiculoId(id:number):void{
     
    this.vehiculoSe.getSingle(id);
    this.vehiculoSe.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe((res:any)=>{
      if(res === null){return;}
      let {data} = res;
       
      this.nombre = data.nombre;
      this.matricula = data.matricula;
      this.seguro = data.caducidadSeguro.toStringDate('EU').replace(/-/g, "/");
      this.caducidad = data.caducidadITV.toStringDate('EU').replace(/-/g, "/");
      this.asignado = `${data.usuario.nombre} ${data.usuario.apellidos}`;
      this._unsubInd2.next("")
    })
  }

}
