import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MAT_ICON_LOCATION_FACTORY } from '@angular/material/icon';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { DescargaPartePopupComponent } from 'src/app/Popups/DescargaPartePopup/DescargaPartePopup.component';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { iParte } from 'src/app/Shared/Interfaces/Api/iParte';
import {
  iGoogleMapsMark,
  MapStyle,
} from 'src/app/Shared/Interfaces/iGoogleMapsMark';
import * as XLSX from 'xlsx';

@Component({
  selector: 'AdminPartes',
  templateUrl: './AdminPartes.component.html',
  styleUrls: ['./AdminPartes.component.scss'],
})
export class AdminPartesComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  forceUpdateWidth = new Subject();

  // vacation popup

  vacationPopup: boolean = false;

  // Calendario

  month = new Date().getMonth() + 1;

  dataCalendario: Array<any> = [];

  isLoadingTabla: boolean = false;

  filterText = {
    placeholder: '',
    model: '',
  };
  // data de los partes de la bbdd
  partesData: Array<iParte> = [];
  partesDataNoFilter: Array<iParte> = [];

  // Filtros

  busqueda: string = '';
  desde: string = '';
  hasta: string = '';
  cargadoBBDD: string = '';

  // Selecteds
  selecteds: Array<iParte> = [];

  //Tabla
  displayedColumnsPartes: Array<iDataTableColumns> = [
    { label: 'Operario', property: 'nombreCompleto' },
    { label: 'Nº Obra', property: 'numeroObra', align: 'center' },
    { label: 'Obra', property: 'obraUbicacion', align: 'center' },
    { label: 'Horas trabajadas', property: 'horas' },
    { label: 'Tarea', property: 'obraConcepto', align: 'center' },
    {
      label: 'Estado',
      property: 'cargadoBBDD',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  // Opciones de la tabla
  optionsPartes: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  // Opciones del selector
  optionEstado: iOptionsSelector = {
    items: [
      { id: 0, label: 'Pendiente', cargadoBBDD: 'false' },
      { id: 1, label: 'Cargado', cargadoBBDD: 'true' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  // Navegacion submenu
  submenuNavegation = ['VALIDACIÓN', 'CARGA', 'CALENDARIO'];

  submenuSel = 0;

  constructor(
    private partesSe: ParteService,
    private datePipe: DatePipe,
    private popup: PopupService,
    private notificationsSE: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getTablaForFilters();
  }

  getEstadoToFilter(evento: any) {
    evento == null
      ? (this.cargadoBBDD = '')
      : (this.cargadoBBDD = evento.cargadoBBDD);
    this.getTablaForFilters();
  }

  refreshPopup(event: boolean) {
    this.vacationPopup = event;
  }

  /**
   * DELETE PARTE
   */

  deletePartePopup(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el parte seleccionado',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.deleteParte([event.id]);
        this._unsubInd.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'Los partes seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteParte(ids);
        this._unsubInd.next('');
      });
  }

  deleteParte(ids: any[]) {
    this.partesSe.delete(ids);
    this.partesSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationsSE.showFeedBack('Parte eliminado correctamente');
        this.getTablaForFilters();
        this._unsubInd2.next('');
      });
    this.partesSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationsSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Partes.xlsx');
  }

  openVacationsPopup() {
    this.vacationPopup = true;
  }

  /**
   *
   * FILTROS
   */

  dataFromPickerHasta(event: any) {
    if (event === null) {
      this.hasta = '';
    }
    if (event !== null) {
      this.hasta = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }

  dataFromPickerDesde(event: any) {
    if (event === null) {
      this.desde = '';
    }
    if (event !== null) {
      this.desde = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }

  getTablaForFilters() {
    this.isLoadingTabla = true;
    this.partesSe.getAllTabla(
      this.busqueda,
      this.desde,
      this.hasta,
      this.cargadoBBDD
    );
    this.partesSe
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        let mappedData = data.map((element: any) => {
          let cargado = element.cargadoBBDD ? 'Cargado' : 'Pendiente';
          return {
            id: element.id,
            nombreCompleto: element.operario,
            numeroObra: element.numero,
            obraUbicacion: element.obra,
            horas: element.horas,
            obraConcepto: element.tarea,
            cargadoBBDD: cargado,
            estado: element.estado,
          };
        });
        this.partesData = mappedData;

        this.isLoadingTabla = false;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1000);
        this._unsubInd3.next('');
      });
  }

  reloadPartes() {
    this.getTablaForFilters();
  }

  /**
   * FUNCIONALIDAD
   */

  selectedPartes(event: any) {
    this.selecteds = event;
  }

  cambiarEstado() {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'de los partes seleccionados',
      tipo: 'estado',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        let ids = this.selecteds.map((e: any) => e.id);
        this.changeStatus(ids);
        this._unsubInd2.next('');
      });
  }

  changeStatus(ids: number[]) {
    this.partesSe.changeCargado({ ids: ids });
    this.partesSe
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationsSE.showFeedBack('Parte actualizado correctamente');
        this.getTablaForFilters();
        this._unsubInd5.next('');
      });
    this.partesSe
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationsSE.showError(res.message);
        this._unsub.next('');
      });
  }
}
