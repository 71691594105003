import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iDate, iOptionsSelector, iResultHttp, iStaticUtilities, iUnsubscribeDestroy, LoaderService, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { iPipeLine } from 'src/app/Shared/Interfaces/iPipeLine';
import { AñadirNuevoSTComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoSeguimientoTecnicoPopup/NuevoSeguimientoTecnicoPopup.component';
import { UserService } from 'src/app/Services/Api/User.service';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';


@Component({
  selector: 'AdminSegtoTecnicoPipeLine',
  templateUrl: './AdminSegtoTecnicoPipeLine.component.html',
  styleUrls: ['./AdminSegtoTecnicoPipeLine.component.scss']
})
export class AdminSegtoTecnicoPipeLineComponent extends iUnsubscribeDestroy implements OnInit {

  /**
   * FILTROS
   */

  tecnico: string = ""
  cliente: string = ""
  estado: string = ""
  fechaIni: string = ""
  fechaFin: string = ""
  busqueda: string = ""
  address: string = ""

    // Stats

    estadisticasST: any = {}

    anterior:boolean = true;

  // -----------MES----------------
  pendientesMes = ""
  contactadoMes = ""
  visitadoMes = ""
  enviadoMes = ""

  // -----------AÑO----------------
  pendientesAno = ""
  contactadoAno = ""
  visitadoAno = ""
  enviadoAno = ""

  // -----------AÑO ANTERIOR----------------
  anoAnterior:any = {
    pendientes: "",
    contactado: "",
    visitado: "",
    enviado: "",
  }


  initialData: Array<any> = [];

  pipeList: Array<iPipeLine> = [
    { title: 'Pendiente', items: [] },
    { title: 'Contactado', items: [] },
    { title: 'Visitado', items: [] },
    { title: 'Entregado', items: [] }
  ];


  loadingElements: any[] = [];

  // Selectores
  optionTecnico: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Técnico'
  }
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Cliente'
  }
  optionAddress: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Dirección'
  };
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pendiente' },
      { id: 2, label: 'Contactado' },
      { id: 3, label: 'Visitado' },
      { id: 4, label: 'Enviado' },
    ],
    clearable: true,
    placeholder: 'Estado'
  }

  constructor(private clienteSE:ClienteService ,private usuarioSE: UserService, private seguiminetoTecnicoSE: SeguimientoTecnicoService, private popup: PopupService, private loader: LoaderService, private notificationSE: NotificationsService, public seguimientoSe: SeguimientoTecnicoService, private seguimientoTecnicoSe:SeguimientoTecnicoService) {
    super();
  }

  ngOnInit() {
    this.loader.open();
    this.getEmpleados();
    this.getClientes();
    this.getAddresses();
    this.getSeguimientoTecnicoData()
    this.getEstadisticasST()
    this.seguimientoSe.getResultUpdate().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (res == null) { return }
       
      this.loadingElements = this.loadingElements.filter(e => e != res.data.id);
    })
    this.loader.close();
  }

  override ngOnDestroy(): void {
    this.pipeList = []
  }


  /**
   * FILTROS
   */

  getAddressFromSelector(event: any) {
    if (event === null) { this.address = ""; }
    if (event !== null) { this.address = event.label }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()

  }


  getTecnicoFromSelector(event: any) {
    if (event === null) { this.tecnico = ""; }
    if (event !== null) { this.tecnico = event.label }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()
  }

  getClienteFromSelector(event: any) {
    if (event === null) { this.cliente = ""; }
    if (event !== null) { this.cliente = event.label }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()
  }

  getEstadoFromSelector(event: any) {
    if (event === null) { this.estado = ""; }
    if (event !== null) { this.estado = event.label }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()
  }

  dataFromPickerDesde(event: any) {
    if (event === null) { this.fechaIni = ""; }
    if (event !== null) { this.fechaIni = iDate.javascriptConvert(new Date(event.value)).toStringDate('JAP') }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()
  }

  dataFromPickerHasta(event: any) {
    if (event === null) { this.fechaFin = ""; }
    if (event !== null) { this.fechaFin = iDate.javascriptConvert(new Date(event.value)).toStringDate('JAP') }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()
  }

  respuestaBusqueda(event: any) {
    if (event === null) { this.busqueda = ""; }
    if (event !== null) { this.busqueda = event }
    this.clearPipeList()
    this.getSeguimientoTecnicoData()
  }


  /**
   * 
   * CREAR ST
   */

  crearST() {
    this.popup.openPopup(AñadirNuevoSTComponent);
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return }
      this.clearPipeList()
      this.getSeguimientoTecnicoData()
      this._unsubInd2.next("")
    })
  }
  clearPipeList() {
    this.initialData = []
    this.pipeList.map(e => {
      e.items = [];
    })
  }
  isLoadingElem(elem: any) {
    if (this.loadingElements.includes(elem.id)) {
      return true;
    }
    return null;
  }
  async changeItem(elem: any) {

    let objectToPass = {
      id: elem.item.id,
      estado: elem.currentColumn.column.title
    }

     
    this.loadingElements.push(elem.item.id);

    this.seguimientoSe.update(objectToPass);
    this.seguimientoSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }
      this.notificationSE.showFeedBack("Estado actualizado correctamente")
      this.getSeguimientoTecnicoData()
      this._unsubInd.next("")
    })

  }
  change(event: any) {
     
  }

  /**
   * API CALLS
   */

  getSeguimientoTecnicoData() {
    this.seguimientoSe.getAllTabla(1, 50000000,this.busqueda, this.tecnico, this.address, this.estado, this.fechaIni, this.fechaFin);
    this.seguimientoSe.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }

      let { data, total } = res.data;

      this.initialData = data;

      this.initialData.forEach(e => {
        let indexAppend = -1;
        switch (e.estado.toLowerCase()) {
          case "pendiente":
            indexAppend = 0;
            break;
          case "contactado":
            indexAppend = 1;
            break;
          case "visitado":
            indexAppend = 2;
            break;
          case "entregado":
            indexAppend = 3;
            break;
        }
        if (indexAppend != -1) {
          this.pipeList[indexAppend].items.push(e);
        }
      })
      this._unsubInd.next("")
    })
  }

  getEmpleados() {
    this.usuarioSE.getEmpleados(1);
    this.usuarioSE.getResultEmpleados().pipe(takeUntil(this._unsubInd4)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res.data;
       
      let mappedEmpleados = data.map((elem: any) => {
        return { id: elem.id, label: elem.nombre, }
      })
      this.optionTecnico.items = mappedEmpleados;
      this._unsubInd4.next("");
    })
  }

  // Get Clientes

  getClientes() {
    this.clienteSE.getAll();
    this.clienteSE.getResult().pipe(takeUntil(this._unsubInd6)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
       
      let mappedClientes = data.map((elem: any) => {
        return { id: elem.id, label: elem.nombre, }
      })
      this.optionCliente.items = mappedClientes;

      this._unsubInd6.next("");
    })
  }

  getAddresses() {
    this.seguimientoSe.getAddresses();
    this.seguimientoSe.getResultGetAddreses().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
       
      let mappedAddresses = data.map((elem: any) => {
        return { id: elem.id, label: elem.ubicacion, }
      })
      this.optionAddress.items = mappedAddresses;
      this._unsubInd2.next("");
    })
    this.seguimientoSe.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:iResultHttp)=>{
      if(!res){return}
      if(res.status != 401){
        if (res.status == 404) {
          this.notificationSE.showError('No se han encontrado resultados');
        } else if (res.status == 500) {
          this.notificationSE.showError('Se ha producido un error, intentalo más tarde.');
        } else {
          this.notificationSE.showError(res.message);
        }
      }
      this._unsub.next('')
    })
  }

  getEstadisticasST() {
    this.seguimientoTecnicoSe.getEstadisticas();
    this.seguimientoTecnicoSe.getResultIndividual().pipe(takeUntil(this._unsubInd5)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
      this.estadisticasST = data
      this.pendientesMes = this.estadisticasST?.mes[0]?.cuenta ?? 0
      this.contactadoMes = this.estadisticasST?.mes[1]?.cuenta ?? 0
      this.visitadoMes = this.estadisticasST?.mes[2]?.cuenta ?? 0
      this.enviadoMes = this.estadisticasST?.mes[3]?.cuenta ?? 0
      this.pendientesAno = this.estadisticasST?.anyo[0]?.cuenta ?? 0
      this.contactadoAno = this.estadisticasST?.anyo[1]?.cuenta ?? 0
      this.visitadoAno = this.estadisticasST?.anyo[2]?.cuenta ?? 0
      this.enviadoAno = this.estadisticasST?.anyo[3]?.cuenta ?? 0
      if(this.estadisticasST.anterior.length === 0){ this.anterior =false; return}
      this.anoAnterior.contactado = this.estadisticasST?.anterior[0]?.cuenta ?? 0
      this.anoAnterior.enviado = this.estadisticasST?.anterior[1]?.cuenta ?? 0
      this.anoAnterior.pendiente = this.estadisticasST?.anterior[2]?.cuenta ?? 0
      this.anoAnterior.visitado = this.estadisticasST?.anterior[3]?.cuenta ?? 0
      
      this._unsubInd5.next("");
    })
  }


}
