import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';
import { takeUntil } from 'rxjs';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';

@Component({
  selector: 'app-AdjuntarDocumentosVehiculosPopup',
  templateUrl: './AdjuntarDocumentosVehiculosPopup.component.html',
  styleUrls: ['./AdjuntarDocumentosVehiculosPopup.component.scss'],
})
export class AdjuntarDocumentosVehiculosPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // Bindings
  carpeta: string = '';
  documento: any = null;
  url: any = null;
  documentName: string = '';
  fechaCaducidad: string = '';
  dataFromVehicle = this.modalData;

  optionCarpeta: iOptionsSelector = {
    items: [],
    clearable: false,
    bindLabel: 'nombre',
    placeholder: 'Carpeta',
    append: true,
    search: true,
  };

  objectToEdit: any = {
    id: this.modalData.idDocumento,
  };

  constructor(
    public dialogRef: MatDialogRef<AdjuntarDocumentosVehiculosPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSE: NotificationsService,
    private carpetaSE: CarpetaService,
    private vehiculoSE: VehiculoService,
    private documentoSE: DocumentoService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllCarpetas();
  }

  // Close

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  addDocumento(event: any) {
    if (
      event[0].file.type != 'image/png' &&
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/jpg' &&
      event[0].file.type != 'application/pdf'
    ) {
      this.notificationSE.showWarning(
        'Formato de archivo no admitido, por favor suba un archivo .png, .pdf o .jpg'
      );
      this.documento = null;
    } else {
      event.forEach((element: any) => {
        this.url = element.base64;
        this.documentName = element.file.name;
      });
    }
  }

  dataFromPicker(event: any, key: string) {
    this.fechaCaducidad = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    if (this.dataFromVehicle.edit === 'edit') {
      this.objectToEdit['fechaCaducidad'] = this.fechaCaducidad;
      this.editDocumento(this.objectToEdit);
    }
  }

  getCarpetaFromInput(event: any) {
    this.carpeta = event.id;
  }

  /**
   * SUBMIT
   */

  adjuntarDocumento() {
    if (this.dataFromVehicle.edit === 'edit') {
      this.closePopup('edit');
      return;
    }
    if (this.modalData.key === 'itv') {
      let objectToPass = {
        fechaCaducidad: this.fechaCaducidad,
        estado: 'pendiente',
        tipoDocumento: this.modalData.tipoDocumento,
        vehiculo: this.modalData.idVehiculo,
        titulo: `Documento de vehículo`,
        carpeta: this.carpeta,
        url: this.url,
      };
      this.crearDocumento(objectToPass);
    } else {
      let objectToPass = {
        fechaCaducidad: this.fechaCaducidad,
        estado: 'pendiente',
        tipoDocumento: this.modalData.tipoDocumento,
        vehiculo: this.modalData.idVehiculo,
        titulo: `Documento de vehículo`,
        carpeta: this.carpeta,
        url: this.url,
      };
      this.crearDocumento(objectToPass);
    }
  }

  /**
   * API CALLS
   */

  getAllCarpetas() {
    this.carpetaSE.getAll();
    this.carpetaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;

        this.optionCarpeta.items = data;
        this._unsubInd6.next('');
      });
  }

  crearDocumento(objectToPass: any) {
    this.documentoSE.create(objectToPass);
    this.documentoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Documento creado correctamente');
        this.closePopup('submitted');
        this._unsubInd6.next('');
      });
    this.documentoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsubInd6.next('');
      });
  }

  editDocumento(objectToEdit: any) {
    this.documentoSE.update(objectToEdit);
    this.documentoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Documento editado correctamente');
        this._unsubInd.next('');
      });
    this.documentoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsubInd.next('');
      });
  }
}
