import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { AlertaService } from 'src/app/Services/Api/Alerta.service';

@Component({
  selector: 'app-VisorDeDocumentoImagen',
  templateUrl: './VisorDeDocumentoImagen.component.html',
  styleUrls: ['./VisorDeDocumentoImagen.component.scss']
})
export class VisorDeDocumentoImagenComponent extends iUnsubscribeDestroy implements OnInit {

  imagen:any =null

  constructor(public dialogRef: MatDialogRef<VisorDeDocumentoImagenComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private alertaSE:AlertaService) {
    super();
  }

  ngOnInit() {
    this.imagen =this.modalData.url
  }

  closePopup() {
    this.dialogRef.close();
  } 


}
