import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iNominas } from 'src/app/Shared/Interfaces/Api/iNominas';

@Injectable({
  providedIn: 'root',
})
export class NominasService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultFilterNominas = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    super();
  }

  getResultFilterNominas() {
    return this._resultFilterNominas;
  }
  clearResultFilterNominas() {
    this._resultFilterNominas.next(null);
  }

  getAll() {
    this.http.get(this.urlApi + '/api/nominas', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedNominas = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iNominas.convertFromCollection(normalizedNominas),
        });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultError', this.res, {
          method: this.getAll,
          args: [],
        });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/nominas/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedNominas = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iNominas.convertFromObject(normalizedNominas),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(nominas: iNominas) {
    this.http
      .post(this.urlApi + '/api/nominas', nominas, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [nominas],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(nominas: iNominas) {
    this.http
      .put(
        this.urlApi + '/api/nominas/' + nominas.id,
        nominas,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [nominas],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'nominas_ids');
    this.http
      .delete(this.urlApi + '/api/nominas', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }

  // Llamadas especificas
  getNominasYearFilters() {
    this.http
      .get(this.urlApi + '/api/nominas/dates', this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalized = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalized,
          });
          this.sendNextObservable(this._resultFilterNominas, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultFilterNominas, this.res, true, {
            method: this.getNominasYearFilters,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
}
