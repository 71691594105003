import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDataTableOptions, iDate, iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { takeUntil } from 'rxjs';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';


@Component({
  selector: 'app-NuevoParteOperarioPopup',
  templateUrl: './NuevoParteOperarioPopup.component.html',
  styleUrls: ['./NuevoParteOperarioPopup.component.scss']
})
export class NuevoParteOperarioPopupComponent extends iUnsubscribeDestroy implements OnInit {

  isLinear = false;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  step: number = 0;
  nObra: string = "Selecciona una obra"
  checkboxAusente: boolean = false
  direccion: any = ""
  tarea: any = null
  moreThan8Hours: boolean = false
  isloading: boolean = false
  datosEmpleado: any = {
    nombre: "",
    apellidos: "",
    fecha: "",
  }
  fileToUpload!: File;
  FinishedFile: any = [];

  objectToPass: any = {
    usuario: "",
    obra: "",
    horaInicio: "",
    fecha: this.modalData.fecha,
    horaFin: "",
    horas: 8,
    cargadoBBDD: false,
    tarea: "",
    comentario: "",
    imagenParte: [],
    estado: 'pendiente',
    nombre: `Parte de ${this.modalData.data.nombre} ${this.modalData.data.apellidos}`
  }


  horaInicio: string = "08:00"
  horaFin: string = "16:00"
  segundos!: number;

  imagen!: any;
  imagen2!: any;

  optionDireccion: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Seleccionar',
    multiple: {
      isMultiple: false,
      checkbox: true
    },
    search: true
  }

  optionTarea: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: 'Seleccionar',
    multiple: {
      isMultiple: false,
      checkbox: true
    },
    search: true
  }

  optionVehiculo: iOptionsSelector = {
    items: [],
    clearable: false,
    bindValue: 'id',
    placeholder: 'Seleccionar',
    multiple: {
      isMultiple: false,
      checkbox: true
    },
    search: true
  }



  constructor(public dialogRef: MatDialogRef<NuevoParteOperarioPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private obrasSE: ObraService, private departamentoSE: DepartamentosService, private notificationSE: NotificationsService, private parteSE: ParteService, private vehiculoSE:VehiculoService) {
    super();
  }

  ngOnInit() {
    this.getDepartamentos()
    this.getVehiculos()
    this.setter()
  }

  async addImage2(event: any) {
    const filesToUpload = event.target.files;
    const filePromises = Array.from(filesToUpload).map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const fileType = file.type;
          const createObjectFile: any = {
            base64: `data:${fileType};base64,${reader?.result?.toString().split(',')[1]}`,
            file: file
          };
          resolve(createObjectFile);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });
    const results = await Promise.all(filePromises);
    this.imagen = 'test'
    this.FinishedFile.push(...results);
    this.addImage(this.FinishedFile);
    this.FinishedFile = [];
  }
  




  /**
   * CLOSE
   */

  forceClosePopup() {
    this.dialogRef.close();
  }
  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  /**
   * CHANGE 
   */

  changeDireccion(event: any) {
    this.nObra = event.nObra
    this.objectToPass.obra = event.id
  }

  changeTime(event: any) {
    let hora = this.datosEmpleado.fecha.split("-");
    //sacar la hora de this.horaInicio
    let horaNumber = parseInt(this.horaInicio.split(":")[0]);

    if( horaNumber < 8){
      this.objectToPass.horas = 'La hora de inicio no puede ser menor que las 8:00';
      this.notificationSE.showWarning("La hora de inicio no puede ser menor que las 8:00");
      return
    }
    let newHora = hora[2] + "/" + hora[1] + "/" + hora[0]
    let horaInicial: any = new Date(newHora + " " + this.horaInicio);
    let horafinal: any = new Date(newHora + " " + this.horaFin)
    let segundos = (horafinal - horaInicial) / 1000;
    this.segundos = segundos
    if (segundos < 0) { this.objectToPass.horas = 'La hora de inicio no puede ser mayor que la hora de fin'; return }
    this.objectToPass.horas = this.takeHoursAndMinutes(segundos)
    if (this.segundos > 28800) {
      this.objectToPass.horas = 'La jornada no puede ser mayor de 8 horas';
      this.notificationSE.showWarning("La jornada no puede ser mayor de 8 horas")
      this.moreThan8Hours = true
      return
    }
    this.moreThan8Hours = false
  }

  changeTarea(event: any) {
    this.objectToPass.tarea = event.label
  }

  takeHoursAndMinutes(segundos: number): string {
    const minutos = Math.floor(segundos / 60)
    const horas = Math.floor(minutos / 60)
    const minutos_restantes = minutos - (horas * 60)
    return (horas + ' horas, ' + minutos_restantes + ' minutos')
  }

  /**
   * ADD IMAGE
   */

  addImage(event: any) {
    console.log(event);
    if (event.some((e:any) => e.file.type != "image/jpeg" && e.file.type != "image/png")) {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.imagen = null;
  } else {
      this.isloading = true
      event.forEach((element: any) => {
          let obj = {
              url: element.base64
          }
          if (this.objectToPass.imagenParte == null) {
              this.objectToPass.imagenParte = [obj];
          } else {
              this.objectToPass.imagenParte.push(obj);
          }
      });
      this.isloading = false
  }  }


  /**
   * SET STEP
   */

  setStep(number: number) {
    this.step = number
  }

  /**
   * MOVE STEP
   */

  moveForward() {
    if (this.step !== 2) {
      this.step++
    }
  }

  moveBack() {
    if (this.step !== 0) {
      this.step--
    }
  }

  /**
   * SUBMIT
   */

  submit() {
    this.objectToPass.horaInicio = this.horaInicio;
    this.objectToPass.horaFin = this.horaFin;
    (this.segundos) ? this.objectToPass.horas = Math.round(this.segundos / 60 / 60) : this.objectToPass.horas = 8
    if (this.checkboxAusente) {
      let objectAusente = {
        usuario: this.objectToPass.usuario,
        fecha: this.objectToPass.fecha,
        obra: "",
        horaInicio: this.datosEmpleado.fecha,
        horaFin: this.datosEmpleado.fecha,
        horas: "0",
        cargadoBBDD: false,
        tarea: "Ausente",
        comentario: "Ausente",
        imagenParte: [],
        estado: 'pendiente',
        nombre: `Parte de ausencia de ${this.modalData.data.nombre} ${this.modalData.data.apellidos}`
      }
      this.parteSE.create(objectAusente)
    } else {
      this.parteSE.create(this.objectToPass)
    }
    this.parteSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return }
      this.notificationSE.showFeedBack("Parte creado correctamente")
      this.closePopup('submitted')
      this._unsubInd.next("")
    })
    this.parteSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      this.notificationSE.showError(res.message)
      this._unsub.next("")
    })
  }

  setter() {
    this.objectToPass.usuario = this.modalData?.data?.id
    this.datosEmpleado.nombre = this.modalData?.data?.nombre
    this.datosEmpleado.apellidos = this.modalData?.data?.apellidos
    this.datosEmpleado.fecha = this.modalData?.fecha
    this.optionDireccion.items = this.modalData?.data?.obras.map((item: any) => { return { id: item.id, label: item.ubicacion, nObra: item.numero } })
    this.direccion = {
      id: this.modalData?.data?.partes[this.modalData?.data?.partes.length - 1].obra?.id,
      label: this.modalData?.data?.partes[this.modalData?.data?.partes.length - 1].obra?.ubicacion,
      nObra: this.modalData?.data?.partes[this.modalData?.data?.partes.length - 1].obra?.numero,
    }
    this.nObra = this.modalData?.data?.partes[this.modalData?.data?.partes.length - 1].obra?.numero
    this.objectToPass.obra = this.modalData?.data?.partes[this.modalData?.data?.partes.length - 1].obra?.id

  }


  /**
   * API CALLS
   */


  getDepartamentos() {
    this.departamentoSE.getAll();
    this.departamentoSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return }
      let { data } = res;
      let mappedDepartamentos = data.map((item: any) => { return { id: item.id, label: item.nombre } })
      this.optionTarea.items = mappedDepartamentos
      this._unsubInd2.next("")
    })
  }

  getVehiculos(){
    this.vehiculoSE.getAll();
    this.vehiculoSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return }
      let { data } = res;
      let mappedVehiculos = data.map((item: any) => { return { id: item.id, label: item.nombre } })
      this.optionVehiculo.items = mappedVehiculos
      this._unsubInd3.next("")
    })
  }

}
