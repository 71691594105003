<div class="partesBlock">
  <filterActions (addNew)="nuvoParteObra()" [placeholderAdd]="'AÑADIR NUEVO'" >
    <ng-container filterContent>
      <div class="containerSelectores">
        <div class="redactor">
          <p>Redactor</p>
          <selector [options]="optionsRedactor" (change)="changeSelector($event, 'redactor','label')"></selector>
        </div>
        <div class="desde">
          <p>Desde</p>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'desde')"></DatePicker>
        </div>
        <div class="hasta">
          <p>Hasta</p>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'hasta')"></DatePicker>
        </div>
      </div>
    </ng-container>
  </filterActions>

  <pill class="tablePil" classList="tablePill" >
    <dataTable [isLoading]="isLoadingTabla" [data]="dataPartesTabla" [displayedColumns]="displayedColumnsPartes" [options]="optionsPartes" (clickRow)="abrirPopupDescarga($event)" (see)="abrirPopupDescarga($event)" (delete)="eliminarParteObra($event)" (deleteSelected)="deleteSelected($event)" (edit)="editarParteObra($event)"></dataTable>
  </pill>


</div>