import { Component, EventEmitter, Input, OnInit,  Output,  SimpleChanges } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { DocumentoRequeridosService } from 'src/app/Services/Api/DocumentoRequeridos.service';
import { takeUntil } from 'rxjs';
import { AddDocumentoRequeridoSolicitadoPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/AddDocumentoRequeridoSolicitadoPopup/AddDocumentoRequeridoSolicitadoPopup.component';
import { EditDocPendienteDeFirmaComponent } from 'src/app/Popups/EditPopup/EditDocPendienteDeFirma/EditDocPendienteDeFirma.component';

@Component({
  selector: 'ProveedoresDocumentosPendientes',
  templateUrl: './ProveedoresDocumentosPendientes.component.html',
  styleUrls: ['./ProveedoresDocumentosPendientes.component.scss']
})
export class ProveedoresDocumentosPendientesComponent extends iUnsubscribeDestroy implements OnInit {

  @Input('pendienteDeFirma') pendienteDeFirma:any = [] 
  @Output() refreshData = new EventEmitter<any>();


  isLoadingTabla: boolean = false;
  dataDocumentos: any = []
  userId!: number;

  //Tabla
  displayedColumnsDocumentos: Array<iDataTableColumns> = [
    { label: 'Tipo de doc.', property: 'nombre' },
  ];

  displayedColumnsFirma: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
    { label: 'Fecha', property: 'fecha' },
    { label: 'Fecha Caducidad', property: 'fechaCaducidad' },
    { label: 'Estado', property: 'estado', classList: 'tableEstado tableEstadoDocumentos' },
  ];

  optionsDocumentos: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsRow: [
      { icon: 'note_add', callback: (event) => {this.createDocumentoRequerido(event) } }
    ]
  }
  optionsFirma: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    canEdit: true,
  }

  constructor(private popup: PopupService, private documentoRequeridoSE: DocumentoRequeridosService) {
    super();
  }

  ngOnInit() {
    this.getUserLocalStorage()
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['pendienteDeFirma']){
      this.pendienteDeFirma = changes['pendienteDeFirma'].currentValue.map((item:any) => {return{...item, fecha: iDate.javascriptConvert(new Date(item.fecha)).toStringDate('EU'), fechaCaducidad:iDate.javascriptConvert(new Date(item.fechaCaducidad)).toStringDate('EU')}})
    }
  }


  getUserLocalStorage() {
    this.userId = parseInt(localStorage.getItem('nmsr')!)
    this.getDocumentosRequeridos()
  }

  buscador(event: any) {
  }


  /**
 * 
 * SEE DOCUMENTO
 *  
 */

  seeDocumento(event: any) {
    if (event.url[event.url.length - 1] === "f") {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup')
    }
    if (event.url[event.url.length - 1] === "g") {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event)
    }
  }

  /**
   * EDIT FIRMA
   */

  editPendienteDeFirma(event:any){
    this.popup.openPopup(EditDocPendienteDeFirmaComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if(!res){return}
      this.refreshData.emit(true)
      this._unsubInd2.next('')
    })
  }

  /**
   * CREATE DOCUMENTO REQUERIDO
   */

  createDocumentoRequerido(event:any) {
    this.popup.openPopup(AddDocumentoRequeridoSolicitadoPopupComponent, event)
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if(!res){return}
      this.getDocumentosRequeridos()
      this._unsubInd2.next('')
    })
  }

  /**
   * API CALLS
   */

  getDocumentosRequeridos(){
    this.isLoadingTabla= true
    this.documentoRequeridoSE.getDocumentosRequeridosUserId(this.userId)
    this.documentoRequeridoSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if(!res){return}
      let { data } = res.data
      this.dataDocumentos = data
      this.isLoadingTabla= false
      this._unsubInd.next('')
    })
  }




}
