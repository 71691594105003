<sectionLogin-containerLogin [toggleAnim]="animSend" (finishAnim)="finishAnim()">
  <div class="centerContainer">
    <div>
      <h2>¡Bienvenido!</h2>
      <br>
      <CustomInput classList="fullWidth tall" placeholder="Introduce tu DNI" label="DNI" [(ngModel)]="dni"></CustomInput>
      <div class="separatorInput"></div>
      <CustomInput classList="fullWidth tall" placeholder="Contraseña" type="password" label="Contraseña" [(ngModel)]="password">
        <ng-container extraLabelContent>
          <a class="lostPassword" routerAnimationLeft [routerLink]="['/forgotPass']" >¿Has olvidado tu contraseña?</a>
        </ng-container>
      </CustomInput>
      <div class="separatorInput"></div>
      <btn classList="fullWidth" (click)="checkLogin()">iniciar sesión</btn>
      <br>
    </div>
  </div>
  
  <ng-container downContent>
    <div class="termsContainer">
      <!-- <span>Terminos&Condiciones</span>
      <span>Política de privacidad</span> -->
    </div>
  </ng-container>
</sectionLogin-containerLogin>