import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';


@Component({
  selector: 'app-NuevoClientePopup',
  templateUrl: './NuevoClientePopup.component.html',
  styleUrls: ['./NuevoClientePopup.component.scss']
})
export class NuevoClientePopupComponent extends iUnsubscribeDestroy implements OnInit {
  public get modalData(): any {
    return this._modalData;
  }
  public set modalData(value: any) {
    this._modalData = value;
  }

  // Inputs

  nombre:string ="";
  mail:string ="";
  telefono:string ="";

  constructor(private notificationSE:NotificationsService ,private clienteSE:ClienteService ,public dialogRef: MatDialogRef<NuevoClientePopupComponent>, @Inject(MAT_DIALOG_DATA) private _modalData: any) {
    super();
   }

  ngOnInit() {
  }

    // Close

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  submitNuevoCliente(){
    if(this.nombre == "" ){return}

    let objectToPass = {
      nombre:this.nombre,
  }

  this.clienteSE.create(objectToPass);
  this.clienteSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
    if(res===null){return}
     
    if(res.status === 200){
      let p = { dataReturn: "submitted" };
      this.notificationSE.showFeedBack("Cliente creado correctamente");
      this.dialogRef.close(p);
    }
    this._unsubInd.next('');
  })
  this.clienteSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res=>{
    if(res === null){return}
    this.notificationSE.showError(res.message);
    this._unsub.next('')
  })



  }



}
