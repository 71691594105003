import { Component, OnInit, ViewChild, Renderer2, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { iDate, iUnsubscribeDestroy, LoaderService } from '@quasar_dynamics/basic-designsystem';
import { Moment } from 'moment';
import { takeUntil } from 'rxjs';
import { ParteService } from 'src/app/Services/Api/Parte.service';


@Component({
  selector: 'MobilCalendar',
  templateUrl: './MobilCalendar.component.html',
  styleUrls: ['./MobilCalendar.component.scss']
})
export class MobilCalendarComponent extends iUnsubscribeDestroy implements OnInit {

  @ViewChild('calendario', { static: false }) calendar!: MatCalendar<Moment>;

  @Output('fechaSelected') fechaSelected = new EventEmitter<string>();
  @Output('monthSelected') monthSelected = new EventEmitter<number>();
  @Output('yearSelected') yearSelected = new EventEmitter<number>();
  @Output('parteDays') parteDays = new EventEmitter<number>();

  @Input('userId') userId: number | null = null;
  @Input('reloadFlag') reloadFlag: boolean = false;

  idUser:number = 0;
  selectedDate: any = null
  datas: any = null
  dataToCalendar: any = []
  id!: number
  actualMonth: any = new Date().getMonth() +1
  actualYear: number = new Date().getFullYear()
  flag:boolean = false
  startDate = new Date(`${this.actualYear}/${this.actualMonth}/01`)

  constructor(private renderer: Renderer2, private parteSE: ParteService, private loader: LoaderService) {
    super();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] !== undefined && changes['userId'].currentValue != undefined && changes['userId'].currentValue != null) {
      this.id = changes['userId'].currentValue
      this.partesCalendar(changes['userId'].currentValue)
    }
    if (changes['reloadFlag'] !== undefined && changes['reloadFlag'].currentValue != undefined && changes['reloadFlag'].currentValue != null) {
      if(changes['reloadFlag'].currentValue === true){
        this.flag = true;
        this.partesCalendar(this.id)
      }
    }
  }

  onSelect(event: any) {
    this.fechaSelected.emit(event)
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      return this.datas.map((d: any) => {
        if (iDate.javascriptConvert(new Date(d)).toStringDate('JAP') === iDate.javascriptConvert(date).toStringDate('JAP')) {
          return 'special-date';
        } else {
          return '';
        }
      })
    };
  }

  /**
   * FUNCTIONALITY
   */

  prevMonth() {
    document.querySelectorAll(".mat-calendar-previous-button").forEach(element => {
      (element as HTMLElement).click();
    })
    this.flag = true;
    this.actualMonth--
    if (this.actualMonth === 0) {
      this.actualMonth = 12;
      this.actualYear--
    }
    this.startDate = new Date(`${this.actualYear}/${this.actualMonth}/01`)
    this.partesCalendar(this.id)
     
     
     
  }

  nextMonth() {
    document.querySelectorAll(".mat-calendar-next-button").forEach(element => {
      (element as HTMLElement).click();
    })
    this.flag = true;
    
    this.actualMonth++
    if (this.actualMonth === 13) {
      this.actualMonth = 1;
      this.actualYear++
    }
    this.startDate = new Date(`${this.actualYear}/${this.actualMonth}/01`)
    this.partesCalendar(this.id)
     
     
     
  }

  /**
   * API CALLS
   */

  partesCalendar(id: number,) {

    this.loader.open()
    this.parteSE.getAllPartesUserMes(this.actualMonth , this.actualYear, id);
    this.parteSE.getResultCalendario().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return }
      let { data } = res
        let mappedDays = data.map((d: any) => d.fecha)
        this.datas = mappedDays
        this.parteDays.emit(mappedDays)
        this.flag = false;
        this.dateClass();
      this.loader.close()
      this._unsubInd3.next("")
    })
  }
}
