<div class=" maxHeight">
  <div class="closePopUpIcon" (click)="forceClosePopup('modified')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">PARTES FALTANTES</h3>
    </div>
    <div class="popupBody">

      <div class="parte" *ngFor="let name of names">
        <p class="parteNombre">{{name.nombre}}</p>
        <btn (click)="openNewPartePopup(name.id, name.nombre)">Crear Parte</btn>
      </div>

    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>