<div class="fullPopup">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="mainBlock">
    <div class="header">
      <p>EDITAR PARTE</p>
    </div>
    <div class="contentBlock">

      <div class="lineContent">
        <p>Nombre</p>
        <div class="content">
          <CustomInput placeholder="nombre" [(ngModel)]="nombre" (change)="getFromInput('nombre', nombre)">
          </CustomInput>
        </div>
      </div>

      <div class="lineContent">
        <p>Hora inicio</p>
        <div class="content">
          <CustomInput [type]="'time'" [maxlength]="5" placeholder="entrada" [(ngModel)]="entrada"
            (change)="getFromInput('horaInicio', entrada)"></CustomInput>
        </div>
      </div>


      <div class="lineContent">
        <p>Hora Fin</p>
        <div class="content">
          <CustomInput [type]="'time'" [maxlength]="5" placeholder="entrada" [(ngModel)]="salida" (change)="getFromInput('horaFin', salida)"></CustomInput>
        </div>
      </div>

      <div class="lineContent">
        <p>Imagen</p>
        <div class="content">
          <button type="button" class="generalbutton" fileInput [multiple]="true" [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="imagen"
          (change)="addImagen(imagen)">Añadir Imagen</button>
        </div>
      </div>

    </div>

    <div class="popupButtons">
      <btn classList="contactButton" class="pb-1" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>


  </div>
</div>