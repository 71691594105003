<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR DOCUMENTO REQUERIDO </h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">        
        <!-- <div class="inputForm">
          <label>Título</label>
          <CustomInput placeholder="Título" [(ngModel)]="objectToPass.titulo">
          </CustomInput>
        </div> -->
        <div class="inputForm">
          <label>Documento</label>
          <ng-container *ngIf="documento">
            <mat-icon class="green">check_circle</mat-icon>
          </ng-container>
          <ng-container *ngIf="!documento && isloading">
            <mat-icon class="yellow">refresh</mat-icon>
          </ng-container>
          <button class="generalbutton" type="button" fileInput [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="documento"
          (change)="addDocumento(documento)">Añadir Documento</button>
        </div>
        <div class="inputForm">
          <label>Fecha de emisión</label>
          <DatePicker [dataPlaceholder]="objectToPass.fecha" (dataFromPicker)="dataFromPicker($event, 'fecha')"></DatePicker>
        </div>
        <div class="inputForm">
          <label>Fecha de caducidad</label>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaCaducidad')"></DatePicker>
        </div>
        <div class="inputFormEdit">
          <label>Notas</label>
          <CustomTextArea [(ngModel)]="objectToPass.comentario"></CustomTextArea>
        </div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="submitNuevoDocumento()">
        <ng-container>
          <span>Añadir Documento Requerido</span>
        </ng-container>
      </btn>
    </div>
  </div>