<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVO PARTE</h3>
    </div>
    <div class="popupBody" *ngIf="!checkboxAusente">
      <div class="formGrid">
        <div class="inputForm" *ngIf="fechaFromCalendar == false">
          <label>Fecha</label>
          <DatePicker (dataFromPicker)="getDataFromFecha($event)" [dataPlaceholder]="fecha">
          </DatePicker>
        </div>

        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="nombre" (change)="getDataFromInput('nombre')">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Hora inicio</label>
          <CustomInput [type]="'time'" [maxlength]="5" placeholder="inicio" [(ngModel)]="inicio" (change)="getDataFromInput(inicio)"></CustomInput>
        </div>

        <div class="inputForm">
          <label>Hora fin</label>
          <CustomInput [type]="'time'" [maxlength]="5" placeholder="salida" [(ngModel)]="salida" (change)="getDataFromInput(salida)"></CustomInput>
        </div>

        <div class="inputForm" *ngIf="this.modalData?.tipo == 'nuevo'">
          <label>Estado</label>
          <selector [options]="optionEstado" (change)="obtenerEstado($event)" classList="obras"></selector>
        </div>
        
        <div class="inputForm" *ngIf="this.modalData?.tipo == 'nuevo'">
          <label>Usuario</label>
          <selector [options]="optionUsuario" (change)="obtenerUsuario($event)" classList="obras"></selector>
        </div>
        
        <div class="inputForm">
          <label>Vehiculo</label>
          <selector [options]="optionVehiculo" (change)="obtenerVehiculo($event)" classList="obras"></selector>
        </div>
        
        <div class="inputForm" *ngIf="!modalData.obraId">
          <label>Obra</label>
          <selector [options]="optionObra" (change)="obtenerObra($event)" classList="obras"></selector>
        </div>

        <div class="inputForm">
          <label>Tarea</label>
          <selector [options]="optionTarea" (change)="obtenerTarea($event)" classList="obras"></selector>
        </div>

        <div class="inputForm">
          <label>Comentario</label>
          <CustomInput placeholder="Comentario" [(ngModel)]="comentario" (change)="getDataFromInput('comentario')">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Imagen Parte</label>
          <button type="button" class="generalbutton" fileInput [multiple]="true" [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="imagen"
          (change)="addImagen(imagen)">Añadir Imagen</button>
          <ng-container *ngIf="imagen">
            <mat-icon class="green">check_circle</mat-icon>
          </ng-container>
          <ng-container *ngIf="!imagen && isloading">
            <mat-icon class="yellow">refresh</mat-icon>
          </ng-container>

        </div>

        
        
        
      </div>
      <br>
      <hr *ngIf="!modalData?.admin"><br>
    </div>
    <div class="inputForm" *ngIf="!modalData?.admin">
      <label>Ausente</label>
      <p><mat-checkbox [(ngModel)]="checkboxAusente">He estado ausente</mat-checkbox></p>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevoParte()">Añadir Nuevo Parte</btn>
    </div>
  </div>
</div>