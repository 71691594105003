<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">{{modalAddEditar}} DOCUMENTOS</h3>
    </div>

    <div class="flexDivider">
      <div *ngIf="tipo === 'DNIAnverso' ||tipo === 'DNIReverso' ">
        <div class="documentTitle">
          <label>DNI</label>
          <div class="caducidadPicker" *ngIf="tipo === 'DNIAnverso' ">
            <DatePicker (dataFromPicker)="getDataFromDNICaducidad($event)">
            </DatePicker>
          </div>
        </div>
        <div class="buttonsSubirImages" *ngIf="tipo === 'DNIAnverso'">
          <div class="dniAnverso" >
            <p class="textCenter">Delante</p>
            <button fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="imagen"
              (change)="addImageDNIAnverso(imagen)" class="buttonSubir">
              <ng-container>
                <mat-icon>upload</mat-icon>
              </ng-container>
              <p>¡Sube tu fichero!</p>
            </button>
          </div>
        </div>
        <div class="buttonsSubirImages" *ngIf="tipo === 'DNIReverso'">
          <div class="dniAnverso" >
            <p class="textCenter">Detrás</p>
            <button fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="imagen"
              (change)="addImageDNIReverso(imagen)" class="buttonSubir">
              <ng-container>
                <mat-icon>upload</mat-icon>
              </ng-container>
              <p>¡Sube tu fichero!</p>
            </button>
          </div>
        </div>
        
      </div>
      <div *ngIf="tipo === 'carnetAnverso' || tipo === 'carnetReverso'">
        <div class="documentTitle">
          <label>Carnet de conducir</label>
          <div class="caducidadPicker" *ngIf="tipo === 'carnetAnverso' ">
            <DatePicker (dataFromPicker)="getDataFromCarnetDeConducirCaducidad($event)">
            </DatePicker>
          </div>
        </div>
        <div class="buttonsSubirImages flex-end" *ngIf="tipo === 'carnetAnverso'">

          <div class="dniAnverso" >
            <p class="textCenter">Delante</p>
            <button fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="imagen"
              (change)="addImageConducirAnverso(imagen)" class="buttonSubir">
              <ng-container>
                <mat-icon>upload</mat-icon>
              </ng-container>
              <p>¡Sube tu fichero!</p>
            </button>
          </div>
        </div>
        <div class="buttonsSubirImages flex-end" *ngIf="tipo === 'carnetReverso'">
          <div class="dniAnverso" >
            <p class="textCenter">Detrás</p>
            <button fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="imagen"
              (change)="addImageConducirReverso(imagen)" class="buttonSubir">
              <ng-container>
                <mat-icon>upload</mat-icon>
              </ng-container>
              <p>¡Sube tu fichero!</p>
            </button>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>