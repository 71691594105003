<!-- <div class="popupGeneralContainer">
  <div class="header" (click)="forceClosePopup()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="body">
    <p class=" text-center title">Seleccione las fechas en las que desea bloquear el alojamiento</p>
    <div class="datePickerContainer">
      <div class="picker">
        <button>Días</button>
        
      </div>
    </div> -->
<div class="globalPopupContainer boxInsider widthPopup">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">

    <div class="textoFechaVacaciones">
      <p>Elija un periodo de vacaciones</p>
    </div>

    <CalendarPicker classList="fullWidth" (rangoSeleccionado)="getDates($event)" *ngIf="calendarActive == 0">
    </CalendarPicker>

    <div class="buttons">
      <btn classList="secundario" (click)="closePopup(false)">Cancelar</btn>
      <btn (click)="closePopup(dates)">Aceptar</btn>
    </div>


  </div>

</div>

<!-- </div> -->

<!-- <div class="footer">
    <btn classList="principalButton" (click)="forceClosePopup()" *ngIf="this.modalData.periodo != true">
      <ng-container >
        Cancelar
      </ng-container>
    </btn>
    <btn classList="principalButton" (click)="closePopup(dates, motivoDelBloqueo)" *ngIf="motivoDelBloqueo !== ''">
      <ng-container >
        Aceptar
      </ng-container>
    </btn>
    <btn classList="disabled" (click)="alertInput()" *ngIf="motivoDelBloqueo === ''">
      <ng-container >
        Aceptar
      </ng-container>
    </btn>
  </div> -->
<!-- </div> -->