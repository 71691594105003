import { Component, OnInit } from '@angular/core';
import { iDate, iOptionsSelector, iStaticUtilities, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { UserService } from 'src/app/Services/Api/User.service';
import { takeUntil } from 'rxjs';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { NominasService } from 'src/app/Services/Api/Nominas.service';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';

@Component({
  selector: 'app-AdminEmpleados',
  templateUrl: './AdminEmpleados.component.html',
  styleUrls: ['./AdminEmpleados.component.scss']
})
export class AdminEmpleadosComponent extends iUnsubscribeDestroy implements OnInit {

  submenuNavegation = [
    'LISTA',
    'TAREAS',
    'NÓMINAS',
    // 'PARTES'
  ];
  submenuSel = 0;
  optionYear: iOptionsSelector = {
    bindLabel: 'anyo',
    items: [
      2020,
      2021,
      2022,
      2023
    ],
    clearable: false,
    placeholder: 'Año'
  }
  optionsMonth: iOptionsSelector = {
    bindLabel: 'labelMes',
    items: [
      { id: 1, label: 'Enero' },
      { id: 2, label: 'Febrero' },
      { id: 3, label: 'Marzo' },
      { id: 4, label: 'Abril' },
      { id: 5, label: 'Mayo' },
      { id: 6, label: 'Junio' },
      { id: 7, label: 'Julio' },
      { id: 8, label: 'Agosto' },
      { id: 9, label: 'Septiembre' },
      { id: 10, label: 'Octubre' },
      { id: 11, label: 'Noviembre' },
      { id: 12, label: 'Diciembre' }
    ],

    clearable: false,
    placeholder: 'Mes'
  }

  rawYearsMonth: any[] = [];

  isLoadingLista = false;
  isLoadingNominas = true;
  isLoadingPartes = true;
  isLoadingDepartamentos = true;

  yearSel: any = new Date().getFullYear();
  monthSel: any = new Date().getMonth() ;
  filterNominas: string = "";

  empleadosTableData: any[] = [];
  nominasTableData: any[] = [];
  partesTableData: any[] = [];
  departamentosTableData: any[] = [];

  // Data para los filtros
  allUsers: any[] = [];
  allVehicles: any[] = [];
  allObras: any[] = [];

  allEstados: any[] = [
    { id: "pendiente", label: "Pendiente" },
    { id: "validado", label: "Validado" },
  ];

  tipoUsuario: any[] = []

  allRoles: any[] = [
    { id: "ROLE_USER", label: "Operario" },
    { id: "ROLE_ADMIN", label: "Administrador" },
    { id: "ROLE_TECNICO", label: "Técnico" },
    { id: "ROLE_JEFE_OBRA", label: "Jefe de obra" }
  ];

  constructor(public usuarioSe: UserService, public vehiculoSe: VehiculoService, public obrasSe: ObraService, public nominasSe: NominasService, public departamentosSe: DepartamentosService) {
    super()
  }

  ngOnInit() {
    let date = iDate.javascriptConvert(new Date());
    this.yearSel = date.year;
    this.isLoadingLista = true;

    this.usuarioSe.getEmpleados(1);
    this.usuarioSe.getResultEmpleados().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }

      let { data, total } = res.data;
      let empleadosPush = data.map((e: any) => {
        let appendOb: any = e;
        appendOb.fechaAlta = iDate.phpConvert(appendOb?.fechaAlta);
        appendOb.typeUser = this.convertRoleToString(appendOb?.usuario[0]);
        if(appendOb?.mail == null) {appendOb.mail = "-";}
        if(appendOb?.telefono == null) {appendOb.telefono = "-";}
        appendOb.contacto = appendOb?.mail + "\n" + appendOb?.telefono ;
        if (appendOb.proyectos == null) { appendOb.proyectos = "-"; }
        if (appendOb.talla == null) {
          appendOb.talla = "-";
        } else {
          appendOb.talla = appendOb.talla.split(",").map((e: any) => e.trim()).join("\n");
        }
        if (appendOb.tiempo == null) { appendOb.tiempo = "-"; }
        appendOb.alertas = "-";
        (appendOb.bloqueado === true) ? appendOb.bloqueo = "Bloqueado" : appendOb.bloqueo = "Activo";
        return appendOb;
      })
      this.empleadosTableData = empleadosPush;
      setTimeout(() => {
        this.isLoadingLista = false;
        
      }, 1);
      this._unsubInd.next('')
    })
    
    if(Object.keys(this.monthSel).length > 0){
      this.monthSel = this.monthSel?.id;
    }

    this.usuarioSe.getEmpleadosNominas(1, this.yearSel, this.monthSel);
    this.usuarioSe.getResultEmpleadosNominas().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (res == null) { return; }
       ;
      this.nominasTableData = res.data
      this.isLoadingNominas = false;
      this._unsubInd2.next('')
    })

    this.usuarioSe.getEmpleadosPartes(1,50000);
    this.usuarioSe.getResultEmpleadosPartes().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (res == null) { return; };
      let {data, total} = res.data;
      this.partesTableData = data.map((e: any) => {
        let obAppend = e;
        obAppend.date = iDate.phpConvert(e?.fecha) ?? '';
        obAppend.horario = obAppend?.horario.split("-").map((e: any) => {
          let time = e?.split(":");
          return time[0] + ":" + time[1];
        }).join(" - ") ?? '';
        if (obAppend?.nombre == null) { obAppend.nombre = "-"; }
        if (obAppend?.url == null) { obAppend.url = "-"; }
        return obAppend;
      })
      this.isLoadingPartes = false;
      this._unsubInd3.next('')
    })

    this.departamentosSe.getAll();
    this.departamentosSe.getResult().pipe(takeUntil(this._unsubInd4)).subscribe((res: any) => {
      if (res == null) { return; }
       ;
      this.departamentosTableData = res["data"].map((e: any) => {
        let obSend = e;
        obSend.nombreEncargado = obSend.encargado.nombre + " " + obSend.encargado.apellidos;
        obSend.nempleados = obSend.users.length;
        return obSend;
      })
      this.isLoadingDepartamentos = false;
      this._unsubInd4.next('')
    })

    // PARA LOS FILTROS

    this.usuarioSe.getAll();
    this.usuarioSe.getResultAll().pipe(takeUntil(this._unsubInd5)).subscribe((res: any) => {
      if (res == null) { return }
      this.allUsers = res["data"];
      this._unsubInd5.next('')
    })

    this.vehiculoSe.getAll();
    this.vehiculoSe.getResult().pipe(takeUntil(this._unsubInd6)).subscribe((res: any) => {
      if (res == null) { return }
      this.allVehicles = res["data"];
      this._unsubInd6.next('')
    })

    this.obrasSe.getAll();
    this.obrasSe.getResult().pipe(takeUntil(this._unsubInd7)).subscribe((res: any) => {
      if (res == null) { return }
      this.allObras = res["data"]?.map((e: any) => {
        let obSend = e;
        obSend.nombreObra = obSend.numero  + " " + obSend.ubicacion + " - " + obSend.cliente.nombre;
        return obSend;
      });
      this._unsubInd7.next('')
    })

    this.nominasSe.getNominasYearFilters();
    this.nominasSe.getResultFilterNominas().pipe(takeUntil(this._unsubInd8)).subscribe(res => {
      if (res == null) { return; }
      let years: any[] = [];
      let app = res["data"]?.map((e: any) => {
        let obAppend = e;
        e.labelMes = this.getLabelMonth(e.mes);
        let existsYear = years.includes(obAppend.anyo);
        if (!existsYear) {
          years.push(obAppend.anyo);
        }
        return obAppend;
      })
      this.rawYearsMonth = app;
      this.optionYear.items = years;
      this.monthSel = {anyo:this.yearSel ,mes:new Date().getMonth() , labelMes:this.getLabelMonth(new Date().getMonth() )};
      this.optionsMonth.items = this.rawYearsMonth.filter(e => parseInt(e.anyo) == parseInt(this.yearSel));
      this._unsubInd8.next('')
    })
  }

  getLabelMonth(month: number) {
    let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return months[month - 1];
  }

  updateYear() {
    this.optionsMonth.items = this.rawYearsMonth.filter(e => parseInt(e.anyo) == parseInt(this.yearSel));
    this.updateMonth();
  }

  updateMonth() {
    this.isLoadingNominas = true;
    let mes = (this.monthSel != null) ? this.monthSel.mes : null;
    if (this.filterNominas != null && this.filterNominas != "") {
      this.usuarioSe.getEmpleadosNominas(1, this.yearSel, mes, { busqueda: this.filterNominas });
      this.usuarioSe.getResultEmpleadosNominas().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
        if (res == null) { return; }
         ;
        this.nominasTableData = res.data
        this.isLoadingNominas = false;
        this._unsubInd2.next('')
      })
  
    } else {
      this.usuarioSe.getEmpleadosNominas(1, this.yearSel, mes);
      this.usuarioSe.getResultEmpleadosNominas().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
        if (res == null) { return; }
         ;
        this.nominasTableData = res.data
        this.isLoadingNominas = false;
        this._unsubInd2.next('')
      })
  
    }
  }

  updateNominasFilter(textUpdate: any) {
    this.filterNominas = textUpdate;
    this.updateMonth();
  }

  refreshMonth(){
    this.updateMonth();
  }

  convertRoleToString(role: string) {
    switch (role) {
      case "ROLE_ADMIN":
        return "Admin"
      case "ROLE_TECNICO":
        return "Técnico"
      case "ROLE_JEFE_OBRA":
        return "Jefe de obra"
        case "ROLE_USER":
          return "Operario"
      default:
        return "Empleado";
    }
  }

  getEmitFilters(event:any){
    this.usuarioSe.getEmpleados(1,event);
    this.usuarioSe.getResultEmpleados().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res == null) { return }

      let { data, total } = res.data;
       ;
      let empleadosPush = data.map((e: any) => {
        let appendOb: any = e;
        appendOb.fechaAlta = iDate.phpConvert(appendOb?.fechaAlta);
        appendOb.typeUser = this.convertRoleToString(appendOb?.usuario[0]);
        appendOb.contacto = appendOb?.contacto?.split(" ").join("\n") ;
        if (appendOb.proyectos == null) { appendOb.proyectos = "-"; }
        if (appendOb.talla == null) {
          appendOb.talla = "-";
        } else {
          appendOb.talla = appendOb.talla.split(",").map((e: any) => e.trim()).join("\n");
        }
        if (appendOb.tiempo == null) { appendOb.tiempo = "-"; }
        appendOb.alertas = "-";
        (appendOb.bloqueado === true) ? appendOb.bloqueo = "Bloqueado" : appendOb.bloqueo = "Activo";
        return appendOb;
      })
      this.empleadosTableData = empleadosPush;
      setTimeout(() => {
        this.isLoadingLista = false;
        
      }, 1);
      this._unsubInd.next('')
    })
  }

  refreshData(event: boolean) {
     
    if (event === true) {
      this.ngOnInit()

    }
  }


}
