<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">INCIDENCIAS: </h3>
    </div>
    <div class="popupBody">

      <filterActions (addNew)="anadirNuevaIncidencia($event)" [disableFilter]="true" [disableSearch]="true">
    </filterActions>
  






      <pill class="tablePil" classList="tablePill">
        <dataTable (deleteAll)="deleteSelected($event)" (deleteSelected)="deleteSelected($event)" [isLoading]="isLoadingTabla" [data]="dataIncidencias" [displayedColumns]="displayedColumnsIncidencias"
          [options]="optionsIncidencias" (delete)="OnclickDeleteButton($event)" (changeSelecteds)="changeSelecteds($event)" (edit)="editIncidencia($event)"></dataTable>
      </pill>
    
      
      
      
      
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Cerrar</span>
        </ng-container>
      </btn>
    </div>
  </div>