<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR NÓMINA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Año</label>
          <selector class="sizeInput" [options]="optionAnyo" (change)="anyoFromSelector($event,'anyo' )"></selector>
        </div>
        <div class="inputForm">
          <label>Mes</label>
          <selector class="sizeInput" [options]="optionMes" (change)="mesFromSelector($event, 'mes')"></selector>
        </div>
        <div class="inputForm">
          <label>Empleado</label>
          <selector class="sizeInput" [options]="optionEmpleado" (change)="empleadoFromSelector($event, 'user')"></selector>
        </div>
        <div class="separatorGrid"></div>
        <br>
        <div class="inputForm">
          <label>Fecha</label>
          <DatePicker (dataFromPicker)="getDataFromFecha($event, 'fecha')" [dataPlaceholder]="fecha"></DatePicker>
        </div>
        <div class="inputForm flexStart">
          <label>Nomina</label>
          <button class="generalbutton" fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="nomina"
            (change)="addNomina(nomina, 'url')">Añadir Nomina</button>
        </div>

        <div class="separatorGrid"></div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>