<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">DOCUMENTOS REQUERIDOS</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Tipo documento</label>
          <selector [options]="optionTipoDeDoc" (change)="changeSelector($event,'tipoDocumento')"></selector>
        </div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevoDocumentoRequerido()">Añadir Documento Requerido</btn>
    </div>
  </div>
</div>