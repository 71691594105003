import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { TipoDocumentoService } from 'src/app/Services/Api/TipoDocumento.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-NuevoTipoDeDocPopup',
  templateUrl: './NuevoTipoDeDocPopup.component.html',
  styleUrls: ['./NuevoTipoDeDocPopup.component.scss']
})
export class NuevoTipoDeDocPopupComponent extends iUnsubscribeDestroy implements OnInit {

  objectToPass: any = {
    nombre: "",
  }

  optionVinculado: iOptionsSelector = {
    items: [
      {id: 1, label: 'Usuario'},
      {id: 2, label: 'Vehículo'},
      {id: 3, label: 'Obra'},
    ],
    clearable: true,
    placeholder: 'Vinculado a...',
    bindValue: 'label',
    multiple: {
      isMultiple: false,
      checkbox: false
    },
    search: true
  }

  constructor(private dialogRef: MatDialogRef<NuevoTipoDeDocPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private tipoDocSE:TipoDocumentoService, private notificationSE:NotificationsService) {
    super();
  }

  ngOnInit() {
  }

  closePopup(dataReturn?:any){
    let p={dataReturn:dataReturn};
    this.dialogRef.close(p);
  }
  forceClosePopup(){
    this.dialogRef.close();
  }

  getVinculado(event:any){
    this.objectToPass['referencia'] = event
  }

  submitNuevoTipoDoc(){
    if(this.objectToPass.nombre == ""){return}
    this.tipoDocSE.create(this.objectToPass)
    this.tipoDocSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      this.notificationSE.showFeedBack('Tipo de documento creado correctamente')
      this.closePopup('submitted')
      this._unsubInd.next('')
    })
    this.tipoDocSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return}
      this.notificationSE.showError(res.message)
      this._unsub.next('')
    })
  }

}
