<FullContainer>
  <h2 class="sectionTitle">alertas</h2>
  <br>
  <div class="alertasContainer">
      <pill classList="alerta" *ngFor="let alerta of alertaData">{{alerta.descripcion}}</pill>
  </div>
  <div class="btnSeparatorContainer">
      <btn classList="bigBtn" (click)="openVerTodoAlertasPopup()" >ver todo</btn>
  </div>
  <br>
  <h2 class="sectionTitle">obras</h2>
  <br>
  <pill classList="tablePill">
      <dataTable [forceUpdateWidth]="forceUpdateWidth" [isLoading]="isLoading"[data]="dataObras" (clickRow)="navigateToPartesObras($event)" [displayedColumns]="displayedColumnsObras" (edit)="editObra($event)"  (delete)="deleteObra($event, 'single')" (deleteAll)="deleteObra($event, 'multiple')" (deleteSelected)="deleteObra($event, 'multipleSelected')" [options]="optionsObras"></dataTable>
  </pill>
  <div class="btnSeparatorContainer">
      <btn classList="bigBtn" [routerLink]="['/jefe/obras']">ver todo</btn>
  </div>

</FullContainer>
