import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { ICryptID } from 'src/app/Shared/Interfaces/iCryptID';

@Component({
  selector: 'app-ProveedorSeeEdit',
  templateUrl: './ProveedorSeeEdit.component.html',
  styleUrls: ['./ProveedorSeeEdit.component.scss']
})
export class ProveedorSeeEditComponent extends iUnsubscribeDestroy implements OnInit {

  idFromUser!: number
  dataFromSingle: any = {}

  submenuNavegation = [
    'DOCUMENTOS',
    'DOCUMENTOS REQUERIDOS',
    'FACTURAS',
  ];
  submenuSel = 0;


  constructor(private router: Router, private route: ActivatedRoute, private proveedorSe: ProveedorObraService, private userSE:UserService) {
    super();
  }

  ngOnInit() {
    this.getProveedorById();
  }
  
  
  getProveedorById(): void {
    this.idFromUser = parseInt(ICryptID.decryptID(this.route.snapshot.paramMap.get("id")))
    this.userSE.getSingle(this.idFromUser);
    this.userSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res;
      this.dataFromSingle = data
      this._unsubInd.next("")
    })
  }

  returnProveedores(): void {
    this.router.navigateByUrl("/admin/proveedores");
  }

  refreshData(event: boolean): void {
    if (event) this.ngOnInit()
  }
}
