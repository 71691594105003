import { Component, OnInit, ViewChild } from '@angular/core';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  LoaderService,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { NuevaObraPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaObraPopup/NuevaObraPopup.component';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { iObra } from 'src/app/Shared/Interfaces/Api/iObra';
import {
  iDataTableColumns,
  iDataTableOptions,
} from 'src/app/Shared/Interfaces/iDataTable';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import * as XLSX from 'xlsx';
import { MapInfoWindow } from '@angular/google-maps';
import { HandlerService } from 'src/app/Services/Utils/Handler.service';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';

@Component({
  selector: 'app-AdminObras',
  templateUrl: './AdminObras.component.html',
  styleUrls: ['./AdminObras.component.scss'],
})
export class AdminObrasComponent extends iUnsubscribeDestroy implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  forceUpdateWidth = new Subject();

  // DATA

  dataObras: Array<iObra> = [];

  // FILTROS

  cliente: string = '';
  estado: string = '';
  busqueda: string = '';
  concepto: string = '';

  // Mapa
  obraToShow: any = {
    concepto: 'Cargando',
    encargadoNombre: 'Cargando',
    ubicacion: 'Cargando',
  };

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  // Cargar

  isLoadingTabla: boolean = true;

  // SUBMENUNAVEGATION

  submenuNavegation = ['LISTA', 'MAPA'];
  submenuSel = 0;

  //#region Lista variables
  isFilterOpen = false;
  isSearchOpen = false;
  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: '',
  };

  // Selectores
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Cliente',
  };
  optionConcepto: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Concepto',
  };
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Activa' },
      { id: 0, label: 'Finalizada' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  //Tabla
  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Nº Obra', property: 'num_obra', align: 'center' },
    { label: 'Proyecto', property: 'concepto' },
    { label: 'Cliente', property: 'cliente' },
    {
      label: 'Ubicación',
      property: 'ubicacion',
      align: 'center',
      linkAction: (rowElem) => {},
    },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  optionsObras: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  //#endregion

  //#region mapa variables
  center = { lat: 39.5, lng: -0.4 };
  zoom = 12;
  markers: Array<any> = [];
  optionsMap: google.maps.MapOptions = {
    center: { lat: 39.5, lng: -0.4 },
    zoom: 12,
    styles: HandlerService.getMapStyle(),
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  };
  styleMap = 'calc(100vh - 78px - 4em - 106px)';
  filterMapActive = '';
  //#endregion

  constructor(
    private notificationSE: NotificationsService,
    private loader: LoaderService,
    private clienteSE: ClienteService,
    private obraSe: ObraService,
    private popup: PopupService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.getObras();
    this.getClientes();
  }

  anadirNuevaObra(event: any) {
    this.popup.openPopup(NuevaObraPopupComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (res === null || res['dataReturn'] !== 'submitted') return;
        this.ngOnInit();
        this._unsubInd5.next('');
      });
  }

  /**
   * InfoWindow
   */

  openInfo(marker: any) {
    let obra = this.dataObras.filter((obra: any) => {
      return obra.id == marker.marker.title;
    });

    this.obraToShow = obra[0];

    this.infoWindow.open(marker);
  }

  /**
   *
   * Abrir Obra
   */
  abrirObra(id: any) {
    this.router.navigate([`admin/obras/` + id]);
  }

  // GETTERS

  getObras(): void {
    this.isLoadingTabla = true;

    this.obraSe.getAllTable(
      this.pagina,
      this.maximo,
      this.busqueda,
      this.cliente,
      this.estado,
      this.concepto
    );
    this.obraSe
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data, total } = res.data;

        let mappedConcepto = data.map((elem: any) => {
          return { id: elem.concepto, label: elem.concepto };
        });
        this.optionConcepto.items = mappedConcepto;
        this.dataObras = data;
        this.setPointers(data);
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;

        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1000);
        this.isLoadingTabla = false;
        this._unsubInd.next('');
      });
  }

  getClientes() {
    this.clienteSE.getAll();
    this.clienteSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        let mappedCliente = data.map((elem: any) => {
          return { id: elem.id, label: elem.nombre };
        });

        this.optionCliente.items = mappedCliente;
        this._unsubInd2.next('');
      });
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getObras();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getObras();
  }

  /**
   * SET POINTERS
   */

  setPointers(data: any) {
    this.markers = [];

    data.map((obra: any, index: number) => {
      let icon =
        obra.estado === 'Activa'
          ? '/assets/Images/MapsMarkers/enviado.png'
          : '/assets/Images/MapsMarkers/pendiente.png';

      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(obra.lan, obra.lon),
        icon: icon,
        title: obra.id.toString(),
      });
      this.markers.push(marker);
    });
  }

  //#region lista functions
  changeFilterOpen(newVal: boolean) {
    this.isFilterOpen = newVal;
    this.updateTableMaxHeight();
  }
  changeSearchOpen(newVal: boolean) {
    this.isSearchOpen = newVal;
    this.updateTableMaxHeight();
  }
  updateTableMaxHeight() {
    let val = '';
    if (this.isFilterOpen) {
      val = val + ' - 68px - 1em';
    }
    if (this.isSearchOpen) {
      val = val + ' - 68px - 1em';
    }
    this.optionsObras.maxHeight =
      'calc(100vh - 78px - 4em - 106px - 47px - 4em' + val + ')';
  }
  //#endregion

  getStyleMapSection() {
    let ob: any = {};
    ob['min-height.px'] = '500';
    ob['height'] = this.styleMap;
    return ob;
  }
  changeFilterMap(type: string) {
    if (type === null) {
      this.estado = '0';
    }
    if (type !== null) {
      this.estado = type;
    }
    this.getObras();
  }
  //#endregion

  openEditPopup(event: any) {
    this.popup.openPopup(EditObrasPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd3.next('');
      });
  }

  /**
   * FILTROS
   */

  respuestaBusqueda(event: any) {
    if (event === null) {
      this.busqueda = '';
    }
    if (event !== null) {
      this.busqueda = event;
    }
    this.getObras();
  }

  getClienteFromSelector(event: any) {
    if (event === null) {
      this.cliente = '';
    }
    if (event !== null) {
      this.cliente = event.id;
    }
    this.getObras();
  }

  getEstadoFromSelector(event: any) {
    if (event === null) {
      this.estado = '';
    }
    if (event !== null) {
      this.estado = event.id;
    }
    this.getObras();
  }

  getConceptoFromSelector(event: any) {
    if (event === null) {
      this.concepto = '';
    }
    if (event !== null) {
      this.concepto = event.label;
    }

    this.getObras();
  }

  /**
   * DELETEPOPUP
   */

  openDeletePopup(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: 'obra' });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res['dataReturn'] === null) {
          return;
        }
        this.deleteObras([event.id]);
        this._unsub.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'Las Obras seleccionadas',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteObras(ids);
        this._unsubInd6.next('');
      });
  }

  /**
   * Delete
   */

  deleteObras(data: any[]) {
    this.obraSe.delete(data);
    this.obraSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack('Obra eliminada correctamente');
        this.ngOnInit();
        this._unsubInd4.next('');
      });
    this.obraSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'obras.xlsx');
  }

  /**
   * Open detail view obra
   */

  openObraDetailView(event: any) {
    let { id } = event;
    this.router.navigate([`admin/obras/` + id]);
  }
}
