import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iObra } from "./iObra";
import { iUser } from "./iUser";
 

export interface iProveedorObra {
    id: number,
    obra?: iObra,
    usuario?: iUser,
}
export class iProveedorObra extends iBaseObject {
    static convertFromCollection(ob: any): Array<iProveedorObra> {
        let iProveedorObraCollection: Array<iProveedorObra> = [];
        ob.forEach((element: any) => {
            iProveedorObraCollection.push(this.convertFromObject(element));
        });
        return iProveedorObraCollection;
    }

    static convertFromObject(ob: any): iProveedorObra {
        let iProveedorObraObj = new iProveedorObra();
        iProveedorObraObj.id = ob.id;
        if(ob.obra !== undefined){
            iProveedorObraObj.obra = iObra.convertFromObject(ob.obra);
        }
        if(ob.usuario !== undefined){
            iProveedorObraObj.usuario = iUser.convertFromObject(ob.usuario);
        }
        return iProveedorObraObj;
    }
}