import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { IncidenciaService } from 'src/app/Services/Api/Incidencia.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';
import { AsegurarDeletePopupComponent } from '../../AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaIncidenciaPopupComponent } from '../../AñadirNuevaPopup/NuevaIncidenciaPopup/NuevaIncidenciaPopup.component';
import { EditDepartamentosPopupComponent } from '../../EditPopup/EditDepartamentosPopup/EditDepartamentosPopup.component';
import { EditIncidenciaPopupComponent } from '../../EditPopup/EditIncidenciaPopup/EditIncidenciaPopup.component';

@Component({
  selector: 'app-IncidenciasVehiculos',
  templateUrl: './IncidenciasVehiculos.component.html',
  styleUrls: ['./IncidenciasVehiculos.component.scss'],
})
export class IncidenciasVehiculosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  dataFromVehiculo = this.modalData;
  isLoadingTabla = false;

  // data

  dataIncidencias!: any;
  mappedSelecteds: Array<any> = [];

  /**
   * TABLAS
   */

  displayedColumnsIncidencias: Array<iDataTableColumns> = [
    { label: 'Incidencia', property: 'descripcion', align: 'center' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoObras',
    },
  ];

  optionsIncidencias: iDataTableOptions = {
    isSelectable: true,
    canDelete: true,
    canEdit: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private incidenciaSE: IncidenciaService,
    private popup: PopupService,
    private vehiculoSE: VehiculoService,
    public dialogRef: MatDialogRef<EditDepartamentosPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getVehiculoById();
  }

  /**
   * Close
   */

  closePopup() {
    this.dialogRef.close();
  }

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  /**
   * DELETE
   */

  OnclickDeleteButton(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: `la incidencia ${event.descripcion}`,
      id: event.id,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteIncidencias([res.dataReturn.id]);
        this._unsubInd2.next('');
      });
  }

  changeSelecteds(event: any) {
    this.mappedSelecteds = event.map((e: any) => e.id);
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: `las incidencias`,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteIncidencias(this.mappedSelecteds);

        this._unsubInd3.next('');
      });
  }

  deleteIncidencias(IDs: Array<any>) {
    this.incidenciaSE.delete(IDs);
    this.incidenciaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'Incidencias eliminadas correctamente'
        );
        this.forceClosePopup('submitted');
        this._unsubInd2.next('');
      });
    this.incidenciaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * EDIT
   */
  editIncidencia(event: any) {
    this.popup.openPopup(EditIncidenciaPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.forceClosePopup('submitted');
        this._unsubInd2.next('');
      });
  }

  /**
   * AÑADIR INCIDENCIA
   * */

  anadirNuevaIncidencia(event: any) {
    this.popup.openPopup(NuevaIncidenciaPopupComponent, this.modalData);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null || res.dataReturn !== 'submitted') {
          return;
        }
        this.forceClosePopup('submitted');
        this._unsubInd2.next('');
      });
  }

  /**
   * API CALLS
   */

  getVehiculoById(): void {
    this.isLoadingTabla = true;
    this.vehiculoSE.getSingle(this.dataFromVehiculo.id);
    this.vehiculoSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        let { incidencias } = data;
        this.dataIncidencias = incidencias.map((res: any) => {
          return {
            descripcion: res.descripcion,
            fecha: iDate
              .javascriptConvert(new Date(res.fecha))
              .toStringDate('EU'),
            estado: res.estado,
            id: res.id,
          };
        });

        this.isLoadingTabla = false;
        this._unsubInd.next('');
      });
  }
}
