<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR SEGUIMIENTO TÉCNICO</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <!-- <div class="inputForm">
          <label>Concepto</label>
          <CustomInput placeholder="concepto" [(ngModel)]="concepto" (change)="getFromInput('concepto', concepto)"></CustomInput>
        </div> -->
        <div class="inputForm">
          <label>Fecha Entrada</label>
          <DatePicker (dataFromPicker)="getDataFromFechaEntrada($event)" [dataPlaceholder]="fechaEntrada"></DatePicker>
        </div>
        <div class="inputForm">
          <label>Estado</label>
          <selector class="sizeInput" [options]="optionEstado" (change)="estadoFromSelector($event)" [(ngModel)]="estado"></selector>
        </div>
        <div class="inputForm">
          <label>Proyecto</label>
          <CustomInput placeholder="Proyecto" [(ngModel)]="concepto" (change)="getFromInput('concepto', concepto)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Ubicacion</label>
          <CustomInput placeholder="ubicacion" [(ngModel)]="ubicacion" (change)="getFromInput('ubicacion', ubicacion)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Solicitado por</label>
          <CustomInput placeholder="Solicitado por" [(ngModel)]="orderedBy" (change)="getFromInput('ordenadoPor', orderedBy)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Contacto</label>
          <CustomInput placeholder="contacto" [(ngModel)]="contacto" (change)="getFromInput('contacto', contacto)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Presupuesto</label>
          <CustomInput placeholder="presupuesto" [(ngModel)]="presupuesto" (change)="getFromInput('presupuesto', presupuesto)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Comentario</label>
          <CustomInput placeholder="comentario" [(ngModel)]="comentario" (change)="getFromInput('comentario', comentario)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email</label>
          <CustomInput placeholder="Email" [(ngModel)]="email" (change)="getFromInput('email', email)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Telefono</label>
          <CustomInput placeholder="telefono" [(ngModel)]="telefono" (change)="getFromInput('telefono', telefono)"></CustomInput>
        </div>
        <!-- <div class="separatorGrid"></div> -->
<!-- 
        <div class="inputForm">
          <label>Cliente</label>
          <selector class="sizeInput" [options]="optionCliente" (change)="clienteFromSelector($event)" [(ngModel)]="cliente"></selector>
        </div> -->
        <div class="inputForm">
          <label>Encargado</label>
          <selector class="sizeInput" [options]="optionEncargado" (change)="encargadoFromSelector($event)" [(ngModel)]="encargado"></selector>
        </div>
        <div class="inputForm">
          <label>Fecha Visita</label>
          <DatePicker (dataFromPicker)="getDataFromFechaVisita($event)" [dataPlaceholder]="fechaVisita"></DatePicker>
        </div>
        <div class="inputForm">
          <label>Hora visita</label>
          <CustomInput [type]="'time'" placeholder="Hora visita" [(ngModel)]="horaVisita" (change)="getFromInput('horaVisita', horaVisita)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Fecha Objetivo</label>
          <DatePicker (dataFromPicker)="getDataFromFechaObjetivo($event)" [dataPlaceholder]="fechaObjetivo"></DatePicker>
        </div>
        <div class="inputForm">
          <label>Imagen Seguimiento</label>
          <button class="generalbutton" [multiple]="true" fileInput [accept]="['image/png', 'image/jpeg']" [(ngModel)]="imagen"
          (change)="addImage(imagen)">Añadir Imagen</button>
        </div>
        <div class="separatorGrid"></div>
        <!-- <input type="time"> -->
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>