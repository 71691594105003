import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iStaticUtilities } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'filterActions',
  templateUrl: './FilterActions.component.html',
  styleUrls: ['./FilterActions.component.scss']
})
export class FilterActionsComponent implements OnInit {

  @Input() textBtn="Añadir nuevo";
  @Input() activeBtn:boolean|string=true;
  @Input("filterOpen") isFilterActive:boolean = false;
  @Input("searchOpen") isSearchActive:boolean = false;
  @Input("disableSearch") isSeachDisabled:boolean = false;
  @Input("disableFilter") isFilterDisabled:boolean = false;
  @Input() placeholderAdd:string = 'AÑADIR NUEVA';
  @Input() inputOptions:{ placeholder?:string, model:string } = {
    placeholder:"Buscar por...",
    model:""
  };
  @Output() addNew = new EventEmitter();
  @Output() filterOpenChange = new EventEmitter();
  @Output() searchOpenChange = new EventEmitter();
  @Output() inputChange = new EventEmitter();
  @Output() searchSend = new EventEmitter();

  classFilter = "iconBtn";
  classSearch = "iconBtn";

  constructor() { }

  ngOnInit() {
  }

  checkBoolean(val:string|boolean){
    if(typeof val=="string"){
      return iStaticUtilities.normalizeBoolean(val);
    }
    return val;
  }

  toggleFilter(){
    if(this.isFilterActive){
      this.classFilter = "iconBtn";
    }else{
      this.classFilter = "iconBtn active";
    }
    this.isFilterActive = !this.isFilterActive;
    this.filterOpenChange.emit(this.isFilterActive);
  }
  toggleSearch(){
    if(this.isSearchActive){
      this.classSearch = "iconBtn";
    }else{
      this.classSearch = "iconBtn active";
    }
    this.isSearchActive = !this.isSearchActive;
    this.searchOpenChange.emit(this.isSearchActive);
  }
  updateInputVal(){
    this.inputChange.emit(this.inputOptions.model);
  }
  sendInputVal(){
    this.searchSend.emit(this.inputOptions.model);
  }
  sendAddNew(){
    this.addNew.emit(true);
  }

}
