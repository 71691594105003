import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DateRange, MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { co } from '@fullcalendar/core/internal-common';
import { iDate, NotificationsService } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'CalendarPicker',
  templateUrl: './CalendarPicker.component.html',
  styleUrls: ['./CalendarPicker.component.scss']
})
export class CalendarPickerComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('calendarOne', {static: false}) calendarOne!: MatCalendar<Date>;
  @ViewChild('calendarTwo', {static: false}) calendarTwo!: MatCalendar<Date>;
  @Input() classList:string = "";
  @Input() bannedDates:Array<any> = [];
  @Input() calendarFather: number | null = null;
  @Output() rangoSeleccionado = new EventEmitter<any>();
  sampleRange: DateRange<Date | null> = new DateRange(null, null);
  clicks: number = 0
  starDate: Date | null = null
  endDate: Date | null = null;
  dates: any = {checkIn: null, checkOut: null};

  selectedMonthOne: Date = new Date();
  selectedMonthTwo:Date = new Date( this.selectedMonthOne.getFullYear(),(this.selectedMonthOne.getMonth()+1),1);
  minDate: Date | null = null;
  maxDate: Date | null = null

  actualMonth: number = new Date().getMonth()
  disabledPrevious: boolean = true;
  disabledNext: boolean = false;
  constructor(private notificaciones: NotificationsService) { }

  ngAfterViewInit(): void {
    this.minDate = new Date();
    this.maxDate = new Date(new Date().getFullYear()+1, new Date().getMonth(), new Date().getDate())
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearData();
    this.datesEmitter();
    this.sampleRange = new DateRange(null, null)
    this.minDate = new Date();
    this.maxDate = new Date(new Date().getFullYear()+1, new Date().getMonth(), new Date().getDate())
  }

  ngOnInit() {
     
  }

  /**
   * FUNCTIONALITY
   */
   nextMonth(){
    document.querySelectorAll(".mat-calendar-next-button").forEach(element=>{
      (element as HTMLElement).click();
    })
    if (this.calendarOne.activeDate.getMonth() != this.actualMonth) {
      this.disabledPrevious = false;
    }
    if (this.calendarTwo.activeDate.getMonth() == this.maxDate?.getMonth()) {
      this.disabledNext = true;
    }
  }
  previousMonth(){
    if (this.calendarOne.activeDate.getMonth() != this.actualMonth) {
      document.querySelectorAll(".mat-calendar-previous-button").forEach(element=>{
        (element as HTMLElement).click();
      })
    }
    if (this.calendarOne.activeDate.getMonth() == this.actualMonth) {
      this.disabledPrevious = true;
    }
    if (this.calendarTwo.activeDate.getMonth() != this.maxDate?.getMonth()) {
      this.disabledNext = false;
    }
  }
  rangeChange(event: Date) {
    if (this.clicks == 0) {
      this.clearData();
      this.clicks++;
      this.starDate = event;
      this.sampleRange = new DateRange(event, null)
      this.dates = {checkIn: this.starDate, checkOut: null}
      this.datesEmitter();
      return;
    }
    if (this.clicks == 1) {
      this.endDate = event;
      this.sampleRange = new DateRange(this.starDate, this.endDate)
      this.dates = {checkIn: this.starDate, checkOut: this.endDate}
      this.datesEmitter();
      this.clearData();
      return;
    }

  }
  clearData(){
    this.dates = {checkIn: null, checkOut: null}
    this.clicks = 0;
  }
  datesEmitter(){
    if (new Date(new Date(this.dates.checkOut).getFullYear()+"-"+new Date(this.dates.checkOut).getMonth()+"-"+new Date(this.dates.checkOut).getDate()).getTime() < new Date(new Date(this.dates.checkIn).getFullYear()+"-"+new Date(this.dates.checkIn).getMonth()+"-"+new Date(this.dates.checkIn).getDate()).getTime() ) {
      this.notificaciones.showWarning("La fecha de salida no puede ser menor a la de entrada")
      this.clearData();
    }else{
      if(this.dates.checkOut == null){
        this.dates.checkOut = this.dates.checkIn;
      }
      this.rangoSeleccionado.emit(this.dates)

    }
  }

  isSelected = (date: any) => {
    return this.bannedDates.includes(iDate.toStringDate(new iDate(iDate.toPhp(date)), "JAP") )? 'ocupado': '';
  };
}
