import { Component, OnInit } from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaFacturaPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaFacturaPopup/NuevaFacturaPopup.component';
import { EditFacturaPopupComponent } from 'src/app/Popups/EditPopup/EditFacturaPopup/EditFacturaPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { FacturaService } from 'src/app/Services/Api/Factura.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';

@Component({
  selector: 'app-ProveedoresFacturas',
  templateUrl: './ProveedoresFacturas.component.html',
  styleUrls: ['./ProveedoresFacturas.component.scss'],
})
export class ProveedoresFacturasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isLoading: boolean = false;
  dataFacturas: any = [];
  userId!: number;
  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  optionsFacturas: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [{ callback: () => {}, icon: 'download' }],
    canEdit: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  // Selectores

  optionsEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pdte. Validación' },
      { id: 2, label: 'Rechazada' },
      { id: 3, label: 'Validada' },
      { id: 4, label: 'Contabilizada' },
      { id: 5, label: 'Aprobada' },
      { id: 6, label: 'Gestoría' },
    ],
    clearable: true,
    placeholder: 'Estado',
    append: true,
  };

  optionsProyecto: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Proyecto',
    append: true,
  };

  displayedColumnsFacturas: Array<iDataTableColumns> = [
    { label: 'Nº Factura', property: 'numero' },
    { label: 'Fecha emisión', property: 'fechaEmision' },
    { label: 'Fecha vencimiento', property: 'fechaVencimiento' },
    { label: 'Base Imponible', property: 'importe' },
    { label: 'Proyecto asociado', property: 'proyecto' },
    { label: 'Notas', property: 'notas' },
    {
      label: 'Estado',
      property: 'estado',
      classList: ' tableEstado tableEstadoFacturas',
    },
  ];

  isFilterOpen = false;
  isSearchOpen = false;
  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: '',
  };

  filter: any = {
    busqueda: '',
    fechaIni: '',
    fechaFin: '',
    estado: '',
    proyecto: '',
  };

  constructor(
    private popup: PopupService,
    private facturaSE: FacturaService,
    private notificationSE: NotificationsService,
    private obrasSE: ObraService
  ) {
    super();
  }

  ngOnInit() {
    this.getLocalStorage();
    this.getAllObras();
  }

  getLocalStorage() {
    this.userId = Number(localStorage.getItem('nmsr')!);
    this.getAllFacturas();
  }

  /**
   *
   * SEE DOCUMENTO
   *
   */

  seeDocumento(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   *
   * EDIT FACTURA
   */

  editFactura(event: any) {
    this.popup.openPopup(EditFacturaPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.getAllFacturas();
        this._unsubInd9.next('');
      });
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getAllFacturas();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getAllFacturas();
  }

  // SELECTOR

  changeSelector(event: any, key: string) {
    if (event === null) {
      this.filter[key] = '';
      this.getAllFacturas();
      return;
    }
    key === 'estado'
      ? (this.filter.estado = event.label)
      : (this.filter.proyecto = event.label);
    this.getAllFacturas();
  }

  // DatePicker

  dataFromPicker(event: any, key: string) {
    if (!event) {
      this.filter[key] = '';
      this.getAllFacturas();
      return;
    }
    this.filter[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    this.getAllFacturas();
  }

  /**
   * Nueva Factura
   */

  nuevaFactura() {
    this.popup.openPopup(NuevaFacturaPopupComponent, {
      id: this.userId,
      tipo: 'proveedor',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.getAllFacturas();
        this._unsubInd9.next('');
      });
  }

  /**
   *
   * DELETE FACTURA
   */

  deleteFactura(event: any, key: string) {
    switch (key) {
      case 'single':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'la factura',
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubInd))
          .subscribe((res) => {
            if (res == null) {
              return;
            }
            this.eliminarFactura([event.id]);
            this._unsubInd.next('');
          });
        break;
      case 'multipleSelected':
        this.popup.openPopup(AsegurarDeletePopupComponent, {
          nombre: 'las facturas seleccionadas',
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubInd))
          .subscribe((res) => {
            if (res == null) {
              return;
            }
            this.eliminarFactura(event.map((e: any) => e.id));
            this._unsubInd.next('');
          });
        break;
    }
  }

  changeFilterOpen(newVal: boolean) {
    this.isFilterOpen = newVal;
    this.updateTableMaxHeight();
  }
  changeSearchOpen(newVal: boolean) {
    this.isSearchOpen = newVal;
    this.updateTableMaxHeight();
  }
  updateTableMaxHeight() {
    let val = '';
    if (this.isFilterOpen) {
      val = val + ' - 68px - 1em';
    }
    if (this.isSearchOpen) {
      val = val + ' - 68px - 1em';
    }
    this.optionsFacturas.maxHeight =
      'calc(100vh - 78px - 4em - 106px - 47px - 4em' + val + ')';
  }

  /**
   * BUSQUEDA
   *
   */
  respuestaBusqueda(event: any) {
    if (!event) {
      this.filter['busqueda'] = '';
      this.getAllFacturas();
      return;
    }
    this.filter['busqueda'] = event;
    this.getAllFacturas();
  }

  /**
   * API CALLS
   */

  eliminarFactura(ids: number[]) {
    this.facturaSE.delete(ids);
    this.facturaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Factura eliminada correctamente');
        this.getAllFacturas();
        this._unsubInd2.next('');
      });
    this.facturaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  getAllFacturas() {
    this.isLoading = true;
    this.facturaSE.getAllProveedor(
      this.userId,
      this.pagina,
      this.maximo,
      this.filter.busqueda,
      this.filter.fechaIni,
      this.filter.fechaFin,
      this.filter.estado,
      this.filter.proyecto
    );
    this.facturaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.dataFacturas = data.map((item: any) => {
          return {
            ...item,
            fechaVencimiento: item.fechaVencimiento
              ? iDate
                  .javascriptConvert(new Date(item.fechaVencimiento))
                  .toStringDate('EU')
              : '',
          };
        });
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }

  getAllObras() {
    this.obrasSE.getAllTable();
    this.obrasSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        this.optionsProyecto.items = data.map((item: any) => {
          return { id: item.id, label: `${item.concepto} ${item.ubicacion}` };
        });
        this._unsubInd2.next('');
      });
  }
}
