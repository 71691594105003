<FullContainer>
  <div class="sectionTitleContainer">
    <h2 class="sectionTitle">Partes de trabajo</h2>
    <!-- <div class="botonVacaciones" *ngIf="submenuSel === 1" >
      <ng-container >
        <mat-icon (click)="openVacationsPopup()">
          edit
        </mat-icon>
      </ng-container>
      <p class="vacacionesEdit"(click)="openVacationsPopup()"> VACACIONES</p>
    </div> -->
    <toggleSubmenu [elements]="submenuNavegation" (change)="reloadPartes()" [(ngModel)]="submenuSel"></toggleSubmenu>
  </div>

  <ng-container *ngIf="submenuSel == 0">
    <JefePartesValidar></JefePartesValidar>
  </ng-container>

  <ng-container *ngIf="submenuSel === 1">
    <div class="calendarioPart">
      <div class="calendarioContainerDesign">
          <div class="moveCalendar">
            <HousingCalendar [jefeDeObra]="userJefeId" (refreshPopup)="refreshPopup($event)" [vacationPopup]="vacationPopup"></HousingCalendar>
          </div>
      </div>
    </div>
  </ng-container>

</FullContainer>