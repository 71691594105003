import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FacturaService } from 'src/app/Services/Api/Factura.service';

@Component({
  selector: 'app-EditFacturaPopup',
  templateUrl: './EditFacturaPopup.component.html',
  styleUrls: ['./EditFacturaPopup.component.scss'],
})
export class EditFacturaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  optionEstado: iOptionsSelector = {
    items: this.modalData.jefeObra
      ? [
          { id: 2, label: 'Rechazada' },
          { id: 3, label: 'Validada' },
        ]
      : [
          { id: 1, label: 'Pdte. Validación' },
          { id: 2, label: 'Rechazada' },
          { id: 3, label: 'Validada' },
          { id: 4, label: 'Contabilizada' },
          { id: 5, label: 'Aprobada' },
          { id: 6, label: 'Gestoría' },
        ],
    clearable: false,
    placeholder: 'Estado',
    append: true,
  };

  /**
   *
   *NG MODELS
   */

  importe: number = this.modalData.importe;
  estado: string = this.modalData.estado;
  notas: string = this.modalData.notas;

  objectToPass: any = {
    id: this.modalData.id,
  };

  constructor(
    public dialogRef: MatDialogRef<EditFacturaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private notificationSer: NotificationsService,
    private facturaSE: FacturaService
  ) {
    super();
  }

  ngOnInit() {}

  /**
   * Input
   */

  getInput(event: any, key: string) {
    this.objectToPass[key] = event.target.value;
    this.editFactura();
  }

  /**
   * Selector
   */

  estadoFromSelector(event: any) {
    this.objectToPass.estado = event.label;
    this.editFactura();
  }

  /**
   * Cerrar
   */

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * API CALLS
   */

  editFactura() {
    this.facturaSE.update(this.objectToPass);
    this.facturaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSer.showFeedBack('Factura editada correctamente');
        this._unsubInd.next('');
      });
    this.facturaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }
}
