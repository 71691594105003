import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
 

export interface iInfoEmpleado {
    id: number,
    tallaCam: string,
    tallaPan: string,
    tallaBota: string,
}
export class iInfoEmpleado extends iBaseObject {
    static convertFromCollection(ob: any): Array<iInfoEmpleado> {
        let iInfoEmpleadoCollection: Array<iInfoEmpleado> = [];
        ob.forEach((element: any) => {
            iInfoEmpleadoCollection.push(this.convertFromObject(element));
        });
        return iInfoEmpleadoCollection;
    }

    static convertFromObject(ob: any): iInfoEmpleado {
        let iInfoEmpleadoObj = new iInfoEmpleado();
        iInfoEmpleadoObj.id = ob.id;
        iInfoEmpleadoObj.tallaCam = ob.tallaCam;
        iInfoEmpleadoObj.tallaPan = ob.tallaPan;
        iInfoEmpleadoObj.tallaBota = ob.tallaBota;
        return iInfoEmpleadoObj;
    }
}