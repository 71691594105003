import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  iOptionsSelector,
  iStaticUtilities,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
  sha256,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { EditEmpleadosPopupComponent } from 'src/app/Popups/EditPopup/EditEmpleadosPopup/EditEmpleadosPopup.component';
import { EmpleadoPopupComponent } from 'src/app/Popups/EmpleadoPopup/EmpleadoPopup.component';
import { UserService } from 'src/app/Services/Api/User.service';
import { ICryptID } from 'src/app/Shared/Interfaces/iCryptID';
import {
  iDataTableColumns,
  iDataTableOptions,
} from 'src/app/Shared/Interfaces/iDataTable';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-AdminEmpleadosLista',
  templateUrl: './AdminEmpleadosLista.component.html',
  styleUrls: ['./AdminEmpleadosLista.component.scss'],
})
export class AdminEmpleadosListaComponent
  extends iUnsubscribeDestroy
  implements OnInit, OnChanges
{
  @Input('dataTable') dataEmpleados: Array<any> = [];
  @Input('isLoading') isLoading: boolean = false;
  @Input('tiposUsers') tiposUsers: Array<any> = [];
  @Input('obras') obras: Array<any> = [];

  @Output() refreshData = new EventEmitter<boolean>();
  @Output() emitFilters = new EventEmitter<any>();

  forceUpdateWidth = new Subject();

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  filterText = {
    placeholder: 'Busca por nombre o contacto',
    model: '',
  };

  // Selectores
  optionUsuario: iOptionsSelector = {
    items: this.tiposUsers,
    clearable: true,
    placeholder: 'Tipo usuario',
  };
  optionsObras: iOptionsSelector = {
    items: this.obras,
    clearable: true,
    bindLabel: 'nombreObra',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias obras seleccionados',
    },
    placeholder: 'Obras',
  };

  optionsBloqueado: iOptionsSelector = {
    items: [
      { id: 'Bloqueado', value: 1, label: 'Bloqueado' },
      { id: 'Activo', value: 0, label: 'Activo' },
    ],
    clearable: true,
    placeholder: 'Bloqueado',
  };

  selectedUsers: any = null;
  selectedObras: any[] = [];
  selectedBloqueado: any = null;

  //Tabla

  cursorPointer: string[] = ['cursor-pointer'];

  displayedColumnsEmpleados: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
    {
      label: 'Contacto',
      property: 'contacto',
      classList: 'tableWhiteSpacePreLine',
    },
    { label: 'Usuario', property: 'typeUser' },
    { label: 'Tiempo trabajado', property: 'tiempo', align: 'center' },
    { label: 'Fecha alta', property: 'fechaAlta', align: 'center' },
    {
      label: 'Talla',
      property: 'talla',
      align: 'center',
      classList: 'tableWhiteSpacePreLine tableSizeLetterSmall',
    },
    {
      label: 'Estado',
      property: 'bloqueo',
      align: 'center',
      classList: 'tableEstado tableEstadoEmpleados',
    },
  ];

  optionsEmpleados: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canEdit: true,
    moreIconsRow: [
      {
        callback: (event: any) => {
          this.bloquearUsuario(event);
        },
        icon: 'lock',
      },
    ],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private notificationSer: NotificationsService,
    public usuarioSe: UserService,
    public router: Router,
    public popupSe: PopupService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['obras'] != null) {
      this.optionsObras.items = changes['obras'].currentValue;
    }
    if (changes['tiposUsers'] != null) {
      this.optionUsuario.items = changes['tiposUsers'].currentValue;
    }
    if (changes['dataEmpleados'] != null) {
      setTimeout(() => {
        this.forceUpdateWidth.next(true);
      }, 100);
    }
  }

  ngOnInit() {}

  updateQuery() {
    let sendOptions: any = {};
    if (this.selectedUsers != null) {
      sendOptions.rol = this.selectedUsers.id;
    }
    if (this.selectedBloqueado != null) {
      sendOptions.bloqueado = this.selectedBloqueado.value;
    }
    if (this.optionsObras != null && this.selectedObras.length > 0) {
      sendOptions.proyecto = this.selectedObras.map((e) => e.id).join(',');
    }
    if (this.filterText.model != null && this.filterText.model != '') {
      sendOptions.busqueda = this.filterText.model;
    }
    this.emitFilters.emit(sendOptions);
    this.isLoading = false;
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.updateQuery();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.updateQuery();
  }

  navigateToEmpleado(row: any) {
    let crypt = ICryptID.cryptID(row.id);
    this.router.navigate(['/empleado/' + crypt]);
  }

  newEmpleado() {
    this.popupSe.openPopup(EmpleadoPopupComponent);
    this.popupSe
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshData.emit(true);
        this._unsubInd.next('');
      });
  }

  /**
   *
   * Bloquear Usuario
   */

  bloquearUsuario(event: any) {
    let tipo = 'bloquear';
    if (event.bloqueado) {
      tipo = 'desbloquear';
    }
    this.popupSe.openPopup(AsegurarDeletePopupComponent, {
      nombre: ' al empleado',
      tipo: tipo,
    });
    this.popupSe
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.bloquearEmpleado(event.id, tipo);
        this._unsubInd.next('');
      });
  }

  bloquearEmpleado(id: any, tipo: string) {
    let obj =
      tipo === 'bloquear'
        ? { id: id, bloqueado: true }
        : { id: id, bloqueado: false };
    this.usuarioSe.update(obj);
    this.usuarioSe
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'Estado del usuario cambiado correctamente'
        );
        this.updateQuery();
        this._unsubInd2.next('');
      });
    this.usuarioSe
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  editEmpleados(event: any): void {
    this.popupSe.openPopup(EditEmpleadosPopupComponent, event);
    this.popupSe
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshData.emit(true);
        this._unsubInd.next('');
      });
  }

  OnclickDeleteButton(event: any): void {
    this.popupSe.openPopup(AsegurarDeletePopupComponent, event);
    this.popupSe
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let idFromButton = [res.dataReturn.id];
        this.deleteEmpleado(idFromButton);
        this._unsubInd2.next('');
      });
  }

  deleteEmpleado(id: any): void {
    this.usuarioSe.delete(id);
    this.usuarioSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El usuario ha sido borrado correctamente'
        );
        this.refreshData.emit(true);
        this._unsubInd3.next('');
      });
    this.usuarioSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(`${res.message}`);
        this._unsub.next('');
      });
  }

  respuestaBusqueda(event: any): void {}

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Empleados.xlsx');
  }
}
