<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">ADJUNTAR</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Fecha de caducidad</label>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaCaducidad')"></DatePicker>
        </div>
        <div class="inputForm" *ngIf="dataFromVehicle.edit !== 'edit'">
          <label>Carpeta</label>
          <selector [options]="optionCarpeta" (change)="getCarpetaFromInput($event)" classList="obras"></selector>
        </div>
        <div class="inputForm"  >
          <label>Adjuntar</label>
          <p class="nombreDelDocumento" *ngIf="this.url" >
            {{documentName}}
          </p>
          <button class="generalbutton" type="button" fileInput [accept]="['application/pdf', 'image/jpeg', 'image/png']" [(ngModel)]="documento"
          (change)="addDocumento(documento)"> Adjuntar </button>
        </div>

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="adjuntarDocumento()">Confirmar</btn>
    </div>
  </div>
</div>