import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DocumentoRequeridosService } from 'src/app/Services/Api/DocumentoRequeridos.service';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';
import { TipoDocumentoService } from 'src/app/Services/Api/TipoDocumento.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-NuevoDocumentoRequerido',
  templateUrl: './NuevoDocumentoRequerido.component.html',
  styleUrls: ['./NuevoDocumentoRequerido.component.scss']
})
export class NuevoDocumentoRequeridoComponent extends iUnsubscribeDestroy implements OnInit {

  // Selectores
  optionTipoDeDoc: iOptionsSelector = {
    items: [],
    bindLabel: 'nombre',
    clearable: false,
    placeholder: 'Tipo de documento',
    multiple: {
      isMultiple: true,
      checkbox: true
    },
    search: true,
    append: true
  }

  optionProveedores: iOptionsSelector = {
    items: [],
    clearable: false,
    bindLabel: 'nombre',
    placeholder: 'Proveedores',
    multiple: {
      isMultiple: false,
      checkbox: true
    },
    search: true,
    append: true
  }

  objectToPass: any = {
    tipoDocumento: "",
    usuario: 0,
  }



  constructor(public dialogRef: MatDialogRef<NuevoDocumentoRequeridoComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private tipoDeDocSE: TipoDocumentoService, private proveedoresSE: ProveedorObraService, private documentoRequeridoSE: DocumentoRequeridosService, private notificationService: NotificationsService) {
    super();
  }

  ngOnInit() {
     
    this.getAllTipoDeDoc()
    this.getAllProveedores()
  }

  /**
   * 
   * CLOSE
   */

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * SELECTORES
   */

  changeSelector(event: any, key: string) {
    this.objectToPass[key] = event.map((e:any) => e.id)
  }


  submitNuevoDocumentoRequerido() {
    if (this.objectToPass.tipoDocumento == "") { return }
    this.objectToPass.usuario = this.modalData.user.id;
    this.createDocumentoRequerido()
  }

  /**
   * API CALLS
  */

  createDocumentoRequerido() {
    this.documentoRequeridoSE.create(this.objectToPass);
    this.documentoRequeridoSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      this.notificationService.showFeedBack('Documento requerido creado correctamente');
      this.closePopup('submitted')
      this._unsubInd.next('')
    })
    this.documentoRequeridoSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      this.notificationService.showError(res.message);
      this._unsub.next('')
    })
  }

  getAllTipoDeDoc() {
    this.tipoDeDocSE.getAll();
    this.tipoDeDocSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
       
      this.optionTipoDeDoc.items = data;
      this._unsubInd.next('')
    })
  }

  getAllProveedores() {
    this.proveedoresSE.getAllTabla();
    this.proveedoresSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res.data;
       
      this.optionProveedores.items = data;
      this._unsubInd2.next('')
    })
  }

}
