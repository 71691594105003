<div class="position-relative">
  <div class="navbar">
    <button class="prevButton" (click)="prevMonth()">
      <ng-container >
        <mat-icon>chevron_left</mat-icon>
      </ng-container>
    </button>
    <p>{{startDate | CustomDatePickerMMMYYY}}</p>
    <button class="nextButton" (click)="nextMonth()">
      <ng-container >
        <mat-icon>chevron_right</mat-icon>
      </ng-container>
    </button>
  </div>
<mat-calendar [startAt]="startDate" #calendario [dateClass]="dateClass()" [(selected)]="selectedDate" (selectedChange)="onSelect($event)" *ngIf="datas && !flag"></mat-calendar>
</div>