import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iUnsubscribeDestroy,
  LoaderService,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { NuevoDocumentoRequeridoComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoRequerido/NuevoDocumentoRequerido.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { VerVersionesDocumentosPopupComponent } from 'src/app/Popups/VerVersionesDocumentosPopup/VerVersionesDocumentosPopup.component';
import { EditDocumentosPopupComponent } from 'src/app/Popups/EditPopup/EditDocumentosPopup/EditDocumentosPopup.component';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { DocumentoRequeridosService } from 'src/app/Services/Api/DocumentoRequeridos.service';

@Component({
  selector: 'app-ProveedoresSeeEditDocumentosRequeridos',
  templateUrl: './ProveedoresSeeEditDocumentosRequeridos.component.html',
  styleUrls: ['./ProveedoresSeeEditDocumentosRequeridos.component.scss'],
})
export class ProveedoresSeeEditDocumentosRequeridosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() dataFromSingle: any = null;
  @Output() refreshData = new EventEmitter<boolean>();

  // Data

  nombreCompleto: string = '';
  mail: string = '';
  telefono: string = '';
  cif: string = '';

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  // Documentos

  dateProveedores = [];
  isLoading: boolean = true;

  //Tabla
  displayedColumnsProveedores: Array<iDataTableColumns> = [
    { label: 'Tipo de documento', property: 'nombre' },
  ];

  optionsProveedores: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };
  constructor(
    private loader: LoaderService,
    private popup: PopupService,
    private documentSE: DocumentoRequeridosService,
    private notificationSE: NotificationsService,
    private docuReqSE: DocumentoRequeridosService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataFromSingle'] != undefined) {
      if (Object.keys(changes['dataFromSingle'].currentValue).length > 0) {
        this.dataFromSingle = changes['dataFromSingle'].currentValue;
        this.nombreCompleto = `${this.dataFromSingle?.user?.nombre} ${this.dataFromSingle?.user?.apellidos}`;
        this.mail = this.dataFromSingle?.user?.mail;
        this.telefono = this.dataFromSingle?.user?.telefono;
        this.cif = this.dataFromSingle?.user?.cif;
        this.getDocuReq();
        this.isLoading = false;
      }
    }
  }

  ngOnInit() {}

  navigateToProveedores(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getDocuReq();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getDocuReq();
  }

  /**
   * ADD DOCUMENTOS REQUERIDOS
   */

  addDocumentosRequeridos() {
    this.popup.openPopup(NuevoDocumentoRequeridoComponent, this.dataFromSingle);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.getDocuReq();
        this._unsubInd.next('');
      });
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'ProveedoresDocumentos.xlsx');
  }

  /**
   * DELETE
   */

  openDeletePopup(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el documento requerido',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteDocument(event.id);
        this._unsubInd2.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los documentos requeridos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        let ids = event.map((e: any) => e.id);
        this.deleteDocument(ids);
        this._unsubInd2.next('');
      });
  }

  deleteDocument(ids: any[]) {
    this.documentSE.delete(ids);
    this.documentSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Documento eliminado correctamente');
        this.getDocuReq();
        this._unsubInd.next('');
      });
    this.documentSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getDocuReq() {
    this.isLoading = true;
    this.docuReqSE.getDocumentosRequeridosUserId(this.dataFromSingle.user.id);
    this.docuReqSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.dateProveedores = data;
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;
        this.isLoading = false;
        this._unsubInd2.next('');
      });
  }
}
