import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iDepartamento } from "./iDepartamento";
import { iDocumento } from "./iDocumento";
import { iObra } from "./iObra";
 

export interface iUser {
    id?: number,
    nombre: string,
    apellidos: string,
    mail: string,
    telefono: number,
    password?: string,
    fechaAlta: iDate,
    documentos?: iDocumento,
    cif: string,
    roles: any,
    validacionAuto: boolean,
    dni: string,
    obras?: iObra[],
    departamento?: iDepartamento,
}
export class iUser extends iBaseObject {
    static convertFromCollection(ob: any): Array<iUser> {
        let iUserCollection: Array<iUser> = [];
        ob.forEach((element: any) => {
            iUserCollection.push(this.convertFromObject(element));
        });
        return iUserCollection;
    }

    static convertFromObject(ob: any): iUser {
        let iUserObj = new iUser();
        if(ob.id !== undefined) {
            iUserObj.id = ob.id
        };
        iUserObj.nombre = ob.nombre;
        iUserObj.apellidos = ob.apellidos;
        iUserObj.mail = ob.mail;
        if(ob.documentos !== undefined) {
            iUserObj.documentos = ob.documentos;
        }
        iUserObj.telefono = ob.telefono;
        if (ob.password !== undefined) {
            iUserObj.password = ob.password;
        }
        iUserObj.fechaAlta = iDate.phpConvert(ob.fechaAlta);
        iUserObj.cif = ob.cif;
        iUserObj.roles = ob.roles;
        if (ob.departamento !== undefined) {
            iUserObj.departamento = ob.departamento;
        }
        iUserObj.dni = ob.dni;
        iUserObj.validacionAuto = ob.validacionAuto;
        if(ob.obras !== undefined){
            iUserObj.obras = ob.obras ? iObra.convertFromCollection(ob.obras) : [];
        }
        return iUserObj;
    }
    static empty(): iUser{
        let iUserObj = new iUser();
        iUserObj.id = -1;
        iUserObj.nombre = '';
        iUserObj.apellidos = '';
        iUserObj.mail = '';
        iUserObj.telefono = -1;
        iUserObj.password = '';
        iUserObj.fechaAlta = iDate.javascriptConvert(new Date());
        iUserObj.cif = '';
        iUserObj.documentos = new iDocumento()
        iUserObj.roles = '';
        iUserObj.departamento! ;
        iUserObj.dni = '';
        iUserObj.validacionAuto = false;
        iUserObj.obras = [];
        return iUserObj;
    }
}