<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modified')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVO VEHÍCULO</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="nombre">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Matricula</label>
          <CustomInput placeholder="Matricula" [(ngModel)]="matricula">
          </CustomInput>
        </div>

        <div class="inputForm">
          <label>Caducidad del Seguro</label>
          <DatePicker (dataFromPicker)="getDataFromSeguro($event)" [dataPlaceholder]="dataPlaceholderSeguro">
          </DatePicker>
        </div>

        <div class="inputForm">
          <label>Caducidad de la ITV</label>
          <DatePicker (dataFromPicker)="getDataFromITV($event)" [dataPlaceholder]="initialDataITV"></DatePicker>
        </div>
        
        <div class="separatorGrid"></div>
      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="submitNuevoVehiculo()">
        <ng-container>
          <span>Añadir Nuevo Vehículo</span>
        </ng-container>
      </btn>
    </div>
  </div>