<div class="empleadosBlock">


  <div class="controlHorarioBlock">

    <div class="headerAndToggle">
      <p class="header">Control Horario</p>
      <!-- <filterActions >
        <ng-container filterContent>
          <div class="containerSelectores">
            <selector ></selector>
          </div>
        </ng-container>
      </filterActions> -->
    </div>

    <div class="tablaControlHorario">
      <pill class="tablePil" classList="tablePill" >
        <dataTable (see)="empleadoDetalle($event)" (clickRow)="empleadoDetalle($event)"  class="TablaHorario" [isLoading]="isLoadingTabla" [data]="dataHorario" [displayedColumns]="displayedColumnsHorario" [options]="optionsHorarios"></dataTable>
      </pill>
      
    </div>

  </div>
  
  <div class="controlHorarioBlock">

    <div class="headerAndToggle">
      <p class="header">Vehiculos Utilizados</p>
      <!-- <filterActions >
        <ng-container filterContent>
          <div class="containerSelectores">
            <selector ></selector>
          </div>
        </ng-container>
      </filterActions> -->
    </div>

    <div class="tablaControlHorario">
      <pill class="tablePil" classList="tablePill" >
        <dataTable class="TablaEmpleado" (see)="openVehiculoDetailView($event)" (clickRow)="openVehiculoDetailView($event)"  [isLoading]="isLoadingVehiculos" [data]="dataVehiculo" [displayedColumns]="displayedColumnsVehiculos" [options]="optionsVehiculos"></dataTable>
      </pill>
      
    </div>

  </div>

</div>