import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
 

export interface iImagen {
    id: number,
    url: string,
}

export interface iImagenCreate{
    url:string
}

export class iImagen extends iBaseObject {
    static convertFromCollection(ob: any): Array<iImagen> {
        let iImagenCollection: Array<iImagen> = [];
        ob.forEach((element: any) => {
            iImagenCollection.push(this.convertFromObject(element));
        });
        return iImagenCollection;
    }

    static convertFromObject(ob: any): iImagen {
        let iImagenObj = new iImagen();
        iImagenObj.id = ob.id;
        iImagenObj.url = ob.url;
        return iImagenObj;
    }

    static empty(): iImagen {
        let iImagenObj = new iImagen();
        iImagenObj.id = -1;
        iImagenObj.url = '';
        return iImagenObj;
    }
}