<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">INFORMACIÓN GENERAL</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" name="fieldName" ngDefaultControl [(ngModel)]="nombre"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Apellidos</label>
          <CustomInput placeholder="Apellidos" name="fieldName" ngDefaultControl [(ngModel)]="apellidos"></CustomInput>
        </div>
        <div class="inputForm">
          <label>DNI</label>
          <CustomInput placeholder="00000000X" name="fieldName" ngDefaultControl [(ngModel)]="dni"></CustomInput>
        </div>
        <div class="inputForm">
          <label>ROLE</label>
          <selector class="maxWidth" [options]="optionsRole" (change)="getRoleFromSelector($event)"></selector>
        </div>

        <div class="inputForm">
          <label>Teléfono</label>
          <CustomInput [type]="'text'" placeholder="00 00 00 000" name="fieldName" ngDefaultControl [(ngModel)]="telefono"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email</label>
          <CustomInput placeholder="teldomo@teldomo.com" name="fieldName" ngDefaultControl [(ngModel)]="email"></CustomInput>
        </div>
        <!-- <div class="inputForm">
          <label>Departamento</label>
          <selector class="maxWidth" [options]="optionsDepartamento" (change)="getDepartamentoFromSelector($event)"></selector>
        </div> -->

        <!-- Asignar obras comentado -->

        <!-- <div class="inputForm">
          <label>Asignar obras</label>
          <selector class="sizeInput" [options]="optionsObras" name="fieldName" ngDefaultControl [(ngModel)]="selectedObras"
            ></selector>
        </div> -->
        <div class="tallasGrid">
          <span></span>
          <span class="textCenter">Camiseta</span>
          <span class="textCenter">Botas</span>
          <span class="textCenter">Pantalones</span>
          <span class="tallasWidth">Tallas</span>
          <div class="textCenter">
            <selector class="maxWidth" [options]="optionsTallas" name="fieldName" ngDefaultControl [(ngModel)]="selectedTallas" classList="widthCamiseta"
              class="widthCamiseta" (change)="getTallaCamisetaFromSelector($event)"></selector>
          </div>
          <div class="textCenter">
            <CustomInput class="maxWidth" classList="fullWidth" type="number" name="fieldName" ngDefaultControl [extraAtributes]="numberAttributes"
              placeholder="Talla" [(ngModel)]="botas"></CustomInput>
          </div>
          <div class="textCenter">
            <CustomInput class="maxWidth" classList="fullWidth" type="number" name="fieldName" ngDefaultControl [extraAtributes]="numberAttributes"
              placeholder="Talla" [(ngModel)]="pantalon"></CustomInput>
          </div>
        </div>
      </div>
      <br>
      <hr><br>
      <h3 class="popupTitle secondTitle">DOCUMENTOS</h3>


      <div class="flexDivider">
        <div>
          <div class="documentTitle">
            <label>DNI</label>
            <div class="caducidadPicker">
              <DatePicker (dataFromPicker)="getDataFromDNICaducidad($event)" name="fieldName" ngDefaultControl [(ngModel)]="dni">
              </DatePicker>
            </div>
          </div>
          <div class="buttonsSubirImages">
            <div class="dniAnverso">
              <p class="textCenter">Delante</p>
              <button fileInput [accept]="['image/png', 'image/jpeg']"  name="fieldName" ngDefaultControl [(ngModel)]="imagen"
                (change)="addImageDNIAnverso(imagen)" class="buttonSubir">
                <ng-container>
                  <mat-icon>upload</mat-icon>
                </ng-container>
                <p>{{dniAnversoName ?? '¡Sube tu fichero!'}}</p>
              </button>
            </div>
            <div class="dniReverso">
              <p class="textCenter">Detrás</p>
              <button fileInput [accept]="['image/png', 'image/jpeg']" name="fieldName" ngDefaultControl [(ngModel)]="imagen"
                (change)="addImageDNIReverso(imagen)" class="buttonSubir">
                <ng-container>
                  <mat-icon>upload</mat-icon>
                </ng-container>
                <p>{{dniReversoName ?? '¡Sube tu fichero!'}}</p>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="documentTitle">
            <label>Carnet de conducir</label>
            <div class="caducidadPicker">
              <DatePicker (dataFromPicker)="getDataFromCarnetDeConducirCaducidad($event)">
              </DatePicker>
            </div>
          </div>
          <div class="buttonsSubirImages flex-end">
            <div class="dniAnverso">
              <p class="textCenter">Delante</p>
              <button fileInput [accept]="['image/png', 'image/jpeg']" name="fieldName" ngDefaultControl [(ngModel)]="imagen"
                (change)="addImageConducirAnverso(imagen)" class="buttonSubir">
                <ng-container>
                  <mat-icon>upload</mat-icon>
                </ng-container>
                <p>{{carnetDeConducirAnversoName ?? '¡Sube tu fichero!'}}</p>
              </button>
            </div>
            <div class="dniReverso">
              <p class="textCenter">Detrás</p>
              <button fileInput [accept]="['image/png', 'image/jpeg']" name="fieldName" ngDefaultControl [(ngModel)]="imagen"
                (change)="addImageConducirReverso(imagen)" class="buttonSubir">
                <ng-container>
                  <mat-icon>upload</mat-icon>
                </ng-container>
                <p>{{carnetDeConducirReversoName ?? '¡Sube tu fichero!'}}</p>
              </button>
            </div>
          </div>
        </div>
      </div>




    </div>
    <div class="popupButtons">
      <btn classList="minWidth secundario bigBtn" (click)="closePopup(false)">Cancelar</btn>
      <btn classList="minWidth  bigBtn" (click)="submit()">Crear Empleado</btn>
    </div>




  </div>
</div>