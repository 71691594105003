import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { AdjuntarDocumentosUsuarioPopupComponent } from 'src/app/Popups/EditPopup/AdjuntarDocumentosUsuarioPopup/AdjuntarDocumentosUsuarioPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { NominasService } from 'src/app/Services/Api/Nominas.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { ICryptID } from 'src/app/Shared/Interfaces/iCryptID';

@Component({
  selector: 'app-EmpleadoSeeEdit',
  templateUrl: './EmpleadoSeeEdit.component.html',
  styleUrls: ['./EmpleadoSeeEdit.component.scss']
})
export class EmpleadoSeeEditComponent extends iUnsubscribeDestroy implements OnInit {
  
  // Tabla
  forceUpdateWidth = new Subject()
  dataNominas: Array<any> = [];
  displayedColumnsNominas: Array<iDataTableColumns> = [
    { label: 'Mes', property: 'month' },
    { label: 'Nómina', property: 'nomina', align: 'center' },
  ];
  optionsNominas: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    canDelete: true,
  }
  isLoading:boolean = false;

  idUser:number = -1;
  user:any={};
  userData:any=[];
  horasTrabajadasData:any=[];

  mesActual = this.getMonth();
  mesAnterior = this.getMonth(1);
  mesAnteriorAnterior = this.getMonth(2);

  dni:any = null;
  carnetConducir:any = null;

  documentosRequeridos: any = {
    dniReverso: '',
    dniAnverso: '',
    conducirReverso: '',
    conducirAnverso: ''
  }

  isWarningDniReverso = true;
  isWarningDniAnverso = true;
  isWarningConducirAnverso = true;
  isWarningConducirReverso = true;
  textWarningDniReverso:string = "";
  textWarningDniAnverso:string = "";
  textWarningConducirReverso:string = "";
  textWarningConducirAnverso:string = "";

  DNIREVERSO:any = []
  DNIANVERSO:any = []
  CONDUCIRANVERSO:any = []
  CONDUCIRREVERSO:any = []

  constructor(public activeRouter:ActivatedRoute, public userSe:UserService, public popup:PopupService, public notificaciones:NotificationsService, private popupSE:PopupService, private nominaSE:NominasService) { 
    super();
  }

  ngOnInit() {    
    this.DNIREVERSO = []
    this.DNIANVERSO = []
    this.CONDUCIRANVERSO = []
    this.CONDUCIRREVERSO = []
  
    this.activeRouter.params.subscribe(params=>{
      this.idUser = parseInt(ICryptID.decryptID(params["id"]));
      this.userSe.getSingle(this.idUser);
    })
    this.userSe.getResultIndividual().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(res==null){return}
       let monthsArray:string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      this.dni = null;
      this.carnetConducir = null;
      this.user = res["data"]["user"];
      this.userData = res["data"]["user"];
      this.horasTrabajadasData = res["data"]["horasTrabajadas"];
      this.dataNominas = res["data"]["user"].nominas.filter((nomina:any)=> nomina.anyo === new Date().getFullYear()).map((nomina:any)=>{
        return {
          id: nomina.id,
          month: monthsArray[nomina.mes - 1],
          nomina: (nomina.url==null) ? "Sin subir" : "Subida",
          url: nomina.url
        }
      });

      res["data"]["user"].documentos.map((doc:any)=>{
        if(doc.tipoDocumento.nombre=="DNIanverso" || doc.tipoDocumento.nombre=="DNIreverso"){
          if(this.dni == null){
            this.dni = doc;
            this.dni.images=[]
            this.dni.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
          }else{
            this.dni.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
          }
        }
        if(doc.tipoDocumento.nombre=="Conduciranverso" || doc.tipoDocumento.nombre=="Conducirreverso"){
          if(this.carnetConducir == null){
            this.carnetConducir = doc;
            this.carnetConducir.images=[]
            this.carnetConducir.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
          }else{
            this.carnetConducir.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
          }
        }
      })

      this.documentCheck()
        })
  }

  getMonth(menos:number = 0){
    let numeroARestar = new Date().getMonth()-menos
    if(numeroARestar<0) {numeroARestar = numeroARestar + 12;}
    let monthsArray:string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    return monthsArray[numeroARestar];
  }

  deleteNomina(data: any) {
    this.popupSE.openPopup(AsegurarDeletePopupComponent, { nombre: `La nómina` })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return };
      this.delete([data.id]);
      this._unsubInd2.next("");
    })
  }

  delete(data: any) {
    this.nominaSE.delete([data])
    this.nominaSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res === null) { return };
      this.notificaciones.showFeedBack("Nómina eliminada correctamente");
      this.userSe.getSingle(this.idUser);
      this.userSe.getResultIndividual().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
        if(res==null){return}
         let monthsArray:string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        this.dni = null;
        this.carnetConducir = null;
        this.user = res["data"]["user"];
        this.userData = res["data"]["user"];
        this.dataNominas = res["data"]["user"].nominas.filter((nomina:any)=> nomina.anyo === new Date().getFullYear()).map((nomina:any)=>{
          return {
            month: monthsArray[nomina.mes],
            nomina: (nomina.url==null) ? "Sin subir" : "Subida",
            url: nomina.url
          }
        });
  
        res["data"].documentos.map((doc:any)=>{
          if(doc.tipoDocumento.nombre=="DNIanverso" || doc.tipoDocumento.nombre=="DNIreverso"){
            if(this.dni == null){
              this.dni = doc;
              this.dni.images=[]
              this.dni.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
            }else{
              this.dni.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
            }
          }
          if(doc.tipoDocumento.nombre=="Conduciranverso" || doc.tipoDocumento.nombre=="Conducirreverso"){
            if(this.carnetConducir == null){
              this.carnetConducir = doc;
              this.carnetConducir.images=[]
              this.carnetConducir.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
            }else{
              this.carnetConducir.images.push({type:doc.tipoDocumento.nombre, value:doc.url});
            }
          }
        })
  
        this.documentCheck()
          })
  
      this._unsubInd3.next("");
    })
    this.nominaSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return };
      this.notificaciones.showError("Error al eliminar la nómina");
      this._unsub.next("");
    })
  }

  desVincular(obra:any){
    this.popup.generalPopup(
      "¿Estás seguro de desvincular la obra?",
      "Quieres desvincular la obra "+obra.concepto+" en "+obra.ubicacion+"<br>del empleado "+this.user.nombre+" "+this.user.apellidos+"Este paso no tiene vuelta atrás",
      [
        {text:'Cancelar',classList:'generalbutton secundario',dataWhenClick:()=>{
          this.popup.closeLastPopup();
        }},
        {text:'Desvincular',classList:'generalbutton',dataWhenClick:()=>{
          this.user.obras = this.user.obras.filter((e:any)=>e.id != obra.id);
          this.popup.closeLastPopup();
          this.desvincular(obra);
        }}
      ]
    )
  }
  desvincular(obra:any){
    this.userSe.desvincularObras(this.user.id, obra.id);
    this.userSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(val=>{
      if(val==null){return;}
      this.notificaciones.showFeedBack("Obra desvinculada");
      this._unsubInd.next("");
    })
    this.userSe.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe(val=>{
      if(val==null){return;}
      this.notificaciones.showError(val["message"]);
      this._unsubInd.next("");
    })
  }
  goBack(){
    history.back()
  }

  getParseDate(date:string){
    return iDate.phpConvert(date).toStringDate("EU").split("-").join("/");
  }

  documentCheck() {
    this.userData.documentos.forEach((documento: any) => {
      switch (documento.tipoDocumento.nombre) {
        case 'DNIreverso':
          this.DNIREVERSO.push(documento)
          break;
        case 'DNIanverso':
          this.DNIANVERSO.push(documento)
          break;
        case "ConducirReverso":
          this.CONDUCIRREVERSO.push(documento)
          break;
        case 'ConducirAnverso':
          this.CONDUCIRANVERSO.push(documento)
          break;
        default:
          break;
      }
    })

      this.divideDocumentos()

  }

  divideDocumentos() {
    if (this.DNIREVERSO.length == 0) {
      this.isWarningDniReverso = true;
      this.textWarningDniReverso = "No se ha subido el DNI por detrás"
    }
    if (this.DNIANVERSO.length == 0) {
      this.isWarningDniAnverso = true;
      this.textWarningDniAnverso = "No se ha subido el DNI por delante"
    }
    if (this.CONDUCIRREVERSO.length == 0) {
      this.isWarningConducirReverso = true;
      this.textWarningConducirReverso = "No se ha subido el carnet de conducir por detrás"
    }
    if (this.CONDUCIRANVERSO.length == 0) {
      this.isWarningConducirAnverso = true;
      this.textWarningConducirAnverso = "No se ha subido el carnet de conducir por delante"
    }
    if (this.DNIREVERSO.length > 0) {
      this.isWarningDniReverso = false;
      this.textWarningDniReverso = ""
      this.documentosRequeridos.dniReverso = this.DNIREVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })
    }
    if (this.DNIANVERSO.length > 0) {
      this.isWarningDniAnverso = false;
      this.textWarningDniAnverso = ""
      this.documentosRequeridos.dniAnverso = this.DNIANVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })
    }
    if (this.CONDUCIRREVERSO.length > 0) {
      this.isWarningConducirReverso = false;
      this.textWarningConducirReverso = ""
      this.documentosRequeridos.conducirReverso = this.CONDUCIRREVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })
    }
    if (this.CONDUCIRANVERSO.length > 0) {
      this.isWarningConducirAnverso = false;
      this.textWarningConducirAnverso = ""
      this.documentosRequeridos.conducirAnverso = this.CONDUCIRANVERSO.reduce((r: any, a: any) => { return r.date > a.date ? r : a })
    }

  }

  nominaDetalle(event: any) {
    this.popupSE.openPopup(VisorDePdfPopupComponent, event, "fullSizePopup")
  }


  verDocumento(event: any) {
    if(event[0].url[event[0].url.length - 1]==="f"){
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup')
    }
    if(event[0].url[event[0].url.length - 1]==="g"){
      this.popup.openPopup(VisorDeDocumentoImagenComponent, {url: event[0].url})
    }
  }

    // AdjuntarDocumentos

    adjuntarFoto(tipo: string) {

      switch (tipo) {
        case "DNIReverso":
          this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.dniReverso, crear: (this.documentosRequeridos.dniReverso) ? 'no' : 'si' })
          break;
        case "DNIAnverso":
          this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.dniAnverso, crear: (this.documentosRequeridos.dniAnverso) ? 'no' : 'si' })
          break;
        case "carnetReverso":
          this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.conducirReverso, crear: (this.documentosRequeridos.conducirReverso != "") ? 'no' : 'si' })
          break;
        case "carnetAnverso":
          this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.documentosRequeridos.conducirAnverso, crear: (this.documentosRequeridos.conducirAnverso != "") ? 'no' : 'si' })
          break;
      }
  
      // this.popup.openPopup(AdjuntarDocumentosUsuarioPopupComponent, { tipo: tipo, data: this.userData, documentos: this.userData.documentos })
      this.popup.returnData().pipe(takeUntil(this._unsubInd9)).subscribe(res => {
        if (res == null) { return; }
        this.ngOnInit();
        this._unsubInd9.next("");
      })
    }
  
  


}
