import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iUser } from "./iUser";

export interface iVehiculoTable {
  id: number,
  nombre: string,
  matricula: string,
  caducidadSeguro: iDate,
  caducidadITV: iDate,
  numIncidencias: string,
  nombreCompleto?: string,
  usuario?: iUser,
}

export class iVehiculoTable extends iBaseObject {
  static convertFromCollection(ob: any): Array<iVehiculoTable> {
       
      let iVehiculoCollection: Array<iVehiculoTable> = [];
      ob.forEach((element: any) => {
          iVehiculoCollection.push(this.convertFromObject(element));
      });
      return iVehiculoCollection;
  }

  static convertFromObject(ob: any): iVehiculoTable {
      let iVehiculoObj = new iVehiculoTable();
      iVehiculoObj.id = ob.id;
      iVehiculoObj.nombre = ob.vehiculo;
      iVehiculoObj.matricula = ob.matricula;
      iVehiculoObj.caducidadSeguro = iDate.phpConvert(ob.caducidadSeguro);
      iVehiculoObj.caducidadITV = iDate.phpConvert(ob.caducidadITV);
      iVehiculoObj.numIncidencias = ob.incidencias;
      iVehiculoObj.nombreCompleto = ob.asignado;

          iVehiculoObj.usuario = ob.usuario != null ? iUser.convertFromObject(ob.usuario) : iUser.empty();
      return iVehiculoObj;
  }

}