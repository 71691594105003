<FullContainer>
    <h2 class="sectionTitle">alertas</h2>
    <br>
    <div class="alertasContainer">
        <pill *ngFor="let alerta of alertaData" classList="alerta" class="{{alerta.metadata}}" >{{alerta.descripcion}}</pill>

    </div>
    <div class="btnSeparatorContainer">
        <btn classList="bigBtn" (click)="openVerTodoAlertasPopup()" >ver todo</btn>
    </div>
    <br>
    <h2 class="sectionTitle">obras</h2>
    <br>
    <pill classList="tablePill">
        <dataTable [forceUpdateWidth]="forceUpdateWidth" (deleteSelected)="deleteSelectedObras($event)" (clickRow)="openObraDetailView($event)" (edit)="openEditObraPopup($event)" (delete)="openDeleteObraPopup($event)"  [isLoading]="isLoadingObras" [data]="dataObras" [displayedColumns]="displayedColumnsObras" [forceUpdateWidth]="forceUpdateWidthObras" [options]="optionsObras"></dataTable>
    </pill>
    <div class="btnSeparatorContainer">
        <btn classList="bigBtn" [routerLink]="['/admin/obras']">ver todo</btn>
        <btn classList="iconBtn plusBtn" (click)="addNewObra()">
            <i class="material-icons">add</i>
            <span>añadir nueva</span>
        </btn>
    </div>
    <br>
    <h2 class="sectionTitle">Seguimiento técnico</h2>
    <br>
    <div class="mapGroupContainer">
        <div class="mapContainer">
            <google-map class="mapsborders" [options]="optionsMap" [zoom]="zoom" [center]="center" >
                <map-marker *ngFor="let mapsMark of markers"
                [position]="mapsMark.position" [icon]="mapsMark.icon" 
                [options]="mapsMark.options" (mapClick)="clickMark($event,mapsMark)">
            </map-marker>
            </google-map>
            <div class="leyendaContainer">
                <div class="leyenda">
                    <i class="material-icons active">place</i>
                    <span>Enviado</span>
                </div>
                <div class="leyenda">
                    <i class="material-icons incomming">place</i>
                    <span>Contactado</span>
                </div>
                <div class="leyenda">
                    <i class="material-icons pending">place</i>
                    <span>Visitado</span>
                </div>
                <div class="leyenda">
                    <i class="material-icons inactive">place</i>
                    <span>Pendiente</span>
                </div>
            </div>
        </div>
        <div class="tableContainer">
            <pill classList="tablePill fullHeight">
                <dataTable [forceUpdateWidth]="forceUpdateWidth" [isLoading]="isLoadingST" (deleteSelected)="deleteSelected($event)"  [data]="dataSeguimiento" classList="max500" (delete)="OpenDeletePopup($event)"  (edit)="editSeguimiento($event)" [displayedColumns]="displayedColumnsSeguimiento" [options]="optionsSeguimiento" (clickRow)="openSeguimientoTecnicoDetailView($event)"></dataTable>
            </pill>
            <div class="btnSeparatorContainer withoutMarginLeft">
                <btn classList="bigBtn" (click)="navigateST()">ver todo</btn>
                <btn classList="iconBtn plusBtn" (click)="addST()">
                    <i class="material-icons">add</i>
                    <span>añadir nueva</span>
                </btn>
            </div>
        </div>
    </div>
</FullContainer>