import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import {
  iDocumento,
  iDocumentoCreate,
} from 'src/app/Shared/Interfaces/Api/iDocumento';
import { iTipoDocumento } from 'src/app/Shared/Interfaces/Api/iTipoDocumento';

@Injectable({
  providedIn: 'root',
})
export class DocumentoService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultTipoDocumentos = new BehaviorSubject<any>(null);
  getResultTipoDocumentos() {
    return this._resultTipoDocumentos;
  }
  clearResultTipoDocumentos() {
    this._resultTipoDocumentos.next(null);
  }

  protected _resultDocumentosProveedoresObras = new BehaviorSubject<any>(null);
  getResultDocumentosProveedoresObras() {
    return this._resultDocumentosProveedoresObras;
  }
  clearResultDocumentosProveedoresObras() {
    this._resultDocumentosProveedoresObras.next(null);
  }

  protected _resultCarpetas = new BehaviorSubject<any>(null);
  getResultCarpetas() {
    return this._resultCarpetas;
  }
  clearResultCarpetas() {
    this._resultCarpetas.next(null);
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    this.http.get(this.urlApi + '/api/documento', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedDocumento =
          iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iDocumento.convertFromCollection(normalizedDocumento),
        });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultError', this.res, {
          method: this.getAll,
          args: [],
        });
        this.checkStatusError(error);
      },
    });
  }

  getAllTiposDocument() {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(
        this.urlApi + '/api/tipo/documento' + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedDocumento =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iTipoDocumento.convertFromCollection(normalizedDocumento),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllTiposDocument,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllDocumentByObra(
    id: number,
    busqueda: string = '',
    estado: string = '',
    fechaIni: string = '',
    fechaFin: string = ''
  ) {
    let send = {
      num_devoluciones: 500000,
      num_pagina: 1,
      busqueda: busqueda,
      estado: estado,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
    };
    this.http
      .get(
        this.urlApi + '/api/documento/tabla/' + id + this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedDocumento = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedDocumento, total: data.total },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllDocumentByObra,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllDocumentByProveedorObra(idProveedor: number, idObra: number) {
    let send = { num_devoluciones: 500000, num_pagina: 1 };
    this.http
      .get(
        this.urlApi +
          '/api/documento/proveedor/' +
          idProveedor +
          '/' +
          idObra +
          this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedDocumento =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedDocumento,
          });
          this.sendNextObservable(
            this._resultDocumentosProveedoresObras,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultDocumentosProveedoresObras,
            this.res,
            true,
            {
              method: this.getAllDocumentByProveedorObra,
              args: [idProveedor, idObra],
            }
          );
          this.checkStatusError(error);
        },
      });
  }
  getAllDocumentByProveedor(
    idProveedor: number,
    fechaIni: string = '',
    fechaFin: string = '',
    estado: string = ''
  ) {
    let send = {
      num_devoluciones: 500000,
      num_pagina: 1,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      estado: estado,
    };
    this.http
      .get(
        this.urlApi +
          '/api/user/documentos/proveedor/' +
          idProveedor +
          this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedDocumento =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedDocumento,
          });
          this.sendNextObservable(
            this._resultDocumentosProveedoresObras,
            this.res
          );
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(
            this._resultDocumentosProveedoresObras,
            this.res,
            true,
            { method: this.getAllDocumentByProveedor, args: [idProveedor] }
          );
          this.checkStatusError(error);
        },
      });
  }
  getByCarpeta(
    idCapreta: number,
    numPag: number = 1,
    max: number,
    busqueda: string = '',
    estado: string = '',
    tipo: number = 0,
    fechaIni: string = '',
    fechaFin: string = ''
  ) {
    let send = {
      num_devoluciones: max,
      num_pagina: numPag,
      busqueda: busqueda,
      estado: estado,
      tipo: tipo,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
    };
    this.http
      .get(
        this.urlApi +
          '/api/documento/carpeta/' +
          idCapreta +
          this.optionsGet(send),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedDocumento = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { total: data.total, data: data.data },
          });
          this.sendNextObservable(this._resultCarpetas, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(this._resultCarpetas, this.res, true, {
            method: this.getByCarpeta,
            args: [idCapreta, numPag],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/documento/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedDocumento = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDocumento.convertFromObject(normalizedDocumento),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(documento: any) {
    this.http
      .post(this.urlApi + '/api/documento', documento, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [documento],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(documento: iDocumento) {
    this.http
      .put(
        this.urlApi + '/api/documento/' + documento.id,
        documento,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [documento],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'documentos_ids');
    this.http
      .delete(this.urlApi + '/api/documento', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
