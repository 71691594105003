import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { AñadirNuevoSTComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoSeguimientoTecnicoPopup/NuevoSeguimientoTecnicoPopup.component';
import { EditSeguimientoTecnicoPopupComponent } from 'src/app/Popups/EditPopup/EditSeguimientoTecnicoPopup/EditSeguimientoTecnicoPopup.component';
import { InfoEmpleadoService } from 'src/app/Services/Api/InfoEmpleado.service';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { iSeguimientoTecnico } from 'src/app/Shared/Interfaces/Api/iSeguimientoTecnico';
import {
  iDataTableColumns,
  iDataTableOptions,
} from 'src/app/Shared/Interfaces/iDataTable';
import { UserService } from 'src/app/Services/Api/User.service';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'AdminSegtoTecnicoLista',
  templateUrl: './AdminSegtoTecnicoLista.component.html',
  styleUrls: ['./AdminSegtoTecnicoLista.component.scss'],
})
export class AdminSegtoTecnicoListaComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input('dataTable') dataSeguimientoTecnico: Array<iSeguimientoTecnico> = [];
  isLoadingLista: boolean = false;

  @Output() refreshData = new EventEmitter();

  // DATA

  dataFromST: any = [];
  filteredData: any = [];
  dataForTable: any = [];

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  // Filtros

  busqueda: string = '';
  tecnico: string = '';
  address: string = '';
  estado: string = '';
  fechaIni: string = '';
  fechaFin: string = '';

  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: '',
  };

  // Selectores
  optionTecnico: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Técnico',
    search: true,
  };
  optionCliente: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Cliente',
    search: true,
  };
  optionAddress: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Dirección',
    search: true,
  };
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pendiente' },
      { id: 2, label: 'Contactado' },
      { id: 3, label: 'Visitado' },
      { id: 4, label: 'Entregado' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  //Tabla
  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Técnico', property: 'encargadoNombre' },
    { label: 'Fecha entrada', property: 'fechaEntrada' },
    {
      label: 'Ubicación',
      property: 'ubicacion',
      align: 'center',
      linkAction: (rowElem) => {},
    },
    { label: 'Contacto', property: 'contacto' },
    { label: 'Teléfono', property: 'telefono' },
    { label: 'Fecha visita', property: 'fechaVisita', align: 'center' },
    { label: 'Pedido por', property: 'ordenadoPor', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoPartes',
    },
  ];

  optionsObras: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canEdit: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private router: Router,
    private clienteSE: ClienteService,
    public usuarioSE: UserService,
    private empleadoSE: InfoEmpleadoService,
    private popup: PopupService,
    private seguimientoTecnicoSe: SeguimientoTecnicoService,
    private notificationSe: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getSeguimientoTecnico();
    this.getEmpleados();
    this.getCliente();
    this.getAddresses();
  }

  // EDIT

  OpenEditPopup(event: any): void {
    this.popup.openPopup(EditSeguimientoTecnicoPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.getSeguimientoTecnico();
        this._unsubInd3.next('');
      });
  }

  // DELETE

  OpenDeletePopup(event: any): void {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el seguimiento tecnico',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteST(event.id);
        this._unsubInd.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los Seguimientos técnicos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteST(ids);
        this._unsubInd9.next('');
      });
  }

  deleteST(obj: any) {
    this.seguimientoTecnicoSe.delete(obj);
    this.seguimientoTecnicoSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSe.showFeedBack(
          'El Seguimiento Tecnico ha sido borrado correctamente'
        );
        this.getSeguimientoTecnico();
        this.ngOnInit();
        this._unsubInd2.next('');
      });
    this.seguimientoTecnicoSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSe.showError(`${res.message}`);
        this._unsub.next('');
      });
  }

  /**
   * NUEVO SEGUIMIENTO TECNICO
   */

  newST() {
    this.popup.openPopup(AñadirNuevoSTComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd8))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.getSeguimientoTecnico();
        this._unsubInd8.next('');
      });
  }

  /**
   * Filtros
   */

  getTecnicoFromSelector(event: any) {
    if (event === null) {
      this.tecnico = '';
    }
    if (event !== null) {
      this.tecnico = event.label;
    }
    this.getTablaForFilters();
  }

  getAddressFromSelector(event: any) {
    if (event === null) {
      this.address = '';
    }
    if (event !== null) {
      this.address = event.label;
    }
    this.getTablaForFilters();
  }

  getEstadoFromSelector(event: any) {
    if (event === null) {
      this.estado = '';
    }
    if (event !== null) {
      this.estado = event.label;
    }
    this.getTablaForFilters();
  }

  getTablaForFilters() {
    this.isLoadingLista = true;
    this.seguimientoTecnicoSe.getAllTabla(
      this.pagina,
      this.maximo,
      this.busqueda,
      this.tecnico,
      this.address,
      this.estado,
      this.fechaIni,
      this.fechaFin
    );
    this.seguimientoTecnicoSe
      .getResult()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data, total } = res.data;
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;

        this.setter(data);
        this.isLoadingLista = false;
        this._unsubInd7.next('');
      });
  }

  // Datepickers

  dataFromPickerDesde(event: any) {
    if (!event) {
      this.fechaIni = '';
    }
    if (event !== null) {
      this.fechaIni = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }

  dataFromPickerHasta(event: any) {
    if (event === null) {
      this.fechaFin = '';
    }
    if (event !== null) {
      this.fechaFin = iDate
        .javascriptConvert(new Date(event.value))
        .toStringDate('JAP');
    }
    this.getTablaForFilters();
  }

  /**
   * Busqueda Seguimiento técnico
   */

  respuestaBusqueda(event: any) {
    if (event === null) {
      this.busqueda = '';
    }
    if (event !== null) {
      this.busqueda = event;
    }
    this.getTablaForFilters();
  }

  setter(data: any) {
    this.dataForTable = data;
  }

  /**
   * SEGUIMIENTO TECNICO DETAIL
   */

  openSeguimientoTecnicoDetailView(event: any) {
    let { id } = event;
    this.router.navigate([`admin/seguimiento/` + id]);
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'SeguimientoTecnico.xlsx');
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getTablaForFilters();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getTablaForFilters();
  }

  /**
   * API CALLS
   */

  // Get Empleados

  getEmpleados() {
    this.usuarioSE.getAdminTecnicos();
    this.usuarioSE
      .getResultEmpleados()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;

        let mappedEmpleados = data.map((elem: any) => {
          return { id: elem.id, label: elem.nombre };
        });
        this.optionTecnico.items = mappedEmpleados;
        this.isLoadingLista = false;

        this._unsubInd4.next('');
      });
  }

  // Get Clientes

  getCliente() {
    this.clienteSE.getAll();
    this.clienteSE
      .getResult()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        let mappedClientes = data.map((elem: any) => {
          return { id: elem.id, label: elem.nombre };
        });
        this.optionCliente.items = mappedClientes;
        this.isLoadingLista = false;

        this._unsubInd6.next('');
      });
  }

  // Get seguimientoTecnico

  getSeguimientoTecnico(): void {
    this.isLoadingLista = true;
    this.seguimientoTecnicoSe.getAllTabla(this.pagina, this.maximo);
    this.seguimientoTecnicoSe
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data, total } = res.data;
        this.dataFromST = data;
        this.dataForTable = data;
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;
        this.isLoadingLista = false;
        this._unsubInd.next('');
      });
  }

  // get addresses

  getAddresses() {
    this.seguimientoTecnicoSe.getAddresses();
    this.seguimientoTecnicoSe
      .getResultGetAddreses()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;

        let mappedAddresses = data.map((elem: any) => {
          return { id: elem.id, label: elem.ubicacion };
        });
        this.optionAddress.items = mappedAddresses;
        this.isLoadingLista = false;

        this._unsubInd2.next('');
      });
    this.seguimientoTecnicoSe
      .getResultError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        if (res.status != 401) {
          if (res.status == 404) {
            this.notificationSe.showError('No se han encontrado resultados');
          } else if (res.status == 500) {
            this.notificationSe.showError(
              'Se ha producido un error, intentalo más tarde.'
            );
          } else {
            this.notificationSe.showError(res.message);
          }
        }
        this._unsub.next('');
      });
  }
}
