<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup('modify')"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">Editar proveedor {{nombre}} {{apellidos}}</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Razón Social</label>
          <CustomInput placeholder="Razón social" [(ngModel)]="nombre" (change)="getFromInput('nombre', nombre)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Nombre Comercial</label>
          <CustomInput placeholder="apellidos" [(ngModel)]="apellidos" (change)="getFromInput('apellidos', apellidos)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>CIF</label>
          <CustomInput placeholder="CIF" [(ngModel)]="cif" (change)="getFromInput('dni', cif)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Dirección</label>
          <CustomInput placeholder="Dirección" [(ngModel)]="direccion" (change)="getFromInput('direccion', direccion)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Población</label>
          <CustomInput placeholder="Población" [(ngModel)]="poblacion" (change)="getFromInput('poblacion', poblacion)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Código postal</label>
          <CustomInput placeholder="Código postal" [(ngModel)]="codigoPostal" (change)="getFromInput('codigoPostal', codigoPostal)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email</label>
          <CustomInput placeholder="email" [(ngModel)]="mail" (change)="getFromInput('mail', mail)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Email Prevención</label>
          <CustomInput placeholder="Email Prevención" [(ngModel)]="emailPrevencion" (change)="getFromInput('emailPrevencion', emailPrevencion)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Teléfono</label>
          <CustomInput placeholder="telefono" [(ngModel)]="telefono" (change)="getFromInput('telefono', telefono)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>Teléfono 2</label>
          <CustomInput placeholder="teléfono 2" [(ngModel)]="telefono2" (change)="getFromInput('telefono2', telefono2)"></CustomInput>
        </div>
        <div class="inputForm">
          <label>IVA</label>
          <CustomInput [type]="'number'" placeholder="IVA" [(ngModel)]="iva" (change)="getFromInput('iva', iva)"></CustomInput>
        </div>


        
        <div class="inputForm">
          <label>Asignar obras</label>
          <selector class="sizeInput" [options]="optionsObras" [(ngModel)]="obras" (change)="obraFromSelector($event)"></selector>
        </div>
        </div>
        <!-- <div class="separatorGrid"></div> -->

      </div>
      <br><hr><br>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="forceClosePopup('modified')">
        <ng-container>
          <span>Finalizar Edición</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>