<FullContainer>
  <br>
  <h2 class="sectionTitle">obras</h2>
  <br>
  <filterActions [filterOpen]="isFilterOpen" [inputOptions]="filterText" [searchOpen]="isSearchOpen"
  (filterOpenChange)="changeFilterOpen($event)" (searchSend)="respuestaBusqueda($event)"
  (searchOpenChange)="changeSearchOpen($event)" [activeBtn]="false">
  <ng-container filterContent>
    <div class="containerSelectores">
      <selector [options]="optionCliente" (change)="getClienteFromSelector($event)"></selector>
      <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
    </div>
  </ng-container>
</filterActions>
<div class="paginacion">
  <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
  <div class="arrows">
    <ng-container class="arrowLeft">
      <mat-icon (click)="previousPage()">
        chevron_left
      </mat-icon>
    </ng-container>
    <ng-container class="arrowRight">
      <mat-icon (click)="nextPage()">
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
</div>

  <pill classList="tablePill">
      <dataTable [forceUpdateWidth]="forceUpdateWidth" [isLoading]="isLoading"[data]="dataObras" (clickRow)="navigateToPartesObras($event)"[displayedColumns]="displayedColumnsObras" (edit)="editObra($event)"  (delete)="deleteObra($event, 'single')" (deleteAll)="deleteObra($event, 'multiple')" (deleteSelected)="deleteObra($event, 'multipleSelected')" [options]="optionsObras"></dataTable>
  </pill>

</FullContainer>
