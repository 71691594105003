<filterActions [inputOptions]="filterText" (searchSend)="updateQuery()" (addNew)="nuevoParte()" (searchSend)="updateQuery()">
  <ng-container filterContent>
    <div class="containerSelectores">
      <selector [(ngModel)]="selectVehiculo" [options]="optionsVehiculo" (change)="updateQuery()"></selector>
      <selector [(ngModel)]="selectEmpleado" [options]="optionsEmpleado" (change)="updateQuery()"></selector>
      <selector [(ngModel)]="selectObra" [options]="optionsObra" (change)="updateQuery()"></selector>
      <selector [(ngModel)]="selectEstado" [options]="optionsEstado" (change)="updateQuery()"></selector>
    </div>
  </ng-container>
</filterActions>
<pill class="tablePil" classList="tablePill">
  <dataTable [data]="datePartes" (deleteSelected)="deleteSelected($event)" [displayedColumns]="displayedColumnsPartes" [options]="optionsPartes" [isLoading]="isLoading" (clickRow)="openParte($event)" (see)="openParte($event)" (delete)="OnclickDeleteButton($event)" (edit)="OnclickEditButton($event)"></dataTable>
</pill>