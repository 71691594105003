import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy, NotificationsService, sha256 } from '@quasar_dynamics/basic-designsystem';
import { UserService } from 'src/app/Services/Api/User.service';
import { takeUntil } from 'rxjs/operators';
 



@Component({
  selector: 'EditarContrasenyaPopup',
  templateUrl: './EditarContrasenyaPopup.component.html',
  styleUrls: ['./EditarContrasenyaPopup.component.scss']
})
export class EditarContrasenyaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  password:string = ""
  password2:string = ""
  passwordActual:string = ""
  disabled:boolean = true


  objectToPass:any = {
    id: this.modalData.id,
    password: {}
  }
  constructor(public dialogRef: MatDialogRef<EditarContrasenyaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private userSE:UserService, private notificationsSE:NotificationsService) {
    super();
  }

  ngOnInit() {
  }

  getDataFromInput(event:any, key:string){
    if(!this.checkPasswords())return;
  }

  checkPasswords():boolean{
    if((this.password === this.password2) && (this.password !== "" && this.password2 !== "") && (this.passwordActual !== this.password ) && (this.passwordActual !== "" && this.password !== "" )){
      this.disabled = false
      return true
    }
    if((this.passwordActual === this.password ) && (this.passwordActual !== "" && this.password !== "" )){
      this.notificationsSE.showWarning("La contraseña actual no puede ser igual a la nueva")
      this.disabled = true
      return false
    }
    if((this.password !== this.password2) && (this.password !== "" && this.password2 !== "")){
      this.notificationsSE.showWarning("La contraseña nueva no coincide con la confirmación")
      this.disabled = true
      return false
    }
     
    return false
  }

  closePopup(dataReturn?:any){
    let p={dataReturn:dataReturn};
    this.dialogRef.close(p);
  }
  forceClosePopup(){
    this.dialogRef.close();
  }

  editarContrasenya(){
    this.objectToPass.password['newPassword'] = sha256(this.password)
    this.objectToPass.password['oldPassword'] = sha256(this.passwordActual)
    this.userSE.update(this.objectToPass);
    this.userSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res) return;
      this.notificationsSE.showFeedBack("Contraseña actualizada correctamente")
      this.closePopup(res)
      this._unsubInd.next("");
    })
    this.userSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res=>{
      if(!res) return;
      this.notificationsSE.showError(res.message)
      this._unsubInd.next("");
    })
  }

}
