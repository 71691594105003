<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="forceClosePopup()"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR INCIDENCIA </h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">        
        
        <div class="inputForm">
          <label>Descripcion</label>
          <CustomInput [(ngModel)]="descripcion" placeholder="Descripcion"  (change)="getDataFromInput($event, 'descripcion')"></CustomInput>
        </div>
        
        <div class="inputForm">
          <label>Estado</label>
          <selector placeholder="Estado" class="sizeInput" [options]="optionEstado" (change)="getEstadoFromSelector($event, 'estado')" ></selector>
        </div>
        <div class="inputForm">
          <label>Fecha</label>
          <DatePicker (dataFromPicker)="dataFromPicker($event, 'fecha' )" [dataPlaceholder]="fecha" ></DatePicker>
        </div>
      </div>
      
      <div class="separatorGrid"></div>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn classList="contactButton" (click)="closePopup('refresh')">
        <ng-container>
          <span>CERRAR</span>
        </ng-container>
      </btn>
    </div>
  </div>