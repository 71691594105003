import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-NuevoProveedorPopup',
  templateUrl: './NuevoProveedorPopup.component.html',
  styleUrls: ['./NuevoProveedorPopup.component.scss']
})
export class NuevoProveedorPopupComponent extends iUnsubscribeDestroy implements OnInit {

  /**
   * NGMODELS
   */

  nombre!:string;
  apellidos!:string;
  telefono!:string;
  telefono2!:string;
  iva!:string;
  mail!:string;
  emailPrevencion!:string;
  dni!:string;
  direccion!:string;
  poblacion!:string;
  codigoPostal!:string;
  obras!:any;
  password!:string;
  checkbox!:any;

  optionsObras: iOptionsSelector = {
    items: this.obras,
    clearable: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 1,
      multipleTextReplacer: "Varias obras seleccionados"
    },
    placeholder: 'Obras',
    append:true
  }

  constructor(public dialogRef: MatDialogRef<NuevoProveedorPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService, private userSE:UserService, private obraSE:ObraService) {
    super();
  }

  ngOnInit() {
    this.getAllObras()
  }

  closePopup(){
    this.dialogRef.close();
  }

  forceClosePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  obraFromSelector(event: any) {
     
    this.obras = event;
  }



  submit(){
    if(this.nombre ==="" || this.apellidos ==="" || this.telefono ==="" || this.mail ==="" || this.dni ==="" || this.obras ===""){
      this.notificationSer.showWarning("Rellene todos los campos")
      return
    }
    let obj = {
      nombre: this.nombre,
      apellidos: this.apellidos,
      telefono: this.telefono,
      mail: this.mail,
      dni: this.dni,
      obra: (this.obras)? this.obras.map((item:any)=>item.id):"",
      role: "ROLE_PROVEEDOR",
      validacionAuto: this.checkbox,
      direccion: this.direccion,
      poblacion: this.poblacion,
      codigoPostal: this.codigoPostal,
      emailPrevencion: this.emailPrevencion,
      telefono2: this.telefono2,
    }


    this.userSE.create(obj);
    this.userSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      this.notificationSer.showFeedBack("Proveedor creado correctamente");
      this.forceClosePopup(res)
      this._unsubInd.next("")
    })
    this.userSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return}
      this.notificationSer.showError(res.message);
      this._unsub.next("")
    })
    
  }

  /**
   * API CALLS
   */

  getAllObras(){
    this.obraSE.getAll()
    this.obraSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      let {data} = res;
       
      let mappedData = data.map((item:any)=>{return {id: item.id,label: `${item.id} - ${item.concepto}`}})
      this.optionsObras.items = mappedData;
      this._unsubInd.next("")
    })
  }

}
