<FullContainer>
  <div class="sectionTitleContainer">
    <mat-icon class="material-icons-circle" (click)="returnVehiculos()">arrow_back</mat-icon>
    <h2 class="sectionTitleCustom">{{dataSingle.nombre}}</h2>
  </div>
  <div class="sectionsProfile">
    <div>
      <div class="userInformationContainer">
        <div class="userGeneralInformation">
          <h3 class="transparentHeader">Información general</h3>
          <pill classList="fullHeight">
            <div class="informationContainer userInformation">
              <i class="material-icons editIcon" (click)="editVehiculo($event)">edit</i>
              <div class="informationLabel">
                <label>Matricula</label>
                <span>{{dataSingle.matricula}}</span>
              </div>
              <!-- <div class="informationLabel">
                <label>Usuario Asignado</label>
                <span>{{dataSingle.usuario.nombre + " " + dataSingle.usuario.apellidos}}</span>
              </div> -->
            </div>
          </pill>
        </div>
        <div class="userDocuments">
          <h3>Documentos requeridos</h3>
          <pill classList="fullHeight">
            <div class="informationContainer documentoRequeridos">
              <div class="documento">
                <div class="documento_title">
                  <span (click)="seeDocument('seguro')" class="cursor-pointer"><b>Seguro</b></span>
                  <btn classList="secundario fino" (click)="adjuntarDocumentosVehiculos('seguro')" >adjuntar</btn>
                </div>
                <div class="documento_caducidad" *ngIf="documentosRequeridos.seguro.length !== 0">
                  <span>Fecha de caducidad: {{documentosRequeridos.seguro.fechaCaducidad | date:'dd/MM/yyyy'}}</span>
                </div>
                <div class="warningContainer" *ngIf="documentosRequeridos.seguro == 0">
                  <i class="material-icons-outlined">report_problem</i>
                  <span>Documento Seguro faltante</span>
                </div>
              </div>
              <div class="documento">
                <div class="documento_title">
                  <span (click)="seeDocument('itv')" class="cursor-pointer"><b>ITV</b></span>
                  <btn classList="secundario fino" (click)="adjuntarDocumentosVehiculos('itv')" *ngIf="documentosRequeridos.itv !== 0">adjuntar</btn>
                </div>
                <div class="documento_caducidad" *ngIf="documentosRequeridos.itv.length !== 0">
                  <span>Fecha de caducidad: {{documentosRequeridos.itv.fechaCaducidad | date:'dd/MM/yyyy'}}</span>
                </div>
                  <div class="warningContainer" *ngIf="documentosRequeridos.itv.length === 0">
                    <i class="material-icons-outlined">report_problem</i>
                    <span>Documento ITV Faltante</span>
                  </div>
              </div>
            </div>
          </pill>
        </div>
      </div>
    </div>
    <!-- <div class="userNominas">
      <h3>Incidencias</h3>
      <pill class="tablePil" classList="fullHeight tablePill">
        <dataTable [isLoading]="isLoadingTabla" [data]="dataIncidencias"
          [displayedColumns]="displayedColumnsIncidencias" [options]="optionsIncidencias"
          (edit)="editIncidencia($event)" (delete)="deleteIncidenciaPopup($event)"
          (deleteSelected)="deleteSelected($event)"></dataTable>
      </pill>
    </div> -->
  </div>
</FullContainer>