import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iOptionsSelector, iResultHttp, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';

@Component({
  selector: 'app-JefeDocumentosObras',
  templateUrl: './JefeDocumentosObras.component.html',
  styleUrls: ['./JefeDocumentosObras.component.scss']
})
export class JefeDocumentosObrasComponent  extends iUnsubscribeDestroy implements OnInit {

  cursorPointer: string = "cursor-pointer";

  selectTypeDocument = null;
  nombre:string = "Cargando..."
  isLoading: boolean = false;
  isReloadingTable: boolean = false;
  // PARAMS SELECTOR

  tipo:number = -1;
  estado:string = ""

  //#region Carpetas

  loadingTipoDocumentos = true;

  isDocumentoSel = null;


  //#region Documentos de carpetas

  loadingDocumentos = false;
  isFilterOpen = false;
  isSearchOpen = false;
  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: ''
  }

  // Selectores
  optionTipoDoc: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Tipo de doc.'
  }
  optionEstado: iOptionsSelector = {
    items: [
      {id:1,label:"Validado"},
      {id:2,label:"Pendiente"},
      {id:3,label:"Pendiente de firma"},
      {id:4,label:"Revisión"},
      {id:5,label:"Rechazado"},
      {id:6,label:"Caducado"},
    ],
    clearable: true,
    placeholder: 'Estado'
  }
  //Tabla
  displayedColumnsDocumentos: Array<iDataTableColumns> = [
    { label: 'Tipo de doc.', property: 'nombre' },
    { label: 'Fecha doc', property: 'fechaRecortada', align: 'center' },
    { label: 'Versión', property: 'version', align: 'center', prefix: "Versión " },
    { label: 'Fecha caducidad', property: 'fechaCaducidadRecortada', align: 'center' },
    { label: 'Estado', property: 'estado', align: 'center', classList: 'tableEstado tableEstadoDocumentos' }
  ];

  optionsDocumentos: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    canSee: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsRow: [
      { icon: 'schedule', callback: (elem) => { } }
    ]
  }
  dataDocumentos:any = []
  forceUpdateWidthDocumentos = new Subject();

  //#endregion

  constructor(public documentosSe: DocumentoService,private obraSE:ObraService,public popup: PopupService, private notificationSer: NotificationsService, private router: Router, private activatedRoute: ActivatedRoute, private notificaciones:NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getSingleObra(this.activatedRoute.snapshot.paramMap.get('id')!)
    this.getDocumentosByObra();
  }

  returnCarpetas() {
    this.router.navigate(["/jefe/documentos"])
  }

  changeFilterOpen(newVal: boolean) {
    this.isFilterOpen = newVal;
    this.updateTableMaxHeight();
  }
  changeSearchOpen(newVal: boolean) {
    this.isSearchOpen = newVal;
    this.updateTableMaxHeight();
  }
  updateTableMaxHeight() {
    let val = "";
    if (this.isFilterOpen) {
      val = val + " - 68px - 1em";
    }
    if (this.isSearchOpen) {
      val = val + " - 68px - 1em";
    }
    this.optionsDocumentos.maxHeight = 'calc(100vh - 78px - 4em - 106px - 47px - 4em' + val + ')';
  }

  /**
   *
   * SELECTORES
   */

  getTipoDocFromSelector(event: any) {
  }

  getEstadoFromSelector(event: any) {
  }



  // FUNCIONALIDAD

  formatearFecha(fecha: any): any {
    let fechaRecortada = fecha.split("T");
    let fechaRecortadaSeparada = fechaRecortada[0].split("-");
    let fechaRecortadaReordenada = `${fechaRecortadaSeparada[2]}/${fechaRecortadaSeparada[1]}/${fechaRecortadaSeparada[0]}`
    return fechaRecortadaReordenada
  }
  openEditPopupDocumento(event:any){

  }

  parseDocumento(data:any){
    data.forEach((element:any) => {
      let obj: any = {
        idObra: element.idObra,
        nombre: element.nombre,
        fechaRecortada: new iDate(element.fecha).toStringDate("EU"),
        fechaCaducidadRecortada: new iDate(element.fechaCaducidad).toStringDate("EU"),
        idDocumento: element.idDocumento,
        version: element.version,
        estado: element.estado,
      }
      this.dataDocumentos.push(obj)
    });
  }

  /**
   * BUSQUEDA
   */

  respuestaBusqueda(event:any){

  }
  /**
   * API CALLS
   */
  addNewDopcument(){

  }

  getDocumentosByObra(){
    this.isLoading = true;
    this.isReloadingTable = true;
    this.documentosSe.getAllDocumentByObra(Number(this.activatedRoute.snapshot.paramMap.get('id')!))
    this.documentosSe.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: iResultHttp) => {
      if (res == null) { return }
      this.parseDocumento(res.data)
      this.isLoading = false;
      this.isReloadingTable = false;
      this._unsubInd2.next('')
    })
    this.documentosSe.getResultError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (res == null) { return }
      this.isLoading = false;
      this.isReloadingTable = false;
      this.notificaciones.showError(res.message)
      this._unsub.next('')
    })
  }
  getSingleObra(id: any) {
    this.obraSE.getSingle(id);
    this.isLoading = true;
    this.obraSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (res == null) { return }
      this.nombre = res.data.concepto + " - " + res.data.ubicacion

      this._unsubInd.next('')
    })
    this.obraSE.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe((res: iResultHttp) => {
      if (res == null) { return }
      this.isLoading = false;
      this.notificaciones.showError(res.message)
      this._unsub.next('')
    })
  }


}
