<div class="botonesCalendario">

  <div class="botonesArrow">
    <div class="arrowLeftRight">

      <div class="arrow" (click)="previousButton()">
        <ng-container>
          <mat-icon>
            chevron_left
          </mat-icon>
        </ng-container>
      </div>
      <div class="arrow" (click)="nextButton()">
        <ng-container>
          <mat-icon>
            chevron_right
          </mat-icon>
        </ng-container>
      </div>
    </div>
    <div class="botonHoy" (click)="goToHoy()">
      <p>Hoy</p>
    </div>
  </div>

  <div class="titulo">
    <p>{{returnMonth()}} {{year}}</p>
  </div>


  <div class="botonesMonthYearBlock">
    <div class="botonMonthYear" (click)="monthView()">
      <p>Mes</p>
    </div>
    <div class="botonMonthYear" (click)="weekView()">
      <p>Semana</p>
    </div>
    <div class="botonMonthYear" (click)="dayView()">
      <p>Día</p>
    </div>
    <div class="botonMonthYear" (click)="listView()">
      <p>Lista</p>
    </div>
  </div>


</div>
<full-calendar #calendario [options]="calendarOptions"></full-calendar>