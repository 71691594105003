import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AñadirNuevoSTComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoSeguimientoTecnicoPopup/NuevoSeguimientoTecnicoPopup.component';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';
import { iSeguimientoTecnico } from 'src/app/Shared/Interfaces/Api/iSeguimientoTecnico';

@Component({
  selector: 'app-AdminSegtoTecnico',
  templateUrl: './AdminSegtoTecnico.component.html',
  styleUrls: ['./AdminSegtoTecnico.component.scss']
})
export class AdminSegtoTecnicoComponent extends iUnsubscribeDestroy implements OnInit {


  submenuNavegation = [
    'LISTA',
    'MAPA',
    'PIPELINE'
  ];
  submenuSel = 0;

  dataObras = [
    { id: 1, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Pendiente', adjuntos: 'Ver adjuntos' },
    { id: 2, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Pendiente', adjuntos: 'Ver adjuntos' },
    { id: 3, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Pendiente', adjuntos: 'Ver adjuntos' },
    { id: 4, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Contactado', adjuntos: 'Ver adjuntos' },
    { id: 5, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Contactado', adjuntos: 'Ver adjuntos' },
    { id: 6, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Visitado', adjuntos: 'Ver adjuntos' },
    { id: 7, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Enviado', adjuntos: 'Ver adjuntos' },
    { id: 8, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Enviado', adjuntos: 'Ver adjuntos' },
    { id: 9, concepto: 'Reforma en Ruzafa', cliente: 'David García', tecnicoEncargado: 'Carla Moreno', fechaEntrada: '10/06/2022', fechaVisita: '10/06/2022', contacto: 'Antonio Muñoz', phone: '645812560', ubicacion: 'Calle del conde de Altea, 16, 46005 València, Valencia', estado: 'Enviado', adjuntos: 'Ver adjuntos' },
  ]
  tecnicos = [
    { id: 1, nombre: 'Carla Moreno' },
    { id: 2, nombre: 'Juan Muñoz' },
    { id: 3, nombre: 'Vicente' },
    { id: 4, nombre: 'José' },
    { id: 5, nombre: 'Enrique' },
    { id: 6, nombre: 'Lorena' },
    { id: 7, nombre: 'Miriam' },
    { id: 8, nombre: 'Juan' },
    { id: 9, nombre: 'Carlos' },
    { id: 10, nombre: 'Elena' }
  ];
  estados = [
    'Pendiente',
    'Contactado',
    'Visitado',
    'Enviado'
  ]

  constructor(private seguimientoTecnicoSe: SeguimientoTecnicoService, private popup: PopupService) {
    super();
  }

  ngOnInit() {
  }




}
