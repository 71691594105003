import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DepartamentosService } from 'src/app/Services/Api/Departamentos.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { VehiculoService } from 'src/app/Services/Api/Vehiculo.service';

@Component({
  selector: 'app-NuevoParteComponent',
  templateUrl: './NuevoParteComponent.component.html',
  styleUrls: ['./NuevoParteComponent.component.scss'],
})
export class NuevoParteComponentComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  fechaFromCalendar: boolean = true;

  /**
   * Data from father
   */

  data = this.modalData;

  // Modals
  nombre!: string;
  fecha = new Date().toISOString().slice(0, 10);
  inicio!: string;
  salida!: string;
  estado: string = this.modalData?.tipo !== 'nuevo' ? 'Pendiente' : '';
  usuario: string = this.modalData?.tipo !== 'nuevo' ? this.modalData?.id : '';
  vehiculo!: string;
  obra: string = this.modalData?.obraId ? this.modalData?.obraId : '';
  comentario!: string;
  imagen!: any;
  imagenes: Array<any> = [];
  tarea!: string;
  checkboxAusente: boolean = false;
  usuarioNombre: string =
    this.modalData?.tipo !== 'nuevo' ? this.modalData?.nombre : '';

  // boolean
  isloading: boolean = false;

  /**
   *
   * Opciones Selectores
   *
   */

  optionEstado: iOptionsSelector = {
    items: [
      { id: 0, label: 'Pendiente' },
      { id: 1, label: 'Validado' },
    ],
    placeholder: 'Estado',
    append: true,
  };

  optionUsuario: iOptionsSelector = {
    items: [],
    placeholder: 'Usuario',
    append: true,
  };
  optionVehiculo: iOptionsSelector = {
    items: [],
    placeholder: 'Vehiculo',
    append: true,
  };
  optionObra: iOptionsSelector = {
    items: [],
    placeholder: 'Obra',
    append: true,
  };
  optionTarea: iOptionsSelector = {
    items: [],
    placeholder: 'Tarea',
    append: true,
  };

  constructor(
    private tareaSE: DepartamentosService,
    private parteSE: ParteService,
    private obraSE: ObraService,
    private vehiculoSE: VehiculoService,
    private userSE: UserService,
    private notificationSE: NotificationsService,
    public dialogRef: MatDialogRef<NuevoParteComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }

  ngOnInit() {
    if (this.modalData.admin) {
      this.getUsers();
    } else {
      this.getUsersJefeDeObra();
    }
    this.getVehicles();
    this.getTareas();
    if (!this.modalData.obraId) {
      this.getObras();
      this.obra = this.modalData.obraId;
    }
  }

  /**
   * Data From fecha
   *
   */

  getDataFromFecha(data: any) {}

  /**
   * Add imagen
   */

  addImagen(event: any) {
    this.isloading = true;
    if (
      event[0].file.type != 'image/jpeg' &&
      event[0].file.type != 'image/png'
    ) {
      this.notificationSE.showWarning('Formato de archivo no admitido');
      this.imagen = null;
      this.isloading = false;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64,
        };
        if (this.imagenes.length == 0) {
          this.imagenes.push(obj);
          this.isloading = false;
        } else {
          this.imagenes.push(obj);
          this.isloading = false;
        }
      });
    }
  }

  /**
   * Submit
   */

  submitNuevoParte() {
    if (this.checkboxAusente) {
      let obj = {
        nombre: `Parte de ausencia de ${this.usuarioNombre}`,
        fecha: this.fecha,
        horaInicio: this.fecha,
        horaFin: this.fecha,
        horas: '0',
        tarea: 'Ausente',
        comentario: 'Ausente',
        imagenParte: [],
        estado: 'pendiente',
        usuario: this.usuario,
        cargadoBBDD: false,
      };

      this.createParte(obj);
      return;
    }

    if (
      !this.nombre ||
      !this.fecha ||
      !this.inicio ||
      !this.salida ||
      !this.estado ||
      !this.usuario ||
      !this.vehiculo ||
      !this.obra
    ) {
      this.notificationSE.showWarning('Rellene todos los campos');
      return;
    }

    let horaInicio =
      this.modalData.tipo === 'calendario'
        ? iDate
            .javascriptConvert(
              new Date(this.modalData.fecha + ' ' + this.inicio)
            )
            .toJavascript()
        : iDate
            .javascriptConvert(new Date(this.fecha + ' ' + this.inicio))
            .toStringHours();
    let horaFin =
      this.modalData.tipo === 'calendario'
        ? iDate
            .javascriptConvert(
              new Date(this.modalData.fecha + ' ' + this.salida)
            )
            .toJavascript()
        : iDate
            .javascriptConvert(new Date(this.fecha + ' ' + this.salida))
            .toStringHours();

    let horafinal: any = new Date(this.fecha + ' ' + this.salida);
    let horaInicial: any = new Date(this.fecha + ' ' + this.inicio);

    let horas = (horafinal - horaInicial) / 1000 / 60 / 60;

    let obj = {
      nombre: this.nombre,
      fecha: this.fecha,
      horaInicio: horaInicio,
      horas,
      horaFin: horaFin,
      tarea: this.tarea,
      estado: this.estado,
      usuario: this.usuario,
      vehiculo: this.vehiculo,
      cargadoBBDD: false,
      obra: this.obra,
      comentario: this.comentario,
      imagenParte: this.imagenes,
    };

    this.createParte(obj);
  }

  /**
   * Cerrar
   */

  closePopup() {
    this.dialogRef.close();
  }

  forceClosePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  /**
   *
   * Data From Input
   */

  obtenerEstado(event: any) {
    this.estado = event.label;
  }
  obtenerUsuario(event: any) {
    this.usuario = event.id;
  }
  obtenerVehiculo(event: any) {
    this.vehiculo = event.id;
  }
  obtenerObra(event: any) {
    this.obra = event.id;
  }
  obtenerTarea(event: any) {
    this.tarea = event.label;
  }

  getDataFromInput(key: string) {}

  /**
   * API Calls
   */

  getUsers() {
    this.userSE.getEmpleados();
    this.userSE
      .getResultEmpleados()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res.data;

        let mappedUsers = data.map((user: any) => {
          return { id: user.id, label: user.nombre };
        });
        this.optionUsuario.items = mappedUsers;
        this._unsubInd.next('');
      });
  }
  getUsersJefeDeObra() {
    this.userSE.getEmpleadosJefeDeObra(this.modalData.jefeDeObra);
    this.userSE
      .getResultEmpleados()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;

        let mappedUsers = data.map((user: any) => {
          return { id: user.id, label: user.nombre };
        });
        this.optionUsuario.items = mappedUsers;
        this._unsubInd.next('');
      });
  }

  getTareas() {
    this.tareaSE.getAll();
    this.tareaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        let mappedTareas = data.map((tarea: any) => {
          return { id: tarea.id, label: tarea.nombre };
        });
        this.optionTarea.items = mappedTareas;
        this._unsubInd4.next('');
      });
  }
  getVehicles() {
    this.vehiculoSE.getAll();
    this.vehiculoSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;

        let mappedVehiculo = data.map((vehiculo: any) => {
          return { id: vehiculo.id, label: vehiculo.nombre };
        });
        this.optionVehiculo.items = mappedVehiculo;
        this._unsubInd2.next('');
      });
  }
  getObras() {
    this.obraSE.getSelectorObras();
    this.obraSE
      .getResultObrasSelector()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        console.log(data);

        this.optionObra.items = data.map((obra: any) => {
          return { id: obra.id, label: obra.ubicacion };
        });
        this._unsubInd3.next('');
      });
    this.obraSE
      .getResultObrasSelectorError()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsubInd3.next('');
      });
  }

  createParte(obj: any) {
    this.parteSE.create(obj);
    this.parteSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res) {
          this.notificationSE.showFeedBack('Parte creado correctamente');
          this.forceClosePopup(res);
          this._unsubInd.next('');
        }
      });
    this.parteSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res) {
          this.notificationSE.showError(res.message);
          this.closePopup();
          this._unsub.next('');
        }
      });
  }
}
