<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">EDITAR FACTURA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm" *ngIf="!modalData.jefeObra">
          <label>Base imponible</label>
          <CustomInput placeholder="Importe" [(ngModel)]="importe" (change)="getInput($event,'importe')">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Notas</label>
          <CustomInput placeholder="Notas" [(ngModel)]="notas" (change)="getInput($event,'notas')">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Estado</label>
          <selector class="sizeInput" [options]="optionEstado" [(ngModel)]="estado" (change)="estadoFromSelector($event)"></selector>
        </div>

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="closePopup(false)">Editar Factura</btn>
    </div>
  </div>
</div>