<FullContainer>
  <br>
  <div class="subHeader">
    <h2 class="sectionTitle">{{nombre}}</h2>
    <toggleSubmenu [elements]="submenuNavegation" [(ngModel)]="submenuSel"></toggleSubmenu>
  </div>
  <div class="lineaButtons">

    <div class="buttonGaleria">
      <btn classList="bigBtn" (click)="verGaleria()" *ngIf="submenuSel === 0">galería fotos</btn>
    </div>
    <div class="bothButtons">
      <div class="buttonGaleria">
        <btn classList="bigBtn" (click)="validateParte()" *ngIf="submenuSel === 0">Validar</btn>
      </div>
      <div class="buttonGaleria">
        <btn classList="bigBtn" (click)="rejectParte()" *ngIf="submenuSel === 0">Rechazar</btn>
      </div>
    </div>
  </div>
  <br>
  <ng-container *ngIf="submenuSel === 0">
    <filterActions [activeBtn]="false" (searchSend)="busqueda($event)">
      <ng-container filterContent>
        <div class="containerSelectores">
          <div class="estado">
            <p>Estado</p>
            <selector [options]="optionsEstado" (change)="changeSelector($event, 'estado','label', 'filtro')">
            </selector>
          </div>
          <div class="redactor">
            <p>Redactor</p>
            <selector [options]="optionsRedactor" (change)="changeSelector($event, 'redactor','label', 'filtro')">
            </selector>
          </div>
          <div class="desde">
            <p>Desde</p>
            <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni', 'filtro')"></DatePicker>
          </div>
          <div class="hasta">
            <p>Hasta</p>
            <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin', 'filtro')"></DatePicker>
          </div>
        </div>
      </ng-container>
    </filterActions>
    <br>

    <pill classList="tablePill">
      <dataTable [isLoading]="isLoading" [data]="dataPartes" [displayedColumns]="displayedColumnsPartes"
        (edit)="editParte($event)" (clickRow)="downloadParte($event)" (see)="downloadParte($event)"
        (delete)="deleteParte($event, 'single')" (deleteAll)="deleteParte($event, 'multiple')"
        (deleteSelected)="deleteParte($event, 'multipleSelected')" [options]="optionsPartes" *ngIf="isReloadingTable">
      </dataTable>
      <dataTable [isLoading]="isLoading" [data]="dataPartes" [displayedColumns]="displayedColumnsPartes"
        (edit)="editParte($event)" (clickRow)="downloadParte($event)" (see)="downloadParte($event)"
        [options]="optionsPartes" (delete)="deleteParte($event, 'single')" (deleteAll)="deleteParte($event, 'multiple')"
        (deleteSelected)="deleteParte($event, 'multipleSelected')" *ngIf="!isReloadingTable"></dataTable>
    </pill>
  </ng-container>

  <ng-container *ngIf="submenuSel === 1">
    <filterActions [disableSearch]="true" (addNew)="addNewDocumento()">
      <ng-container filterContent>
        <div class="containerSelectores">
          <div class="desde">
            <p>Desde</p>
            <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni','filtroDocumento')"></DatePicker>
          </div>
          <div class="hasta">
            <p>Hasta</p>
            <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin','filtroDocumento')"></DatePicker>
          </div>
          <div>
            <p>Estado</p>
            <selector [options]="optionEstadoDocumento"
              (change)="changeSelector($event, 'estado','label','filtroDocumento')"></selector>
          </div>
        </div>
      </ng-container>
    </filterActions>
    <br>
    <pill class="tablePil tableDocumentos" classList="tablePill">
      <dataTable [isLoading]="isLoadingTabla" [data]="dataDocumentosTabla"
        [displayedColumns]="displayedColumnsDocumentos" [options]="optionsDocumentos" (edit)="editDocumento($event)"
        (delete)="openDeletePopupDocumento($event)" (deleteSelected)="deleteSelectedDocuments($event)"
        (clickRow)="seeDocumento($event)" (see)="seeDocumento($event)"></dataTable>
    </pill>

  </ng-container>

</FullContainer>