import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NominasService } from 'src/app/Services/Api/Nominas.service';
import { UserService } from 'src/app/Services/Api/User.service';

@Component({
  selector: 'app-NuevaNominaPopup',
  templateUrl: './NuevaNominaPopup.component.html',
  styleUrls: ['./NuevaNominaPopup.component.scss'],
})
export class NuevaNominaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  // NG-Model

  anyo: string = '';
  mes: string = '';
  empleado!: string;
  nomina!: string;

  // Selectores
  optionEmpleado: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Empleado.',
    append: true,
  };
  optionYear: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Año.',
    append: true,
  };
  optionMes: iOptionsSelector = {
    items: [
      { id: 1, label: 'Enero' },
      { id: 2, label: 'Febrero' },
      { id: 3, label: 'Marzo' },
      { id: 4, label: 'Abril' },
      { id: 5, label: 'Mayo' },
      { id: 6, label: 'Junio' },
      { id: 7, label: 'Julio' },
      { id: 8, label: 'Agosto' },
      { id: 9, label: 'Septiembre' },
      { id: 10, label: 'Octubre' },
      { id: 11, label: 'Noviembre' },
      { id: 12, label: 'Diciembre' },
    ],
    clearable: true,
    placeholder: 'Mes.',
    append: true,
  };

  // Object to submit

  submit: any = {
    anyo: new Date().getFullYear().toString(),
    mesToShow: this.optionMes.items[new Date().getMonth() - 1].label,
    mes: new Date().getMonth() - 1,
    user: '',
    url: '',
  };

  constructor(
    private nominaSE: NominasService,
    private notificationSE: NotificationsService,
    public userSE: UserService,
    public dialogRef: MatDialogRef<NuevaNominaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }

  ngOnInit() {
    this.getEmpleado();
    this.getYears();
    this.setAnyoMes();
  }

  getYears() {
    for (let index = 0; index < 5; index++) {
      this.optionYear.items.push({
        id: new Date().getFullYear() - index,
        label: new Date().getFullYear() - index,
      });
    }
  }

  // Setter

  setAnyoMes() {
    if (new Date().getMonth() == 0) {
      this.submit.mes = 12;
      this.submit.anyo = new Date().getFullYear() - 1;
    } else {
      this.submit.mes = new Date().getMonth();
      this.submit.anyo = new Date().getFullYear();
    }
  }

  /**
   * Funcionalidad
   */

  // Cerrar

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * INPUTS
   */

  getEmpleadoFromInput(event: any, key: string) {
    if (key === 'mes') {
      this.submit.mesToShow = this.optionMes.items[event.id - 1].label;
    }
    this.submit[key] = event.id;
  }
  addNomina(event: any, key: string) {
    if (event[0].file.type != 'application/pdf') {
      this.notificationSE.showWarning(
        'Formato de archivo no admitido, por favor suba un archivo pdf'
      );
      return;
    }
    this.nomina = event[0].file.name;
    this.submit[key] = event[0].base64;
  }

  /**
   *
   * SUBMIT
   */

  submitNuevaObra() {
    if (
      this.submit.user === '' ||
      this.submit.url === '' ||
      this.submit.anyo === '' ||
      this.submit.mes === ''
    ) {
      this.notificationSE.showWarning(
        'Por favor, rellene todos los campos y añada la nómina en formato correcto.'
      );
      return;
    }
    this.nominaSE.create(this.submit);
    this.nominaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack(
          'La nómina ha sido creada correctamente.'
        );
        this.dialogRef.close({ dataReturn: 'submit' });
        this._unsubInd2.next('');
      });
    this.nominaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getEmpleado() {
    this.userSE.getEmpleados();
    this.userSE
      .getResultEmpleados()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;

        let mappedData = data.map((item: any) => {
          return {
            id: item.id,
            label: item.nombre,
          };
        });
        this.optionEmpleado.items = mappedData;
        this._unsubInd.next('');
      });
  }
}
