import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iDocumento } from "./iDocumento";
import { iUser } from "./iUser";
 

export interface iVehiculo {
    id: number,
    nombre: string,
    matricula: string,
    caducidadSeguro: iDate,
    caducidadITV: iDate,
    incidencias: string,
    usuario?: iUser,
    documentos?:iDocumento,
}

export interface iVehiculoCreate{
    nombre: string;
    matricula: string;
    caducidadSeguro: string;
    caducidadITV: string;
    usuario: string;
}



export class iVehiculo extends iBaseObject {
    static convertFromCollection(ob: any): Array<iVehiculo> {
        let iVehiculoCollection: Array<iVehiculo> = [];
        ob.forEach((element: any) => {
            iVehiculoCollection.push(this.convertFromObject(element));
        });
        return iVehiculoCollection;
    }

    static convertFromObject(ob: any): iVehiculo {
        let iVehiculoObj = new iVehiculo();
        iVehiculoObj.id = ob.id;
        iVehiculoObj.nombre = ob.nombre;
        iVehiculoObj.matricula = ob.matricula;
        iVehiculoObj.caducidadSeguro = iDate.phpConvert(ob.caducidadSeguro);
        iVehiculoObj.caducidadITV = iDate.phpConvert(ob.caducidadItv);
        iVehiculoObj.incidencias = ob.incidencias;
        if(ob.usuario != null){

            iVehiculoObj.usuario = iUser.convertFromObject(ob.usuario);
        }
        if(ob.documentos){
            iVehiculoObj.documentos = ob.documentos;
        }
        return iVehiculoObj;
    }

}