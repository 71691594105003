import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NotificationsService,
  iOptionsSelector,
  iStaticUtilities,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ParteService } from 'src/app/Services/Api/Parte.service';

@Component({
  selector: 'app-EditParteValidarRechazar',
  templateUrl: './EditParteValidarRechazar.component.html',
  styleUrls: ['./EditParteValidarRechazar.component.scss'],
})
export class EditParteValidarRechazarComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Validado' },
      { id: 2, label: 'Rechazado' },
    ],
    clearable: false,
    placeholder: 'Seleccione un estado',
  };

  updateObject: any = {
    id: 0,
  };

  estadoSeleccionado: any = null;

  constructor(
    public dialogRef: MatDialogRef<EditParteValidarRechazarComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private parteSE: ParteService,
    private notificationSE: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.updateObject.id = this.modalData.id;
  }

  // CLOSE
  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  // API CALLS

  editEstado(event: any) {
    this.updateObject.estado = event.label;
    this.parteSE.update(this.updateObject);
    this.parteSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) return;
        this.notificationSE.showFeedBack('Estado actualizado correctamente');
        this._unsubInd.next('');
      });
    this.parteSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) return;
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }
}
