import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { IncidenciaService } from 'src/app/Services/Api/Incidencia.service';

@Component({
  selector: 'app-NuevaIncidenciaPopup',
  templateUrl: './NuevaIncidenciaPopup.component.html',
  styleUrls: ['./NuevaIncidenciaPopup.component.scss'],
})
export class NuevaIncidenciaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  descripcion!: string;
  estado!: string;
  fecha: string = new Date().toISOString().split('T')[0];
  vehiculo = this.modalData.id;

  // Selector

  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pendiente' },
      { id: 2, label: 'Finalizado' },
    ],
    placeholder: 'Estado',
    multiple: {
      isMultiple: false,
      checkbox: true,
    },
    clearable: false,
    search: true,
    append: true,
  };

  constructor(
    private incidenciaSE: IncidenciaService,
    private notificationSE: NotificationsService,
    public dialogRef: MatDialogRef<NuevaIncidenciaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    super();
  }

  ngOnInit() {}

  /**
   * CERRAR
   */

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * SELECTOR
   */

  getEstadoFromSelector(event: any) {
    this.estado = event.label;
  }

  /**
   * SUBMIT
   */

  submitNuevaIncidencia() {
    if (
      this.descripcion == undefined ||
      this.estado == undefined ||
      this.fecha == undefined ||
      this.vehiculo == undefined
    ) {
      this.notificationSE.showWarning(
        'Por favor, rellena todos los campos antes de enviar el formulario.'
      );
    }
    let obj = {
      descripcion: this.descripcion,
      estado: this.estado,
      fecha: this.fecha,
      vehiculo: this.vehiculo,
    };

    this.incidenciaSE.create(obj);
    this.incidenciaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showFeedBack('Incidencia creada correctamente.');
        this.closePopup('submitted');
        this._unsubInd.next('');
      });
    this.incidenciaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }
}
