<div class="globalPopupContainer boxInsider">
  <div class="closePopUpIcon" (click)="closePopup(false)"></div>
  <div class="popupContent">
    <div class="popupHead">
      <h3 class="popupTitle">AÑADIR NUEVA CARPETA</h3>
    </div>
    <div class="popupBody">
      <div class="formGrid">
        <div class="inputForm">
          <label>Nombre</label>
          <CustomInput placeholder="Nombre" [(ngModel)]="nombre" (change)="getDataFromInput('nombre')">
          </CustomInput>
        </div>
        <div class="inputForm">
          <label>Descripción</label>
          <CustomInput placeholder="Descripción" [(ngModel)]="descripcion" (change)="getDataFromInput('descripcion')">
          </CustomInput>
        </div>

      </div>
      <br>
      <hr><br>
    </div>
    <div class="popupButtons">
      <btn (click)="submitNuevaCarpeta()">Añadir Nueva Carpeta</btn>
    </div>
  </div>
</div>