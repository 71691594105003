import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';

@Component({
  selector: 'app-EditCarpetaDocumentosPopup',
  templateUrl: './EditCarpetaDocumentosPopup.component.html',
  styleUrls: ['./EditCarpetaDocumentosPopup.component.scss']
})
export class EditCarpetaDocumentosPopupComponent extends iUnsubscribeDestroy implements OnInit {


  // DATA
  dataFromCarpeta = this.modalData

  // OBJECT TO PASS

    obj: any = {
    id: this.dataFromCarpeta.id
  }

  // INPUTS
  nombre: string = this.modalData.nombre;
  descripcion: string = this.modalData.descripcion;


  constructor(private carpetaSe: CarpetaService, public dialogRef: MatDialogRef<EditCarpetaDocumentosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService) {
    super();
  }
  ngOnInit() {
     
  }


  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  obtainChange(element:string, value:any){
    this.obj[element] = value
    this.updateCarpeta(this.obj);
  }

  updateCarpeta(obj:any){
    this.carpetaSe.update(obj);
    this.carpetaSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showFeedBack("La carpeta ha sido modificada correctamente")
      this._unsubInd.next("")
    })
    this.carpetaSe.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificationSer.showError(res.message)
      this._unsub.next("")
    })

  }
}
