import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import {
  iIncidencia,
  iIncidenciaCrear,
} from 'src/app/Shared/Interfaces/Api/iIncidencia';

@Injectable({
  providedIn: 'root',
})
export class IncidenciaService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    this.http
      .get(
        this.urlApi + '/api/incidencia?num_pagina=1&num_devoluciones=5000',
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedIncidencia =
            iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iIncidencia.convertFromCollection(normalizedIncidencia),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/incidenecia/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedIncidencia = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iIncidencia.convertFromObject(normalizedIncidencia),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(incidencia: iIncidenciaCrear) {
    this.http
      .post(this.urlApi + '/api/incidencia', incidencia, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [incidencia],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(incidencia: iIncidencia) {
    this.http
      .put(
        this.urlApi + '/api/incidencia/' + incidencia.id,
        incidencia,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [incidencia],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'ids');
    this.http
      .delete(this.urlApi + '/api/incidencia', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
