import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { EditObrasPopupComponent } from 'src/app/Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-JefeDocumentos',
  templateUrl: './JefeDocumentos.component.html',
  styleUrls: ['./JefeDocumentos.component.scss']
})
export class JefeDocumentosComponent extends iUnsubscribeDestroy implements OnInit {

  displayedColumnsObras: Array<iDataTableColumns> = [
    { label: 'Concepto', property: 'concepto' },
    { label: 'Cliente', property: 'cliente', align: 'center' },
    { label: 'Ubicación', property: 'ubicacion', align: 'center', linkAction: (rowElem) => { } },
    { label: 'Nº Obra', property: 'num_obra', align: 'center' },
    { label: 'Partes pendientes', property: 'partesPendientes', align: 'center', classList: 'tableEstado tableEstadoObras' }
  ];

  optionsObras: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
    moreIconsHead: [{ callback: () => { this.exportexcel() }, icon: "download" }],

  }

  dataObras = []
  isLoading: boolean = true;
  constructor(private obraSE: ObraService, private router: Router, private popup: PopupService,private notificaciones:NotificationsService) {
    super();
  }

  ngOnInit() {
    this.getAllObras();
  }

  /**
   * FUNCTIONALITIES
   */
  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.classList.contains('mat-checkbox-checked')
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Obras.xlsx');

  }
  editObra(event: Event) {
    this.popup.openPopup(EditObrasPopupComponent, event);
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      this.isLoading = true;
      this.getAllObras()
      this._unsubInd2.next("")
    })
  }

  deleteObra(event: any, type: string) {
    switch (type) {
      case 'single':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "la obra" });
        break;
      case 'multiple':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras" });
        break;
      case 'multipleSelected':
        this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras seleccionadas" });
        break;
    }
    this.popup.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return };
      switch (type) {

        case 'single':
        this.removeObra(event["id"]);
        break;
        case 'multiple':
          this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras" });
          break;
        case 'multipleSelected':
          this.popup.openPopup(AsegurarDeletePopupComponent, { nombre: "las obras seleccionadas" });
          break;
      }
      this._unsubInd2.next("")
    })
  }
  navigateToDocumentosObra(event:any){
    this.router.navigate(["/jefe/documentos/obra/"+event["id"]])
  }
  /**
   * API Calls
   */

  getAllObras() {
    this.obraSE.getAllTable();
    this.obraSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (res === null) { return }
      let { data } = res.data;
      this.dataObras = data;
      this.isLoading = false;
      this._unsubInd2.next("");
    })
  }
  removeObra(id: string[] | number[]): void {
    this.obraSE.delete(id);
    this.obraSE.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (res === null) { return; }
      this.notificaciones.showFeedBack("La obra ha sido borrado correctamente")
      this.ngOnInit();
      this._unsubInd4.next("")
    })
    this.obraSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return; }
      this.notificaciones.showError(res.message)
      this._unsub.next("")
    })
  }

}
