<FullContainer>
  <br>
  <h2 class="sectionTitle">FACTURAS</h2>
  <filterActions (searchSend)="respuestaBusqueda($event)" [inputOptions]="filterText" textBtn="Añadir empleado"
  (addNew)="nuevaFactura()">
  <ng-container filterContent>
    <div class="containerSelectores">
      <div class="estado">
        <p>Estado</p>
        <selector [options]="optionsEstado" (change)="changeSelector($event, 'estado')"></selector>
      </div>
      <div class="redactor">
        <p>Proyecto</p>
        <selector [options]="optionsProyecto" (change)="changeSelector($event, 'proyecto')"></selector>
      </div>
      <div class="desde">
        <p>Desde</p>
        <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni')"></DatePicker>
      </div>
      <div class="hasta">
        <p>Hasta</p>
        <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin')"></DatePicker>
      </div>
    </div>
  </ng-container>
</filterActions>
    <br>
    <div class="paginacion">
      <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
      <div class="arrows">
        <ng-container class="arrowLeft">
          <mat-icon (click)="previousPage()">
            chevron_left
          </mat-icon>
        </ng-container>
        <ng-container class="arrowRight">
          <mat-icon (click)="nextPage()">
            chevron_right
          </mat-icon>
        </ng-container>
      </div>
    </div>
    <pill classList="tablePill">
      <dataTable [isLoading]="isLoading" [data]="dataFacturas" (clickRow)="seeDocumento($event)"
        [displayedColumns]="displayedColumnsFacturas" (edit)="editFactura($event)" (delete)="deleteFactura($event, 'single')"
        (deleteAll)="deleteFactura($event, 'multiple')" (deleteSelected)="deleteFactura($event, 'multipleSelected')"
        [options]="optionsFacturas"></dataTable>
    </pill>

</FullContainer>