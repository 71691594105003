import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDate, iOptionsSelector, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ClienteService } from 'src/app/Services/Api/Cliente.service';
import { UserService } from 'src/app/Services/Api/User.service';
import { takeUntil } from 'rxjs';
import { SeguimientoTecnicoService } from 'src/app/Services/Api/SeguimientoTecnico.service';

@Component({
  selector: 'NuevoSeguimientoTecnicoPopup',
  templateUrl: './NuevoSeguimientoTecnicoPopup.component.html',
  styleUrls: ['./NuevoSeguimientoTecnicoPopup.component.scss']
})
export class AñadirNuevoSTComponent extends iUnsubscribeDestroy implements OnInit {

  /**
   * NG MODEL
   */

  concepto: string = '';
  calle: string = '';
  numero!: number;
  codigoPostal: string = '';
  localidad: string = '';
  contacto: string = '';
  telefono!: number;
  comentario: string = '';
  estado: string = 'Pendiente';
  // cliente!: number;
  encargado: number | null = null;
  fechaVisita: string = '';
  fechaObjetivo: string = '';
  fechaEntrada: string = iDate.javascriptConvert(new Date()).toStringDate('EU');
  presupuesto!: number;
  orderedBy: string = '';
  email: string = '';
  horaVisita: any = '';

  /**
   * IMAGENES
   */

  imagen: any = '';
  imagenes: Array<any> = [];


  /**
   * PLACEHOLDERS
   */

  placeholderFechaVisita: string = new Date().toISOString().split('T')[0];
  placeholderFechaEntrada: string = new Date().toISOString().split('T')[0];

  // Opciones del selector
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: "Pendiente" },
      { id: 2, label: "Contactado" },
      { id: 3, label: "Visitado" },
      { id: 4, label: "Entregado" },
    ],
    clearable: false,
    placeholder: "Estado",
    append: true,
    search: true,
    bindValue: "label"
  }

  optionCliente: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "Cliente",
    append: true,
    search: true
  }

  optionEncargado: iOptionsSelector = {
    items: [],
    clearable: false,
    placeholder: "Encargado",
    append: true,
    search: true
  }


  constructor(private popup: PopupService, private notificationSE: NotificationsService, public dialogRef: MatDialogRef<AñadirNuevoSTComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private clienteSE: ClienteService, private userSE: UserService, private seguimientoTecnicoSE: SeguimientoTecnicoService) {
    super();
  }

  ngOnInit() {
    this.getAllClientes();
    this.getAllUsers();

  }

  /**
   * SUBMIT
   */

  submitNuevoST() {
    // if (this.concepto == '' || this.localidad =="" || this.contacto == '' || this.telefono == 0 || this.comentario == '' || this.estado == '' || this.cliente == 0 || this.encargado == 0 || this.fechaEntrada == '' || this.presupuesto == 0 || this.calle == '' || this.numero == 0 || this.codigoPostal == '') {
    //   this.notificationSE.showWarning('Debes rellenar todos los campos',);
    //   return;
    // }

    let ubicacion = `C/${this.calle} ${this.numero}, ${this.codigoPostal} ${this.localidad}`

    let objectToPass: any = {}

    if (this.concepto) objectToPass['concepto'] = this.concepto;
    if (this.calle && this.numero && this.codigoPostal && this.localidad) objectToPass['ubicacion'] = ubicacion;
    if (this.contacto) objectToPass['contacto'] = this.contacto;
    if (this.telefono) objectToPass['telefono'] = this.telefono;
    if (this.comentario) objectToPass['comentario'] = this.comentario;
    if (this.estado) objectToPass['estado'] = this.estado;
    if (this.encargado) objectToPass['encargado'] = this.encargado;
    if (this.fechaVisita) objectToPass['fechaVisita'] = this.fechaVisita;
    if (this.fechaObjetivo) objectToPass['fechaObjetivo'] = this.fechaObjetivo;
    if (this.fechaEntrada) objectToPass['fechaEntrada'] = this.fechaEntrada;
    if (this.presupuesto) objectToPass['presupuesto'] = this.presupuesto;
    if (this.imagenes) objectToPass['imagenSeguimiento'] = this.imagenes;
    if (this.orderedBy) objectToPass['ordenadoPor'] = this.orderedBy;
    if (this.email) objectToPass['email'] = this.email;
    if (this.horaVisita) objectToPass['horaVisita'] = this.horaVisita;



    this.seguimientoTecnicoSE.create(objectToPass);
    this.seguimientoTecnicoSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res === null) { return }
      this.notificationSE.showFeedBack('Seguimiento Técnico creado correctamente');
      this.closePopup(res);
      this._unsubInd2.next("");
    })
    this.seguimientoTecnicoSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (res === null) { return }
      res.message
      this.notificationSE.showError(res.message);
      this._unsub.next("");
    })
  }

  /**
   * 
   * SELECTORES
   */

  obtenerEstado(event: any) {
    this.estado = event.label;
  }

  // obtenerCliente(event: any) {
  //   this.cliente = event.id;
  // }

  obtenerEncargado(event: any) {
    this.encargado = event.id;
  }

  /**
   * 
   * DATEPICKER
   * 
   *   */

  getFechaVisita(event: any) {
    this.fechaVisita = iDate.javascriptConvert(new Date(event.value)).toStringDate('EU');
  }
  getFechaObjetivo(event: any) {
    this.fechaObjetivo = iDate.javascriptConvert(new Date(event.value)).toStringDate('EU');
  }

  getFechaEntrada(event: any) {
    this.fechaEntrada = iDate.javascriptConvert(new Date(event.value)).toStringDate('EU');
  }


  // Close

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  /**
   * ADD DOCUMENT
   */

  addImagen(event: any) {

    if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png") {
      this.notificationSE.showWarning("Formato de archivo no admitido")
      this.imagen = null;
    } else {
      event.forEach((element: any) => {
        let obj = {
          url: element.base64
        }
        if (this.imagenes.length == 0) {
          this.imagenes.push(obj)
        } else {
          this.imagenes.push(obj)
        }
         
      });

    }

  }

  /**
   * API CALLS
   */

  getAllClientes() {
    this.clienteSE.getAll();
    this.clienteSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (res === null) { return }
      let { data } = res
      let dataForClienteInput = data.map((cliente: any) => {
        return { id: cliente.id, label: cliente.nombre }
      })
      this.optionCliente.items = dataForClienteInput
      this._unsubInd.next("")
    })
  }

  getAllUsers(): void {
    this.userSE.getAdminTecnicos();
    this.userSE.getResultEmpleados().pipe(takeUntil(this._unsubInd4)).subscribe((res: any) => {
      if (res === null) { return; }
      let { data } = res.data;
       
      let mappedUsers = data.map((d: any) => {
        return { id: d.id, label: d.nombre }
      })
      this.optionEncargado.items = mappedUsers;
      this._unsubInd4.next("")
    })
  }


}
