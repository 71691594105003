import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'VisorDeImagen',
  templateUrl: './VisorDeImagen.component.html',
  styleUrls: ['./VisorDeImagen.component.scss']
})
export class VisorDeImagenComponent implements OnInit {

  url: string = "";

  constructor(public dialogRef: MatDialogRef<VisorDeImagenComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    this.url = this.modalData.url
  }

  forceClosePopup(){
    this.dialogRef.close();
  }

}
