import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { TipoDocumentoService } from 'src/app/Services/Api/TipoDocumento.service';

@Component({
  selector: 'app-EditTipoDeDocPopup',
  templateUrl: './EditTipoDeDocPopup.component.html',
  styleUrls: ['./EditTipoDeDocPopup.component.scss'],
})
export class EditTipoDeDocPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  nombre: string = this.modalData.label;

  objectToPass: any = {
    id: this.modalData.id,
  };

  optionVinculado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Usuario' },
      { id: 2, label: 'Vehículo' },
      { id: 3, label: 'Obra' },
    ],
    clearable: true,
    placeholder: 'Vinculado a...',
    multiple: {
      isMultiple: false,
      checkbox: false,
    },
    search: true,
  };

  constructor(
    private dialogRef: MatDialogRef<EditTipoDeDocPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private tipoDeDocSE: TipoDocumentoService,
    private notificationSE: NotificationsService
  ) {
    super();
  }

  ngOnInit() {}

  inputEdit(event: any, key: string) {
    this.objectToPass[key] = event.label;
    this.editTipoDoc();
  }

  editTipoDoc() {
    this.tipoDeDocSE.update(this.objectToPass);
    this.tipoDeDocSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack(
          'Tipo de documento editado correctamente'
        );
        this._unsubInd.next('');
      });
    this.tipoDeDocSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  closePopup(dataReturn?: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  getVinculado(event: any) {}
}
