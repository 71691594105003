<FullContainer>
  <div class="sectionTitleContainer">
    <div class="titleSec">
      <i class="material-icons" (click)="returnProveedores()">arrow_back</i>
      <h2 class="sectionTitle">Proveedores</h2>
      <toggleSubmenu [elements]="submenuNavegation" [(ngModel)]="submenuSel" ></toggleSubmenu>
    </div>
  </div>
  <app-ProveedorSeeEditDocumentos [dataFromSingle]="dataFromSingle" *ngIf="submenuSel === 0" (refreshData)="refreshData($event)"></app-ProveedorSeeEditDocumentos>
  <app-ProveedoresSeeEditDocumentosRequeridos [dataFromSingle]="dataFromSingle"*ngIf="submenuSel === 1" (refreshData)="refreshData($event)"></app-ProveedoresSeeEditDocumentosRequeridos>
  <app-ProveedorSeeEditFacturas [idFromUser]="idFromUser" *ngIf="submenuSel === 2"></app-ProveedorSeeEditFacturas>

  <!-- <app-ProveedorSeeEditDocumentos [dataFromSingle]="dataFromSingle"></app-ProveedorSeeEditDocumentos> -->
</FullContainer>