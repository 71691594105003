import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { takeUntil } from 'rxjs';
import * as XLSX from 'xlsx';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { EditDocumentosPopupComponent } from 'src/app/Popups/EditPopup/EditDocumentosPopup/EditDocumentosPopup.component';
import { NuevoDocumentoPorCarpetaComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoPorCarpeta/NuevoDocumentoPorCarpeta.component';
import { NuevoDocumentoPorObraComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoPorObra/NuevoDocumentoPorObra.component';
import { ActivatedRoute, Route, Routes } from '@angular/router';

@Component({
  selector: 'ObraDetailDocumentos',
  templateUrl: './ObraDetailDocumentos.component.html',
  styleUrls: ['./ObraDetailDocumentos.component.scss'],
})
export class ObraDetailDocumentosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() dataDocumentos: Array<any> = [];
  @Output() refreshData = new EventEmitter<boolean>();
  @Output() filters = new EventEmitter<any>();

  isLoadingTabla: boolean = false;
  obraId!: string;

  dataDocumentosTabla: any[] = [];

  displayedColumnsDocumentos: Array<iDataTableColumns> = [
    { label: 'Documento', property: 'documento' },
    { label: 'Tipo de doc.', property: 'tipoDocumento', align: 'center' },
    { label: 'Comentario', property: 'comentario', align: 'center' },
    { label: 'Fecha doc.', property: 'fechaDoc', align: 'center' },
  ];

  optionsDocumentos: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [
      {
        callback: () => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Aprobado' },
      { id: 2, label: 'Pendiente' },
      { id: 3, label: 'Rechazado' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  filtros: any = {
    buscador: '',
    estado: '',
    fechaIni: '',
    fechaFin: '',
  };

  constructor(
    private popup: PopupService,
    private documentosSe: DocumentoService,
    private notificationSer: NotificationsService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.obraId = this.activatedRoute.snapshot.paramMap.get('id')!;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataDocumentos']) {
      let data = changes['dataDocumentos'].currentValue;

      let mappedData = data.map((doc: any) => {
        return {
          ...doc,
          documento: doc.titulo,
          tipoDeDoc: doc.tipoDocumento,
          fechaDoc: iDate
            .javascriptConvert(new Date(doc.fecha))
            .toStringDate('EU'),
          version: doc.version,
          fechaCaducidad: iDate
            .javascriptConvert(new Date(doc.fechaCaducidad))
            .toStringDate('EU'),
          estado: doc.estado,
          url: doc.url,
        };
      });

      this.dataDocumentosTabla = mappedData;
    }
  }

  /**
   * Delete Documento
   */

  openDeletePopupDocumento(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el documento',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteDocumento([event.id]);
        this._unsubInd.next('');
      });
  }

  deleteSelectedDocuments(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los documentos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteDocumento(ids);
        this._unsubInd.next('');
      });
  }

  deleteDocumento(id: any[]) {
    this.documentosSe.delete(id);
    this.documentosSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El documento ha sido borrado correctamente'
        );
        this.refreshData.emit(true);
        this._unsubInd2.next('');
      });
    this.documentosSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * Add new Documento
   */

  addNewDocumento() {
    this.popup.openPopup(NuevoDocumentoPorObraComponent, this.obraId);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshData.emit(true);
        this._unsubInd2.next('');
      });
  }

  /**
   *
   * EDIT
   */

  openEditPopupDocumento(event: any) {
    this.popup.openPopup(EditDocumentosPopupComponent, {
      tipo: 'obra',
      data: event,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.refreshData.emit(true);
        this._unsubInd.next('');
      });
  }

  /**
   * FILTROS
   */

  dataFromPicker(event: any, key: string) {
    this.filtros[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('EU');
    this.filters.emit(this.filtros);
  }

  getFromSelector(event: any, key: string) {
    event === null
      ? (this.filtros[key] = '')
      : (this.filtros[key] = event.label);
    this.filters.emit(this.filtros);
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'DocumentosReforma.xlsx');
  }

  /**
   *
   * SEE DOCUMENTO
   *
   */

  seeDocumento(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   * BUSQUEDA
   */

  busqueda(event: any) {
    event === null
      ? (this.filtros.buscador = '')
      : (this.filtros.buscador = event);
    this.filters.emit(this.filtros);
  }
}
