<div class="fullView">
  <div class="headerBlock">
    <p class="headerText">{{conceptoObra ?? 'No hay documentos en esta obra'}}</p>
  </div>
  <div class="contentBlock">
    <div class="whiteblock">
      <div class="eachParte" id="{{index}}" (click)="openDocumento(documento)" *ngFor="let documento of dataDocumentos; let index = index" id="{{documento.id}}" >
        <div class="lines">
          <p class="lineaParte fw-600">{{documento.titulo}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="footerBlock">
    <Footer></Footer>
  </div>
</div>