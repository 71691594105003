import { Component, Input, OnInit } from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaFacturaPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaFacturaPopup/NuevaFacturaPopup.component';
import { EditFacturaPopupComponent } from 'src/app/Popups/EditPopup/EditFacturaPopup/EditFacturaPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { FacturaService } from 'src/app/Services/Api/Factura.service';
import { ObraService } from 'src/app/Services/Api/Obra.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ProveedorSeeEditFacturas',
  templateUrl: './ProveedorSeeEditFacturas.component.html',
  styleUrls: ['./ProveedorSeeEditFacturas.component.scss'],
})
export class ProveedorSeeEditFacturasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() idFromUser!: number;

  forceUpdateWidth = new Subject();

  /**
   * data
   */

  // Paginacion

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  isLoading: boolean = false;

  dataFacturas: any = [];

  filterText = {
    placeholder: 'Buscar por número de factura ',
    model: '',
  };

  // Filtros

  filtros: any = {
    fechaIni: '',
    fechaFin: '',
    estado: '',
    proyecto: '',
    busqueda: '',
  };

  // Selectores

  optionsEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pdte. Validación' },
      { id: 2, label: 'Rechazada' },
      { id: 3, label: 'Validada' },
      { id: 4, label: 'Contabilizada' },
      { id: 5, label: 'Aprobada' },
      { id: 6, label: 'Gestoría' },
    ],
    clearable: true,
    placeholder: 'Estado',
    append: true,
  };

  optionsProyecto: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Proyecto',
    append: true,
  };

  //Tabla
  displayedColumnsFacturas: Array<iDataTableColumns> = [
    { label: 'Nº Factura', property: 'numero' },
    { label: 'Fecha emisión', property: 'fechaEmision' },
    { label: 'Fecha vencimiento', property: 'fechaVencimiento' },
    { label: 'Base Imponible', property: 'importe' },
    { label: 'Proyecto asociado', property: 'proyecto' },
    { label: 'Notas', property: 'notas' },
    {
      label: 'Estado',
      property: 'estado',
      classList: ' tableEstado tableEstadoFacturas',
    },
  ];

  optionsFacturas: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [{ callback: () => {}, icon: 'download' }],
    canEdit: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private popup: PopupService,
    private facturaSE: FacturaService,
    private notificationSE: NotificationsService,
    private obrasSE: ObraService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllFacturasUser();
    this.getAllObras();
  }

  /**
   * DETAIL
   */

  navigateToFactura(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   *
   * FILTROS
   */

  // Busqueda
  respuestaBusqueda(event: any) {
    if (event === null) {
      this.filtros.busqueda = null;
      return;
    }
    this.filtros.busqueda = event;
    this.getAllFacturasUser();
  }

  // SELECTOR

  changeSelector(event: any, key: string) {
    if (event === null) {
      this.filtros[key] = '';
      this.getAllFacturasUser();
      return;
    }
    key === 'estado'
      ? (this.filtros.estado = event.label)
      : (this.filtros.proyecto = event.label);
    this.getAllFacturasUser();
  }

  // DatePicker

  dataFromPicker(event: any, key: string) {
    this.filtros[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    this.getAllFacturasUser();
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return row
        .querySelector('.checkboxContainer mat-checkbox ')!
        .classList.contains('mat-checkbox-checked');
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'ProveedoresFacturas.xlsx');
  }

  /**
   * NUEVA FACTURA
   */

  nuevaFactura() {
    this.popup.openPopup(NuevaFacturaPopupComponent, {
      id: this.idFromUser,
      tipo: 'proveedor',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  /**
   * EDIT
   */

  editFacturas(event: any) {
    this.popup.openPopup(EditFacturaPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2.next('');
      });
  }

  /**
   * DELETE
   */

  OnclickDeleteButton(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'la factura',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteFactura([event.id]);
        this._unsubInd.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'las facturas seleccionadas',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteFactura(ids);
        this._unsubInd.next('');
      });
  }

  deleteFactura(ids: any[]) {
    this.facturaSE.delete(ids);
    this.facturaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Factura eliminada correctamente');
        this.ngOnInit();
        this._unsubInd2.next('');
      });
    this.facturaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * NEXT PAGE
   */

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.getAllFacturasUser();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.getAllFacturasUser();
  }

  /**
   * API CALLS
   */

  // Facturas
  getAllFacturasUser() {
    this.isLoading = true;
    this.facturaSE.getAllProveedor(
      this.idFromUser,
      this.filtros.busqueda,
      this.filtros.fechaIni,
      this.filtros.fechaFin,
      this.filtros.estado,
      this.filtros.proyecto
    );
    this.facturaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data, total } = res.data;
        this.dataFacturas = data.map((item: any) => {
          return {
            ...item,
            fechaVencimiento: item?.fechaVencimiento
              ? iDate
                  .javascriptConvert(new Date(item?.fechaVencimiento))
                  .toStringDate('EU')
              : '-',
          };
        });
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;

        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1000);
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }

  // Proyectos

  getAllObras() {
    this.obrasSE.getAllTable();
    this.obrasSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res.data;
        this.optionsProyecto.items = data.map((item: any) => {
          return { id: item.id, label: `${item.concepto} ${item.ubicacion}` };
        });
        this._unsubInd2.next('');
      });
  }
}
