import { Component, OnInit } from '@angular/core';
import {
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { NuevaCarpetaPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaCarpetaPopup/NuevaCarpetaPopup.component';
import { NuevoDocumentoPorCarpetaComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoDocumentoPorCarpeta/NuevoDocumentoPorCarpeta.component';
import { NuevoTipoDeDocPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevoTipoDeDocPopup/NuevoTipoDeDocPopup.component';
import { EditCarpetaDocumentosPopupComponent } from 'src/app/Popups/EditPopup/EditCarpetaDocumentosPopup/EditCarpetaDocumentosPopup.component';
import { EditDocumentosPopupComponent } from 'src/app/Popups/EditPopup/EditDocumentosPopup/EditDocumentosPopup.component';
import { EditTipoDeDocPopupComponent } from 'src/app/Popups/EditPopup/EditTipoDeDocPopup/EditTipoDeDocPopup.component';
import { GaleriaFotosComponent } from 'src/app/Popups/GaleriaFotos/GaleriaFotos.component';
import { VerVersionesDocumentosPopupComponent } from 'src/app/Popups/VerVersionesDocumentosPopup/VerVersionesDocumentosPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { CarpetaService } from 'src/app/Services/Api/Carpeta.service';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { TipoDocumentoService } from 'src/app/Services/Api/TipoDocumento.service';
import { iTipoDocumento } from 'src/app/Shared/Interfaces/Api/iTipoDocumento';
import {
  iDataTableColumns,
  iDataTableOptions,
} from 'src/app/Shared/Interfaces/iDataTable';

@Component({
  selector: 'app-AdminDocumentos',
  templateUrl: './AdminDocumentos.component.html',
  styleUrls: ['./AdminDocumentos.component.scss'],
})
export class AdminDocumentosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  /**
   * TIPO DOCS
   */
  cursorPointer: string = 'cursor-pointer';
  loadingTipoDocs: boolean = false;
  dataTipoDocs: any = null;

  /**
   * SUBMENU SEL
   */

  submenuNavegation: any = ['CARPETAS', 'TIPO DE DOC.'];
  submenuSel: number = 0;

  /**
   * ROW
   */

  selectTypeDocument = null;
  carpetaId: number = -1;

  /**
   * FILTROS
   */

  tipoDeDocFiltro: number = 0;
  estadoFiltro: string = '';
  desdeFiltro: string = '';
  hastaFiltro: string = '';
  busquedaFiltro: string = '';

  /**
   * Paginacion
   */

  // Carpeta

  paginacionTotalCarpeta!: number;
  paginacionActualCarpeta!: number;
  numMaxPagCarpeta!: number;
  numMinCarpeta: number = 1;
  numMaxCarpeta: number = 20;

  paginaCarpeta: number = 1;
  maximoCarpeta: number = 20;

  // Documentos

  paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  // PARAMS SELECTOR

  tipo: number = -1;
  estado: string = '';

  //#region Carpetas

  loadingTipoDocumentos = false;

  isDocumentoSel = null;

  // IDCarpeta

  carpeta: any = {};

  //Tabla
  displayedColumnsTipoDocs: Array<iDataTableColumns> = [
    { label: 'Tipo de doc.', property: 'label' },
  ];

  optionsTipoDocs: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    maxHeight: 'calc(100vh - 78px - 4em - 106px)',
    maxHeightType: 'calc',
  };

  displayedColumnsTipoDocumentos: Array<iDataTableColumns> = [
    { label: 'Carpeta', property: 'nombre' },
    { label: 'Notas', property: 'descripcion' },
  ];

  optionsTipoDocumentos: iDataTableOptions = {
    canEdit: true,
    isHovered: true,
    maxHeight: 'calc(100vh - 78px - 4em - 106px)',
    maxHeightType: 'calc',
  };
  dataTipoDocumentos: any[] = [];

  //#endregion

  //#region Documentos de carpetas

  loadingDocumentos = false;
  isFilterOpen = false;
  isSearchOpen = false;
  filterText = {
    placeholder: 'Busca por concepto, Nº obra o ubicación',
    model: '',
  };

  // Selectores
  optionTipoDoc: iOptionsSelector = {
    items: [],
    clearable: true,
    placeholder: 'Tipo de doc.',
  };
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Aprobado' },
      { id: 2, label: 'Pendiente' },
      { id: 3, label: 'Rechazado' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };
  //Tabla
  displayedColumnsDocumentos: Array<iDataTableColumns> = [
    { label: 'Tipo de doc.', property: 'tipo' },
    { label: 'Fecha doc', property: 'fechaRecortada', align: 'center' },
    {
      label: 'Versión',
      property: 'version',
      align: 'center',
      prefix: 'Versión ',
    },
    {
      label: 'Fecha caducidad',
      property: 'fechaCaducidadRecortada',
      align: 'center',
    },
    { label: 'Comentario', property: 'comentario', align: 'center' },
    {
      label: 'Estado',
      property: 'estado',
      align: 'center',
      classList: 'tableEstado tableEstadoDocumentos',
    },
  ];

  optionsDocumentos: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    canSee: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsRow: [
      {
        icon: 'schedule',
        callback: (event) => {
          this.seeVersiones(event);
        },
      },
    ],
  };
  dataDocumentos = [];
  forceUpdateWidthDocumentos = new Subject();

  //#endregion

  constructor(
    private tipoDeDocSE: TipoDocumentoService,
    public carpetasSe: CarpetaService,
    public documentosSe: DocumentoService,
    public popup: PopupService,
    private notificationSer: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllCarpetas();
    this.getAllTiposDocumentos();
  }

  returnCarpetas() {
    this.selectTypeDocument = null;
  }
  loadDocumentsFromType(row: any) {
    this.carpeta = { idCarpeta: row.id, nombreCarpeta: row.nombre };
    this.loadingDocumentos = true;
    this.selectTypeDocument = row;
    this.getDocumentByCarpeta(row);
  }
  getDocumentByCarpeta(row: any) {
    this.carpetaId = row.id;
    this.documentosCarpeta();
  }

  documentosCarpeta() {
    this.documentosSe.getByCarpeta(
      this.carpetaId,
      this.pagina,
      this.maximo,
      this.busquedaFiltro,
      this.estadoFiltro,
      this.tipoDeDocFiltro,
      this.desdeFiltro,
      this.hastaFiltro
    );
    this.documentosSe
      .getResultCarpetas()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        let { data, total } = res.data;
        let mappedDates = data.map((d: any) => {
          // fecha
          let fechaFormateada = this.formatearFecha(d.fecha);
          // fecha de caducidad
          let fechaCaducidadFormateada = this.formatearFecha(d.fechaCaducidad);
          return {
            fechaRecortada: fechaFormateada,
            fechaCaducidadRecortada: fechaCaducidadFormateada,
          };
        });
        let dataDocumentosFix = data.map((data: any, index: number) => {
          let obj = Object.assign(data, mappedDates[index]);
          return obj;
        });
        this.paginacionTotal = total;
        this.numMaxPag = Math.ceil(total / this.maximo);
        if (total < this.maximo) this.numMax = total;
        this.loadingDocumentos = false;
        this.dataDocumentos = dataDocumentosFix;
        this.forceUpdateWidthDocumentos.next('');
        this._unsubInd.next('');
      });
  }
  changeFilterOpen(newVal: boolean) {
    this.isFilterOpen = newVal;
    this.updateTableMaxHeight();
  }
  changeSearchOpen(newVal: boolean) {
    this.isSearchOpen = newVal;
    this.updateTableMaxHeight();
  }
  updateTableMaxHeight() {
    let val = '';
    if (this.isFilterOpen) {
      val = val + ' - 68px - 1em';
    }
    if (this.isSearchOpen) {
      val = val + ' - 68px - 1em';
    }
    this.optionsDocumentos.maxHeight =
      'calc(100vh - 78px - 4em - 106px - 47px - 4em' + val + ')';
  }

  /**
   *
   * SELECTORES
   */

  getTipoDocFromSelector(event: any) {
    if (event === null) {
      this.tipoDeDocFiltro = 0;
    }
    if (event) {
      this.tipoDeDocFiltro = event.id;
    }
    this.documentosCarpeta();
  }

  getEstadoFromSelector(event: any) {
    if (event === null) {
      this.estadoFiltro = '';
    }
    if (event) {
      this.estadoFiltro = event.label;
    }
    this.documentosCarpeta();
  }

  dataFromPicker(event: any, key: string) {
    if (key === 'desde') {
      event
        ? (this.desdeFiltro = iDate
            .javascriptConvert(new Date(event.value))
            .toStringDate('JAP'))
        : (this.desdeFiltro = '');
    }
    if (key === 'hasta') {
      event
        ? (this.hastaFiltro = iDate
            .javascriptConvert(new Date(event.value))
            .toStringDate('JAP'))
        : (this.hastaFiltro = '');
    }
    this.documentosCarpeta();
  }

  openEditPopup(event: any) {
    this.popup.openPopup(EditCarpetaDocumentosPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd2;
      });
  }

  openDeletePopup(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let idFromButton = [res.dataReturn.id];
        this.deleteCarpeta(idFromButton);
        this._unsubInd3.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'las carpetas seleccionadas',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteCarpeta(ids);
        this._unsubInd3.next('');
      });
  }

  deleteCarpeta(id: any) {
    this.carpetasSe.delete(id);
    this.carpetasSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'La carpeta ha sido borrada correctamente'
        );
        this.ngOnInit();
        this._unsubInd4.next('');
      });
    this.carpetasSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(`${res.message}`);
        this._unsub.next('');
      });
  }

  openEditPopupDocumento(event: any) {
    let eventPlusID = Object.assign(event, this.carpeta);
    this.popup.openPopup(EditDocumentosPopupComponent, {
      data: eventPlusID,
      tipo: 'carpeta',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd5;
      });
  }

  openEditPopupTipoDoc(event: any) {
    this.popup.openPopup(EditTipoDeDocPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.getAllTiposDocumentos();
        this._unsubInd.next('');
      });
  }

  openDeletePopupTipoDoc(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el tipo de documento',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteTipoDoc([event.id]);
        this._unsubInd.next('');
      });
  }

  deleteTipoDoc(id: any[]) {
    this.tipoDeDocSE.delete(id);
    this.tipoDeDocSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El tipo de documento ha sido borrado correctamente'
        );
        this.getAllTiposDocumentos();
        this._unsubInd2.next('');
      });
    this.tipoDeDocSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  nextPage() {
    if (this.numMax === this.paginacionTotal) {
      return;
    }
    this.pagina++;
    this.numMin = this.numMin + this.maximo;
    this.numMax =
      this.numMax + this.maximo < this.paginacionTotal
        ? this.numMax + this.maximo
        : this.paginacionTotal;
    this.documentosCarpeta();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPage() {
    if (this.numMin === 1) {
      return;
    }
    this.numMin = this.numMin - this.maximo;
    this.numMax =
      this.numMax - this.maximo < this.maximo
        ? this.maximo
        : this.numMax - this.maximo;
    this.pagina--;
    this.documentosCarpeta();
  }

  /**
   *
   * SEE DOCUMENTO
   *
   */

  seeDocumento(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   *
   * See versiones
   *
   */

  seeVersiones(event: any) {
    this.popup.openPopup(VerVersionesDocumentosPopupComponent, event);
  }

  // FUNCIONALIDAD

  formatearFecha(fecha: any): any {
    let fechaRecortada = fecha.split('T');
    let fechaRecortadaSeparada = fechaRecortada[0].split('-');
    let fechaRecortadaReordenada = `${fechaRecortadaSeparada[2]}/${fechaRecortadaSeparada[1]}/${fechaRecortadaSeparada[0]}`;
    return fechaRecortadaReordenada;
  }

  openAnadirCarpeta() {
    this.popup.openPopup(NuevaCarpetaPopupComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd6))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        if (res.dataReturn === 'submitted') {
          window.location.reload();
        }

        this._unsubInd6.next('');
      });
  }

  /**
   * NEXT PAGE
   */

  nextPageCarpetas() {
    if (this.numMaxCarpeta === this.paginacionTotalCarpeta) {
      return;
    }
    this.paginaCarpeta++;
    this.numMinCarpeta = this.numMinCarpeta + this.maximoCarpeta;
    this.numMaxCarpeta =
      this.numMaxCarpeta + this.maximoCarpeta < this.paginacionTotalCarpeta
        ? this.numMaxCarpeta + this.maximoCarpeta
        : this.paginacionTotalCarpeta;
    this.getAllCarpetas();
  }

  /**
   * PREVIOUS PAGE
   */

  previousPageCarpetas() {
    if (this.numMinCarpeta === 1) {
      return;
    }
    this.numMinCarpeta = this.numMinCarpeta - this.maximoCarpeta;
    this.numMaxCarpeta =
      this.numMaxCarpeta - this.maximoCarpeta < this.maximoCarpeta
        ? this.maximoCarpeta
        : this.numMaxCarpeta - this.maximoCarpeta;
    this.paginaCarpeta--;
    this.getAllCarpetas();
  }

  /**
   * BUSQUEDA
   */

  respuestaBusqueda(event: any) {
    this.documentosSe.getByCarpeta(this.carpeta.idCarpeta, 1, event);
    this.documentosSe
      .getResultCarpetas()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        this._unsubInd9.next('');
      });
  }

  addNewDocument() {
    this.popup.openPopup(
      NuevoDocumentoPorCarpetaComponent,
      this.carpeta.idCarpeta
    );
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.loadingDocumentos = true;
        setTimeout(() => {
          this.getDocumentByCarpeta(this.selectTypeDocument);
        }, 1000);
        this.loadingDocumentos = false;
        this._unsubInd7.next('');
      });
  }

  addNewTipoDoc() {
    this.popup.openPopup(NuevoTipoDeDocPopupComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.getAllTiposDocumentos();
        this._unsubInd.next('');
      });
  }

  /**
   * Delete Documento
   */

  openDeletePopupDocumento(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'el documento',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteDocumento([event.documentoId]);
        this._unsubInd.next('');
      });
  }

  deleteSelectedDocuments(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'los documentos seleccionados',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        let ids = event.map((e: any) => e.documentoId);
        this.deleteDocumento(ids);
        this._unsubInd.next('');
      });
  }

  deleteDocumento(id: any[]) {
    this.documentosSe.delete(id);
    this.documentosSe
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showFeedBack(
          'El documento ha sido borrado correctamente'
        );
        this.getDocumentByCarpeta(this.selectTypeDocument);
        this._unsubInd2.next('');
      });
    this.documentosSe
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.notificationSer.showError(res.message);
        this._unsub.next('');
      });
  }

  /**
   * API CALLS
   */

  getAllCarpetas() {
    this.loadingTipoDocumentos = true;
    this.carpetasSe.getAll(this.paginaCarpeta, this.maximoCarpeta);
    this.carpetasSe
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        let { data, total } = res.data;
        this.dataTipoDocumentos = [];
        data.forEach((tipoDoc: iTipoDocumento) => {
          this.dataTipoDocumentos.push(tipoDoc);
        });
        this.paginacionTotalCarpeta = total;
        this.numMaxPagCarpeta = Math.ceil(total / this.maximoCarpeta);
        if (total < this.maximoCarpeta) this.numMaxCarpeta = total;

        this.loadingTipoDocumentos = false;
        this._unsubInd.next('');
      });
  }

  getAllTiposDocumentos() {
    this.documentosSe.getAllTiposDocument();
    this.documentosSe
      .getResult()
      .pipe(takeUntil(this._unsubInd8))
      .subscribe((res: any) => {
        if (res === null) {
          return;
        }
        let { data } = res;
        let mappedData = data.map((d: any) => {
          return { id: d.id, label: d.nombre };
        });
        this.optionTipoDoc.items = mappedData;
        this.dataTipoDocs = mappedData;
        this._unsubInd8.next('');
      });
  }
}
