import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// EXTERNAL MODULES
import { NgSelectModule } from '@ng-select/ng-select';
import { QuasarLibraryModule } from '@quasar_dynamics/basic-designsystem';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleMapsModule } from '@angular/google-maps';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MatOption } from '@angular/material/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {MatStepperModule} from '@angular/material/stepper';




// POPUPS
import { AddDocumentoRequeridoSolicitadoPopupComponent } from './Popups/AñadirNuevaPopup/AddDocumentoRequeridoSolicitadoPopup/AddDocumentoRequeridoSolicitadoPopup.component';
import { AsegurarDeletePopupComponent } from './Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { LoginLoadingComponent } from './Popups/LoginLoading/LoginLoading.component';
import { EmpleadoPopupComponent } from './Popups/EmpleadoPopup/EmpleadoPopup.component';
import { EditVehiculoPopupComponent } from './Popups/EditPopup/EditVehiculoPopup/EditVehiculoPopup.component';
import { EditClientePopupComponent } from './Popups/EditPopup/EditClientePopup/EditClientePopup.component';
import { EditEmpleadosPopupComponent } from './Popups/EditPopup/EditEmpleadosPopup/EditEmpleadosPopup.component';
import { EditDepartamentosPopupComponent } from './Popups/EditPopup/EditDepartamentosPopup/EditDepartamentosPopup.component';
import { EditCarpetaDocumentosPopupComponent } from './Popups/EditPopup/EditCarpetaDocumentosPopup/EditCarpetaDocumentosPopup.component';
import { EditPartesPopupComponent } from './Popups/EditPopup/EditPartesPopup/EditPartesPopup.component';
import { EditDocumentosPopupComponent } from './Popups/EditPopup/EditDocumentosPopup/EditDocumentosPopup.component';
import { EditSeguimientoTecnicoPopupComponent } from './Popups/EditPopup/EditSeguimientoTecnicoPopup/EditSeguimientoTecnicoPopup.component';
import { EditObrasPopupComponent } from './Popups/EditPopup/EditObrasPopup/EditObrasPopup.component';
import { EditProveedorPopupComponent } from './Popups/EditPopup/EditProveedorPopup/EditProveedorPopup.component';
import { NuevaObraPopupComponent } from './Popups/AñadirNuevaPopup/NuevaObraPopup/NuevaObraPopup.component';
import { NuevaCarpetaPopupComponent } from './Popups/AñadirNuevaPopup/NuevaCarpetaPopup/NuevaCarpetaPopup.component';
import { NuevoClientePopupComponent } from './Popups/AñadirNuevaPopup/NuevoClientePopup/NuevoClientePopup.component';
import { NuevoVehiculoPopupComponent } from './Popups/AñadirNuevaPopup/NuevoVehiculoPopup/NuevoVehiculoPopup.component';
import { AñadirNuevoSTComponent } from './Popups/AñadirNuevaPopup/NuevoSeguimientoTecnicoPopup/NuevoSeguimientoTecnicoPopup.component';
import { NuevoDocumentoPorCarpetaComponent } from './Popups/AñadirNuevaPopup/NuevoDocumentoPorCarpeta/NuevoDocumentoPorCarpeta.component';
import { NuevoDepartamentoPopupComponent } from './Popups/AñadirNuevaPopup/NuevoDepartamentoPopup/NuevoDepartamentoPopup.component';
import { IncidenciasVehiculosComponent } from './Popups/Incidencias/IncidenciasVehiculos/IncidenciasVehiculos.component';
import { NuevaNominaPopupComponent } from './Popups/AñadirNuevaPopup/NuevaNominaPopup/NuevaNominaPopup.component';
import { EditNominaPopupComponent } from './Popups/EditPopup/EditNominaPopup/EditNominaPopup.component';
import { NuevaIncidenciaPopupComponent } from './Popups/AñadirNuevaPopup/NuevaIncidenciaPopup/NuevaIncidenciaPopup.component';
import { EditIncidenciaPopupComponent } from './Popups/EditPopup/EditIncidenciaPopup/EditIncidenciaPopup.component';
import { GaleriaFotosComponent } from './Popups/GaleriaFotos/GaleriaFotos.component';
import { CalendarioFestivosPopupComponent } from './Popups/CalendarioFestivosPopup/CalendarioFestivosPopup.component';
import { PartesFaltantesPopupComponent } from './Popups/PartesFaltantesPopup/PartesFaltantesPopup.component';
import { NuevoParteComponentComponent } from './Popups/AñadirNuevaPopup/NuevoParteComponent/NuevoParteComponent.component';
import { DescargaPartePopupComponent } from './Popups/DescargaPartePopup/DescargaPartePopup.component';
import { PartesObrasComponent } from './Pages/Section-Jefe/JefeObras/PartesObras/PartesObras.component';
import { VisorDePdfPopupComponent } from './Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { EditarProveedorPopupComponentComponent } from './Popups/EditPopup/EditarProveedorPopupComponent/EditarProveedorPopupComponent.component';
import { NuevoProveedorPopupComponent } from './Popups/AñadirNuevaPopup/NuevoProveedorPopup/NuevoProveedorPopup.component';
import { AdjuntarDocumentosUsuarioPopupComponent } from './Popups/EditPopup/AdjuntarDocumentosUsuarioPopup/AdjuntarDocumentosUsuarioPopup.component';
import { VerTodoAlertasPopupComponent } from './Popups/VerTodoAlertasPopup/VerTodoAlertasPopup.component';
import { VisorDeDocumentoImagenComponent } from './Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { VerVersionesDocumentosPopupComponent } from './Popups/VerVersionesDocumentosPopup/VerVersionesDocumentosPopup.component';
import { NuevaFacturaPopupComponent } from './Popups/AñadirNuevaPopup/NuevaFacturaPopup/NuevaFacturaPopup.component';
import { NuevoParteOperarioPopupComponent } from './Popups/AñadirNuevaPopup/NuevoParteOperarioPopup/NuevoParteOperarioPopup.component';
import { EditarProfileMovilComponent } from './Popups/EditPopup/EditarProfileMovil/EditarProfileMovil.component';
import { MovilAdjuntarDocumentosPopupComponent } from './Popups/EditPopup/MovilAdjuntarDocumentosPopup/MovilAdjuntarDocumentosPopup.component';
import { NuevoTipoDeDocPopupComponent } from './Popups/AñadirNuevaPopup/NuevoTipoDeDocPopup/NuevoTipoDeDocPopup.component';
import { EditTipoDeDocPopupComponent } from './Popups/EditPopup/EditTipoDeDocPopup/EditTipoDeDocPopup.component';
import { NuevoDocumentoRequeridoComponent } from './Popups/AñadirNuevaPopup/NuevoDocumentoRequerido/NuevoDocumentoRequerido.component';
import { EditDocPendienteDeFirmaComponent } from './Popups/EditPopup/EditDocPendienteDeFirma/EditDocPendienteDeFirma.component';
import { NuevoDocumentoAFirmarComponent } from './Popups/AñadirNuevaPopup/NuevoDocumentoAFirmar/NuevoDocumentoAFirmar.component';
import { EditFacturaPopupComponent } from './Popups/EditPopup/EditFacturaPopup/EditFacturaPopup.component';
import { NuevoDocumentoPorObraComponent } from './Popups/AñadirNuevaPopup/NuevoDocumentoPorObra/NuevoDocumentoPorObra.component';
import { AdjuntarDocumentosVehiculosPopupComponent } from './Popups/AñadirNuevaPopup/AdjuntarDocumentosVehiculosPopup/AdjuntarDocumentosVehiculosPopup.component';
import { EditarContrasenyaPopupComponent } from './Popups/EditPopup/EditarContrasenyaPopup/EditarContrasenyaPopup.component';

// COMPONENTS
import { ContainerLoginComponent } from './Pages/Section-Login/_holder/containerLogin/containerLogin.component';
import { CustomInputComponent } from './Shared/Components/CustomInput/CustomInput.component';
import { HeaderComponent } from './Shared/Layout/Header/Header.component';
import { FullContainerComponent } from './Shared/Layout/FullContainer/FullContainer.component';
import { FilterActionsComponent } from './Shared/Components/FilterActions/FilterActions.component';
import { DatePickerComponent } from './Shared/Components/DatePicker/DatePicker.component';
import { TestingCalendarComponent } from './Shared/Components/TestingCalendar/TestingCalendar.component';
import { HousingCalendarComponent } from './Shared/Components/HousingCalendar/HousingCalendar.component';
import { CalendarPickerComponent } from './Shared/Components/CalendarPicker/CalendarPicker.component';
import { MobilCalendarComponent } from './Shared/Components/MobilCalendar/MobilCalendar.component';
import { FooterComponent } from './Shared/Components/Footer/Footer.component';
import { CustomTextAreaComponent } from './Shared/Components/CustomTextArea/CustomTextArea.component';
import { EditarParteMovilComponent } from './Popups/EditPopup/EditarParteMovil/EditarParteMovil.component';
import { ProveedoresSeeEditDocumentosRequeridosComponent } from './Pages/SharedComponents/ProveedorSeeEdit/_holders/ProveedoresSeeEditDocumentosRequeridos/ProveedoresSeeEditDocumentosRequeridos.component';


// PAGES
import { VehiculoDetailComponent } from './Pages/Section-Admin/AdminVehiculos/VehiculoDetail/VehiculoDetail.component';
import { LoginPageComponent } from './Pages/Section-Login/LoginPage/LoginPage.component';
import { ForgotPasswordPageComponent } from './Pages/Section-Login/ForgotPasswordPage/ForgotPasswordPage.component';
import { UserProfileComponent } from './Pages/SharedComponents/UserProfile/UserProfile.component';
import { EmpleadoSeeEditComponent } from './Pages/SharedComponents/EmpleadoSeeEdit/EmpleadoSeeEdit.component';
import { ProveedorSeeEditComponent } from './Pages/SharedComponents/ProveedorSeeEdit/ProveedorSeeEdit.component';
import { ProveedorSeeEditDocumentosComponent } from './Pages/SharedComponents/ProveedorSeeEdit/_holders/ProveedorSeeEditDocumentos/ProveedorSeeEditDocumentos.component';
import { ProveedorSeeEditFacturasComponent } from './Pages/SharedComponents/ProveedorSeeEdit/_holders/ProveedorSeeEditFacturas/ProveedorSeeEditFacturas.component';
import { SeguimientoTecnicoDetailViewComponent } from './Pages/Section-Admin/AdminSegtoTecnico/SeguimientoTecnicoDetailView/SeguimientoTecnicoDetailView.component';
import { ObraDetaiParteObraComponent } from './Pages/Section-Admin/AdminObras/ObraDetailView/_holder/ObraDetaiParteObra/ObraDetaiParteObra.component';
import { ObraDetailEmpleadosComponent } from './Pages/Section-Admin/AdminObras/ObraDetailView/_holder/ObraDetailEmpleados/ObraDetailEmpleados.component';
import { ObraDetailDocumentosComponent } from './Pages/Section-Admin/AdminObras/ObraDetailView/_holder/ObraDetailDocumentos/ObraDetailDocumentos.component';
import { JefeObrasComponent } from './Pages/Section-Jefe/JefeObras/JefeObras.component';
import { JefePartesComponent } from './Pages/Section-Jefe/JefePartes/JefePartes.component';
import { JefeDocumentosComponent } from './Pages/Section-Jefe/JefeDocumentos/JefeDocumentos.component';
import { JefeDocumentosObrasComponent } from './Pages/Section-Jefe/JefeDocumentos/JefeDocumentosObras/JefeDocumentosObras.component';
import { RecoverPasswordPageComponent } from './Pages/Section-Login/RecoverPasswordPage/RecoverPasswordPage.component';

// ADMIN
import { AdminClientesComponent } from './Pages/Section-Admin/AdminClientes/AdminClientes.component';
import { AdminDashboardComponent } from './Pages/Section-Admin/AdminDashboard/AdminDashboard.component';
import { AdminObrasComponent } from './Pages/Section-Admin/AdminObras/AdminObras.component';
import { ObraDetailViewComponent } from './Pages/Section-Admin/AdminObras/ObraDetailView/ObraDetailView.component';
import { AdminSegtoTecnicoComponent } from './Pages/Section-Admin/AdminSegtoTecnico/AdminSegtoTecnico.component';
import { AdminSegtoTecnicoListaComponent } from './Pages/Section-Admin/AdminSegtoTecnico/_holder/AdminSegtoTecnicoLista/AdminSegtoTecnicoLista.component';
import { AdminSegtoTecnicoMapaComponent } from './Pages/Section-Admin/AdminSegtoTecnico/_holder/AdminSegtoTecnicoMapa/AdminSegtoTecnicoMapa.component';
import { AdminSegtoTecnicoPipeLineComponent } from './Pages/Section-Admin/AdminSegtoTecnico/_holder/AdminSegtoTecnicoPipeLine/AdminSegtoTecnicoPipeLine.component';
import { AdminEmpleadosComponent } from './Pages/Section-Admin/AdminEmpleados/AdminEmpleados.component';
import { AdminEmpleadosListaComponent } from './Pages/Section-Admin/AdminEmpleados/_holder/AdminEmpleadosLista/AdminEmpleadosLista.component';
import { AdminEmpleadosNominasComponent } from './Pages/Section-Admin/AdminEmpleados/_holder/AdminEmpleadosNominas/AdminEmpleadosNominas.component';
import { AdminEmpleadosPartesComponent } from './Pages/Section-Admin/AdminEmpleados/_holder/AdminEmpleadosPartes/AdminEmpleadosPartes.component';
import { AdminEmpleadosDepartamentosComponent } from './Pages/Section-Admin/AdminEmpleados/_holder/AdminEmpleadosDepartamentos/AdminEmpleadosDepartamentos.component';
import { AdminDocumentosComponent } from './Pages/Section-Admin/AdminDocumentos/AdminDocumentos.component';
import { AdminProveedoresComponent } from './Pages/Section-Admin/AdminProveedores/AdminProveedores.component';
// JEFE
import { JefeDashboardComponent } from './Pages/Section-Jefe/JefeDashboard/JefeDashboard.component';
import { JefeFacturasComponent } from './Pages/Section-Jefe/JefeFacturas/JefeFacturas.component';
// OPERARIO
import { OperarioDashboardComponent } from './Pages/Section-Operario/OperarioDashboard/OperarioDashboard.component';
import { OperarioDocumentosComponent } from './Pages/Section-Operario/OperarioDocumentos/OperarioDocumentos.component';
import { OperarioPofileComponent } from './Pages/Section-Operario/OperarioPofile/OperarioPofile.component';
import { OperarioDocumentosObraIdComponent } from './Pages/Section-Operario/OperarioDocumentos/OperarioDocumentosObraId/OperarioDocumentosObraId.component';
// PROVEEDORES
import { ProveedoresDashboardComponent } from './Pages/Section-Proveedores/ProveedoresDashboard/ProveedoresDashboard.component';
import { ProveedoresDashboarDocumentosComponent } from './Pages/Section-Proveedores/ProveedoresDashboarDocumentos/ProveedoresDashboarDocumentos.component';
import { ProveedoresProveedoresComponent } from './Pages/Section-Proveedores/ProveedoresProveedores/ProveedoresProveedores.component';
import { ProveedoresDocumentosPendientesComponent } from './Pages/Section-Proveedores/ProveedoresProveedores/_holder/ProveedoresDocumentosPendientes/ProveedoresDocumentosPendientes.component';
import { ProveedoresProveedoresDocumentosComponent } from './Pages/Section-Proveedores/ProveedoresProveedores/_holder/ProveedoresProveedoresDocumentos/ProveedoresProveedoresDocumentos.component';
import { ProveedoresFacturasComponent } from './Pages/Section-Proveedores/ProveedoresFacturas/ProveedoresFacturas.component';
// TECNICO
import { TecnicoDashboardComponent } from './Pages/Section-Tecnico/TecnicoDashboard/TecnicoDashboard.component';
import { TecnicoSeguimientoTecnicoLiistaComponent } from './Pages/Section-Tecnico/TecnicoDashboard/TecnicoSeguimientoTecnicoLiista/TecnicoSeguimientoTecnicoLiista.component';
import { AdminVehiculosComponent } from './Pages/Section-Admin/AdminVehiculos/AdminVehiculos.component';
import { AdminPartesComponent } from './Pages/Section-Admin/AdminPartes/AdminPartes.component';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
// PIPES
import { CustomDatePickerMMMYYYPipe } from './Shared/Pipes/CustomDatePickerMMMYYY.pipe';
// LOCALE
registerLocaleData(localeEs, 'es');
import localeEs from '@angular/common/locales/es';
import { AdminAlertasComponent } from './Pages/Section-Admin/AdminAlertas/AdminAlertas.component';
import { AdminPartesValidarComponent } from './Pages/Section-Admin/AdminPartes/_holder/AdminPartesValidar/AdminPartesValidar.component';
import { EditParteValidarRechazarComponent } from './Popups/EditPopup/EditParteValidarRechazar/EditParteValidarRechazar.component';
import { TecnicoSeguimientoTecnicoDetailComponent } from './Pages/Section-Tecnico/TecnicoDashboard/TecnicoSeguimientoTecnicoDetail/TecnicoSeguimientoTecnicoDetail.component';
import { VisorDeImagenComponent } from './Popups/VisorDeImagen/VisorDeImagen.component';
import { JefePartesValidarComponent } from './Pages/Section-Jefe/JefePartes/JefePartesValidar/JefePartesValidar.component';



@NgModule({
  declarations: [
    AppComponent,
    //Pages
    LoginPageComponent,
    ForgotPasswordPageComponent,
    UserProfileComponent,
    ProveedorSeeEditComponent,
    ProveedorSeeEditDocumentosComponent,
    ProveedorSeeEditFacturasComponent,
    EmpleadoSeeEditComponent,
    SeguimientoTecnicoDetailViewComponent,
    ObraDetaiParteObraComponent,
    ObraDetailEmpleadosComponent,
    ObraDetailDocumentosComponent,
    JefePartesComponent,
    VehiculoDetailComponent,
    RecoverPasswordPageComponent,
    // Admin
    AdminClientesComponent,
    AdminDashboardComponent,
    AdminObrasComponent,
    ObraDetailViewComponent,
    AdminSegtoTecnicoComponent,
    AdminSegtoTecnicoListaComponent,
    AdminSegtoTecnicoMapaComponent,
    AdminSegtoTecnicoPipeLineComponent,
    AdminDocumentosComponent,
    AdminEmpleadosComponent,
    AdminEmpleadosListaComponent,
    AdminEmpleadosNominasComponent,
    AdminEmpleadosPartesComponent,
    AdminEmpleadosDepartamentosComponent,
    AdminProveedoresComponent,
    AdminVehiculosComponent,
    AdminPartesComponent,
    AdminAlertasComponent,
    AdminPartesValidarComponent,
    // Jefe
    JefeDashboardComponent,
    JefeObrasComponent,
    PartesObrasComponent,
    JefeDocumentosComponent,
    JefeDocumentosObrasComponent,
    JefeFacturasComponent,
    EditParteValidarRechazarComponent,
    // Operario
    OperarioDashboardComponent,
    OperarioDocumentosComponent,
    OperarioPofileComponent,
    OperarioDocumentosObraIdComponent,
    // Proveedores
    ProveedoresDashboardComponent,
    ProveedoresDashboarDocumentosComponent,
    ProveedoresProveedoresComponent,
    ProveedoresDocumentosPendientesComponent,
    ProveedoresProveedoresDocumentosComponent,
    ProveedoresFacturasComponent,
    // Tecnico
    TecnicoDashboardComponent,
    TecnicoSeguimientoTecnicoLiistaComponent,
    TecnicoSeguimientoTecnicoDetailComponent,
    //Components
    ContainerLoginComponent,
    CustomInputComponent,
    FilterActionsComponent,
    DatePickerComponent,
    TestingCalendarComponent,
    HousingCalendarComponent,
    CalendarPickerComponent,
    FooterComponent,
    MobilCalendarComponent,
    CustomTextAreaComponent,
    ProveedoresSeeEditDocumentosRequeridosComponent,
    //Layouts
    HeaderComponent,
    FullContainerComponent,
    // Pipes
    CustomDatePickerMMMYYYPipe,
    //Popups
    LoginLoadingComponent,
    EmpleadoPopupComponent,
    AsegurarDeletePopupComponent,
    EditVehiculoPopupComponent,
    EditClientePopupComponent,
    EditEmpleadosPopupComponent,
    EditDepartamentosPopupComponent,
    EditCarpetaDocumentosPopupComponent,
    EditPartesPopupComponent,
    EditDocumentosPopupComponent,
    EditSeguimientoTecnicoPopupComponent,
    EditObrasPopupComponent,
    EditProveedorPopupComponent,
    NuevaObraPopupComponent,
    NuevaCarpetaPopupComponent,
    NuevoClientePopupComponent,
    EditarProfileMovilComponent,
    NuevoVehiculoPopupComponent,
    NuevoDocumentoPorCarpetaComponent,
    AñadirNuevoSTComponent,
    NuevoDepartamentoPopupComponent,
    IncidenciasVehiculosComponent,
    NuevaNominaPopupComponent,
    EditNominaPopupComponent,
    NuevaIncidenciaPopupComponent,
    EditIncidenciaPopupComponent,
    DescargaPartePopupComponent,
    GaleriaFotosComponent,
    CalendarioFestivosPopupComponent,
    PartesFaltantesPopupComponent,
    NuevoParteComponentComponent,
    VisorDePdfPopupComponent,
    EditarProveedorPopupComponentComponent,
    NuevoProveedorPopupComponent,
    AdjuntarDocumentosUsuarioPopupComponent,
    VerTodoAlertasPopupComponent,
    VisorDeDocumentoImagenComponent,
    VerVersionesDocumentosPopupComponent,
    NuevaFacturaPopupComponent,
    NuevoParteOperarioPopupComponent,
    EditarParteMovilComponent,
    MovilAdjuntarDocumentosPopupComponent,
    NuevoTipoDeDocPopupComponent,
    EditTipoDeDocPopupComponent,
    NuevoDocumentoRequeridoComponent,
    AddDocumentoRequeridoSolicitadoPopupComponent,
    EditDocPendienteDeFirmaComponent,
    NuevoDocumentoAFirmarComponent,
    EditFacturaPopupComponent,
    NuevoDocumentoPorObraComponent,
    AdjuntarDocumentosVehiculosPopupComponent,
    EditarContrasenyaPopupComponent,
    VisorDeImagenComponent,
    JefePartesValidarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    MatCheckboxModule,
    MatIconModule,
    DragDropModule,
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    GoogleMapsModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    QuasarLibraryModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    NgxExtendedPdfViewerModule,
    MatStepperModule,


  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es' },
    DatePipe,
  { provide: DateAdapter, useClass: CustomDateAdapter },],
  bootstrap: [AppComponent]
})
export class AppModule { }
