import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ParteService } from 'src/app/Services/Api/Parte.service';
import {
  iDate,
  iUnsubscribeDestroy,
  LoaderService,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CalendarioFestivosPopupComponent } from 'src/app/Popups/CalendarioFestivosPopup/CalendarioFestivosPopup.component';
import { FestivosService } from 'src/app/Services/Api/Festivos.service';
import { PartesFaltantesPopupComponent } from 'src/app/Popups/PartesFaltantesPopup/PartesFaltantesPopup.component';

@Component({
  selector: 'HousingCalendar',
  templateUrl: './HousingCalendar.component.html',
  styleUrls: ['./HousingCalendar.component.scss'],
})
export class HousingCalendarComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @ViewChild('calendario') calendarComponent?: FullCalendarComponent;

  @Output() refreshPopup = new EventEmitter<boolean>();

  @Input() vacationPopup = false;
  @Input() jefeDeObra = 0;

  calendar = this.calendarComponent?.getApi();

  month: number = new Date().getMonth();
  year: number = new Date().getFullYear();

  dataCalendario: any[] = [];
  vacationDates: any[] = [];

  vista: string = 'month';

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    firstDay: 1,
    buttonText: {
      today: 'Hoy',
      month: 'Mes',
      week: 'Semana',
      day: 'Dia',
      list: 'Lista',
    },
    selectable: true,
    locales: [esLocale],
    showNonCurrentDates: false,
    events: [],
    dateClick: this.handleDateClick.bind(this),
  };

  constructor(
    private loader: LoaderService,
    private notificationSE: NotificationsService,
    private festivoSE: FestivosService,
    private popup: PopupService,
    private parteSE: ParteService
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.jefeDeObra);
    if (!this.jefeDeObra) {
      this.getDataForCalendario();
    } else {
      this.getDataForCalendarioJefeDeObra();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['vacationPopup']) {
      if (changes['vacationPopup'].currentValue) {
        this.openVacacionesPopup();
      }
    }
  }

  openVacacionesPopup() {
    this.popup.openPopup(CalendarioFestivosPopupComponent);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (res['returnValue'] == false) {
          this.refreshPopup.emit(false);
          return;
        }
        let primerDiaVacaciones = new Date(res['returnValue'].checkIn);
        let lastDiaVacaciones = new Date(res['returnValue'].checkOut);

        let rangoFechas = iDate
          .getDaysFromInterval(primerDiaVacaciones, lastDiaVacaciones)
          .map((fecha) => {
            return iDate.javascriptConvert(fecha).toStringDate();
          });
        this.createFestivos(rangoFechas);
        this.refreshPopup.emit(false);
        this._unsubInd2.next('');
      });
  }

  createFestivos(rangoFechas: string[]) {
    this.festivoSE.create({ fecha: rangoFechas });
    this.festivoSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack(
          'Las vacaciones se han creado correctamente'
        );
        this.getDataForCalendario();
        this._unsubInd4.next('');
      });
    this.festivoSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  getChange(event: any) {}

  // Funcionalidad

  returnMonth() {
    let months: Array<string> = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return months[this.month];
  }

  previousButton() {
    this.calendarComponent?.getApi().prev();
    if (this.vista === 'month') {
      this.month--;

      if (this.month === -1) {
        this.month = 11;
        this.year--;
      }
      this.getDataForCalendario();
    }
  }

  nextButton() {
    this.calendarComponent?.getApi().next();
    if (this.vista === 'month') {
      this.month++;
      if (this.month === 12) {
        this.month = 0;
        this.year++;
      }
    }
    this.getDataForCalendario();
  }

  goToHoy() {
    this.calendarComponent?.getApi().today();
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    this.getDataForCalendario();
  }

  monthView() {
    this.calendarComponent?.getApi().changeView('dayGridMonth');
    this.vista = 'month';
  }

  weekView() {
    this.calendarComponent?.getApi().changeView('timeGridWeek');
    this.vista = 'week';
  }

  dayView() {
    this.calendarComponent?.getApi().changeView('timeGridDay');
    this.vista = 'day';
  }

  listView() {
    this.calendarComponent?.getApi().changeView('listWeek');
    this.vista = 'list';
  }

  handleDateClick(arg: any) {
    let filteredDay = this.dataCalendario.filter(
      (day) => day.fecha === arg.dateStr
    );
    if (filteredDay.length === 0) {
      return;
    }
    if (this.jefeDeObra) {
      this.parteSE.getAllPartesFaltantesUserByJefeDeObra(
        arg.dateStr,
        this.jefeDeObra
      );
    } else {
      this.parteSE.getAllPartesFaltantesUser(arg.dateStr);
    }
    this.parteSE
      .getResultCalendarioUsers()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.popup.openPopup(PartesFaltantesPopupComponent, {
          data: data,
          fecha: arg.dateStr,
        });
        this.popup
          .returnData()
          .pipe(takeUntil(this._unsubInd6))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            this.getDataForCalendario();
            this._unsubInd6.next('');
          });
        this._unsubInd5.next('');
      });
  }

  eliminarRepetidos(array: any[]) {
    var set = new Set(array);
    var newArray = Array.from(set);
    return newArray;
  }

  /**
   * API CALL
   */

  getDataForCalendario() {
    this.loader.open();
    this.parteSE.getAllPartesMes(this.month + 1, this.year);
    this.parteSE
      .getResultCalendario()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;

        let mappedData = data.map((parte: any) => {
          let obj = {
            start: parte.fecha,
            end: parte.fecha,
            title: parte.num,
            color: '#FF928D',
          };
          return obj;
        });
        this.calendarOptions.events = mappedData;
        this.dataCalendario = data;
        this.getVacacionDates();
        this.loader.close();
        this._unsubInd3.next('');
      });
  }

  getDataForCalendarioJefeDeObra() {
    this.loader.open();
    this.parteSE.getAllPartesMesByJefeDeObra(
      this.month + 1,
      this.year,
      this.jefeDeObra
    );
    this.parteSE
      .getResultCalendario()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        let mappedData = data.map((parte: any) => {
          let obj = {
            start: parte.fecha,
            end: parte.fecha,
            title: parte.num,
            color: '#FF928D',
          };
          return obj;
        });
        this.calendarOptions.events = mappedData;
        this.dataCalendario = data;
        this.getVacacionDates();
        this.loader.close();
        this._unsubInd3.next('');
      });
  }

  getVacacionDates() {
    this.parteSE.getAllVacationDates();
    this.parteSE
      .getResultVacationDates()
      .pipe(takeUntil(this._unsubInd7))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        // Quitamos las duplicadas
        let filteredData = [
          ...new Set(data.map((fecha: any) => fecha.fecha.toString())),
        ];
        let filteredDataMapped = filteredData.map((fecha: any) => {
          return {
            fecha: iDate.javascriptConvert(new Date(fecha)).toStringDate('JAP'),
          };
        });
        let vacationDates: any = filteredDataMapped.map((vacation: any) => {
          let obj = {
            start: vacation.fecha,
            end: vacation.fecha,
            title: 'Vacaciones',
            textColor: '#FFFFFF',
            backgroundColor: '#000000',
            display: 'background',
            allDay: true,
            rendering: 'background',
            className: 'white',
          };
          return obj;
        });
        this.vacationDates = vacationDates;
        this.calendarOptions.events = [
          ...(this.calendarOptions.events as []),
          ...this.vacationDates,
        ];
        this._unsubInd7.next('');
      });
  }
}
