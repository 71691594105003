import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { DocumentoService } from 'src/app/Services/Api/Documento.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-EditDocPendienteDeFirma',
  templateUrl: './EditDocPendienteDeFirma.component.html',
  styleUrls: ['./EditDocPendienteDeFirma.component.scss']
})
export class EditDocPendienteDeFirmaComponent extends iUnsubscribeDestroy implements OnInit {

  documento: any = null;
  url: any = null;
  objectToPass: any = {
    id: this.modalData.id,
    estado: 'pendiente',
    url:"",
    isFirma:true,
  }

  constructor(public dialogRef: MatDialogRef<EditDocPendienteDeFirmaComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private notificationSer: NotificationsService, private documentoSE:DocumentoService) {
    super();
  }

  ngOnInit() {
     
  }

  forceClosePopup(dataReturn: any) {
    let p = { dataReturn: dataReturn };
    this.dialogRef.close(p);
  }

  addDocumento(event: any) {
    if (event[0].file.type != "image/png" && event[0].file.type != "image/jpeg" && event[0].file.type != "image/jpg" && event[0].file.type != "application/pdf") {
      this.notificationSer.showWarning("Formato de archivo no admitido, por favor suba un archivo .png, .pdf o .jpg")
      this.documento = null;
    } else {
      event.forEach((element: any) => {
        this.objectToPass.url = element.base64
         
        this.editDocumento()
      });

    }
  }

  editDocumento(){
    this.documentoSE.update(this.objectToPass);
    this.documentoSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return}
      this.notificationSer.showFeedBack("El documento ha sido modificado correctamente")
      this.forceClosePopup('submitted')
      this._unsubInd.next('')
    })
  }
}
