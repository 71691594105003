<filterActions [placeholderAdd]="'AÑADIR NUEVO'" [inputOptions]="filterText" (addNew)="newST()" (searchSend)="respuestaBusqueda($event)">
  <ng-container filterContent>
    <div class="containerSelectores">
      <div>
        <p>Técnico</p>
        <selector [options]="optionTecnico" [(ngModel)]="selectorTecnico" (change)="getTecnicoFromSelector($event)"></selector>
      </div>
      <div>
        <p>Dirección</p>
        <selector [options]="optionAddress" (change)="getAddressFromSelector($event)"></selector>
      </div>
      <div>
        <p>Estado</p>
        <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
      </div>
      <div class="desde">
        <p>Desde</p>
        <DatePicker (dataFromPicker)="dataFromPickerDesde($event)"></DatePicker>
      </div>
      <div class="hasta">
        <p>Hasta</p>
        <DatePicker (dataFromPicker)="dataFromPickerHasta($event)"></DatePicker>
      </div>

    </div>
  </ng-container>
</filterActions>

<div class="paginacion">
  <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
  <div class="arrows">
    <ng-container class="arrowLeft">
      <mat-icon (click)="previousPage()">
        chevron_left
      </mat-icon>
    </ng-container>
    <ng-container class="arrowRight">
      <mat-icon (click)="nextPage()">
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
</div>

<pill class="tablePil" classList="tablePill">
  <dataTable (deleteSelected)="deleteSelected($event)" [data]="dataForTable" [displayedColumns]="displayedColumnsObras"
    [options]="optionsObras" [isLoading]="isLoadingLista" (edit)="OpenEditPopup($event)"
    (delete)="OpenDeletePopup($event)" (clickRow)="openSeguimientoTecnicoDetailView($event)"></dataTable>
</pill>