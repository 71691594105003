<div class="headerContainer">

  <!-- Hamburguer -->

  <div class="hamburguer" *ngIf="isMobile" >
    <div class="hamburguerIcon" (click)="toggleMenu()">
        <i class="material-icons" >menu</i>
    </div>
  
    <div class="hamburguerContainer" *ngIf="istoggleMenuUp" >
      <div class="hamburguerLink" *ngFor="let nav of navegationContainer" (click)="navigateTo(nav)" [routerLink]="[nav.router]" routerLinkActive="activated">
        <span>{{nav.label}}</span>
      </div>
    </div>

  </div>


  <div class="lettermarkContainer" *ngIf="!isMobile">
    <img class="lettermark" src="/assets/Images/Lettermark.png">
  </div>
  <div class="navegationContainer" *ngIf="!isMobile">
    <a class="navElem" *ngFor="let nav of navegationContainer" (keyup.enter)="navigateTo(nav)" (click)="navigateTo(nav)" [routerLink]="[nav.router]" routerLinkActive="activated" >
      <img class="image-icons" *ngIf="nav.icon == null" [src]="nav.image">
      <i class="material-icons" *ngIf="nav.icon != null">{{nav.icon}}</i>
      <span>{{nav.label}}</span>
    </a>
  </div>
</div>