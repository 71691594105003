import { Component, OnInit } from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  NotificationsService,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { FacturaService } from 'src/app/Services/Api/Factura.service';
import { takeUntil } from 'rxjs/operators';
import { EditFacturaPopupComponent } from 'src/app/Popups/EditPopup/EditFacturaPopup/EditFacturaPopup.component';
import { AsegurarDeletePopupComponent } from 'src/app/Popups/AsegurarDeletePopup/AsegurarDeletePopup.component';
import { VisorDePdfPopupComponent } from 'src/app/Popups/VisorDePdfPopup/VisorDePdfPopup.component';
import { VisorDeDocumentoImagenComponent } from 'src/app/Popups/VisorDeDocumentoImagen/VisorDeDocumentoImagen.component';
import { UserService } from 'src/app/Services/Api/User.service';
import { ProveedorObraService } from 'src/app/Services/Api/ProveedorObra.service';
import { NuevaFacturaPopupComponent } from 'src/app/Popups/AñadirNuevaPopup/NuevaFacturaPopup/NuevaFacturaPopup.component';

@Component({
  selector: 'app-JefeFacturas',
  templateUrl: './JefeFacturas.component.html',
  styleUrls: ['./JefeFacturas.component.scss'],
})
export class JefeFacturasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isLoading: boolean = false;
  dataFacturas: any = [];

  optionProveedor: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Proveedor',
  };
  optionEstado: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pdte. Validacion' },
      { id: 2, label: 'Rechazada' },
      { id: 3, label: 'Validada' },
      { id: 4, label: 'Contabilizada' },
      { id: 5, label: 'Aprobada' },
      { id: 6, label: 'Gestoría' },
    ],
    clearable: true,
    placeholder: 'Estado',
  };

  filter: any = {
    fechaIni: '',
    fechaFin: '',
    estado: '',
    proveedor: '',
    busqueda: '',
  };

  displayedColumnsFacturas: Array<iDataTableColumns> = [
    { label: 'Nº Factura', property: 'numero' },
    { label: 'Fecha emisión', property: 'fecha_emision' },
    { label: 'Base Imponible', property: 'importe' },
    { label: 'Proyecto asociado', property: 'proyecto' },
    { label: 'Proveedor', property: 'proveedor' },
    { label: 'Notas', property: 'notas' },
    {
      label: 'Estado',
      property: 'estado',
      classList: ' tableEstado tableEstadoFacturas',
    },
  ];
  optionsFacturas: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [{ callback: () => {}, icon: 'download' }],
    canEdit: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private popup: PopupService,
    private facturaSE: FacturaService,
    private notificationSE: NotificationsService,
    private proveedorSE: ProveedorObraService
  ) {
    super();
  }

  ngOnInit() {
    this.getFacturasPendientes();
    this.getProveedores();
  }

  navigateToFacturas(event: any) {
    if (event.url[event.url.length - 1] === 'f') {
      this.popup.openPopup(VisorDePdfPopupComponent, event, 'fullSizePopup');
    }
    if (event.url[event.url.length - 1] === 'g') {
      this.popup.openPopup(VisorDeDocumentoImagenComponent, event);
    }
  }

  /**
   * FILTERS
   */

  dataFromPicker(event: any, key: string) {
    if (!event) {
      this.filter[key] = '';
      this.getFacturasPendientes();
      return;
    }
    this.filter[key] = iDate
      .javascriptConvert(new Date(event.value))
      .toStringDate('JAP');
    this.getFacturasPendientes();
  }

  busqueda(event: any) {
    if (!event) {
      this.filter['busqueda'] = '';
      this.getFacturasPendientes();
      return;
    }
    this.filter['busqueda'] = event;
    this.getFacturasPendientes();
  }

  getFromSelector(event: any, key: string) {
    if (!event) {
      this.filter[key] = '';
      this.getFacturasPendientes();
      return;
    }
    this.filter[key] = event.label;
    this.getFacturasPendientes();
  }

  /**
   * NUEVA FACTURA
   */

  addFactura() {
    this.popup.openPopup(NuevaFacturaPopupComponent, {
      id: Number(localStorage.getItem('nmsr')!),
      tipo: 'jefeObra',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.getFacturasPendientes();
        this._unsubInd.next('');
      });
  }

  /**
   * EDIT
   */

  editFacturas(event: any) {
    this.popup.openPopup(EditFacturaPopupComponent, event);
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.ngOnInit();
        this._unsubInd.next('');
      });
  }

  /**
   * DELETE
   */

  OnclickDeleteButton(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'la factura',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        this.deleteFactura([event.id]);
        this._unsubInd.next('');
      });
  }

  deleteSelected(event: any) {
    this.popup.openPopup(AsegurarDeletePopupComponent, {
      nombre: 'las facturas seleccionadas',
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let ids = event.map((e: any) => e.id);
        this.deleteFactura(ids);
        this._unsubInd.next('');
      });
  }

  /**
   * API CALLS
   */

  deleteFactura(ids: any[]) {
    this.facturaSE.delete(ids);
    this.facturaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showFeedBack('Factura eliminada correctamente');
        this.ngOnInit();
        this._unsubInd2.next('');
      });
    this.facturaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.notificationSE.showError(res.message);
        this._unsub.next('');
      });
  }

  getProveedores() {
    this.proveedorSE.getAllTabla();
    this.proveedorSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;
        this.optionProveedor.items = data.map((item: any) => {
          return { ...item, label: item.nombre };
        });
        this._unsubInd3.next('');
      });
  }

  getFacturasPendientes() {
    this.isLoading = true;
    let id = Number(localStorage.getItem('nmsr')!);
    this.facturaSE.getFacturasPendientesJefeObra(
      id,
      this.filter.fechaIni,
      this.filter.fechaFin,
      this.filter.estado,
      this.filter.proveedor,
      this.filter.busqueda
    );
    this.facturaSE
      .getResultFacturasJefeObra()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }

        let { data } = res;
        this.dataFacturas = data.map((item: any) => {
          return {
            ...item,
            fecha_emision: iDate
              .javascriptConvert(new Date(item.fecha_emision))
              .toStringDate('EU'),
            jefeObra: true,
          };
        });
        this.isLoading = false;
        this._unsubInd2.next('');
      });
  }
}
