import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { iDepartamento } from 'src/app/Shared/Interfaces/Api/iDepartamento';

@Injectable({
  providedIn: 'root',
})
export class DepartamentosService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    let sendOptions = { num_devoluciones: 50000, num_pagina: 1 };
    this.http
      .get(
        this.urlApi + '/api/departamento' + this.optionsGet(sendOptions),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalized = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDepartamento.convertFromCollection(normalized),
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/departamento/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalized = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDepartamento.convertFromObject(normalized),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  update(id: number, dataSend: any) {
    this.http
      .put(this.urlApi + '/api/departamento/' + id, dataSend, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res.status = error.error.code;
          this.res.ok = false;
          this.res.message = error.error.message;
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [id, dataSend],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(dataSend: any) {
    this.http
      .post(this.urlApi + '/api/departamento', dataSend, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [dataSend],
          });
          this.checkStatusError(error);
        },
      });
  }

  delete(IDs: Array<number>) {
    let idsObjs = this.convertArrayObject(IDs, 'ids');
    this.http
      .delete(this.urlApi + '/api/departamento', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [],
          });
          this.checkStatusError(error);
        },
      });
  }
}
