<FullContainer>
  <br>
  <h2 class="sectionTitle">Facturas</h2>
  <br>
  <filterActions (searchSend)="busqueda($event)" (addNew)="addFactura()" >
  <ng-container filterContent>

    <div class="containerSelectores">
      <div class="desde">
        <p>Desde</p>
        <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaIni')"></DatePicker>
      </div>
      <div class="hasta">
        <p>Hasta</p>
        <DatePicker (dataFromPicker)="dataFromPicker($event, 'fechaFin')"></DatePicker>
      </div>
      <div class="estado">
        <p>Estado</p>
        <selector [options]="optionEstado" (change)="getFromSelector($event, 'estado')"></selector>
      </div>
      <div class="redactor">
        <p>Proveedor</p>
        <selector [options]="optionProveedor" (change)="getFromSelector($event, 'proveedor')"></selector>
      </div>
    </div>
  </ng-container>
</filterActions>
<br>
  <pill classList="tablePill">
      <dataTable [isLoading]="isLoading"[data]="dataFacturas" (clickRow)="navigateToFacturas($event)"[displayedColumns]="displayedColumnsFacturas" (edit)="editFacturas($event)"  (delete)="OnclickDeleteButton($event)"  (deleteSelected)="deleteSelected($event)" (see)="navigateToFacturas($event)" [options]="optionsFacturas"></dataTable>
  </pill>

</FullContainer>
