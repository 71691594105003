<FullContainer>
  <div class="sectionTitleContainer">
    <h2 class="sectionTitle">OBRAS</h2>
    <toggleSubmenu [elements]="submenuNavegation" [(ngModel)]="submenuSel"></toggleSubmenu>
  </div>
  <ng-container *ngIf="submenuSel == 0">
    <filterActions [filterOpen]="isFilterOpen" [inputOptions]="filterText" [searchOpen]="isSearchOpen"
      (filterOpenChange)="changeFilterOpen($event)" (searchSend)="respuestaBusqueda($event)"
      (searchOpenChange)="changeSearchOpen($event)" (addNew)="anadirNuevaObra($event)">
      <ng-container filterContent>
        <div class="containerSelectores">
          <selector [options]="optionConcepto" (change)="getConceptoFromSelector($event)"></selector>
          <selector [options]="optionCliente" (change)="getClienteFromSelector($event)"></selector>
          <selector [options]="optionEstado" (change)="getEstadoFromSelector($event)"></selector>
        </div>
      </ng-container>
    </filterActions>
    <div class="paginacion">
      <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
      <div class="arrows">
        <ng-container class="arrowLeft">
          <mat-icon (click)="previousPage()">
            chevron_left
          </mat-icon>
        </ng-container>
        <ng-container class="arrowRight">
          <mat-icon (click)="nextPage()">
            chevron_right
          </mat-icon>
        </ng-container>
      </div>
    </div>

    <pill class="tablePil" classList="tablePill">
      <dataTable [forceUpdateWidth]="forceUpdateWidth" [isLoading]="isLoadingTabla" [data]="dataObras" (deleteSelected)="deleteSelected($event)" (delete)="openDeletePopup($event)" [displayedColumns]="displayedColumnsObras"
        [options]="optionsObras" (edit)="openEditPopup($event)" (clickRow)="openObraDetailView($event)"></dataTable>
    </pill>
  </ng-container>
  <ng-container *ngIf="submenuSel == 1">
    <div class="mapSection" [ngStyle]="getStyleMapSection()">
      <div class="map">

        <google-map id="map" class="mapsborders fullWidth fullHeight" [options]="optionsMap" [zoom]="zoom"
        [center]="center">
  
        <map-info-window>
          <div class="infoWindow">
            <div class="ubicacionBlock">
              <div class="icon">
                <ng-container>
                  <mat-icon>
                    location_on
                  </mat-icon>
                </ng-container>
              </div>
              <div class="text">
                <p class="lineaText">{{obraToShow.concepto}}</p>
                </div>
            </div>
            <p class="linea">{{obraToShow.encargadoNombre}}</p>
            <p class="linea">{{obraToShow.ubicacion}}</p>
            <btn classList="fullWidth finisimo ml-24px" (click)="abrirObra(obraToShow.id)">
              <p>Abrir</p>
            </btn>
          </div>
        </map-info-window>
  
        <map-marker #marker="mapMarker" *ngFor="let mapsMark of markers" [id]="mapsMark.title" [title]="mapsMark.title" [position]="mapsMark.position"
          [icon]="mapsMark.icon" [options]="mapsMark.options" 
          (mapClick)="openInfo(marker)">
        </map-marker>
      </google-map>        
      </div>
      <div class="mapInfo">
        <pill classList="fullHeight">
          <div class="filterMapContainer">
            <h4>Filtrar por: </h4>
            <div class="filterMap" [ngClass]="{isFilter: filterMapActive == '1'}" tabindex="0"
              (click)="changeFilterMap('1')" (keyup.enter)="changeFilterMap('1')">
              <i class="material-icons active">place</i>
              <span>Activa</span>
            </div>
            <div class="filterMap" [ngClass]="{isFilter: filterMapActive == '0'}" tabindex="0"
              (click)="changeFilterMap('0')" (keyup.enter)="changeFilterMap('0')">
              <i class="material-icons inactive">place</i>
              <span>Finalizada</span>
            </div>
          </div>
        </pill>
      </div>
    </div>
  </ng-container>



</FullContainer>