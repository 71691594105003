import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { iImagen, iImagenCreate } from 'src/app/Shared/Interfaces/Api/iImagen';

@Injectable({
  providedIn: 'root',
})
export class ImagenService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll() {
    this.http.get(this.urlApi + '/api/imagen', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedImagen = iStaticUtilities.normalizeNamesCollection(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iImagen.convertFromCollection(normalizedImagen),
        });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextResult('resultError', this.res, {
          method: this.getAll,
          args: [],
        });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/imagen/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedImagen = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iImagen.convertFromObject(normalizedImagen),
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(imagen: iImagenCreate) {
    this.http
      .post(this.urlApi + '/api/imagen', imagen, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [imagen],
          });
          this.checkStatusError(error);
        },
      });
  }
  update(imagen: iImagen) {
    this.http
      .put(this.urlApi + '/api/imagen/' + imagen.id, imagen, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [imagen],
          });
          this.checkStatusError(error);
        },
      });
  }
  delete(IDs: Array<number | string>) {
    let idsObjs = this.convertArrayObject(IDs, 'imagens_ids');
    this.http
      .delete(this.urlApi + '/api/imagen', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [IDs],
          });
          this.checkStatusError(error);
        },
      });
  }
}
